import { action, extendObservable } from "mobx";

class HeaderStore {
  constructor() {
    this.defaults = {
      title: "",
      searchTypeId: 3,
      searchText: ""
    };

    extendObservable(this, {
      title: this.defaults["title"],
      searchTypeId: this.defaults["searchTypeId"],
      searchText: this.defaults["searchText"],
      resetStore: action(() => {
        this.title = this.defaults["title"];
        this.searchTypeId = this.defaults["searchTypeId"];
        this.searchText = this.defaults["searchText"];
      })
    });
  }
}

export default HeaderStore;
