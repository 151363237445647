import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestSelect, DangerButton, LightButton, Modal, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";
import _ from "lodash";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { FormSelectStyles } from "../../Theme";
import { PlayerConstants } from "../../constants/PlayerConstants";
import { StatConstants } from "../../constants/StatConstants";
import TitleCard from "../widgets/TitleCard";

const DragHandle = sortableHandle(() => <span className="drag-handle">{"\u28FF"}</span>);
const SortableItem = sortableElement(({ detail, props, onRemove }) => (
  <TitleCard key={"detail_" + props.index} className={"modal-list-row row" + (props.index > 0 ? " mt-1" : "")}>
    <div className="col-12 p-0 d-flex align-items-center justify-content-between">
      <div className="item-row h-inherit">
        <DragHandle />
        <div className="item card-item-separator"></div>
        <span className="item">
          <BestLabel>{detail.objectname}</BestLabel>
        </span>
      </div>

      <DangerButton
        onClick={() => {
          onRemove();
        }}
      >
        Remove
      </DangerButton>
    </div>
  </TitleCard>
));
const SortableContainer = sortableContainer(({ children }) => {
  return <div className="sortable-list">{children}</div>;
});

class StatLineModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stat: { statId: -1 }
    };
  }

  getStats(lookupStore, playerStore) {
    let statCollection = lookupStore.getCollection("stattypes").Statistics;
    let name = "C_" + playerStore.modalStatLine.statlineType;
    let isBatting = PlayerConstants.STAT_LINES.BATTING_CUSTOM === name;
    let isPitching =
      PlayerConstants.STAT_LINES.RELIEF_CUSTOM === name ||
      PlayerConstants.STAT_LINES.STARTING_CUSTOM === name ||
      PlayerConstants.STAT_LINES.SWING_CUSTOM === name;
    let isFielding =
      PlayerConstants.STAT_LINES.CATCHING_CUSTOM === name || PlayerConstants.STAT_LINES.FIELDING_CUSTOM === name;
    let isCatching = PlayerConstants.STAT_LINES.CATCHING_CUSTOM === name;
    let stats = [];
    let addedStatIds = playerStore.modalStatLine.userStatlineDetails.map(detail => detail.statId * 1);

    if (statCollection) {
      statCollection.categories.forEach(category => {
        if (
          (isBatting &&
            (StatConstants.TYPES.BATTING === category.category ||
              StatConstants.TYPES.BATTING_ADV === category.category ||
              StatConstants.TYPES.RUNNING === category.category ||
              StatConstants.TYPES.WINSHARES === category.category)) ||
          (isPitching &&
            (StatConstants.TYPES.PITCHING === category.category ||
              StatConstants.TYPES.PITCHING_ADV === category.category ||
              StatConstants.TYPES.WINSHARES === category.category)) ||
          (isFielding && StatConstants.TYPES.FIELDING === category.category) ||
          (isCatching && StatConstants.TYPES.CATCHING === category.category)
        ) {
          stats = stats.concat(
            _.filter(
              category.stats,
              stat => stat.statListEligible && "NUMERIC" === stat.datatype && addedStatIds.indexOf(stat.statId) === -1
            )
          );
        }
      });
    }

    return _.sortBy(stats, "statDisplayName");
  }

  getDetailRows(playerStore) {
    let rows = [];

    playerStore.modalStatLine.userStatlineDetails.forEach((detail, index) => {
      rows.push(
        <SortableItem
          detail={detail}
          index={index}
          props={{ index: index }}
          onRemove={() => {
            playerStore.modalStatLine.userStatlineDetails.splice(index, 1);
          }}
        />
      );
    });

    return (
      <SortableContainer helperClass="sortable-list" onSortEnd={this.onSortEnd} useDragHandle>
        {rows}
      </SortableContainer>
    );
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    let { playerStore } = this.props.rootStore;
    if (oldIndex !== newIndex) {
      playerStore.modalStatLine.userStatlineDetails.splice(
        newIndex,
        0,
        playerStore.modalStatLine.userStatlineDetails.splice(oldIndex, 1)[0]
      );
    }
  };

  addStat() {
    let { playerStore, piaApi } = this.props.rootStore;

    piaApi.getPlayerStatLineDetailFromStat(this.state.stat, playerStore.modalStatLine.statlineType).then(response => {
      if (response) {
        this.setState({ stat: { statId: -1 } });
        playerStore.modalStatLine.userStatlineDetails.push(response);
      }
    });
  }

  render() {
    let { lookupStore, playerStore } = this.props.rootStore;
    let stats = this.getStats(lookupStore, playerStore);
    return (
      <Modal show={playerStore.statLineModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Edit Stat Line"
          close={() => {
            playerStore.statLineModalOpen = false;
          }}
        />
        <Modal.Body>
          <div className="row modal-section">
            <div className="col-9 pr-0">
              <BestSelect
                name="pslStat"
                value={lookupStore.getDropdownItem(stats, this.state.stat.statId, "statId", "statDisplayName")}
                onChange={option => {
                  this.setState({ stat: option });
                }}
                options={stats}
                getOptionValue={option => option.statId}
                getOptionLabel={option => option.statDisplayName}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3 d-flex flex-fill form-group">
              <PrimaryButton
                className="w-100"
                onClick={() => {
                  this.addStat();
                }}
                disabled={this.state.stat.statId <= -0}
              >
                Add
              </PrimaryButton>
            </div>
          </div>
          <div className="row mx-0 mt-3">
            <div className="col-12">
              <React.Fragment>{this.getDetailRows(playerStore)}</React.Fragment>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            <PrimaryButton
              onClick={() => {
                playerStore.saveStatLine();
              }}
            >
              Ok
            </PrimaryButton>
            <LightButton
              onClick={() => {
                playerStore.openStatLineModal("C_" + playerStore.modalStatLine.statlineType);
              }}
            >
              Reset
            </LightButton>
            <LightButton
              onClick={() => {
                playerStore.statLineModalOpen = false;
              }}
            >
              Cancel
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

StatLineModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(StatLineModal));
