import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { DangerButton, LightButton, PrimaryButton } from "best-common-react";
import HeaderInput from "./HeaderInput";

class EditPlayerListHeaderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false
    };
  }

  render() {
    let { compStore, playerStore } = this.props.rootStore;
    return (
      <div className="d-flex flex-fill justify-content-between align-items-center h-100">
        <div className="p-3">
          <HeaderInput
            className={playerStore.playerNameError === true ? "error" : ""}
            value={playerStore.playerList.playerlistname}
            onChange={event => {
              playerStore.playerList.playerlistname = event.target.value;
            }}
          />
        </div>
        <div className="col-5">
          <div className="item-row">
            {playerStore.playerList.comparisononlyFlg ? (
              <DangerButton
                onClick={() => {
                  compStore.openComp(compStore.comp.comparisonId, {
                    plMasters: [playerStore.playerList],
                    slMasters: compStore.comp.slMasters
                  });
                }}
              >
                Return to Comparison
              </DangerButton>
            ) : (
              ""
            )}
            <PrimaryButton
              onClick={() => {
                playerStore.savePlayerList();
              }}
            >
              Save Player List
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

EditPlayerListHeaderDetails.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(EditPlayerListHeaderDetails));
