import React from "react";
import { inject, observer } from "mobx-react";
import styled, { withTheme } from "styled-components";
import Moment from "moment";
import { BestSelect, Icon } from "best-common-react";
import PropTypes from "prop-types";
import { HeaderSearchStyles, HeaderSearchSelectStyles, HeaderSelectStyles } from "../../Theme";
import CompFinderHeaderDetails from "./CompFinderHeaderDetails";
import CompHeaderDetails from "./CompHeaderDetails";
import EditCompHeaderDetails from "./EditCompHeaderDetails";
import EditPlayerListHeaderDetails from "./EditPlayerListHeaderDetails";
import EditStatHeaderDetails from "./EditStatHeaderDetails";
import EditStatListHeaderDetails from "./EditStatListHeaderDetails";
import ManagePlayerListsHeaderDetails from "./ManagePlayerListsHeaderDetails";
import ManageStatsHeaderDetails from "./ManageStatsHeaderDetails";
import ManageStatListsHeaderDetails from "./ManageStatListsHeaderDetails";
import HeaderTitle from "./HeaderTitle";
import NewsFeedHeaderDetails from "./NewsFeedHeaderDetails";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";
import PlayerProfileHeaderDetails from "./PlayerProfileHeaderDetails";
import SearchBar from "../widgets/SearchBar";
import StatAutocompleteRow from "../autocomplete/StatAutocompleteRow";

const HeaderContainer = styled.div`
  position: fixed;
  width: calc(100% - 240px);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  z-index: 699;
`;

const HeaderOptions = styled.div`
  height: 60px;
  background-color: ${props => props.theme["light-grey"]};
  border-bottom: solid 1px ${props => props.theme.fog};
`;

const HeaderDetails = styled.div.attrs({
  className: "header-details container-fluid align-items-center justify-content-between"
})`
  height: 36px;
  background-color: white;
  [class^="TabSwitcher__ComponentContainer-"],
  [class*=" TabSwitcher__ComponentContainer-"] {
    display: none;
  }
`;

const DropdownButton = styled.button.attrs(() => ({
  type: "button",
  className: "btn btn-sm btn-link",
  id: "dropdownMenuButton best-user-dropdown",
  "data-toggle": "dropdown",
  "aria-haspopup": "true",
  "aria-expanded": "false"
}))`
  &&& {
    text-decoration: none !important;
    color: ${props => props.color};
  }
`;

/*
<NavbarUsernameDropdown username={username}>
                <NavBarSignoutLink onClick={authStore.logout}>Sign Out</NavBarSignoutLink>
              </NavbarUsernameDropdown>
*/

class PiaHeader extends React.Component {
  getCreateNewOptions() {
    return [
      { value: 0, label: "Comparison" },
      { value: 1, label: "Player List" },
      { value: 2, label: "Stat List" },
      { value: 3, label: "Statistic" }
    ];
  }
  getSearchOptions() {
    return [
      { value: 0, label: "Comparisons" },
      { value: 1, label: "Player Lists" },
      { value: 2, label: "Player Queries" },
      { value: 3, label: "Players" },
      { value: 4, label: "Stat Lists" },
      { value: 5, label: "Statistics" }
    ];
  }
  getSearchBar(headerStore) {
    switch (headerStore.searchTypeId) {
      case 0:
        return this.getCompSearchBar(headerStore);
      case 1:
        return this.getPlayerListSearchBar();
      case 2:
        return this.getPlayerQuerySearchBar();
      case 3:
        return this.getPlayerSearchBar();
      case 4:
        return this.getStatListSearchBar();
      case 5:
        return this.getStatSearchBar();
      default:
        return "";
    }
  }
  getCompSearchBar() {
    let { compStore, headerStore } = this.props.rootStore;
    return (
      <SearchBar
        type="text"
        className="header-search"
        onChangeFn={event => {
          headerStore.searchText = event.target.value;
        }}
        onEnterFn={() => {
          compStore.goToCompSearch(headerStore.searchText);
        }}
        value={headerStore.searchText}
        placeholder="search"
      />
    );
  }
  getPlayerListSearchBar() {
    let { headerStore, playerStore } = this.props.rootStore;
    return (
      <SearchBar
        type="text"
        className="header-search"
        onChangeFn={event => {
          headerStore.searchText = event.target.value;
        }}
        onEnterFn={() => {
          playerStore.goToPlayerListSearch(headerStore.searchText);
        }}
        value={headerStore.searchText}
        placeholder="search"
      />
    );
  }
  getPlayerQuerySearchBar() {
    let { headerStore, playerStore } = this.props.rootStore;
    return (
      <SearchBar
        type="text"
        className="header-search"
        onChangeFn={event => {
          headerStore.searchText = event.target.value;
        }}
        onEnterFn={() => {
          playerStore.goToQuerySearch(headerStore.searchText);
        }}
        value={headerStore.searchText}
        placeholder="search"
      />
    );
  }
  getPlayerSearchBar() {
    let { lookupStore, playerStore } = this.props.rootStore;
    return (
      <SearchBar
        options={lookupStore.autocompletePlayers}
        renderSuggestion={suggestion => {
          return <PlayerAutocompleteRow player={suggestion} />;
        }}
        getSuggestionValue={player => {
          return playerStore.getAutocompleteText(player);
        }}
        getSuggestions={playerStore.filterBySearchTerm}
        placeholder="search"
        onEnterFn={event => {
          playerStore.goToPlayerSearch(event.target.value);
        }}
        onSuggestionSelected={(event, { suggestion }) => {
          playerStore.openPlayerProfile(suggestion.playerId);
        }}
        styles={HeaderSearchStyles}
      />
    );
  }
  getStatListSearchBar() {
    let { headerStore, statStore } = this.props.rootStore;
    return (
      <SearchBar
        type="text"
        className="header-search"
        onChangeFn={event => {
          headerStore.searchText = event.target.value;
        }}
        onEnterFn={() => {
          statStore.goToStatListSearch(headerStore.searchText);
        }}
        value={headerStore.searchText}
        placeholder="search"
      />
    );
  }
  getStatSearchBar() {
    let { lookupStore, statStore } = this.props.rootStore;
    return (
      <SearchBar
        options={lookupStore.autocompleteStats}
        renderSuggestion={suggestion => {
          return <StatAutocompleteRow stat={suggestion} />;
        }}
        getSuggestionValue={stat => {
          return statStore.getAutocompleteText(stat);
        }}
        getSuggestions={(stats, searchTerm) => {
          return statStore.filterBySearchTerm(stats, searchTerm);
        }}
        placeholder="search"
        onEnterFn={event => {
          statStore.goToStatSearch(event.target.value);
        }}
        onSuggestionSelected={(event, { suggestion }) => {
          statStore.openStat(suggestion.statId);
        }}
        styles={HeaderSearchStyles}
      />
    );
  }

  getDayOfSeasonText(lookupStore) {
    let cal = lookupStore.getCollection("bballcalendar");
    let currentDate = Moment(new Date()).startOf("day");
    let currentYear = currentDate.year();
    let text = "";

    for (let year in cal) {
      if (year == currentYear) {
        let currentCal = cal[year];

        if (currentDate.isBefore(currentCal.springtrainingstartDte)) {
          // Pre spring training
          text =
            Moment(currentCal.springtrainingstartDte)
              .startOf("day")
              .diff(currentDate, "days") + " Days until Spring Training";
        } else if (currentDate.isBefore(currentCal.firstdayofseasonDte)) {
          // Pre opening day
          text =
            Moment(currentCal.firstdayofseasonDte)
              .startOf("day")
              .diff(currentDate, "days") + " Days until Opening Day";
        } else if (currentDate.isBefore(currentCal.lastdayofseasonDte)) {
          // Pre opening day
          text =
            "Day " +
            Moment(currentDate).diff(Moment(currentCal.firstdayofseasonDte).startOf("day"), "days") +
            " of the Major League Championship Season";
        } else {
          // After season is over - use next years calendar
          let nextCal = cal[year * 1 + 1];

          if (!!nextCal) {
            text = Moment(nextCal.springtrainingstartDte).diff(currentDate, "days") + " Days until Spring Training";
          }
        }

        break;
      }
    }

    return text;
  }

  getHeaderDetails(routerStore) {
    if (routerStore.isComparisonsPage) {
      return <CompHeaderDetails />;
    } else if (routerStore.isCompFinderPage) {
      return <CompFinderHeaderDetails />;
    } else if (routerStore.isEditComparisonPage) {
      return <EditCompHeaderDetails />;
    } else if (routerStore.isEditPlayerListPage) {
      return <EditPlayerListHeaderDetails />;
    } else if (routerStore.isEditStatisticPage) {
      return <EditStatHeaderDetails />;
    } else if (routerStore.isEditStatListPage) {
      return <EditStatListHeaderDetails />;
    } else if (routerStore.isMyNewsFeedPage) {
      return <NewsFeedHeaderDetails />;
    } else if (routerStore.isPlayerListsPage) {
      return <ManagePlayerListsHeaderDetails />;
    } else if (routerStore.isPlayerProfilePage) {
      return <PlayerProfileHeaderDetails />;
    } else if (routerStore.isStatisticsPage) {
      return <ManageStatsHeaderDetails />;
    } else if (routerStore.isStatListsPage) {
      return <ManageStatListsHeaderDetails />;
    } else {
      return <HeaderTitle />;
    }
  }
  render() {
    const {
      authStore,
      compStore,
      headerStore,
      lookupStore,
      playerStore,
      routerStore,
      statStore,
      trackingHelper
    } = this.props.rootStore;
    let createNewOptions = this.getCreateNewOptions();
    let searches = this.getSearchOptions();
    return (
      <HeaderContainer>
        <HeaderOptions className="container-fluid">
          <div className="row h-100 align-items-center">
            <div className="d-block d-xl-flex col-md-10 col-xl-9 pr-xl-0">
              <div className="d-flex col-xl-9 pr-xl-1">
                <div className="mr-3 ml-3 header-btn">
                  <BestSelect
                    name="createNew"
                    value={-1}
                    onChange={option => {
                      trackingHelper.track("Create New " + option.label);
                      switch (option.value) {
                        case 0:
                          compStore.openComp(0);
                          break;
                        case 1:
                          playerStore.openPlayerList(0);
                          break;
                        case 2:
                          statStore.openStatList(0);
                          break;
                        case 3:
                          statStore.openStat(0);
                          break;
                        default:
                          break;
                      }
                    }}
                    options={createNewOptions}
                    placeholder="Create New"
                    styles={HeaderSelectStyles}
                  />
                </div>

                <div className="header-btn">
                  <BestSelect
                    name="searchType"
                    value={lookupStore.getDropdownItem(searches, headerStore.searchTypeId)}
                    onChange={option => {
                      headerStore.searchTypeId = option.value;
                    }}
                    options={searches}
                    styles={HeaderSearchSelectStyles}
                  />
                </div>

                <div className="col-md-11 flex-fill pl-0 pr-xl-0">
                  <div className="row m-0 col-12 p-0">{this.getSearchBar(headerStore)}</div>
                </div>
              </div>
              <div className="pl-3 ml-3 pl-xl-0 ml-xl-0 pl-xl-0 my-auto">{this.getDayOfSeasonText(lookupStore)}</div>
            </div>

            <div className="col-md-2 col-xl-3 d-flex pl-0 pr-0 justify-content-end">
              <div>
                <span className="user-header">
                  <DropdownButton>
                    <span className="link top-level">
                      {authStore.userData ? authStore.userData.username : ""}
                      <Icon className="caret" iconName="fa-sort-down" />
                    </span>
                  </DropdownButton>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <span className="dropdown-item link">
                      <a className="feedback" href="mailto:piasupport@mlb.com?subject=PIA+ Feedback">
                        Feedback
                      </a>
                    </span>
                    <span
                      className="dropdown-item logout link"
                      onClick={() => {
                        authStore.logout();
                      }}
                    >
                      Log Out
                    </span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </HeaderOptions>
        <HeaderDetails>{this.getHeaderDetails(routerStore)}</HeaderDetails>
      </HeaderContainer>
    );
  }
}

PiaHeader.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(PiaHeader)));
