import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

class StatAutocompleteRow extends React.Component {
  render() {
    let { stat } = this.props;
    let { statStore } = this.props.rootStore;
    return <div>{statStore.getAutocompleteText(stat)}</div>;
  }
}

StatAutocompleteRow.propTypes = {
  rootStore: PropTypes.object.isRequired,
  stat: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(StatAutocompleteRow));
