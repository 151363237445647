import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { CardContainer, DateTimeFormatter } from "best-common-react";
import PiaTable from "../widgets/PiaTable";

class News extends React.Component {
  getCols() {
    return [
      {
        key: "truncatedArticleName",
        name: "Article",
        formatter: row => (
          <a
            href="/#"
            onClick={event => {
              event.preventDefault();
              window.open(row.row.url);
            }}
          >
            <span>{row.value}</span>
          </a>
        )
      },
      {
        key: "articleDtstmp",
        name: "Release Date",
        width: 100,
        formatter: row => <DateTimeFormatter value={row.value} />
      },
      { key: "source", name: "Souce", width: 150 },
      { key: "author", name: "Byline", width: 250 }
    ];
  }

  render() {
    let { playerStore } = this.props.rootStore;
    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="container-fluid">
            <div className="row mx-0">
              <div className="col-12 p-0">
                {playerStore.player.news ? (
                  <PiaTable
                    data={playerStore.player.news}
                    columns={this.getCols(playerStore)}
                    height={playerStore.calculateTableHeight(playerStore.player.news.length, 336)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </CardContainer.Body>
      </CardContainer>
    );
  }
}

News.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(News));
