import React from "react";
import { inject, observer } from "mobx-react";
import { LightButton, Modal, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";

class OverrideModal extends React.Component {
  render() {
    let { confirmText, cancelText, isOpen, onConfirm, onCancel, text, title } = this.props;
    return (
      <Modal show={isOpen} size="lg">
        <Modal.HeaderWithTitleAndClose title={title} close={onCancel} />
        <Modal.Body>
          <div className="row">
            <div className="col-12">{text}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 col-12 p-0 justify-content-between">
            <PrimaryButton className="flex-fill" onClick={onConfirm}>
              {confirmText || "Yes"}
            </PrimaryButton>
            <LightButton className="flex-fill" onClick={onCancel}>
              {cancelText || "No"}
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

OverrideModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string
};

export default inject("rootStore")(observer(OverrideModal));
