import { action, autorun, extendObservable } from "mobx";
import { AlertConstants } from "../../constants/AlertConstants";
import WidgetStore from "../WidgetStore";

class AdminStore extends WidgetStore {
  constructor(authStore, routerStore, alertStore, piaApi) {
    super();
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.alertStore = alertStore;
    this.piaApi = piaApi;

    this.defaults = {
      playerIdString: ""
    };

    extendObservable(this, {
      playerIdString: this.defaults["playerIdString"],
      resetStore: action(() => {
        this.playerIdString = this.defaults["playerIdString"];
      })
    });

    autorun(() => {
      if (!this.authStore.loggedIn) {
        this.resetStore();
      }
    });
  }

  loadPlayerCache() {
    // Split string and filter empty entries
    let playerIds = this.playerIdString.split(",").filter(id => id);
    // load player cache with ids
    this.piaApi.loadPlayerCache(playerIds).then(response => {
      if (!!response || 0 === response) {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The player cache batch has run successfully - " + response + " players have been updated."
        });
      }
    });
  }

  loadContractDetails() {
    // Split string and filter empty entries
    let playerIds = this.playerIdString.split(",").filter(id => id);
    // load contract details with ids
    this.piaApi.loadContractDetails(playerIds).then(response => {
      if (!!response || 0 === response) {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text:
            "The contract details batch has run successfully - contracts for " +
            response +
            " players have been updated."
        });
      }
    });
  }

  syncStats() {
    this.piaApi.syncStats().then(response => {
      if (!!response || 0 === response) {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The stat sync batch has run successfully - " + response + " stats have been updated."
        });
      }
    });
  }
}

export default AdminStore;
