import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestSelect, Checkbox, Modal, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { FormSelectStyles } from "../../Theme";

class ImportPlayersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      step1Completed: false,
      file: null,
      importBean: {
        playerImportHeadingsFlg: false,
        idCol: "-1",
        idTypeCol: "pia",
        yearCol: "-1",
        rows: []
      }
    };
  }

  renderStep1Body() {
    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-12">
            <Dropzone
              accept={[".xls", ".xlsx"]}
              onDrop={(accepted, rejected) => {
                if (accepted && accepted.length > 0) {
                  this.setState({ file: accepted[0] });
                }
              }}
              noClick
              noDragEventsBubbling
            >
              {({ getRootProps, getInputProps, open }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />

                  <div className="row flex-fill align-items-center">
                    <div className="col-2 flex-fill">
                      <PrimaryButton onClick={() => open()}>Browse</PrimaryButton>
                    </div>
                    <div className="col-10">
                      <span>{this.state.file ? this.state.file.name : "No file selected"}</span>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Checkbox
              id="playerImportHeadingsFlg"
              label="Use first row as column names"
              onChange={event => {
                this.setState({ importBean: { playerImportHeadingsFlg: event.target.checked } });
              }}
              checked={this.state.importBean.playerImportHeadingsFlg}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderStep1Buttons() {
    return (
      <PrimaryButton
        onClick={() => {
          let { piaApi } = this.props.rootStore;
          let formData = new FormData();
          formData.append("file", this.state.file);
          piaApi.parsePlayerList(formData, this.state.importBean.playerImportHeadingsFlg).then(response => {
            if (response) {
              this.setState({ importBean: response, step1Completed: true });
            }
          });
        }}
        disabled={!this.state.file}
      >
        Upload
      </PrimaryButton>
    );
  }

  getIdColOptions() {
    let options = [];

    if (this.state.importBean.rows && this.state.importBean.rows.length > 0) {
      options = this.state.importBean.rows[0];
    }

    return options;
  }

  getYearColOptions(idCols) {
    let options = [{ columnIndex: "-1", columnHeading: "Use Last Platform Year" }];

    if (idCols) {
      options = options.concat(idCols);
    }

    return options;
  }

  renderStep2Body() {
    let { lookupStore } = this.props.rootStore;
    let idTypes = [
      { value: "pia", label: "PIA" },
      { value: "ebis", label: "eBIS" },
      { value: "bam", label: "MLB" }
    ];
    let idCols = this.getIdColOptions();
    let yearCols = this.getYearColOptions(idCols);

    return (
      <React.Fragment>
        <div className="row mb-2 col-12">
          <span>
            Choose the column of the spreadsheet to be used as the ID and (optionally) the year. For reference, the list
            of columns and sample values is displayed below.
          </span>
        </div>
        <div className="row mb-2">
          <div className="col-5">
            <div className="form-group">
              <BestLabel>ID Column</BestLabel>
              <BestSelect
                name="idCol"
                value={lookupStore.getDropdownItem(
                  idCols,
                  this.state.importBean.idCol * 1,
                  "columnIndex",
                  "columnHeading"
                )}
                onChange={option => {
                  this.setState(state => {
                    state.importBean.idCol = option.columnIndex + "";
                    return { importBean: state.importBean };
                  });
                }}
                options={idCols}
                getOptionValue={option => option.columnIndex}
                getOptionLabel={option => option.columnHeading}
                placeholder="Choose an ID"
                styles={FormSelectStyles}
              />
            </div>
          </div>

          <div className="col-2">
            <div className="form-group">
              <BestLabel>ID Type</BestLabel>
              <BestSelect
                name="idType"
                value={lookupStore.getDropdownItem(idTypes, this.state.importBean.idTypeCol)}
                onChange={option => {
                  this.setState(state => {
                    state.importBean.idTypeCol = option.value;
                    return { importBean: state.importBean };
                  });
                }}
                options={idTypes}
                styles={FormSelectStyles}
              />
            </div>
          </div>

          <div className="col-5">
            <div className="form-group">
              <BestLabel>Year Column</BestLabel>
              <BestSelect
                name="yearCol"
                value={lookupStore.getDropdownItem(
                  yearCols,
                  this.state.importBean.yearCol * 1,
                  "columnIndex",
                  "columnHeading"
                )}
                onChange={option => {
                  this.setState(state => {
                    state.importBean.yearCol = option.columnIndex + "";
                    return { importBean: state.importBean };
                  });
                }}
                options={yearCols}
                getOptionValue={option => option.columnIndex}
                getOptionLabel={option => option.columnHeading}
                styles={FormSelectStyles}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderStep2Buttons() {
    let { playerStore, piaApi } = this.props.rootStore;
    return (
      <PrimaryButton
        onClick={() => {
          this.setState(state => {
            state.importBean.currentPlayerList = playerStore.playerList;
            return { importBean: state.importBean };
          });

          piaApi.importPlayerList(this.state.importBean).then(response => {
            if (response) {
              playerStore.playerList = response;
              this.close(playerStore);
            }
          });
        }}
      >
        Upload
      </PrimaryButton>
    );
  }

  close(playerStore) {
    this.setState(this.getDefaultState());
    playerStore.playerList.importPlayersModalOpen = false;
  }

  render() {
    let { playerStore } = this.props.rootStore;

    return (
      <Modal show={!!playerStore.playerList.importPlayersModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Import Players"
          close={() => {
            this.close(playerStore);
          }}
        />
        <Modal.Body>{this.state.step1Completed ? this.renderStep2Body() : this.renderStep1Body()}</Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            {this.state.step1Completed ? this.renderStep2Buttons() : this.renderStep1Buttons()}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

ImportPlayersModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ImportPlayersModal));
