import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  BestLabel,
  BestSelect,
  DataTable,
  LightButton,
  DangerButton,
  PrimaryButton,
  Checkbox,
  DateTimeFormatter,
  FormInput,
  Icon
} from "best-common-react";
import { FormSelectStyles } from "../../Theme";
import { CompConstants } from "../../constants/CompConstants";
import SelectTimeFrame from "./SelectTimeFrame";
import TitleCard from "../widgets/TitleCard";
import OverrideModal from "../../components/alerts/OverrideModal";

class TimeFrames extends React.Component {
  getTfTypeOptions(lookupStore) {
    let list = lookupStore.getCollection("timeframetypes");
    list = _.filter(list, type => this.props.showContract || type.description !== "Contract Length");
    return list;
  }

  getFromToTypeOptions() {
    return [
      { value: "Platform", label: "Platform Year" },
      { value: "Delta-", label: "Platform Year Minus" },
      { value: "Delta+", label: "Platform Year Plus" },
      { value: "Year", label: "Year" }
    ];
  }

  updateTypes(tfDetail, option) {
    if (tfDetail && tfDetail !== null) {
      let fromType = "Platform";
      let toType = "Platform";

      if ("Multiple Years" === option.description) {
        fromType = "Delta-";
      } else if ("Date Range" === option.description) {
        fromType = "DateRange";
        toType = "DateRange";
      }

      tfDetail.luTimeframetype = option;
      tfDetail.fromType = fromType;
      tfDetail.toType = toType;
    }
  }

  getConstantOptions() {
    let list = [];

    for (let i = 1; i <= 50; i++) {
      list.push({ value: i, label: i });
    }

    return list;
  }

  hasDefaultTf(tab) {
    let { compStore } = this.props.rootStore;
    let result = false;
    compStore.compTimeFrames.forEach(tf => {
      if (tf.timeframeId !== -1 && tf.timeframeId !== -2) {
        let flagToCheck = tf.oneOnOneDefaultFlg;
        if (tab === "StatSummary") {
          flagToCheck = tf.statSummaryDefaultFlg;
        }

        if (flagToCheck === true) {
          result = true;
        }
      }
    });

    return result;
  }

  defaultBoxChecked(timeFrames) {
    if (CompConstants.TABS.ONE_ON_ONE === this.props.tab) {
      return !!timeFrames[this.props.tab].oneOnOneDefaultFlg || timeFrames[this.props.tab].timeframeId === -1;
    } else if (CompConstants.TABS.STAT_SUMMARY === this.props.tab) {
      return !!timeFrames[this.props.tab].statSummaryDefaultFlg || timeFrames[this.props.tab].timeframeId === -1;
    }
  }

  getDetailCols() {
    let { compStore } = this.props.rootStore;
    return [
      {
        key: "tfname",
        name: "TF Name",
        formatter: row => {
          return (
            <span className="d-flex justify-content-between">
              <span>{row.row.luTimeframetype.description}</span>
              <span className="d-flex justify-content-end">
                {"Career" !== row.row.luTimeframetype.description && (
                  <span className="tf-range">
                    {row.row.genericFrom}
                    {"-" !== row.row.genericThrough && (
                      <React.Fragment>
                        <span className="tf-range-separator">&nbsp;through&nbsp;</span>
                        {row.row.genericThrough}
                      </React.Fragment>
                    )}
                  </span>
                )}

                {compStore.comp.reportCriteria.timeFrames[this.props.tab] && (
                  <span className="ml-3">
                    <Icon
                      className="mlb-red-icon"
                      iconName="fa-trash-alt"
                      onClick={() => {
                        let timeFrames = compStore.comp.reportCriteria.timeFrames;
                        let index = timeFrames[this.props.tab].tfDetail.indexOf(row.row);

                        if (index > -1) {
                          let removed = timeFrames[this.props.tab].tfDetail.splice(index, 1);
                          timeFrames[this.props.tab].deletedDetails.push(removed[0]);
                        }
                      }}
                    />
                  </span>
                )}
              </span>
            </span>
          );
        }
      }
    ];
  }
  renderTable() {
    let { compStore, lookupStore, piaApi } = this.props.rootStore;
    let timeFrames = compStore.comp.reportCriteria.timeFrames;
    let tfTypes = this.getTfTypeOptions(lookupStore);
    let tfDetail = compStore.tfDetail;
    let tfLkup = compStore.compTimeFrames;

    if (CompConstants.TABS.ONE_ON_ONE === this.props.tab) {
      tfLkup = tfLkup.filter(tf => tf.timeframereporttypeLk && tf.timeframereporttypeLk.timeframereporttypeLk === "1");
    } else {
      tfLkup = tfLkup.filter(tf => tf.timeframereporttypeLk && tf.timeframereporttypeLk.timeframereporttypeLk === "3");
    }

    return (
      <React.Fragment>
        <div>
          <span className="item d-flex align-items-center">
            <BestLabel className="mr-2">Time Frame List</BestLabel>
            {(timeFrames[this.props.tab].timeframeId !== -1 && timeFrames[this.props.tab].timeframeId !== -2) ||
            this.hasDefaultTf(this.props.tab) === false ? (
              <Checkbox
                id="tfDefaultFlg"
                label="Default"
                disabled={timeFrames[this.props.tab].timeframeId === -1}
                onChange={event => {
                  if (CompConstants.TABS.ONE_ON_ONE === this.props.tab) {
                    timeFrames[this.props.tab].oneOnOneDefaultFlg = !timeFrames[this.props.tab].oneOnOneDefaultFlg;
                  } else if (CompConstants.TABS.STAT_SUMMARY === this.props.tab) {
                    timeFrames[this.props.tab].statSummaryDefaultFlg = !timeFrames[this.props.tab]
                      .statSummaryDefaultFlg;
                  }
                }}
                checked={this.defaultBoxChecked(timeFrames)}
              />
            ) : (
              <PrimaryButton
                className="ml-1 mb-1"
                onClick={() => {
                  piaApi.resetTfDefaults(this.props.tab).then(response => {
                    compStore.postResetTfActions();
                  });
                }}
              >
                Reset To Default
              </PrimaryButton>
            )}
          </span>

          <div className="d-flex">
            <div className="col-7 pl-0 form-inline">
              <div className="item flex-fill">
                <BestSelect
                  name="tfTimeFrames"
                  value={lookupStore.getDropdownItem(
                    tfLkup,
                    timeFrames[this.props.tab].timeframeId,
                    "timeframeId",
                    "timeframeName"
                  )}
                  onChange={option => {
                    if (-1 === option.timeframeId || -2 === option.timeframeId) {
                      if (CompConstants.TABS.ONE_ON_ONE === this.props.tab) {
                        timeFrames[this.props.tab] = _.cloneDeep(
                          compStore.compTimeFrames.filter(tf => {
                            return tf.timeframeId === -1;
                          })[0]
                        );
                      } else if (CompConstants.TABS.STAT_SUMMARY === this.props.tab) {
                        timeFrames[this.props.tab] = _.cloneDeep(
                          compStore.compTimeFrames.filter(tf => {
                            return tf.timeframeId === -2;
                          })[0]
                        );
                      }
                      //timeFrames[this.props.tab].timeframeId = -1;
                    } else {
                      timeFrames[this.props.tab] = option;
                    }

                    if (CompConstants.TABS.ONE_ON_ONE === this.props.tab) {
                      let i = compStore.comp.cmpTimeframeMasters
                        .map(tf => (tf.timeframereporttypeLk ? tf.timeframereporttypeLk.timeframereporttypeLk : "-1"))
                        .indexOf("1");
                      compStore.comp.cmpTimeframeMasters[i] = option;
                    } else {
                      let i = compStore.comp.cmpTimeframeMasters
                        .map(tf => (tf.timeframereporttypeLk ? tf.timeframereporttypeLk.timeframereporttypeLk : "-1"))
                        .indexOf("3");
                      compStore.comp.cmpTimeframeMasters[i] = option;
                    }
                  }}
                  options={tfLkup}
                  getOptionValue={option => option.timeframeId}
                  getOptionLabel={option => option.timeframeName}
                  placeholder="Time Frame"
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>

            <div className="col-5 pr-0 d-flex justify-content-end">
              <PrimaryButton
                className="mr-2"
                onClick={() => {
                  let tab = this.props.tab;
                  this.save(timeFrames[tab], tab);
                }}
                disabled={timeFrames[this.props.tab].timeframeId <= 0}
              >
                Save List
              </PrimaryButton>
              <DangerButton
                onClick={() => {
                  compStore.showTimeframeDelete = true;
                }}
                disabled={timeFrames[this.props.tab].timeframeId <= 0}
              >
                Delete List
              </DangerButton>
            </div>
          </div>

          <div className="d-flex justify-content-start align-items-center">
            <div className="col-3 pl-0">
              <BestLabel>Range</BestLabel>
              <BestSelect
                name="stfType"
                value={lookupStore.getDropdownItem(
                  tfTypes,
                  compStore.tfDetail.luTimeframetype.timeframetypeLk,
                  "timeframetypeLk",
                  "description"
                )}
                onChange={option => {
                  this.updateTypes(compStore.tfDetail, option);
                }}
                options={tfTypes}
                getOptionValue={option => option.timeframetypeLk}
                getOptionLabel={option => option.description}
                placeholder="Type"
                portal={document.body}
                styles={FormSelectStyles}
              />
            </div>

            {compStore.tfDetail.luTimeframetype.timeframetypeLk === "1" &&
              this.renderSingleMultipleTfDropdowns("From", true)}
            {compStore.tfDetail.luTimeframetype.timeframetypeLk === "1" &&
              ((compStore.tfDetail.fromType === "Year" && this.renderYear(true)) ||
                (compStore.tfDetail.fromType &&
                  compStore.tfDetail.fromType.indexOf("Delta") > -1 &&
                  this.renderDelta(true)))}

            {compStore.tfDetail.luTimeframetype.timeframetypeLk === "3" && this.renderCareerDropdowns()}
            {compStore.tfDetail.luTimeframetype.timeframetypeLk === "3" &&
              ((compStore.tfDetail.toType === "Year" && this.renderYear(false)) ||
                (compStore.tfDetail.toType &&
                  compStore.tfDetail.toType.indexOf("Delta") > -1 &&
                  this.renderDelta(false)))}

            {compStore.tfDetail.luTimeframetype.timeframetypeLk === "4" && this.renderDate("From", true)}
            {compStore.tfDetail.luTimeframetype.timeframetypeLk === "4" && this.renderDate("To", false)}

            <div className={this.getAddButtonClass() + " pr-0 d-flex justify-content-end mt-4"}>
              {compStore.tfDetail.luTimeframetype.timeframetypeLk &&
                compStore.tfDetail.luTimeframetype.timeframetypeLk !== null && (
                  <LightButton
                    onClick={() => {
                      let row = _.cloneDeep(compStore.tfDetail);
                      let tf = timeFrames[this.props.tab];
                      row.id.detailId = tf.tfDetail.length + 1;

                      piaApi.validateComparisonTimeFrameDetail(row).then(response => {
                        if (!!response) {
                          timeFrames[this.props.tab].tfDetail.push(response);
                        }
                      });
                    }}
                  >
                    Add
                  </LightButton>
                )}
            </div>
          </div>

          {compStore.tfDetail.luTimeframetype.timeframetypeLk === "2" ? (
            <div className="d-flex justify-content-start align-items-center">
              {this.renderSingleMultipleTfDropdowns("From", true)}
              {compStore.tfDetail.fromType === "Year" ? this.renderYear(true) : this.renderDelta(true)}
            </div>
          ) : (
            ""
          )}

          {compStore.tfDetail.luTimeframetype.timeframetypeLk === "2" ? (
            <div className="d-flex justify-content-start align-items-center">
              {this.renderSingleMultipleTfDropdowns("To", false)}
              {compStore.tfDetail.toType === "Year" ? this.renderYear(false) : this.renderDelta(false)}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="row mx-0 mt-4">
          <div className="col-12 p-0 no-grid-header">
            <DataTable
              data={timeFrames[this.props.tab].tfDetail}
              columns={this.getDetailCols()}
              headerRowHeight={-1}
              rowHeight={28}
              height={compStore.calculateTableHeight(timeFrames[this.props.tab].tfDetail.length, 280, false)}
            />
          </div>
          <div className="col-12 d-flex mt-2 justify-content-end">
            <LightButton
              className="mr-2"
              onClick={() => {
                timeFrames[this.props.tab].deletedDetails = [];
                compStore.openTimeFrameModal(this.props.tab);
              }}
              disabled={false}
            >
              New List
            </LightButton>
          </div>
        </div>
      </React.Fragment>
    );
  }

  getAddButtonClass() {
    let { compStore, lookupStore } = this.props.rootStore;
    switch (compStore.tfDetail.luTimeframetype.timeframetypeLk) {
      case "1":
        if (
          compStore.tfDetail.fromType === "Year" ||
          (compStore.tfDetail.fromType && compStore.tfDetail.fromType.indexOf("Delta") > -1)
        ) {
          return "col-3";
        } else {
          return "col-6";
        }
        break;

      case "2":
        return "col-9";
        break;

      case "3":
        if (
          compStore.tfDetail.toType === "Year" ||
          (compStore.tfDetail.toType && compStore.tfDetail.toType.indexOf("Delta") > -1)
        ) {
          return "col-3";
        } else {
          return "col-6";
        }
        break;

      case "4":
        return "col-3";
        break;

      default:
        break;
    }
  }

  renderSingleMultipleTfDropdowns(label, isFrom) {
    let { compStore, lookupStore } = this.props.rootStore;
    let fromToTypes = this.getFromToTypeOptions();
    return (
      <div className={"col-3" + (compStore.tfDetail.luTimeframetype.timeframetypeLk === "2" ? " pl-0" : "")}>
        <BestLabel>{label}</BestLabel>
        <BestSelect
          name={"tf" + label}
          options={fromToTypes}
          value={lookupStore.getDropdownItem(
            fromToTypes,
            isFrom ? compStore.tfDetail.fromType : compStore.tfDetail.toType
          )}
          onChange={option => {
            if (isFrom) {
              compStore.tfDetail.fromType = option.value;
            } else {
              compStore.tfDetail.toType = option.value;
            }
          }}
          placeholder="From Type"
          portal={document.body}
          styles={FormSelectStyles}
        />
      </div>
    );
  }

  renderCareerDropdowns() {
    let { compStore, lookupStore } = this.props.rootStore;
    let fromToTypes = this.getFromToTypeOptions();
    return (
      <div className="col-3">
        <BestLabel>To</BestLabel>
        <BestSelect
          name="stfToType"
          value={lookupStore.getDropdownItem(fromToTypes, compStore.tfDetail.toType)}
          onChange={option => {
            compStore.tfDetail.toType = option.value;
          }}
          options={fromToTypes}
          placeholder="To Type"
          portal={document.body}
          styles={FormSelectStyles}
        />
      </div>
    );
  }

  renderDelta(isFrom) {
    let { compStore, lookupStore } = this.props.rootStore;
    let constantVals = this.getConstantOptions();
    return (
      <div className="col-3">
        <BestLabel></BestLabel>
        <BestSelect
          name="stfFrompyConstant"
          value={lookupStore.getDropdownItem(
            constantVals,
            isFrom ? compStore.tfDetail.frompyConstant : compStore.tfDetail.topyConstant
          )}
          onChange={option => {
            if (isFrom) {
              compStore.tfDetail.frompyConstant = option.value;
            } else {
              compStore.tfDetail.topyConstant = option.value;
            }
          }}
          options={constantVals}
          placeholder="Select a value..."
          portal={document.body}
          styles={FormSelectStyles}
        />
      </div>
    );
  }

  renderYear(isFrom) {
    let { compStore, lookupStore, commonStore } = this.props.rootStore;
    let years = commonStore.getListOfYears(1871);
    return (
      <div className="col-3">
        <BestLabel></BestLabel>
        <BestSelect
          name="stfToYear"
          value={lookupStore.getDropdownItem(years, isFrom ? compStore.tfDetail.fromYear : compStore.tfDetail.toYear)}
          onChange={option => {
            if (isFrom) {
              compStore.tfDetail.fromYear = option.value;
            } else {
              compStore.tfDetail.toYear = option.value;
            }
          }}
          options={years}
          placeholder="Select a value..."
          portal={document.body}
          styles={FormSelectStyles}
        />
      </div>
    );
  }

  renderDate(label, isFrom) {
    let { compStore, lookupStore, commonStore } = this.props.rootStore;
    return (
      <div className="col-3">
        <FormInput
          name={"stf" + label + "Date"}
          label={label}
          type="date"
          className="mb-0"
          value={isFrom ? compStore.tfDetail.fromDate : compStore.tfDetail.toDate}
          onChangeFn={date => {
            if (isFrom === true) {
              compStore.tfDetail.fromDate = date;
            } else {
              compStore.tfDetail.toDate = date;
            }
          }}
          formatter={DateTimeFormatter}
          editable
        />
      </div>
    );
  }

  save(timeframe, tab) {
    let { compStore } = this.props.rootStore;
    compStore.saveTimeFrame(timeframe, tab);
  }

  renderSelect() {
    let { compStore } = this.props.rootStore;
    return (
      <div className="col-6 p-0">
        <SelectTimeFrame tfDetail={compStore.comp.reportCriteria.timeFrames[this.props.tab].tfDetail[0]} />
      </div>
    );
  }
  render() {
    let { compStore, lookupStore } = this.props.rootStore;
    let timeFrames = compStore.comp.reportCriteria.timeFrames;
    let tfText = lookupStore.getDropdownItem(
      compStore.compTimeFrames,
      timeFrames[this.props.tab].timeframeId,
      "timeframeId",
      "timeframeName"
    );

    return (
      <div className="col-6 pl-0">
        <div className="row mx-0 mb-4">
          <div className="col-12 p-0">
            <OverrideModal
              isOpen={!!compStore.showTimeframeDelete}
              title="Delete List"
              text={
                <React.Fragment>
                  Are you sure you want to delete the following list?
                  <br />
                  <br />
                  <br /> {tfText && tfText.timeframeName}
                </React.Fragment>
              }
              onConfirm={() => {
                compStore.modalTimeFrame = _.cloneDeep(timeFrames[this.props.tab]);
                compStore.deleteTimeFrame(this.props.tab);
              }}
              onCancel={() => {
                compStore.showTimeframeDelete = undefined;
              }}
            />

            <TitleCard>
              <span>Time Frames</span>
            </TitleCard>
          </div>
        </div>
        {CompConstants.TABS.ONE_ON_ONE === this.props.tab || CompConstants.TABS.STAT_SUMMARY === this.props.tab
          ? this.renderTable()
          : this.renderSelect()}
      </div>
    );
  }
}

TimeFrames.propTypes = {
  rootStore: PropTypes.object.isRequired,
  tab: PropTypes.string.isRequired,
  showContract: PropTypes.bool
};

export default inject("rootStore")(observer(TimeFrames));
