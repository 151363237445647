import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import CompTabs from "../../components/comparisons/CompTabs";
import EditCompLists from "../../components/comparisons/EditCompLists";
import FutureYearSubstitutionModal from "../../components/comparisons/FutureYearSubstitutionModal";
import OverrideModal from "../../components/alerts/OverrideModal";
import PiaWidget from "../../components/widgets/PiaWidget";
import { RouteConstants } from "../../constants/RouteConstants";

class EditCompContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.EDIT_COMPARISON.title);
  }

  render() {
    let { compStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <OverrideModal
          isOpen={!!compStore.comp.showOverride}
          title="Warning"
          text={compStore.comp.overrideMessage || ""}
          confirmText="Run"
          cancelText="Cancel"
          onConfirm={() => {
            if (compStore.comp.overrideFn) {
              compStore.comp.overrideFn();
            }
          }}
          onCancel={() => {
            compStore.comp.showOverride = undefined;
          }}
        />
        <FutureYearSubstitutionModal />
        <div className="container-fluid">
          <div className="col-12 p-0">
            <EditCompLists />
          </div>
          <PiaWidget className="tab-container col-12 mb-4">
            <CompTabs />
          </PiaWidget>
        </div>
      </React.Fragment>
    );
  }
}

EditCompContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(EditCompContainer));
