import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import CannedReports from "../../components/reports/CannedReports";
import { RouteConstants } from "../../constants/RouteConstants";

class CannedReportContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.CANNED_REPORTS.title);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="col-12 p-0 mb-4">
          <CannedReports />
        </div>
      </div>
    );
  }
}

CannedReportContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CannedReportContainer));
