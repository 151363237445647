import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import ManageComps from "../../components/comparisons/ManageComps";
import ManageFoldersModal from "../../components/common/ManageFoldersModal";
import { RouteConstants } from "../../constants/RouteConstants";

class CompContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.COMPARISONS.title);
  }

  render() {
    let { compStore, piaApi } = this.props.rootStore;
    return (
      <div className="container-fluid">
        <div className="col-12 p-0 mb-4">
          <ManageFoldersModal
            isOpen={!!compStore.openFolderModal}
            foldersLoaded={!!compStore.foldersLoaded}
            folders={compStore.folders}
            defaultFolderName="Uncategorized"
            createFolder={piaApi.createComparisonFolder}
            deleteFolder={piaApi.deleteComparisonFolder}
            beforeReload={compStore.onLoadComparisons}
            confirmType="comparisons"
            onClose={() => {
              compStore.openFolderModal = false;
            }}
          />
          <ManageComps />
        </div>
      </div>
    );
  }
}

CompContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CompContainer));
