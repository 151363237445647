import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { BestSelect, CardContainer, Checkbox, LightButton, PriceFormatter, PrimaryButton } from "best-common-react";
import { FormSelectStyles, FooterLightSelectStyles, FooterWarningSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import { ReportConstants } from "../../constants/ReportConstants";
import ResultCount from "../common/ResultCount";
import ValueFormatter from "../utilities/ValueFormatter";

class EditPlayerListResults extends React.Component {
  getFirstContractOptions() {
    return [
      { value: false, label: "Display Last Contract in Year" },
      { value: true, label: "Display First Contract in Year" }
    ];
  }
  getContractPlusOneOptions() {
    return [
      { value: false, label: "Use PY for Contract Info" },
      { value: true, label: "Use PY+1 for Contract Info" }
    ];
  }
  getSalaryTypeOptions() {
    return [
      { value: "LRD Salary", label: "Show LRD Salary" },
      { value: "AAV", label: "Show AAV" },
      { value: "Base Salary", label: "Show Base Salary" },
      { value: "LRD No Bonus", label: "Show LRD No Bonus" }
    ];
  }
  getCols(playerStore) {
    return [
      {
        key: "focusplayerFlg",
        name: "Focus",
        width: 60,
        formatter: row => (
          <div className="d-flex justify-content-center">
            <Checkbox
              id={row.row.sequencenum + "_focusplayerFlg"}
              onChange={event => {
                playerStore.setFocusPlayer(row.row);
              }}
              checked={row.value}
            />
          </div>
        )
      },
      { key: "playername", name: "Player Name", width: 300, sortable: true },
      { key: "platformYear", name: "Platform Year", width: 115, sortable: true },
      { key: "orgLk", name: "Club", width: 55, sortable: true },
      { key: "odmls", name: "OD MLS", width: 75, sortable: true },
      { key: "eosmls", name: "EOS MLS", width: 85, sortable: true },
      { key: "position", name: "Pos", width: 50, sortable: true },
      {
        key: "contractstartyear",
        name: "Contract Start",
        width: 115,
        formatter: row => <ValueFormatter value={row.value} />,
        sortable: true
      },
      {
        key: "contractlength",
        name: "Contract Length",
        width: 125,
        formatter: row => <ValueFormatter value={row.value} excludedValues={["0+0"]} />,
        sortable: true
      },
      {
        key: "contracttype",
        name: "Contract Type",
        width: 115,
        formatter: row => <ValueFormatter value={row.value} />,
        sortable: true
      },
      {
        key: "salary",
        name: playerStore.playerList.displaySalaryType,
        formatter: row => <PriceFormatter value={row.value} />,
        sortable: true
      }
    ];
  }
  getExportOptions() {
    return [
      { value: 0, label: "Export to Excel" },
      { value: 1, label: "Export Player IDs" },
      { value: 2, label: "Generate Criteria Based Report" }
    ];
  }
  getAddOptions() {
    return [
      { value: 0, label: "Add Players To Comparison" },
      { value: 1, label: "Add To New Comparison" }
    ];
  }
  getDeleteOptions() {
    return [
      { value: 0, label: "Delete Selected" },
      { value: 1, label: "Delete All" },
      { value: 2, label: "Delete Recent" }
    ];
  }
  render() {
    let { compStore, lookupStore, playerStore, reportStore } = this.props.rootStore;
    let firstContractOptions = this.getFirstContractOptions();
    let contractPlusOneOptions = this.getContractPlusOneOptions();
    let salaryTypes = this.getSalaryTypeOptions();
    let exportOptions = this.getExportOptions();
    let addOptions = this.getAddOptions();
    let deleteOptions = this.getDeleteOptions();

    return (
      <CardContainer>
        <CardContainer.Header>
          <div className="header-item-row justify-content-between">
            <span className="header-title">Search Results</span>
            <div className="header-item-row h-inherit">
              <LightButton
                onClick={() => {
                  playerStore.playerList.addPlayerModalOpen = true;
                }}
              >
                Add Player
              </LightButton>
              <LightButton
                onClick={() => {
                  playerStore.playerList.importPlayersModalOpen = true;
                }}
              >
                Import Players
              </LightButton>
            </div>
          </div>
        </CardContainer.Header>
        <CardContainer.Body>
          <div className="row mx-0 py-2 justify-content-end filter-row">
            <div className="col-2 pr-0">
              <BestSelect
                name="displayFirstContract"
                options={firstContractOptions}
                onChange={option => {
                  playerStore.listCriteria.displayFirstContract = option.value;
                  playerStore.playerList.displayFirstContract = option.value;
                }}
                value={lookupStore.getDropdownItem(firstContractOptions, playerStore.listCriteria.displayFirstContract)}
                styles={FormSelectStyles}
              />
            </div>

            <div className="col-2 pr-0">
              <BestSelect
                name="displayPyplusone"
                options={contractPlusOneOptions}
                onChange={option => {
                  playerStore.listCriteria.displayPyplusone = option.value;
                  playerStore.playerList.displayPyplusone = option.value;
                }}
                value={lookupStore.getDropdownItem(contractPlusOneOptions, playerStore.listCriteria.displayPyplusone)}
                styles={FormSelectStyles}
              />
            </div>

            <div className="col-2">
              <BestSelect
                name="displaySalaryType"
                options={salaryTypes}
                onChange={option => {
                  playerStore.listCriteria.displaySalaryType = option.value;
                  playerStore.playerList.displaySalaryType = option.value;
                }}
                value={lookupStore.getDropdownItem(salaryTypes, playerStore.listCriteria.displaySalaryType)}
                styles={FormSelectStyles}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <PiaTable
                data={playerStore.displayPlayerListDetails}
                columns={this.getCols(playerStore)}
                rowSelection={{
                  showCheckbox: true,
                  onRowsSelected: rows => {
                    rows.forEach(row => {
                      if (row.row.playerid) {
                        playerStore.playerList.plDetails[row.rowIdx].selectedFlg = true;
                      }
                    });
                  },
                  onRowsDeselected: rows => {
                    rows.forEach(row => {
                      if (row.row.playerid) {
                        playerStore.playerList.plDetails[row.rowIdx].selectedFlg = false;
                      }
                    });
                  },
                  selectBy: {
                    isSelectedKey: "selectedFlg"
                  },
                  enableShiftSelect: true
                }}
                sortColumn={playerStore.playerListDetailSortColumn}
                sortDirection={playerStore.playerListDetailSortDirection}
                sortFunc={playerStore.changePlayerListDetailSort}
                height={playerStore.calculateTableHeight(playerStore.displayPlayerListDetails.length, 336)}
              />
            </div>
          </div>
        </CardContainer.Body>
        <CardContainer.Footer>
          <ResultCount value={playerStore.displayPlayerListDetails.length} type="player">
            <div className="header-item-row">
              <LightButton
                onClick={() => {
                  playerStore.refreshPlayerListPlayerInfo();
                }}
              >
                Refresh Player Info
              </LightButton>
              <div className="item">
                <BestSelect
                  name="exportOptions"
                  value={-1}
                  onChange={option => {
                    switch (option.value) {
                      case 0:
                        playerStore.exportProp(playerStore.playerList, "plDetails");
                        break;
                      case 1:
                        playerStore.playerList.exportPlayersModalOpen = true;
                        break;
                      case 2:
                        let criteria = { ...playerStore.listCriteria, existingList: playerStore.playerList };
                        reportStore.launchReport(ReportConstants.REPORT_ADVANCEDPLAYERLIST, {
                          playerListReportCriteria: criteria
                        });
                        break;
                      default:
                        break;
                    }
                  }}
                  options={exportOptions}
                  placeholder="Export"
                  menuPosition="fixed"
                  styles={FooterLightSelectStyles}
                />
              </div>
              <div className="item">
                <BestSelect
                  name="addOptions"
                  value={-1}
                  onChange={option => {
                    switch (option.value) {
                      case 0:
                        compStore.openComp(compStore.comp.comparisonId || 0, {
                          plMasters: [playerStore.playerList],
                          slMasters: compStore.comp.slMasters
                        });
                        break;
                      case 1:
                        compStore.resetStore();
                        compStore.openComp(0, {
                          plMasters: [playerStore.playerList],
                          slMasters: compStore.comp.slMasters
                        });
                        break;
                      default:
                        break;
                    }
                  }}
                  options={addOptions}
                  placeholder="Add To"
                  menuPosition="fixed"
                  styles={FooterLightSelectStyles}
                />
              </div>
              <div className="item">
                <BestSelect
                  name="deleteOptions"
                  value={-1}
                  onChange={option => {
                    switch (option.value) {
                      case 0:
                        playerStore.playerList.plDetails = _.filter(
                          playerStore.playerList.plDetails,
                          player => !player.selectedFlg
                        );
                        break;
                      case 1:
                        playerStore.playerList.plDetails = [];
                        break;
                      case 2:
                        playerStore.playerList.plDetails = _.filter(
                          playerStore.playerList.plDetails,
                          player => !player.recentlyAddedFlg
                        );
                        break;
                      default:
                        break;
                    }
                  }}
                  options={deleteOptions}
                  placeholder="Delete"
                  menuPosition="fixed"
                  styles={FooterWarningSelectStyles}
                />
              </div>
            </div>
          </ResultCount>
        </CardContainer.Footer>
      </CardContainer>
    );
  }
}

EditPlayerListResults.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(EditPlayerListResults)));
