import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { LightButton, PrimaryButton } from "best-common-react";
import { RouteConstants } from "../../constants/RouteConstants";

class CompFinderHeaderDetails extends React.Component {
  getButton() {
    let { compFinderStore, lookupStore } = this.props.rootStore;

    switch (compFinderStore.activeTab) {
      case RouteConstants.COMP_FINDER.tabs[0]:
        return (
          <LightButton
            onClick={() => {
              if (lookupStore.compFinderUserGuideUrl) {
                window.open(lookupStore.compFinderUserGuideUrl);
              }
            }}
          >
            View User Guide
          </LightButton>
        );
      case RouteConstants.COMP_FINDER.tabs[1]:
        return <PrimaryButton onClick={() => {}}>Create New Query</PrimaryButton>;
      default:
        return "";
    }
  }
  render() {
    return (
      <div className="row align-items-center h-100 m-0 p-0">
        <div className="col-7">
          <h5>{RouteConstants.COMP_FINDER.title}</h5>
        </div>
        <div className="col-5">
          <div className="row m-0 align-items-center justify-content-end">{this.getButton()}</div>
        </div>
      </div>
    );
  }
}

CompFinderHeaderDetails.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CompFinderHeaderDetails));
