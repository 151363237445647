import React from "react";
import { inject, observer } from "mobx-react";
import { BestSelect, CardContainer, LightButton } from "best-common-react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { FormSelectStyles } from "../../Theme";
import PiaWidget from "../widgets/PiaWidget";
import TitleCard from "../widgets/TitleCard";

const ImageColumn = styled.div`
  flex: 0 0 90px;
`;

const ResultColumn = styled.div`
  flex: 1;
`;

class LeagueLeaders extends React.Component {
  getLeagueOptions() {
    return [
      { value: "ML", label: "ML" },
      { value: "AL", label: "AL" },
      { value: "NL", label: "NL" }
    ];
  }

  getSortOptions() {
    return [
      { value: true, label: "Best" },
      { value: false, label: "Worst" }
    ];
  }

  getResultTable(results) {
    let rows = [];

    if (results) {
      results.forEach((result, index) => {
        let row = (
          <tr key={index} className={index === 0 ? "top-rank-row" : ""}>
            <td>{result.rank}</td>
            <td>
              {result.numTiedFor10th > 0 && result.displayTied
                ? result.numTiedFor10thDisplay + " more tied with"
                : result.displayName}
            </td>
            <td className="text-right">{result.statValue}</td>
          </tr>
        );

        rows.push(row);
      });
    }

    return (
      <table className="bordered">
        <tbody>{rows}</tbody>
      </table>
    );
  }

  getResultCols() {
    return [
      { key: "rank", name: "Rank", width: 60 },
      { key: "displayName", name: "Rank", width: 250 },
      { key: "statValue", name: "Stat", width: 80 }
    ];
  }

  render() {
    let { commonStore, homePlateStore, lookupStore } = this.props.rootStore;
    let classes = this.props.className ? this.props.className : "";
    let leagues = this.getLeagueOptions();
    let battingStats = lookupStore.getCollection("battingstats");
    let pitchingStats = lookupStore.getCollection("pitchingstats");
    let years = commonStore.getListOfYears(1988);
    let sortOptions = this.getSortOptions();
    let servicesURL = process.env.REACT_APP_SERVICES_URL;
    return (
      <PiaWidget className={classes}>
        <CardContainer>
          <CardContainer.Header>
            <span className="header-title">League Leaders</span>
          </CardContainer.Header>
          <CardContainer.Body>
            <div className="container-fluid">
              <div className="row m-0">
                <div className="col-6 pl-0">
                  <div className="row m-0 p-0">
                    <div className="col-2 pl-0 league-leaders-field">
                      <BestSelect
                        name="llbLeague"
                        value={lookupStore.getDropdownItem(
                          leagues,
                          homePlateStore.leagueLeaders.batting.leagueLeaderLeague
                        )}
                        onChange={option => {
                          homePlateStore.leagueLeaders.batting.leagueLeaderLeague = option.value;
                        }}
                        options={leagues}
                        styles={FormSelectStyles}
                      />
                    </div>
                    <div className="col-4 pl-0 league-leaders-field">
                      <BestSelect
                        name="llbStat"
                        options={battingStats}
                        value={lookupStore.getDropdownItem(
                          battingStats,
                          homePlateStore.leagueLeaders.batting.leagueLeaderStat.statId,
                          "statId",
                          "statName"
                        )}
                        onChange={option => {
                          homePlateStore.leagueLeaders.batting.leagueLeaderStat = option;
                        }}
                        getOptionValue={option => option.statId}
                        getOptionLabel={option => option.statName}
                        placeholder="Please choose a stat..."
                        styles={FormSelectStyles}
                      />
                    </div>
                    <div className="col-2 pl-0 league-leaders-field">
                      <BestSelect
                        name="llbYear"
                        value={lookupStore.getDropdownItem(years, homePlateStore.leagueLeaders.batting.statYear * 1)}
                        onChange={option => {
                          homePlateStore.leagueLeaders.batting.statYear = option.value + "";
                        }}
                        options={years}
                        styles={FormSelectStyles}
                      />
                    </div>
                    <div className="col-2 pl-0 league-leaders-field">
                      <BestSelect
                        name="llbSort"
                        value={lookupStore.getDropdownItem(
                          sortOptions,
                          homePlateStore.leagueLeaders.batting.useOriginalSort
                        )}
                        onChange={option => {
                          homePlateStore.leagueLeaders.batting.useOriginalSort = option.value;
                        }}
                        options={sortOptions}
                        styles={FormSelectStyles}
                      />
                    </div>
                    <div className="col-2 p-0 d-flex">
                      <LightButton
                        className="flex-fill"
                        onClick={() => {
                          homePlateStore.getLeagueBattingLeaders();
                        }}
                      >
                        View
                      </LightButton>
                    </div>
                  </div>
                  <TitleCard className="row col-12 p-0 mx-0 my-4">
                    <span className="d-flex flex-fill justify-content-between align-items-center">
                      <span>Batting</span>
                      <h5>{homePlateStore.leagueLeaders.batting.leagueLeaderStat.statCode}</h5>
                    </span>
                  </TitleCard>
                  <div className="row m-0 p-0">
                    <ImageColumn>
                      {homePlateStore.leagueLeaders.battingResults.length > 0 &&
                      homePlateStore.leagueLeaders.battingResults[0].imageURL ? (
                        <img
                          className="player-image col-12 p-0"
                          src={servicesURL + homePlateStore.leagueLeaders.battingResults[0].imageURL}
                        />
                      ) : (
                        <div className="player-placeholder col-12 p-0" />
                      )}
                    </ImageColumn>
                    <ResultColumn>
                      <div className="row col-12 m-0 pr-0">
                        {this.getResultTable(homePlateStore.leagueLeaders.battingResults)}
                      </div>
                    </ResultColumn>
                  </div>
                </div>

                <div className="col-6 pr-0">
                  <div className="row m-0 p-0">
                    <div className="col-2 pl-0 league-leaders-field">
                      <BestSelect
                        name="llpLeague"
                        value={lookupStore.getDropdownItem(
                          leagues,
                          homePlateStore.leagueLeaders.pitching.leagueLeaderLeague
                        )}
                        onChange={option => {
                          homePlateStore.leagueLeaders.pitching.leagueLeaderLeague = option.value;
                        }}
                        options={leagues}
                        styles={FormSelectStyles}
                      />
                    </div>
                    <div className="col-4 pl-0 league-leaders-field">
                      <BestSelect
                        name="llpStat"
                        options={pitchingStats}
                        value={lookupStore.getDropdownItem(
                          pitchingStats,
                          homePlateStore.leagueLeaders.pitching.leagueLeaderStat.statId,
                          "statId",
                          "statName"
                        )}
                        onChange={option => {
                          homePlateStore.leagueLeaders.pitching.leagueLeaderStat = option;
                        }}
                        getOptionValue={option => option.statId}
                        getOptionLabel={option => option.statName}
                        placeholder="Please choose a stat..."
                        styles={FormSelectStyles}
                      />
                    </div>
                    <div className="col-2 pl-0 league-leaders-field">
                      <BestSelect
                        name="llpYear"
                        value={lookupStore.getDropdownItem(years, homePlateStore.leagueLeaders.pitching.statYear * 1)}
                        onChange={option => {
                          homePlateStore.leagueLeaders.pitching.statYear = option.value + "";
                        }}
                        options={years}
                        styles={FormSelectStyles}
                      />
                    </div>
                    <div className="col-2 pl-0 league-leaders-field">
                      <BestSelect
                        name="llpSort"
                        value={lookupStore.getDropdownItem(
                          sortOptions,
                          homePlateStore.leagueLeaders.pitching.useOriginalSort
                        )}
                        onChange={option => {
                          homePlateStore.leagueLeaders.pitching.useOriginalSort = option.value;
                        }}
                        options={sortOptions}
                        styles={FormSelectStyles}
                      />
                    </div>
                    <div className="col-2 p-0 d-flex">
                      <LightButton
                        className="flex-fill"
                        onClick={() => {
                          homePlateStore.getLeaguePitchingLeaders();
                        }}
                      >
                        View
                      </LightButton>
                    </div>
                  </div>
                  <TitleCard className="row col-12 p-0 mx-0 my-4">
                    <span className="d-flex flex-fill justify-content-between align-items-center">
                      <span>Pitching</span>
                      <h5>{homePlateStore.leagueLeaders.pitching.leagueLeaderStat.statCode}</h5>
                    </span>
                  </TitleCard>
                  <div className="row m-0 p-0">
                    <ImageColumn>
                      {homePlateStore.leagueLeaders.pitchingResults.length > 0 &&
                      homePlateStore.leagueLeaders.pitchingResults[0].imageURL ? (
                        <img
                          className="player-image col-12 p-0"
                          src={servicesURL + homePlateStore.leagueLeaders.pitchingResults[0].imageURL}
                        />
                      ) : (
                        <div className="player-placeholder col-12 p-0" />
                      )}
                    </ImageColumn>
                    <ResultColumn>
                      <div className="row col-12 m-0 pr-0">
                        {this.getResultTable(homePlateStore.leagueLeaders.pitchingResults)}
                      </div>
                    </ResultColumn>
                  </div>
                </div>
              </div>
            </div>
          </CardContainer.Body>
        </CardContainer>
      </PiaWidget>
    );
  }
}

LeagueLeaders.propTypes = {
  rootStore: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default inject("rootStore")(withTheme(observer(LeagueLeaders)));
