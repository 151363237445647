import React from "react";
import { BestQuickSearch, Icon } from "best-common-react";
import PropTypes from "prop-types";
import { SearchBarStyles } from "../../Theme";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClear = this.onClear.bind(this);
    this.state = {};
  }

  onChange(value) {
    this.props.onChangeFn();
    this.hideSearchIcon = value !== "";
    this.setState(this.state);
  }

  onClear() {
    this.props.onClearFn();
    this.hideSearchIcon = false;
    this.setState(this.state);
  }

  render() {
    let validStyles = this.props.styles ? this.props.styles : SearchBarStyles;
    return (
      <div
        className={
          "d-flex flex-fill pia-search-wrapper" + (this.hideSearchIcon !== true ? " search-result-display" : "")
        }
      >
        <div className="p-0 pia-search-container">
          {this.props.type === "search" ? (
            <BestQuickSearch
              options={this.props.options}
              renderSuggestion={this.props.renderSuggestion}
              getSuggestionValue={this.props.getSuggestionValue}
              getSuggestions={this.props.getSuggestions}
              placeholder={this.props.placeholder}
              onEnter={this.props.onEnterFn}
              onClear={this.onClear}
              onChange={this.onChange}
              onSuggestionSelected={this.props.onSuggestionSelected}
              styles={validStyles}
              searchValue={this.props.searchValue}
            />
          ) : (
            <input
              className={this.props.className}
              type={this.props.type}
              value={this.props.value}
              onChange={this.props.onChangeFn}
              onKeyPress={event => {
                let keyCode = event.keyCode ? event.keyCode : event.which ? event.which : event.charCode;
                if (13 === keyCode) {
                  this.props.onEnterFn();
                }
              }}
              placeholder={this.props.placeholder}
            />
          )}
        </div>
        <div
          className={
            "p-0 align-items-center justify-content-center pia-search-icon-container" +
            (this.hideSearchIcon === true ? " d-none" : " d-flex")
          }
        >
          <Icon iconName="fa-search" onClick={this.props.onClickFn} />
        </div>
      </div>
    );
  }
}

SearchBar.defaultProps = {
  type: "search",
  options: [],
  renderSuggestion: () => {},
  getSuggestionValue: () => {},
  getSuggestions: () => {
    return [];
  },
  onSuggestionSelected: () => {},
  className: "",
  onChangeFn: () => {},
  onEnterFn: () => {},
  onClickFn: () => {},
  onClearFn: () => {},
  placeholder: "",
  searchValue: ""
};

SearchBar.propTypes = {
  type: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  getSuggestionValue: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  className: PropTypes.string,
  onChangeFn: PropTypes.func,
  onEnterFn: PropTypes.func,
  onClickFn: PropTypes.func,
  onClearFn: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  styles: PropTypes.object,
  searchValue: PropTypes.string
};

export default SearchBar;
