import React from "react";
import { inject, observer } from "mobx-react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { InfoCard, TabSwitcher } from "best-common-react";
import CompFinder from "./CompFinder";
import { RouteConstants } from "../../constants/RouteConstants";
import SavedSearches from "./SavedSearches";
import Setup from "./Setup";
import { TabSwitcherStyles } from "../../Theme";

const StyledWarningCard = styled(InfoCard)`
  background-color: #dc3545;
  color: white;
  .card-body {
    padding: 0.5em 1em !important;
    background-color: #dc3545 !important;
  }
`;

class CompFinderTabs extends React.Component {
  getTabs() {
    let { authStore } = this.props.rootStore;
    let tabs = [
      {
        name: RouteConstants.COMP_FINDER.tabs[0],
        component: <CompFinder className="simple-footer col-12" />
      },
      {
        name: RouteConstants.COMP_FINDER.tabs[1],
        component: <SavedSearches />
      }
    ];

    if (authStore.userData.admin) {
      tabs.push({
        name: RouteConstants.COMP_FINDER.tabs[2],
        component: <Setup />
      });
    }

    return tabs;
  }

  onSwitch(index) {
    let { compFinderStore } = this.props.rootStore;
    let tabs = this.getTabs();
    compFinderStore.activeTab = tabs[index].name;
  }

  render() {
    return (
      <React.Fragment>
        <StyledWarningCard className="mb-4">
          <p>
            <b>DISCLAIMER:</b> This utility is intended to supplement and guide your analysis rather than
            comprehensively conduct it. The tool may omit potentially relevant comparable players and may return players
            who have little bearing on a case. As always, please contact a member of the LRD with any questions.
          </p>
        </StyledWarningCard>
        <TabSwitcher
          tabs={this.getTabs()}
          hasPadding={false}
          onSwitch={index => {
            this.onSwitch(index);
          }}
          styles={TabSwitcherStyles}
        />
      </React.Fragment>
    );
  }
}

CompFinderTabs.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(CompFinderTabs)));
