import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { TabSwitcher } from "best-common-react";
import { TabSwitcherStyles } from "../../Theme";
import EditPlayerListCriteria from "./EditPlayerListCriteria";
import SavedPlayerLists from "./SavedPlayerLists";

class EditPlayerListTabs extends React.Component {
  getTabs() {
    return [
      {
        name: "Search Criteria",
        component: <EditPlayerListCriteria />
      },
      {
        name: "My Saved Player Lists",
        component: <SavedPlayerLists />
      }
    ];
  }

  render() {
    return (
      <React.Fragment>
        <TabSwitcher tabs={this.getTabs()} hasPadding={false} styles={TabSwitcherStyles} />
      </React.Fragment>
    );
  }
}

EditPlayerListTabs.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(EditPlayerListTabs));
