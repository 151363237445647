import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { BestLabel, DateTimeFormatter, Modal, LightButton } from "best-common-react";
import TitleCard from "../widgets/TitleCard";

class TransactionModal extends React.Component {
  render() {
    let { playerStore } = this.props.rootStore;
    let tx = playerStore.modalTxDetails;

    if (!tx) {
      return "";
    }

    return (
      <Modal show={!!playerStore.modalTxDetails} size="lg">
        <Modal.HeaderWithTitleAndClose
          title={tx.transactionCode.substring(0, 2) + " Transaction Details"}
          close={() => {
            playerStore.modalTxDetails = null;
          }}
        />
        <Modal.Body>
          <div className="row mx-0 mb-2">
            <div className="col-12 p-0">
              <TitleCard>
                <span>
                  <BestLabel>Transaction Type:</BestLabel>&nbsp;{tx.transactionDesc}
                </span>
              </TitleCard>
            </div>
          </div>

          <div className="row mx-0 mb-4">
            <div className="col-12 p-0">
              <table>
                <tbody>
                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Original {tx.transactionCode.substring(0, 2)} Placement Date</BestLabel>
                      <span>
                        <DateTimeFormatter value={tx.originalDLPlacementDate} />
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Roster Affected Date</BestLabel>
                      <span>
                        <DateTimeFormatter value={tx.rosterAffectedDate} />
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Earliest Reinstatement Date</BestLabel>
                      <span>
                        <DateTimeFormatter value={tx.earliestReinstDate} />
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Last Game Appearance Date</BestLabel>
                      <span>
                        <DateTimeFormatter value={tx.lastGamePlayedDate} />
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Doubleheader Exception</BestLabel>
                      <span>{tx.doubleheaderExceptionFlag ? "Yes" : "No"}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row mx-0 mb-2">
            <div className="col-12 p-0">
              <TitleCard>
                <span>Injury Details</span>
              </TitleCard>
            </div>
          </div>

          <div className="row mx-0 mb-4">
            <div className="col-12 p-0">
              <table>
                <tbody>
                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Injury Date</BestLabel>
                      <span>
                        <DateTimeFormatter value={tx.injuryDate} />
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Exam Date</BestLabel>
                      <span>
                        <DateTimeFormatter value={tx.examDate} />
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Injury Sustained</BestLabel>
                      <span>{tx.injurySustainedLocation}</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Country of Injury</BestLabel>
                      <span>{tx.injuryCountry}</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>State of Injury</BestLabel>
                      <span>{tx.injuryState}</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Body Side</BestLabel>
                      <span>{tx.bodySide}</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Part of Body Injured</BestLabel>
                      <span>{tx.bodyPart}</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Body Part Detail</BestLabel>
                      <span>{tx.bodyPartDetail}</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Diagnosis Description</BestLabel>
                      <span>{tx.diagnosis}</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>Ailment</BestLabel>
                      <span>{tx.ailment}</span>
                    </td>
                  </tr>

                  <tr>
                    <td className="d-flex justify-content-between">
                      <BestLabel>New Injury</BestLabel>
                      <span>{tx.newInjuryFlag ? "Yes" : "No"}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row mx-0 mb-2">
            <div className="col-12 p-0">
              <TitleCard>
                <span>Comments</span>
              </TitleCard>
            </div>
          </div>

          <div className="row m-0">
            <div className="col-12 p-0">
              <div>
                {tx.transactionComments && "" !== tx.transactionComments
                  ? tx.transactionComments
                  : "(No commments available)"}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            <LightButton
              onClick={() => {
                playerStore.modalTxDetails = null;
              }}
            >
              Close
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

TransactionModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(TransactionModal));
