import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import {
  BestLabel,
  BestSelect,
  CardContainer,
  Checkbox,
  DangerButton,
  FormInput,
  LightButton,
  NumberInput
} from "best-common-react";
import { FormSelectStyles } from "../../Theme";
import FormulaPart from "./FormulaPart";
import PiaWidget from "../widgets/PiaWidget";
import TitleCard from "../widgets/TitleCard";

class EditStat extends React.Component {
  getFormulaParts(statStore) {
    let list = [];
    let num = 0;
    statStore.statFormula.parts.forEach((part, index) => {
      let fp = (
        <FormulaPart
          key={"fp_" + index}
          index={num}
          value={part}
          onClickFn={idx => {
            statStore.statFormula.selectedIndex = idx;
          }}
        />
      );
      list.push(fp);
      ++num;
    });

    return <React.Fragment>{list}</React.Fragment>;
  }
  render() {
    let { lookupStore, statStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Body>
              <div className="row mx-0 mb-4">
                <div className="col-12">
                  <TitleCard>
                    <span>Edit Stat Info</span>
                  </TitleCard>
                </div>
              </div>

              <div className="row m-0">
                <div className="row m-0 col-6">
                  <div className="col-3">
                    <BestLabel>Statistic Name</BestLabel>
                  </div>
                  <div className="col-7">
                    <FormInput
                      name="nsStatName"
                      type="text"
                      value={statStore.stat.statName}
                      onChangeFn={value => {
                        statStore.stat.statName = value;
                      }}
                      editable
                    />
                  </div>
                </div>

                <div className="row m-0 col-6">
                  <div className="col-3">
                    <BestLabel>Show Leading Zero</BestLabel>
                  </div>
                  <div className="col-7">
                    <Checkbox
                      id="nsShowLeadingZeroFlg"
                      onChange={event => {
                        statStore.stat.showLeadingZeroFlg = event.target.checked ? "1" : "0";
                      }}
                      checked={statStore.stat.showLeadingZeroFlg * 1}
                    />
                  </div>
                </div>
              </div>

              <div className="row m-0">
                <div className="row m-0 col-6">
                  <div className="col-3">
                    <BestLabel>Short Name</BestLabel>
                  </div>
                  <div className="col-7">
                    <FormInput
                      name="nsStatCode"
                      type="text"
                      value={statStore.stat.statCode}
                      onChangeFn={value => {
                        statStore.stat.statCode = value;
                      }}
                      editable
                    />
                  </div>
                </div>

                <div className="row m-0 col-6">
                  <div className="col-3">
                    <BestLabel>Higher Values Better</BestLabel>
                  </div>
                  <div className="col-7">
                    <Checkbox
                      id="nsHigherValuesBetterFlg"
                      onChange={event => {
                        statStore.stat.higherValuesBetterFlg = event.target.checked ? "H" : "L";
                      }}
                      checked={statStore.stat.higherValuesBetterFlg === "H"}
                    />
                  </div>
                </div>
              </div>

              <div className="row m-0">
                <div className="row m-0 col-6">
                  <div className="col-3">
                    <BestLabel>Decimal Places</BestLabel>
                  </div>
                  <div className="col-7">
                    <FormInput
                      name="nsDecimalPlaces"
                      type="number"
                      value={statStore.stat.decimalPlaces}
                      onChangeFn={value => {
                        statStore.stat.decimalPlaces = value;
                      }}
                      editable
                    />
                  </div>
                </div>

                <div className="row m-0 col-6">
                  <div className="col-3">
                    <BestLabel>Stat Can Be Averaged</BestLabel>
                  </div>
                  <div className="col-7">
                    <Checkbox
                      id="nsCanBeAveragedFlg"
                      onChange={event => {
                        statStore.stat.canBeAveragedFlg = event.target.checked ? "1" : "0";
                      }}
                      checked={statStore.stat.canBeAveragedFlg * 1}
                    />
                  </div>
                </div>
              </div>

              <div className="row mx-0 mb-4">
                <div className="col-12">
                  <TitleCard>
                    <span>Edit Stat Formula</span>
                  </TitleCard>
                </div>
              </div>

              <div className="row col-12 m-0">
                <div className="col-4 pl-0">
                  <div className="form-block">
                    <div className="form-block-title-row">
                      <span className="form-block-title">Add Stat</span>
                    </div>
                    <div className="row form-block-row">
                      <div className="col-9 pl-0">
                        <BestSelect
                          name="nsFormulaStatId"
                          placeholder="Select a stat..."
                          options={lookupStore.autocompleteStatsOnly}
                          onChange={option => {
                            statStore.statFormula.objectName = option.objectName;
                            statStore.statFormula.statCode = option.statCode;
                          }}
                          value={lookupStore.getDropdownItem(
                            lookupStore.autocompleteStatsOnly,
                            statStore.statFormula.statCode,
                            "statCode",
                            "statName"
                          )}
                          getOptionValue={option => option.statCode}
                          getOptionLabel={option => statStore.getAutocompleteText(option)}
                          styles={FormSelectStyles}
                        />
                      </div>
                      <div className="col-3 p-0 d-flex flex-fill">
                        <LightButton
                          className="w-100"
                          onClick={() => {
                            statStore.addToNewStatFormula("{" + statStore.statFormula.objectName + "}");
                          }}
                          disabled={!statStore.statFormula.objectName}
                        >
                          Add
                        </LightButton>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4 p-0">
                  <div className="form-block">
                    <div className="form-block-title-row">
                      <span className="form-block-title">Add Constant</span>
                    </div>
                    <div className="row form-block-row">
                      <div className="col-9 pl-0">
                        <FormInput
                          type="text"
                          name="nsConstantNum"
                          value={statStore.statFormula.constant === null ? "" : statStore.statFormula.constant}
                          onChangeFn={value => {
                            statStore.statFormula.constant = value;
                          }}
                          editable
                        />
                      </div>
                      <div className="col-3 p-0 d-flex flex-fill">
                        <LightButton
                          className="w-100"
                          onClick={() => {
                            statStore.addToNewStatFormula(statStore.statFormula.constant);
                          }}
                          disabled={!statStore.statFormula.constant}
                        >
                          Add
                        </LightButton>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-4 pr-0">
                  <div className="form-block">
                    <div className="form-block-title-row">
                      <span className="form-block-title">Add Operator</span>
                    </div>

                    <div className="row form-block-row">
                      <div className="col-2 d-flex flex-fill pl-0">
                        <LightButton
                          className="w-100"
                          onClick={() => {
                            statStore.addToNewStatFormula("+");
                          }}
                        >
                          +
                        </LightButton>
                      </div>
                      <div className="col-2 d-flex flex-fill pl-0">
                        <LightButton
                          className="w-100"
                          onClick={() => {
                            statStore.addToNewStatFormula("-");
                          }}
                        >
                          -
                        </LightButton>
                      </div>
                      <div className="col-2 d-flex flex-fill pl-0">
                        <LightButton
                          className="w-100"
                          onClick={() => {
                            statStore.addToNewStatFormula("*");
                          }}
                        >
                          *
                        </LightButton>
                      </div>
                      <div className="col-2 d-flex flex-fill pr-0">
                        <LightButton
                          className="w-100"
                          onClick={() => {
                            statStore.addToNewStatFormula("/");
                          }}
                        >
                          /
                        </LightButton>
                      </div>
                      <div className="col-2 d-flex flex-fill pr-0">
                        <LightButton
                          className="w-100"
                          onClick={() => {
                            statStore.addToNewStatFormula("(");
                          }}
                        >
                          (
                        </LightButton>
                      </div>
                      <div className="col-2 d-flex flex-fill pr-0">
                        <LightButton
                          className="w-100"
                          onClick={() => {
                            statStore.addToNewStatFormula(")");
                          }}
                        >
                          )
                        </LightButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row col-12 mx-0 mt-4">{this.getFormulaParts(statStore)}</div>

              <div className="row col-12 mx-0 mt-4">
                <DangerButton
                  onClick={() => {
                    statStore.deleteSelectedFormulaPart();
                  }}
                >
                  Delete Selected
                </DangerButton>
              </div>
            </CardContainer.Body>
            <CardContainer.Footer></CardContainer.Footer>
          </CardContainer>
        </PiaWidget>
      </React.Fragment>
    );
  }
}

EditStat.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(EditStat)));
