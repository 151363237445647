import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { FadeLoader } from "react-spinners";

const Parent = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1053;
  display: none;
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledFadeLoader = styled(FadeLoader)`
  margin: 0 auto;
  opacity: 1;
`;

class PiaLoader extends React.Component {
  static propTypes = {
    theme: PropTypes.object.isRequired
  };

  render() {
    return (
      <Parent id={"pia-loader"}>
        <Overlay>
          <StyledFadeLoader color={this.props.theme["mlb-navy"]} />
        </Overlay>
      </Parent>
    );
  }
}

export default withTheme(PiaLoader);
