import React from "react";
import styled, { withTheme } from "styled-components";
import { InfoCard } from "best-common-react";
import PropTypes from "prop-types";
import Theme from "../../Theme";

const StyledInfoCard = styled(InfoCard)`
  background-color: ${props => props.backgroundColor};

  .card-body {
    display: flex;
    align-items: center;
    vertical-align: middle;
    padding: 0.5em 1em !important;
    line-height: normal;
    background-color: ${props => props.backgroundColor} !important;
  }
`;

class TitleCard extends React.Component {
  render() {
    let { backgroundColor, children, className } = this.props;
    return (
      <StyledInfoCard className={className} height="2em" backgroundColor={backgroundColor || Theme.fog}>
        {children}
      </StyledInfoCard>
    );
  }
}

TitleCard.defaultProps = {
  className: ""
};

TitleCard.propTypes = {
  backgroundColor: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string
};

export default withTheme(TitleCard);
