export const ReportConstants = {
  REPORT_1ON1: "oneonone",
  REPORT_1ON1AWARDS: "oneononeawards",
  REPORT_ADVANCEDPLAYERLIST: "advancedplayerlist",
  REPORT_AWARD: "award",
  REPORT_AWARD_SUMMARY: "awardsummary",
  REPORT_CAREER1ON1: "careeroneonone",
  REPORT_COMPFINDER: "compfinder",
  REPORT_COMPPERF: "compperf",
  REPORT_CONTRACT: "contract",
  REPORT_DETAILEDRANK: "detailedrank",
  REPORT_INDUSTRY: "industry",
  REPORT_MULTIPLAYER1ON1: "multioneonone",
  REPORT_PLAYERPROFILE: "playerprofile",
  REPORT_PLAYERQUICKVIEW: "playerquickview",
  REPORT_PLAYERSTATS: "playerstats",
  REPORT_STATSUMMARY: "statsummary",
  REPORT_TOPPERF: "topperf"
};
