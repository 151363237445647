import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import ManageStatLists from "../../components/statistics/ManageStatLists";
import { RouteConstants } from "../../constants/RouteConstants";

class StatListContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.STAT_LISTS.title);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="col-12 p-0 mb-4">
          <ManageStatLists />
        </div>
      </div>
    );
  }
}

StatListContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(StatListContainer));
