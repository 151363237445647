import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import {
  BestDropdown,
  BestLabel,
  BestQuickSearch,
  BestSelect,
  CardContainer,
  FormInput,
  Icon,
  LightButton,
  PrimaryButton
} from "best-common-react";
import { FormSearchStyles, FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import { ReportConstants } from "../../constants/ReportConstants";
import StatAutocompleteRow from "../autocomplete/StatAutocompleteRow";
import TitleCard from "../widgets/TitleCard";

class Reports extends React.Component {
  getGroupOptions() {
    return [
      { value: "Totals", label: "Totals" },
      { value: "Season", label: "Season" },
      { value: "Month", label: "Month" },
      { value: "Game", label: "Game" }
    ];
  }
  getProrateOptions() {
    return [
      { value: "prorate", label: "Prorate current season stats" },
      { value: "actuals", label: "Use current season actuals" },
      { value: "last", label: "Use last season statistics" },
      { value: "per600", label: "Normalize over 600 PAs" },
      { value: "per162", label: "Use career 162 game average" }
    ];
  }
  getTimeframeTypes() {
    return [
      { value: "Single Year", label: "Single Year" },
      { value: "Multiple Years", label: "Multiple Years" },
      { value: "Career", label: "Career" },
      { value: "Date Range", label: "Date Range" }
    ];
  }
  addStats(playerStore, stats) {
    let statIds = playerStore.reportCriteria.stats.map(stat => stat.statId);
    stats.forEach(stat => {
      if (!statIds.includes(stat.statId)) {
        playerStore.reportCriteria.stats.push(stat);
      }
    });
  }
  renderStatTable(playerStore) {
    let rows = [];

    playerStore.reportCriteria.stats.forEach((stat, index) => {
      rows.push(
        <tr key={"stat_" + index}>
          <td className="d-flex justify-content-between align-items-center">
            <span>{stat.statisticname}</span>
            <span className="item-row h-inherit">
              <span className="item">
                <Icon
                  className="caret-icon"
                  iconName="fa-caret-square-up"
                  onClick={() => {
                    if (index === 0) {
                      return;
                    }

                    playerStore.reportCriteria.stats.splice(index, 1);
                    playerStore.reportCriteria.stats.splice(index - 1, 0, stat);
                  }}
                ></Icon>
              </span>
              <span className="item">
                <Icon
                  className="caret-icon"
                  iconName="fa-caret-square-down"
                  onClick={() => {
                    if (index >= playerStore.reportCriteria.stats.length - 1) {
                      return;
                    }

                    playerStore.reportCriteria.stats.splice(index, 1);
                    playerStore.reportCriteria.stats.splice(index + 1, 0, stat);
                  }}
                ></Icon>
              </span>
              <span className="item">
                <Icon
                  className="mlb-red-icon"
                  iconName="fa-trash-alt"
                  onClick={() => {
                    playerStore.reportCriteria.stats.splice(index, 1);
                  }}
                ></Icon>
              </span>
            </span>
          </td>
        </tr>
      );
    });

    return (
      <table>
        <tbody>{rows}</tbody>
      </table>
    );
  }
  getSplitCols(playerStore) {
    return [
      { key: "splitCategory", name: "Category", width: 180 },
      {
        key: "splitName",
        name: "Name",
        formatter: row => {
          return <span>{row.value + ("View All" === row.value ? "*" : "")}</span>;
        }
      },
      {
        key: "deleteSplit",
        name: "",
        width: 40,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Icon
                className="mlb-red-icon"
                iconName="fa-trash-alt"
                onClick={() => {
                  let index = playerStore.reportCriteria.splits.indexOf(row.row);
                  if (index > -1) {
                    playerStore.reportCriteria.splits.splice(index, 1);
                  }
                }}
              />
            </div>
          );
        }
      }
    ];
  }
  render() {
    let { commonStore, lookupStore, playerStore, reportStore, statStore, piaApi } = this.props.rootStore;
    let groups = this.getGroupOptions();
    let prorates = this.getProrateOptions();
    let timeframes = this.getTimeframeTypes();
    let years = commonStore.getListOfYears(1871);

    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="container-fluid">
            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <TitleCard>
                  <span>Report Options</span>
                </TitleCard>
              </div>
            </div>
            <div className="row mx-0 mb-4">
              <div className="col-6 row mx-0 pl-0">
                <div className="col-6 pl-0">
                  <BestDropdown
                    name="grouping"
                    title="Group By"
                    value={lookupStore.getDropdownItem(groups, playerStore.reportCriteria.grouping)}
                    onChange={option => {
                      playerStore.reportCriteria.grouping = option.value;
                    }}
                    options={groups}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-6 pr-0">
                  <BestDropdown
                    name="prorateType"
                    title="Prorate Stats"
                    value={lookupStore.getDropdownItem(prorates, playerStore.reportCriteria.prorateType)}
                    onChange={option => {
                      playerStore.reportCriteria.prorateType = option.value;
                    }}
                    options={prorates}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>

              <div className="col-6 row mx-0 pr-0">
                <div className="col-6 pl-0">
                  <BestDropdown
                    name="timeframeType"
                    title="Timeframe"
                    value={lookupStore.getDropdownItem(timeframes, playerStore.reportCriteria.timeframeType)}
                    onChange={option => {
                      playerStore.reportCriteria.timeframeType = option.value;
                    }}
                    options={timeframes}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-3">
                  {"Career" === playerStore.reportCriteria.timeframeType ? (
                    ""
                  ) : "Date Range" === playerStore.reportCriteria.timeframeType ? (
                    <FormInput
                      name="timeframeFromDate"
                      label="From Date"
                      type="date"
                      value={playerStore.reportCriteria.timeframeFromDate}
                      onChangeFn={date => {
                        playerStore.reportCriteria.timeframeFromDate = date;
                      }}
                      editable
                    />
                  ) : (
                    <BestDropdown
                      name="timeframeFromYear"
                      title={"Multiple Years" === playerStore.reportCriteria.timeframeType ? "From Year" : "Year"}
                      value={lookupStore.getDropdownItem(
                        years,
                        ("Multiple Years" === playerStore.reportCriteria.timeframeType
                          ? playerStore.reportCriteria.timeframeFromYear
                          : playerStore.reportCriteria.timeframeSingleYear) * 1
                      )}
                      onChange={option => {
                        playerStore.reportCriteria.timeframeFromYear = option.value + "";
                        playerStore.reportCriteria.timeframeSingleYear = option.value + "";
                      }}
                      options={years}
                      styles={FormSelectStyles}
                    />
                  )}
                </div>

                <div className="col-3">
                  {"Date Range" === playerStore.reportCriteria.timeframeType ? (
                    <FormInput
                      name="timeframeToDate"
                      label="To Date"
                      type="date"
                      value={playerStore.reportCriteria.timeframeToDate}
                      onChangeFn={date => {
                        playerStore.reportCriteria.timeframeToDate = date;
                      }}
                      editable
                    />
                  ) : "Multiple Years" === playerStore.reportCriteria.timeframeType ? (
                    <BestDropdown
                      name="timeframeToYear"
                      title={"To Year"}
                      value={lookupStore.getDropdownItem(years, playerStore.reportCriteria.timeframeToYear * 1)}
                      onChange={option => {
                        playerStore.reportCriteria.timeframeToYear = option.value + "";
                      }}
                      options={years}
                      styles={FormSelectStyles}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="row mx-0 mb-4">
              <div className="col-6 pl-0">
                <div className="row mx-0 mb-2">
                  <div className="col-12 p-0">
                    <TitleCard>
                      <span>Stats</span>
                    </TitleCard>
                  </div>
                </div>
                <div className="row mx-0 mb-2 align-items-center">
                  <div className="col-3 pl-0">
                    <BestLabel>Add Stats From List</BestLabel>
                  </div>
                  <div className="col-6 pl-0">
                    <BestSelect
                      name="selectedStatListId"
                      options={statStore.displayLists}
                      onChange={option => {
                        playerStore.reportCriteria.selectedStatListId = option.statlistId;
                      }}
                      value={lookupStore.getDropdownItem(
                        statStore.displayLists,
                        playerStore.reportCriteria.selectedStatListId,
                        "statlistId",
                        "statlistname"
                      )}
                      getOptionValue={option => option.statlistId}
                      getOptionLabel={option => option.statlistname}
                      portal={document.body}
                      styles={FormSelectStyles}
                    />
                  </div>
                  <div className="col-2 p-0 d-flex flex-fill">
                    <LightButton
                      className="flex-fill"
                      onClick={() => {
                        if (!playerStore.reportCriteria.selectedStatListId) {
                          return;
                        }

                        piaApi.getStatList(playerStore.reportCriteria.selectedStatListId).then(response => {
                          if (response) {
                            this.addStats(playerStore, response.slDetails || []);
                          }
                        });
                      }}
                    >
                      Add
                    </LightButton>
                  </div>
                </div>

                <div className="row mx-0 mb-2 align-items-center">
                  <div className="col-3 pl-0">
                    <BestLabel>Add Single Stat</BestLabel>
                  </div>
                  <div className="col-6 pl-0">
                    <BestQuickSearch
                      options={lookupStore.autocompleteStats}
                      renderSuggestion={suggestion => {
                        return <StatAutocompleteRow stat={suggestion} />;
                      }}
                      getSuggestionValue={stat => {
                        return statStore.getAutocompleteText(stat);
                      }}
                      getSuggestions={(stats, searchTerm) => {
                        playerStore.reportCriteria.selectedStat = null;
                        return statStore.filterBySearchTerm(stats, searchTerm);
                      }}
                      onSuggestionSelected={(event, { suggestion }) => {
                        playerStore.reportCriteria.selectedStat = suggestion;
                      }}
                      styles={FormSearchStyles}
                    />
                  </div>
                  <div className="col-2 p-0 d-flex flex-fill">
                    <LightButton
                      className="flex-fill"
                      onClick={() => {
                        if (!playerStore.reportCriteria.selectedStat) {
                          return;
                        }

                        piaApi.getStatFromResult(playerStore.reportCriteria.selectedStat).then(response => {
                          if (response) {
                            this.addStats(playerStore, [response]);
                          }
                        });
                      }}
                    >
                      Add
                    </LightButton>
                  </div>
                </div>

                <div className="row mx-0">{this.renderStatTable(playerStore)}</div>
              </div>

              <div className="col-6 pr-0">
                <div className="row mx-0 mb-2">
                  <div className="col-12 p-0">
                    <TitleCard>
                      <span>Splits</span>
                    </TitleCard>
                  </div>
                </div>
                <div className="row mx-0 mb-2">
                  <div className="col-6 pl-0">
                    <BestSelect
                      name="selectedSplitCategory"
                      placeholder="Select a split category..."
                      options={playerStore.displaySplitCategories}
                      onChange={option => {
                        playerStore.reportCriteria.selectedSplitCategory = option;
                        playerStore.reportCriteria.selectedSplitName = "";
                      }}
                      value={lookupStore.getDropdownItem(
                        playerStore.displaySplitCategories,
                        playerStore.reportCriteria.selectedSplitCategory.splitType,
                        "splitType",
                        "splitType"
                      )}
                      getOptionValue={option => option.splitType}
                      getOptionLabel={option => option.splitType}
                      styles={FormSelectStyles}
                    />
                  </div>

                  <div className="col-6 pr-0">
                    <BestSelect
                      name="splitName"
                      placeholder="Select a split..."
                      options={playerStore.reportCriteria.selectedSplitCategory.splits}
                      onChange={option => {
                        playerStore.reportCriteria.selectedSplitName = option.splitName;
                      }}
                      value={lookupStore.getDropdownItem(
                        playerStore.reportCriteria.selectedSplitCategory.splits,
                        playerStore.reportCriteria.selectedSplitName,
                        "splitName",
                        "splitName"
                      )}
                      getOptionValue={option => option.splitName}
                      getOptionLabel={option => option.splitName}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
                <div className="row mx-0 mb-2 item-row h-inherit">
                  <div className="col-2 p-0 d-flex flex-fill">
                    <LightButton
                      className="flex-fill"
                      onClick={() => {
                        if (
                          playerStore.reportCriteria.selectedSplitCategory.splitType &&
                          playerStore.reportCriteria.selectedSplitName &&
                          "" !== playerStore.reportCriteria.selectedSplitName
                        ) {
                          playerStore.reportCriteria.splits.push({
                            splitCategory: playerStore.reportCriteria.selectedSplitCategory.splitType,
                            splitName: playerStore.reportCriteria.selectedSplitName
                          });
                          playerStore.reportCriteria.selectedSplitCategory.splitType = "";
                          playerStore.reportCriteria.selectedSplitName = "";
                        }
                      }}
                    >
                      Add
                    </LightButton>
                  </div>
                </div>

                <div className="row mx-0 mb-2">
                  <div className="col-12 p-0">
                    <PiaTable
                      data={playerStore.reportCriteria.splits}
                      columns={this.getSplitCols(playerStore)}
                      height={playerStore.calculateTableHeight(playerStore.reportCriteria.splits.length, 336)}
                      minColumnWidth={40}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContainer.Body>
        <CardContainer.Footer>
          <div className="header-item-row">
            <PrimaryButton
              onClick={() => {
                reportStore.launchReport(
                  ReportConstants.REPORT_PLAYERPROFILE,
                  { playerBean: playerStore.player, playerReportCriteria: playerStore.reportCriteria },
                  false,
                  true
                );
              }}
            >
              Run Player Profile Report
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                reportStore.launchReport(
                  ReportConstants.REPORT_PLAYERSTATS,
                  { playerBean: playerStore.player, playerReportCriteria: playerStore.reportCriteria },
                  false,
                  true
                );
              }}
            >
              Run Player Statistics Report
            </PrimaryButton>
          </div>
        </CardContainer.Footer>
      </CardContainer>
    );
  }
}

Reports.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(Reports));
