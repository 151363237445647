import React from "react";
import { inject, observer } from "mobx-react";
import { ThemeContext } from "styled-components";
import { CardContainer, Icon, LightButton } from "best-common-react";
import PropTypes from "prop-types";
import ReactToolTip from "react-tooltip";
import Theme from "../../Theme";
import { RouteConstants } from "../../constants/RouteConstants";

class NavigationLinks extends React.Component {
  render() {
    const { authStore, playerStore, routerStore, piaApi } = this.props.rootStore;

    return (
      <div id="nav-bar-supported-content" isProd={authStore.isProd}>
        <div className="nav-bar">
          <div
            className={"nav-link-container" + (routerStore.isHomePlatePage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.HOME_PLATE.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-home" />
            <span>{RouteConstants.HOME_PLATE.title}</span>
          </div>

          <div className="nav-link-container" data-for="ad-hoc-options-tooltip" data-tip="" data-event="click focus">
            <span>
              <Icon className="nav-icon" iconName="fa-question-circle" />
              <span>Ad Hoc Query</span>
            </span>
            <ReactToolTip
              id="ad-hoc-options-tooltip"
              class="tool-options"
              place="right"
              effect="solid"
              globalEventOff="click"
              scrollHide={false}
              clickable={true}
              isCapture={true}
            >
              <ThemeContext.Provider value={Theme}>
                <CardContainer>
                  <CardContainer.Header>
                    <span className="header-title">Ad-Hoc Options</span>
                  </CardContainer.Header>
                  <CardContainer.Body>
                    <div className="container-fluid">
                      <div className="row mx-0 my-2">
                        <LightButton
                          className="flex-grow-1"
                          onClick={() => {
                            ReactToolTip.hide();
                            piaApi.getAdHocReport("PIA Report Template").then(response => {
                              if (response) {
                                window.open(response);
                              }
                            });
                          }}
                        >
                          PIA+
                        </LightButton>
                      </div>

                      <div className="row mx-0 my-2">
                        <LightButton
                          className="flex-grow-1"
                          onClick={() => {
                            ReactToolTip.hide();
                            piaApi.getAdHocReport("PIA Report Template MN").then(response => {
                              if (response) {
                                window.open(response);
                              }
                            });
                          }}
                        >
                          PIA+ MN
                        </LightButton>
                      </div>

                      <div className="row mx-0 my-2">
                        <LightButton
                          className="flex-grow-1"
                          onClick={() => {
                            ReactToolTip.hide();
                            piaApi.getAdHocReport("PIA Report Template College Stats").then(response => {
                              if (response) {
                                window.open(response);
                              }
                            });
                          }}
                        >
                          College
                        </LightButton>
                      </div>
                    </div>
                  </CardContainer.Body>
                </CardContainer>
              </ThemeContext.Provider>
            </ReactToolTip>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isComparisonsPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.COMPARISONS.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-balance-scale" />
            <span>{RouteConstants.COMPARISONS.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isPlayerListsPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.PLAYER_LISTS.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-clipboard-list" />
            <span>{RouteConstants.PLAYER_LISTS.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isPlayerQueriesPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.PLAYER_QUERIES.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-code" />
            <span>{RouteConstants.PLAYER_QUERIES.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isStatListsPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.STAT_LISTS.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-file-alt" />
            <span>{RouteConstants.STAT_LISTS.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isPlayersPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.PLAYERS.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-user" />
            <span>{RouteConstants.PLAYERS.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isStatisticsPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.STATISTICS.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-chart-bar" />
            <span>{RouteConstants.STATISTICS.title}</span>
          </div>

          <div
            className={
              "nav-link-container" + (routerStore.isEditPlayerListPage && playerStore.playerList.avplFlg ? " open" : "")
            }
            onClick={() => {
              playerStore.openPlayerList(0, false, true);
            }}
          >
            <Icon className="nav-icon" iconName="fa-baseball-ball" />
            <span>Advanced Player List</span>
          </div>

          <div
            className={
              "nav-link-container" + (routerStore.isCompFinderPage || routerStore.isEditCompFinderPage ? " open" : "")
            }
            onClick={() => {
              routerStore.pushHistory(RouteConstants.COMP_FINDER.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-search" />
            <span>{RouteConstants.COMP_FINDER.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isCannedReportsPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.CANNED_REPORTS.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-database" />
            <span>{RouteConstants.CANNED_REPORTS.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isMySavedReportsPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.MY_SAVED_REPORTS.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-save" />
            <span>{RouteConstants.MY_SAVED_REPORTS.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isMyNewsFeedPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.MY_NEWS_FEED.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-rss-square" />
            <span>{RouteConstants.MY_NEWS_FEED.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isSharingPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.SHARING.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-share" />
            <span>{RouteConstants.SHARING.title}</span>
          </div>

          <div
            className={"nav-link-container" + (routerStore.isLinksPage ? " open" : "")}
            onClick={() => {
              routerStore.pushHistory(RouteConstants.LINKS.route, false);
            }}
          >
            <Icon className="nav-icon" iconName="fa-link" />
            <span>{RouteConstants.LINKS.title}</span>
          </div>

          {authStore.userData.admin ? (
            <div
              className={"nav-link-container" + (routerStore.isAdminToolsPage ? " open" : "")}
              onClick={() => {
                routerStore.pushHistory(RouteConstants.ADMIN_TOOLS.route, false);
              }}
            >
              <Icon className="nav-icon" iconName="fa-cog" />
              <span>{RouteConstants.ADMIN_TOOLS.title}</span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

NavigationLinks.propTypes = {
  rootStore: PropTypes.object.isRequired,
  signOut: PropTypes.string.isRequired
};

export default inject("rootStore")(observer(NavigationLinks));
