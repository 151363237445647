import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import AddWeightModal from "../../components/comp-finder/AddWeightModal";
import CompFinderTabs from "../../components/comp-finder/CompFinderTabs";
import PiaWidget from "../../components/widgets/PiaWidget";
import SaveNewModal from "../../components/comp-finder/SaveNewModal";
import { RouteConstants } from "../../constants/RouteConstants";

class CompFinderContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.COMP_FINDER.title);
  }

  render() {
    let { compFinderStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <SaveNewModal />
        <AddWeightModal weight={compFinderStore.weightToAdd} />
        <div className="container-fluid">
          <div className="row mx-0 mb-4">
            <PiaWidget className="tab-container col-12">
              <CompFinderTabs />
            </PiaWidget>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

CompFinderContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CompFinderContainer));
