import React from "react";
import { inject, observer } from "mobx-react";
import { FormInput, LightButton, Modal } from "best-common-react";
import PropTypes from "prop-types";

class SaveNewModal extends React.Component {
  close(compFinderStore) {
    compFinderStore.saveNewModalOpen = false;
  }

  save(compFinderStore) {
    compFinderStore.saveSearch();
  }

  render() {
    let { compFinderStore } = this.props.rootStore;
    return (
      <Modal show={compFinderStore.saveNewModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Save Query"
          close={() => {
            this.close(compFinderStore);
          }}
        />
        <Modal.Body>
          <div className="row m-0">
            <div className="col-12 p-0">
              <FormInput
                name="cfNewCompFinderName"
                label="Comp Finder Name"
                type="text"
                value={compFinderStore.cfMaster.compFinderName}
                onChangeFn={value => {
                  compFinderStore.cfMaster.compFinderName = value;
                }}
                editable
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 col-12 justify-content-center">
            <div className="col-4 d-flex">
              <LightButton
                className="flex-fill"
                onClick={() => {
                  this.save(compFinderStore);
                }}
              >
                Save
              </LightButton>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

SaveNewModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(SaveNewModal));
