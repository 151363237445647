import { extendObservable, action, autorun } from "mobx";
import { AlertConstants } from "../../constants/AlertConstants";

class LookupStore {
  constructor(authStore, alertStore, piaApi) {
    this.authStore = authStore;
    this.alertStore = alertStore;
    this.piaApi = piaApi;

    this.defaults = {
      lookups: {},
      collections: {},
      loaded: false,
      systemUsers: [],
      autocompletePlayers: [],
      autocompleteStats: [],
      autocompleteStatsOnly: [],
      compFinderUserGuideUrl: "",
      universeObjects: []
    };

    extendObservable(this, {
      lookups: this.defaults["lookups"],
      collections: this.defaults["collections"],
      loaded: this.defaults["loaded"],
      systemUsers: this.defaults["systemUsers"],
      autocompletePlayers: this.defaults["autocompletePlayers"],
      autocompleteStats: this.defaults["autocompleteStats"],
      autocompleteStatsOnly: this.defaults["autocompleteStatsOnly"],
      compFinderUserGuideUrl: this.defaults["compFinderUserGuideUrl"],
      universeObjects: this.defaults["universeObjects"],
      setLookups: action(lookups => {
        this.lookups = lookups;
      }),
      resetStore: action(() => {
        this.lookups = this.defaults["lookups"];
        this.collections = this.defaults["collections"];
        this.loaded = this.defaults["loaded"];
        this.systemUsers = this.defaults["systemUsers"];
        this.autocompletePlayers = this.defaults["autocompletePlayers"];
        this.autocompleteStats = this.defaults["autocompleteStats"];
        this.autocompleteStatsOnly = this.defaults["autocompleteStatsOnly"];
        this.compFinderUserGuideUrl = this.defaults["compFinderUserGuideUrl"];
        this.universeObjects = this.defaults["universeObjects"];
      })
    });

    autorun(() => {
      if (this.authStore.loggedIn) {
        this.fetchLookups();
        this.getSystemUsers();
        this.getAutocompletePlayers();
        this.getAutocompleteStats();
        this.getCompFinderUserGuideUrl();
      } else {
        this.resetStore();
      }
    });
  }

  fetchLookups() {
    this.lookupsPromise = this.piaApi.getUserLookups();

    this.lookupsPromise.then(lookupResponse => {
      if (!!lookupResponse) {
        this.lookups = lookupResponse;
      }

      this.piaApi.getCollections().then(collectionResponse => {
        this.postLoadCollections(collectionResponse);
      });
    });
  }

  reloadLookups(callback = () => {}) {
    this.piaApi.getUserLookups().then(lookupResponse => {
      if (!!lookupResponse) {
        this.lookups = lookupResponse;
        callback();
      }
    });
  }

  loadCollections() {
    this.piaApi.loadCollections().then(response => {
      this.postLoadCollections(response);
      this.alertStore.addAlert({
        type: AlertConstants.TYPES.SUCCESS,
        text: "Cached collections were successfully reloaded."
      });
    });
  }

  postLoadCollections(collectionResponse) {
    this.loaded = false;
    if (!!collectionResponse) {
      this.collections = collectionResponse;

      if (this.collections.leagueleaderstats) {
        let battingStats = [];
        let pitchingStats = [];

        this.collections.leagueleaderstats.forEach((stat, index) => {
          if (stat.category.toLowerCase().indexOf("pitching") > -1) {
            pitchingStats.push(stat);
          } else {
            battingStats.push(stat);
          }
        });

        // Sort by label
        battingStats.sort((a, b) => {
          let la = a.statName.toLowerCase();
          let lb = b.statName.toLowerCase();

          if (la < lb) {
            return -1;
          } else if (la > lb) {
            return 1;
          } else {
            return 0;
          }
        });

        // Sort by label
        pitchingStats.sort((a, b) => {
          let la = a.statName.toLowerCase();
          let lb = b.statName.toLowerCase();

          if (la < lb) {
            return -1;
          } else if (la > lb) {
            return 1;
          } else {
            return 0;
          }
        });

        this.collections.battingstats = battingStats;
        this.collections.pitchingstats = pitchingStats;
      }
    }
    this.loaded = true;
  }

  getSystemUsers() {
    this.piaApi.getSystemUsers().then(response => {
      if (!!response) {
        this.systemUsers = response;
      }
    });
  }

  getAutocompletePlayers() {
    // Get quick search players
    this.piaApi.getAutocompletePlayers().then(response => {
      if (!!response) {
        this.autocompletePlayers = response;
      }
    });
  }

  getAutocompleteStats() {
    // Get quick search stats
    this.piaApi.getAutocompleteStats().then(response => {
      if (!!response) {
        this.autocompleteStats = response;
        this.autocompleteStatsOnly = response.filter(stat => stat.statOnlyFlag);
      }
    });
  }

  loadAutocompletes() {
    this.piaApi.loadAutocompletes().then(response => {
      this.getAutocompletePlayers();
      this.getAutocompleteStats();
      this.alertStore.addAlert({
        type: AlertConstants.TYPES.SUCCESS,
        text: "Autocompletes were successfully reloaded."
      });
    });
  }

  getCompFinderUserGuideUrl() {
    this.piaApi.getCompFinderUserGuideUrl().then(response => {
      if (!!response) {
        this.compFinderUserGuideUrl = response;
      }
    });
  }

  getUniverseObjects() {
    if (this.universeObjects.length) {
      // Return stored value if populated
      return Promise.resolve(this.universeObjects);
    } else {
      // Get from server and store
      return this.piaApi.getUniverseObjects().then(response => {
        if (!!response) {
          this.universeObjects = response;
          return response;
        }
      });
    }
  }

  loadUniverseObjects() {
    this.piaApi.loadUniverseObjects().then(response => {
      if (!!response) {
        this.universeObjects = response;
      }

      this.alertStore.addAlert({
        type: AlertConstants.TYPES.SUCCESS,
        text: "Universe objects were successfully reloaded."
      });
    });
  }

  getBackendVersion() {
    return this.piaApi.getBackEndVersion();
  }

  getLookup(name) {
    if (this.lookups[name]) {
      return this.lookups[name];
    } else {
      return [];
    }
  }

  getCollection(name) {
    if (this.collections[name]) {
      return this.collections[name];
    } else {
      return [];
    }
  }

  getAndOrOptions() {
    return [
      { value: "OR", label: "OR" },
      { value: "AND", label: "AND" }
    ];
  }

  // Get an item from a dropdown by lookup id
  getDropdownItem(options, id, valueProp = "value", labelProp = "label") {
    // Given an empty list or empty id
    if (!options || id === null || id === undefined) {
      return "";
    }

    // Given a dropdown item already
    if (id[valueProp] && id[labelProp]) {
      return id;
    }

    return options.find(item => {
      if (item[valueProp] === id || Number(item[valueProp]) === id) {
        // Given dropdown list
        return item;
      } else if (item.id === id || Number(item.id) === id) {
        // Given lookup list
        return {
          value: item.id,
          label: item.value
        };
      } else {
        return "";
      }
    });
  }
}

export default LookupStore;
