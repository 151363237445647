import React from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactToolTip from "react-tooltip";
import { Icon, Input } from "best-common-react";

const StyledIconHolder = styled.span`
  margin-right: 4px;
`;

const StyledInput = styled(Input)`
  width: 300px;
  height: 30px;
  padding-left: 6px;
  font-size: 18px;
  font-weight: bold;

  &:disabled {
    color: #00549d;
    background-color: transparent;
  }
`;

const StyledHoverDiv = styled.div`
  color: "#00549D";

  &:hover {
    width: 300px;
    height: 30px;
    padding-left: 6px;
    padding-top: 4px;
    font-size: 18px;
    font-weight: bold;
    color: #00549d !important;
    background-color: transparent;
    border: 1px solid #d2d2d2;
  }
`;

const StyledDisabledDiv = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
`;

class HeaderInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      showInput: false,
      editMode: false
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.editMode && !prevState.editMode) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const { value, ...rest } = this.props;
    return (
      <React.Fragment>
        <div
          className={"form-inline header-input " + (this.props.className ? this.props.className : "")}
          data-for="headerInputTip"
          data-tip=""
          onClick={() => {
            if (!this.state.editMode) {
              this.setState({ editMode: true, showInput: true });
            }
          }}
        >
          <StyledIconHolder>
            <Icon
              iconName="fa-edit"
              onClick={() => {
                this.setState({ showInput: true, editMode: true });
              }}
              styles={{ color: "#00549D !important", hoverColor: "#00549D !important" }}
            />
          </StyledIconHolder>
          {this.state.showInput ? (
            <span style={{ position: "relative" }}>
              <StyledInput ref={this.inputRef} value={value} disabled={!this.state.editMode} {...rest} />
              {this.state.editMode ? (
                ""
              ) : (
                <React.Fragment>
                  <StyledDisabledDiv
                    onMouseOut={() => {
                      this.setState({ showInput: false });
                    }}
                  ></StyledDisabledDiv>
                </React.Fragment>
              )}
            </span>
          ) : (
            <React.Fragment>
              <StyledHoverDiv>
                <h5>{value}</h5>
              </StyledHoverDiv>
            </React.Fragment>
          )}
        </div>
        {this.state.editMode ? (
          ""
        ) : (
          <ReactToolTip id="headerInputTip" effect="solid" place="bottom">
            Click to edit name
          </ReactToolTip>
        )}
      </React.Fragment>
    );
  }
}

HeaderInput.defaultProps = {
  value: ""
};

HeaderInput.propTypes = {
  rootStore: PropTypes.object.isRequired,
  value: PropTypes.string,
  className: PropTypes.string
};

export default inject("rootStore")(observer(HeaderInput));
