import React from "react";
import { inject, observer } from "mobx-react";
import { BestSelect, LightButton, Modal } from "best-common-react";
import PropTypes from "prop-types";
import { FormSelectStyles } from "../../Theme";

class LoadQueryModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      queryId: 0,
      avplFlg: false
    };
  }

  close(playerStore) {
    this.setState(this.getDefaultState());
    playerStore.playerList.loadQueryModalOpen = false;
  }

  render() {
    let { lookupStore, playerStore } = this.props.rootStore;

    return (
      <Modal show={!!playerStore.playerList.loadQueryModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Load Query"
          close={() => {
            this.close(playerStore);
          }}
        />
        <Modal.Body>
          <div className="row mb-2">
            <div className="col-10 pr-0">
              <BestSelect
                name="loadQueryId"
                value={lookupStore.getDropdownItem(
                  playerStore.displayQueries,
                  this.state.queryId,
                  "queryId",
                  "playerQueryName"
                )}
                onChange={option => {
                  this.setState({ queryId: option.queryId, avplFlg: option.avplFlg });
                }}
                getOptionValue={option => option.queryId}
                getOptionLabel={option => option.playerQueryName}
                options={playerStore.displayQueries}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-2 d-flex flex-fill">
              <LightButton
                className="flex-fill"
                onClick={() => {
                  if (!this.state.queryId) {
                    return;
                  }

                  playerStore.openQuery(
                    playerStore.playerList.playerlistId || 0,
                    this.state.queryId,
                    this.state.avplFlg
                  );
                  this.close(playerStore);
                }}
              >
                Load Query
              </LightButton>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            <LightButton
              onClick={() => {
                this.close(playerStore);
              }}
            >
              Close
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

LoadQueryModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(LoadQueryModal));
