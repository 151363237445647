import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { TabSwitcher } from "best-common-react";
import { TabSwitcherStyles } from "../../Theme";
import ArbitrationInfo from "./ArbitrationInfo";
import Awards from "./Awards";
import Contracts from "./Contracts";
import News from "./News";
import { PlayerConstants } from "../../constants/PlayerConstants";
import QuickView from "./QuickView";
import Reports from "./Reports";
import Service from "./Service";
import Statistics from "./Statistics";
import Transactions from "./Transactions";

class ProfileTabs extends React.Component {
  getTabs() {
    return [
      {
        name: "Quick View",
        component: <QuickView />
      },
      {
        name: "Contracts",
        component: <Contracts />
      },
      {
        name: "Service",
        component: <Service />
      },
      {
        name: "Arbitration Information",
        component: <ArbitrationInfo />
      },
      {
        name: "Statistics",
        component: <Statistics />
      },
      {
        name: "Transactions",
        component: <Transactions />
      },
      {
        name: "Awards",
        component: <Awards />
      },
      {
        name: "Reports",
        component: <Reports />
      },
      {
        name: "News",
        component: <News />
      }
    ];
  }

  onSwitch() {
    let { playerStore } = this.props.rootStore;
    playerStore.playerStatSplitType = PlayerConstants.STAT_SPLIT_TYPES.CAREER;
  }

  render() {
    return (
      <React.Fragment>
        <TabSwitcher
          tabs={this.getTabs()}
          onSwitch={() => {
            this.onSwitch();
          }}
          hasPadding={false}
          styles={TabSwitcherStyles}
        />
      </React.Fragment>
    );
  }
}

ProfileTabs.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ProfileTabs));
