import React, { Component, Fragment } from "react";
import { Redirect, withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { RouteConstants } from "../constants/RouteConstants";

class HomeContainer extends Component {
  render() {
    const { authStore } = this.props.rootStore;

    return (
      <Fragment>
        {!authStore.loggedIn && !this.props.location.key ? ( // Not logged in or logging in
          <Redirect to={RouteConstants.LOGIN.route} />
        ) : !authStore.loggedIn ? ( // Currently logging in
          ""
        ) : (
          <Redirect to={RouteConstants.HOME_PLATE.route} />
        )}
      </Fragment>
    );
  }
}

HomeContainer.propTypes = {
  rootStore: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default inject("rootStore")(withRouter(observer(HomeContainer)));
