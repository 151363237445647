import React from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { BestSelect, CardContainer, DangerButton, LightButton, PrimaryButton } from "best-common-react";
import { FormSelectStyles, InlineHeader } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";
import { RouteConstants } from "../../constants/RouteConstants";
import { SharingConstants } from "../../constants/SharingConstants";

class SavedReports extends React.Component {
  getDisplayFolderOptions(reportStore) {
    let folders = _.cloneDeep(reportStore.folders);
    folders.unshift({ folderId: -1, folderName: "All Folders" });
    return folders;
  }

  render() {
    let { lookupStore, reportStore, routerStore } = this.props.rootStore;
    let columns = reportStore.getSavedReportCols();
    let displayFolderOptions = this.getDisplayFolderOptions(reportStore);
    const onRowsSelected = rows => {
      reportStore.addSelectedReports(rows.map(row => row.row));
    };
    const onRowsDeselected = rows => {
      reportStore.removeUnselectedReports(rows.map(row => row.row));
    };
    return (
      <PiaWidget className="col-12 table-container">
        <CardContainer>
          <CardContainer.Header>
            <div className="header-item-row justify-content-between">
              <span className="header-title">Search Results</span>
              <div className="d-flex col-6 pr-0 justify-content-end align-items-center">
                <InlineHeader className="item">Show Folder</InlineHeader>

                <div className="d-inline-flex w-50 item">
                  <div className="col-12 p-0">
                    <BestSelect
                      name="mcDisplayFolderId"
                      value={lookupStore.getDropdownItem(
                        displayFolderOptions,
                        reportStore.displayFolderId,
                        "folderId",
                        "folderName"
                      )}
                      onChange={option => {
                        reportStore.displayFolderId = option.folderId;
                      }}
                      options={displayFolderOptions}
                      getOptionValue={option => option.folderId}
                      getOptionLabel={option => option.folderName}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
                <PrimaryButton
                  onClick={() => {
                    reportStore.openFolderModal = true;
                  }}
                >
                  Manage Folders
                </PrimaryButton>
              </div>
            </div>
          </CardContainer.Header>
          <CardContainer.Body>
            {reportStore.displaySavedReports.length > 0 ? (
              <PiaTable
                data={reportStore.displaySavedReports}
                columns={columns}
                rowSelection={{
                  showCheckbox: true,
                  onRowsSelected: onRowsSelected,
                  onRowsDeselected: onRowsDeselected,
                  selectBy: {
                    isSelectedKey: "isSelected"
                  },
                  enableShiftSelect: true
                }}
                height={reportStore.calculateTableHeight(reportStore.displaySavedReports.length, 308)}
              />
            ) : (
              <div className="col-12">No Results</div>
            )}
          </CardContainer.Body>
          <CardContainer.Footer>
            <div className="header-item-row justify-content-between">
              <div className="d-flex col-6 pl-0 align-items-center">
                <InlineHeader className="item">Move To Folder</InlineHeader>

                <div className="d-inline-flex w-50 item">
                  <div className="col-12 p-0">
                    <BestSelect
                      name="mcFolderId"
                      value={lookupStore.getDropdownItem(
                        reportStore.folders,
                        reportStore.moveToFolderId,
                        "folderId",
                        "folderName"
                      )}
                      onChange={option => {
                        reportStore.moveToFolderId = option.folderId;
                      }}
                      options={reportStore.folders}
                      getOptionValue={option => option.folderId}
                      getOptionLabel={option => option.folderName}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
                <LightButton
                  onClick={() => {
                    reportStore.moveReports();
                  }}
                >
                  Move
                </LightButton>
              </div>
              <div>
                <LightButton
                  onClick={() => {
                    routerStore.pushHistory(RouteConstants.SHARING.route + "/" + SharingConstants.TYPE.REPORTS, false);
                  }}
                >
                  Share
                </LightButton>
                <DangerButton
                  onClick={() => {
                    reportStore.deleteReports();
                  }}
                >
                  Delete
                </DangerButton>
              </div>
            </div>
          </CardContainer.Footer>
        </CardContainer>
      </PiaWidget>
    );
  }
}

SavedReports.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(SavedReports)));
