export const AuthConstants = {
  KEYS: {
    OKTA_TOKEN_STORAGE: "okta-token-storage",
    USER_DATA: "user_data",
    ACCESS_TOKEN: "accessToken",
    ID_TOKEN: "idToken",
    AUTHORITIES: "authorities",
    PIA_BO_ENCODED_AUTH: "PIA_BO_ENCODED_AUTH",
    PIA_BO_TOKEN: "PIA_BO_TOKEN"
  }
};
