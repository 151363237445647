import React from "react";
import { inject, observer } from "mobx-react";
import { FormInput, Icon, LightButton, Modal, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";
import _ from "lodash";

class NewTimeFrameModal extends React.Component {
  getDetailCols(compStore) {
    return [
      {
        key: "tfName",
        name: "TF Name",
        formatter: row => {
          return (
            <span className="d-flex justify-content-between">
              <span>{row.row.contractLengthAttribute + row.row.luTimeframetype.description}</span>
              {"Career" !== row.row.luTimeframetype.description ? (
                <span className="tf-range">
                  {row.row.genericFrom}
                  {"-" !== row.row.genericThrough ? (
                    <React.Fragment>
                      <span className="tf-range-separator">&nbsp;through&nbsp;</span>
                      {row.row.genericThrough}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </span>
              ) : (
                ""
              )}
            </span>
          );
        }
      },
      {
        key: "delete",
        name: "",
        width: 40,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Icon
                className="mlb-red-icon"
                iconName="fa-trash-alt"
                onClick={() => {
                  let index = compStore.modalTimeFrame.tfDetail.indexOf(row.row);
                  if (index > -1) {
                    compStore.modalTimeFrame.tfDetail.splice(index, 1);
                  }
                }}
              />
            </div>
          );
        }
      }
    ];
  }

  close(compStore) {
    compStore.timeFrameModalOpen = false;
  }

  save(compStore) {
    compStore.saveTimeFrame(compStore.modalTimeFrame, this.props.tab);
    this.close(compStore);
  }

  delete(compStore) {
    compStore.deleteTimeFrame(this.props.tab);
    this.close(compStore);
  }

  render() {
    let { compStore } = this.props.rootStore;

    return (
      <Modal show={compStore.timeFrameModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="New List"
          close={() => {
            this.close(compStore);
          }}
        />
        <Modal.Body>
          <div>
            <FormInput
              name="listName"
              label="List Name"
              type="text"
              value={compStore.modalTimeFrame.timeframeName}
              onChangeFn={value => {
                compStore.modalTimeFrame.timeframeName = value;
              }}
              editable
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="header-item-row">
            <PrimaryButton
              onClick={() => {
                this.save(compStore);
              }}
            >
              Save
            </PrimaryButton>

            <LightButton
              onClick={() => {
                this.close(compStore);
              }}
            >
              Cancel
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

NewTimeFrameModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  tab: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(NewTimeFrameModal));
