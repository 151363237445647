import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { TabSwitcher } from "best-common-react";
import { RouteConstants } from "../../constants/RouteConstants";
import ShareItems from "./ShareItems";
import SharingHistory from "./SharingHistory";
import { TabSwitcherStyles } from "../../Theme";

class SharingTabs extends React.Component {
  getTabs() {
    return [
      {
        name: RouteConstants.SHARING.tabs[0],
        component: <ShareItems />
      },
      {
        name: RouteConstants.SHARING.tabs[1],
        component: <SharingHistory />
      }
    ];
  }

  render() {
    return <TabSwitcher tabs={this.getTabs()} hasPadding={false} styles={TabSwitcherStyles} />;
  }
}

SharingTabs.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(SharingTabs));
