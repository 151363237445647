import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  BestSelect,
  CardContainer,
  Collapse,
  DateTimeFormatter,
  Icon,
  PriceFormatter,
  BestLabel
} from "best-common-react";
import Theme, { FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import TitleCard from "../widgets/TitleCard";
import ToFixedFormatter from "../utilities/ToFixedFormatter";

const ContractContainer = styled.div.attrs({
  className: "container-fluid"
})`
  .best-collapse {
    display: flex;
    flex-direction: column;

    [class^="Collapse__Header-"],
    [class*=" Collapse__Header-"] {
      margin-bottom: 0 !important;
      background-color: ${props => props.theme["light-blue"]};
      border-bottom: solid 1px ${props => props.theme.fog} !important;
      justify-content: space-between;
      align-items: center;
      height: 28px;

      & > *:first-child {
        margin-left: ${props => props.theme["size-gutter"]};
      }

      & > :last-child {
        margin-right: ${props => props.theme["size-gutter"]};
      }

      [class^="Collapse__Title-"],
      [class*=" Collapse__Title-"] {
        width: auto !important;
      }
    }

    & > *:not(:first-child) {
      width: 90%;
      align-self: flex-end;
    }

    & > *:nth-child(2) {
      margin-top: ${props => props.theme["size-gutter"]};
    }

    & > *:last-child {
      margin-bottom: ${props => props.theme["size-gutter"]};
    }
  }
`;

class Contracts extends React.Component {
  getContractTypes() {
    return [
      { value: 0, label: "Major League Contracts" },
      { value: 1, label: "Minor League Contracts" }
    ];
  }

  getContractsData(type) {
    let data = {};

    if (0 === type) {
      data.listExportProp = "contracts";
      data.detailsTitle = "Additional Contract Details";
      data.provisionsTitle = "Additional Contract Provisions";
    } else {
      data.listExportProp = "mncontracts";
      data.detailsTitle = "Addendum C Details";
      data.provisionsTitle = "Addendum B and D Details";
    }

    return data;
  }

  getListCols(playerStore) {
    return [
      {
        key: "startYear",
        name: "Start Year",
        width: 90,
        formatter: row => (
          <a
            href="/#"
            onClick={event => {
              event.preventDefault();
              playerStore.getContractDetails(row.row.contractNumber);
            }}
          >
            <span>{row.value}</span>
          </a>
        )
      },
      { key: "contractStatus", name: "Status", width: 120 },
      {
        key: "confirmedMls",
        name: "Confirmed MLS",
        width: 120,
        formatter: row => <ToFixedFormatter value={row.value} />
      },
      {
        key: "confirmedDate",
        name: "Confirmed Terms Date",
        width: 160,
        formatter: row => <DateTimeFormatter value={row.value} />
      },
      { key: "club", name: "Org", width: 60 },
      { key: "contractLength", name: "Years", width: 60 },
      { key: "aav", name: "AAV", width: 150, formatter: row => <PriceFormatter value={row.value} /> },

      {
        key: "totalSalary",
        name: "Total Guaranteed",
        width: 150,
        formatter: row => <PriceFormatter value={row.value} />
      },
      {
        key: "earnedBonuses",
        name: "Earned Bonuses",
        width: 150,
        formatter: row => <PriceFormatter value={row.value} />
      },
      {
        key: "viewInEbis",
        name: "",
        formatter: row => (
          <span>
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                window.open(
                  "https://ebis.mlb.com/ebisui/#/major-contract?fromPIA=true&action=load&mjcId=" +
                    row.row.ebisContractNumber
                );
              }}
            >
              <span>EBIS&nbsp;</span>
              <Icon
                iconName="fa-external-link-alt"
                styles={{
                  color: Theme["link-primary"] + " !important",
                  hoverColor: Theme["link-primary"] + " !important"
                }}
              />
            </a>
          </span>
        )
      }
    ];
  }

  getMnListCols(playerStore) {
    return [
      {
        key: "startYear",
        name: "Start Year",
        width: 90,
        formatter: row => (
          <a
            href="/#"
            onClick={event => {
              event.preventDefault();
              playerStore.getMnContractDetails(row.row.mnContractId);
            }}
          >
            <span>{row.value}</span>
          </a>
        )
      },
      { key: "status", name: "Status", width: 120 },
      {
        key: "signingDte",
        name: "Signing Date",
        width: 110,
        formatter: row => <DateTimeFormatter value={row.value} />
      },
      { key: "contractLength", name: "Years", width: 60 },
      { key: "orgShortname", name: "Org", width: 60 },
      { key: "clubShortname", name: "Club", width: 200 },
      { key: "lop", name: "Level of Play" }
    ];
  }

  getContractCompTable(contract) {
    let { piaApi } = this.props.rootStore;
    let headerYears = [];
    let baseSalaries = [];
    let guaranteedSalaries = [];
    let splitSalaries = [];
    let signingBonuses = [];
    let escalators = [];
    let buyouts = [];
    let bonuses = [];
    let lrdSalaries = [];
    let awards = [];
    let perfOffered = [];
    let escOffered = [];

    if (contract.contractDetails) {
      contract.contractDetails.forEach((detail, index) => {
        headerYears.push(
          <th key={"year_" + index} style={{ width: "150px" }}>
            {detail.year}&nbsp;{detail.optionType}
          </th>
        );
        baseSalaries.push(
          <td key={"baseSalary_" + index}>
            {detail.compScheduleAvailable ? (
              <a
                href="/#"
                onClick={event => {
                  event.preventDefault();
                  piaApi.getPlayerCompSchedule(detail.contractId, detail.year).then(response => {
                    if (response) {
                      contract.currentPaySchedule = response;
                    }
                  });
                }}
              >
                <PriceFormatter value={detail.baseSalary} />
              </a>
            ) : (
              <PriceFormatter value={detail.baseSalary} />
            )}
          </td>
        );
        guaranteedSalaries.push(
          <td key={"guaranteedSalary_" + index}>
            <PriceFormatter value={detail.guaranteedSalary} />
          </td>
        );

        splitSalaries.push(
          <td key={"splitSalary_" + index}>
            {detail.splitSalary ? (
              <a
                href="/#"
                onClick={event => {
                  event.preventDefault();
                  piaApi.getPlayerContractSplits(detail.contractId, detail.year).then(response => {
                    if (response) {
                      contract.currentSplits = response;
                    }
                  });
                }}
              >
                <PriceFormatter value={detail.splitSalary} />
              </a>
            ) : (
              <PriceFormatter value={detail.splitSalary} />
            )}
          </td>
        );
        signingBonuses.push(
          <td key={"signingBonus_" + index}>
            {detail.signingBonusScheduleAvailable ? (
              <a
                href="/#"
                onClick={event => {
                  event.preventDefault();
                  piaApi.getPlayerSigningBonusSchedule(detail.contractId, detail.year).then(response => {
                    if (response) {
                      contract.currentPaySchedule = response;
                    }
                  });
                }}
              >
                <PriceFormatter value={detail.proratedSigningBonus} />
              </a>
            ) : (
              <PriceFormatter value={detail.proratedSigningBonus} />
            )}
          </td>
        );
        escalators.push(
          <td key={"escalators_" + index}>
            <PriceFormatter value={detail.escalators} />
          </td>
        );
        buyouts.push(
          <td key={"buyouts_" + index}>
            {detail.buyoutScheduleAvailable ? (
              <a
                href="/#"
                onClick={event => {
                  event.preventDefault();
                  piaApi.getPlayerBuyoutSchedule(detail.contractId, detail.optionsId).then(response => {
                    if (response) {
                      contract.currentPaySchedule = response;
                    }
                  });
                }}
              >
                <PriceFormatter value={detail.proratedSigningBonus} />
              </a>
            ) : (
              <PriceFormatter value={detail.proratedSigningBonus} />
            )}
          </td>
        );
        bonuses.push(
          <td key={"bonus_" + index}>
            <PriceFormatter value={detail.earnedBonuses} />
          </td>
        );
        lrdSalaries.push(
          <td key={"lrdSalary_" + index}>
            <PriceFormatter value={detail.lrdSalary} />
          </td>
        );
        awards.push(
          <td key={"award_" + index}>
            <PriceFormatter value={detail.awardOffered} />
          </td>
        );
        perfOffered.push(
          <td key={"performanceOffered_" + index}>
            <PriceFormatter value={detail.performanceOffered} />
          </td>
        );
        escOffered.push(
          <td key={"escOffered_" + index}>
            <PriceFormatter value={detail.escalatorOffered} />
          </td>
        );
      });
    }

    return (
      <table className="layout-fixed">
        <thead>
          <tr>
            <th style={{ width: "200px" }}>Option Type</th>
            {headerYears}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Base Salary</td>
            {baseSalaries}
          </tr>
          <tr>
            <td>Guaranteed Salary</td>
            {guaranteedSalaries}
          </tr>
          <tr>
            <td>Split Salary</td>
            {splitSalaries}
          </tr>
          <tr>
            <td>Prorated Signing Bonus</td>
            {signingBonuses}
          </tr>
          <tr>
            <td>Earned Escalators</td>
            {escalators}
          </tr>
          <tr>
            <td>Buyout</td>
            {buyouts}
          </tr>
          <tr>
            <td>Earned Bonuses</td>
            {bonuses}
          </tr>
          <tr>
            <td>Total LRD Salary</td>
            {lrdSalaries}
          </tr>
          <tr>
            <td>Award Offered</td>
            {awards}
          </tr>
          <tr>
            <td>Performance Offered</td>
            {perfOffered}
          </tr>
          <tr>
            <td>Escalator Offered</td>
            {escOffered}
          </tr>
        </tbody>
      </table>
    );
  }

  getAddendumCDetails(details) {
    let headerYears = [];
    let singleSalaries = [];
    let monthlySalaries = [];
    let tenderDates = [];
    let signingDates = [];
    let orgs = [];
    let clubs = [];
    let noContractYearFlags = [];
    let statuses = [];

    if (details && details.addendumCAsList) {
      details.addendumCAsList.forEach((detail, index) => {
        headerYears.push(
          <th key={"year_" + index} style={{ width: "150px" }}>
            {detail.year}
          </th>
        );
        singleSalaries.push(
          <td key={"singleSalary_" + index}>
            <PriceFormatter value={detail.singleSalaryAmount} />
          </td>
        );
        monthlySalaries.push(
          <td key={"monthlySalary_" + index}>
            {detail.multipleSalaries ? (
              <a
                href="/#"
                onClick={event => {
                  event.preventDefault();
                  let addC = details.addendumCMap[detail.id] || {};
                  details.currentSalary = addC.salaries;
                }}
              >
                <PriceFormatter value={detail.highestMonthlySalary} />
              </a>
            ) : (
              <PriceFormatter value={detail.highestMonthlySalary} />
            )}
          </td>
        );
        tenderDates.push(
          <td key={"tenderDate_" + index}>
            <DateTimeFormatter value={detail.tenderDate} />
          </td>
        );
        signingDates.push(
          <td key={"signingDate_" + index}>
            <DateTimeFormatter value={detail.signingDate} />
          </td>
        );
        orgs.push(<td key={"org_" + index}>{detail.orgShortname}</td>);
        clubs.push(<td key={"club_" + index}>{detail.clubShortname}</td>);
        noContractYearFlags.push(
          <td key={"noContractYearFlag_" + index}>{detail.noContractYearFlag ? "Yes" : "No"}</td>
        );
        statuses.push(<td key={"status_" + index}>{detail.status}</td>);
      });
    }

    return (
      <table className="layout-fixed">
        <thead>
          <tr>
            <th style={{ width: "200px" }}>Year</th>
            {headerYears}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Single Salary Amount</td>
            {singleSalaries}
          </tr>
          <tr>
            <td>Monthly Salary</td>
            {monthlySalaries}
          </tr>
          <tr>
            <td>Tender Date</td>
            {tenderDates}
          </tr>
          <tr>
            <td>Signing Date</td>
            {signingDates}
          </tr>
          <tr>
            <td>Org</td>
            {orgs}
          </tr>
          <tr>
            <td>Club</td>
            {clubs}
          </tr>
          <tr>
            <td>No Contract Year</td>
            {noContractYearFlags}
          </tr>
          <tr>
            <td>Status</td>
            {statuses}
          </tr>
        </tbody>
      </table>
    );
  }

  getContractDetailTable(contract, org) {
    return (
      <table>
        <tbody>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Contract Type</BestLabel>
              <span>{contract.contractType}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Contract Number</BestLabel>
              <span>{contract.ebisContractNumber}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Signing Org</BestLabel>
              <span>{contract.club}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Current Org</BestLabel>
              <span>{org}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Signing Date</BestLabel>
              <span>
                <DateTimeFormatter value={contract.signingDate} />
              </span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>CT Date</BestLabel>
              <span>
                <DateTimeFormatter value={contract.confirmedDate} />
              </span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Start Year</BestLabel>
              <span>{contract.startYear}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Guaranteed Years</BestLabel>
              <span>{contract.guranteedYears}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Option Years</BestLabel>
              <span>{contract.optionYears}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Signing MLS</BestLabel>
              <span>
                <ToFixedFormatter value={contract.signingMls} />
              </span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>CT MLS</BestLabel>
              <span>
                <ToFixedFormatter value={contract.confirmedMls} />
              </span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Agent</BestLabel>
              <span>{contract.signingAgent}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>AAV</BestLabel>
              <span>
                <PriceFormatter value={contract.aav} />
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  getPayScheduleCols() {
    return [
      {
        key: "paymentDate",
        name: "Date",
        width: 120,
        formatter: row => {
          if (row.value) {
            return <DateTimeFormatter value={row.value} />;
          } else {
            return <span>Within {row.row.payDaysFrom} day(s)</span>;
          }
        }
      },
      { key: "paymentAmount", name: "Amount", formatter: row => <PriceFormatter value={row.value} /> },
      { key: "presentValue", name: "LRD Present Value", formatter: row => <PriceFormatter value={row.value} /> }
    ];
  }

  getSplitCols() {
    return [
      { key: "levelofPlay", name: "Level Of Play", width: 300 },
      { key: "amount", name: "Amount", formatter: row => <PriceFormatter value={row.value} /> }
    ];
  }

  getMnSalaryCols() {
    return [
      { key: "monthlySalary", name: "Amount", width: 120, formatter: row => <PriceFormatter value={row.value} /> },
      { key: "salaryLevelOfPlay", name: "Level Of Play", width: 120 },
      { key: "salaryClubNames", name: "Club Names" }
    ];
  }

  getAdditionalDetailsAndProvisions(player) {
    let options = [];
    let covenants = [];
    let awardBonuses = [];
    let performanceBonuses = [];
    let otherBonuses = [];
    let escalators = [];
    let assignments = [];
    let comments = [];

    if (player.contractDetailsAndProvisions.options) {
      player.contractDetailsAndProvisions.options.forEach((option, index) => {
        let terms = [];

        if (option.optionTerms) {
          option.optionTerms.forEach((term, ti) => {
            let termLabel = (ti > 0 ? term.expression + " " : "") + term.term;
            termLabel += (term.placeFinishThreshold ? term.placeFinishThreshold + " " : "") + term.criteriaDescription;

            if (term.instance > 1) {
              termLabel += " (Instance " + term.instance + ")";
            }

            terms.push(
              <tr key={"term_" + ti}>
                <td>{termLabel}</td>
              </tr>
            );
          });
        }

        let label =
          option.year +
          " " +
          option.optionTypeFull +
          " Option for " +
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0
          }).format(option.baseSalary);

        if (option.buyout) {
          label +=
            " (" +
            new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 0
            }).format(option.buyout) +
            " Buyout)";
        }

        if ("D" === option.optionType) {
          label += " - If Met Becomes " + option.ifMetWillBecomeFull + " Option";
        }

        if (option.exercisedOption) {
          label = <BestLabel>{label}</BestLabel>;
        }

        options.push(
          <Collapse
            key={"option_" + index}
            title={label}
            iconAfterTitle={true}
            expandIcon="fa-chevron-down"
            collapseIcon="fa-chevron-up"
            emptyText=" "
          >
            {terms.length > 0 ? (
              <table>
                <tbody>{terms}</tbody>
              </table>
            ) : (
              terms
            )}
          </Collapse>
        );
      });
    }

    if (player.contractDetailsAndProvisions.covenants) {
      player.contractDetailsAndProvisions.covenants.forEach((covenant, index) => {
        let lnts = [];

        if (covenant.lnts) {
          covenant.lnts.forEach((lnt, li) => {
            let teams = [];

            if (lnt.teams) {
              lnt.teams.forEach((team, ti) => {
                teams.push(
                  <tr key={"team_" + ti}>
                    <td>{team}</td>
                  </tr>
                );
              });
            }

            lnts.push(
              <Collapse
                key={"lnt_" + li}
                title={lnt.description}
                iconAfterTitle={true}
                expandIcon="fa-chevron-down"
                collapseIcon="fa-chevron-up"
                emptyText=" "
              >
                {teams.length > 0 ? (
                  <table>
                    <tbody>{teams}</tbody>
                  </table>
                ) : (
                  teams
                )}
              </Collapse>
            );
          });
        }

        covenants.push(
          <Collapse
            key={"covenant_" + index}
            title={covenant.covenantName}
            iconAfterTitle={true}
            expandIcon="fa-chevron-down"
            collapseIcon="fa-chevron-up"
            emptyText=" "
          >
            {lnts}
          </Collapse>
        );
      });
    }

    this.populateBonusList(player.contractDetailsAndProvisions.awardsBonuses, awardBonuses, "placeFinishes");
    this.populateBonusList(player.contractDetailsAndProvisions.performanceBonuses, performanceBonuses, "thresholds");
    this.populateBonusList(player.contractDetailsAndProvisions.otherBonuses, otherBonuses, "thresholds");
    this.populateBonusList(player.contractDetailsAndProvisions.salaryEscalators, escalators);
    this.populateBonusList(player.contractDetailsAndProvisions.assignments, assignments);

    if (player.contractDetailsAndProvisions.commentBeans) {
      player.contractDetailsAndProvisions.commentBeans.forEach((comment, index) => {
        let list = [];

        if (comment.commentText && comment.commentText.length) {
          let lines = comment.commentText.split("* ");

          lines.forEach((line, li) => {
            if (line && line.length) {
              list.push(
                <tr key={"line_" + li}>
                  <td>{line.replace("\r\n", "")}</td>
                </tr>
              );
            }
          });
        }

        comments.push(
          <Collapse
            key={"comment_" + index}
            title={comment.commentType}
            iconAfterTitle={true}
            expandIcon="fa-chevron-down"
            collapseIcon="fa-chevron-up"
            emptyText=" "
          >
            {list.length > 0 ? (
              <table>
                <tbody>{list}</tbody>
              </table>
            ) : (
              list
            )}
          </Collapse>
        );
      });
    }

    return (
      <React.Fragment>
        <div className="row mx-0">
          <div className="col-4 pl-0">{this.getContractDetailTable(player.currentContract, player.org)}</div>

          <div className="col-8 pr-0">
            <Collapse
              title={<BestLabel>Options</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText="(None Available)"
            >
              {options}
            </Collapse>
            <Collapse
              title={<BestLabel>Covenants</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText="(None Available)"
            >
              {covenants}
            </Collapse>
            <Collapse
              title={<BestLabel>Award Bonuses</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText="(None Available)"
            >
              {awardBonuses}
            </Collapse>
            <Collapse
              title={<BestLabel>Performance Bonuses</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText="(None Available)"
            >
              {performanceBonuses}
            </Collapse>
            <Collapse
              title={<BestLabel>Other Bonuses</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText="(None Available)"
            >
              {otherBonuses}
            </Collapse>
            <Collapse
              title={<BestLabel>Salary Escalators</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText="(None Available)"
            >
              {escalators}
            </Collapse>
            <Collapse
              title={<BestLabel>Assignment Bonuses</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText="(None Available)"
            >
              {assignments}
            </Collapse>
            <Collapse
              title={<BestLabel>Comments</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText="(None Available)"
            >
              {comments}
            </Collapse>
          </div>
        </div>
      </React.Fragment>
    );
  }

  populateBonusList(bonuses, list, placeOrThresholdProperty) {
    if (bonuses) {
      bonuses.forEach((bonus, index) => {
        let earnedList = [];
        let placeOrThresholdList = [];

        if (bonus.earnedList) {
          bonus.earnedList.forEach((earned, ei) => {
            earnedList.push(
              <tr key={"earned_" + ei}>
                <td>{earned.description}</td>
              </tr>
            );
          });
        } else if (placeOrThresholdProperty && bonus[placeOrThresholdProperty]) {
          bonus[placeOrThresholdProperty].forEach((prop, pi) => {
            let nestedList = [];

            if (prop.earnedList) {
              prop.earnedList.forEach((earned, ei) => {
                nestedList.push(
                  <tr key={"earned_" + ei}>
                    <td>{earned.description}</td>
                  </tr>
                );
              });
            }

            placeOrThresholdList.push(
              <Collapse
                key={"place_" + pi}
                title={prop.hasEarnedMoney ? <BestLabel>{prop.description}</BestLabel> : prop.description}
                iconAfterTitle={true}
                expandIcon="fa-chevron-down"
                collapseIcon="fa-chevron-up"
                emptyText=" "
              >
                {nestedList.length > 0 ? (
                  <table>
                    <tbody>{nestedList}</tbody>
                  </table>
                ) : (
                  nestedList
                )}
              </Collapse>
            );
          });
        }

        list.push(
          <Collapse
            key={"bonus_" + index}
            title={bonus.hasEarnedMoney ? <BestLabel>{bonus.description}</BestLabel> : bonus.description}
            iconAfterTitle={true}
            expandIcon="fa-chevron-down"
            collapseIcon="fa-chevron-up"
            emptyText=" "
          >
            {earnedList.length > 0 ? (
              <table>
                <tbody>{earnedList}</tbody>
              </table>
            ) : (
              placeOrThresholdList
            )}
          </Collapse>
        );
      });
    }
  }

  getMnContractDetailTable(contract) {
    return (
      <table>
        <tbody>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Contract Number</BestLabel>
              <span>{contract.contractId}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>EBIS Contract Number</BestLabel>
              <span>{contract.ebisContractNumber}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Signing Org</BestLabel>
              <span>{contract.org}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Signing Club</BestLabel>
              <span>{contract.club}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Level of Play</BestLabel>
              <span>{contract.levelOfPlay}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Contract Status</BestLabel>
              <span>{contract.contractStatus}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Contract Length</BestLabel>
              <span>{contract.contractLength}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>First Year Contract</BestLabel>
              <span>{contract.firstYearFlag ? "Yes" : "No"}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Signing Date</BestLabel>
              <span>
                <DateTimeFormatter value={contract.signingDate} />
              </span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Terms Agreed Upon Date</BestLabel>
              <span>
                <DateTimeFormatter value={contract.termsDate} />
              </span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Authorizing Signatory</BestLabel>
              <span>{contract.authorizingSignatory}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Negotiator 1</BestLabel>
              <span>{contract.negotiator1}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Negotiator 2</BestLabel>
              <span>{contract.negotiator2}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Addendum F</BestLabel>
              <span>{contract.addendumFFlag ? "Yes" : "No"}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Addendum F State</BestLabel>
              <span>{contract.addendumFState}</span>
            </td>
          </tr>
          <tr>
            <td className="d-flex justify-content-between">
              <BestLabel>Addendum G</BestLabel>
              <span>{contract.addendumGFlag ? "Yes" : "No"}</span>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  getAdditionalMnDetails(details) {
    let odrBonuses = [];
    let rorBonuses = [];
    let foreignInquiries = [];
    let mlbSalaries = [];
    let mnaSalaries = [];
    let contingencyBonuses = [];
    let dualSports = [];
    let releaseClauses = [];
    let addDs = [];

    if (details.addendumB) {
      if (details.addendumB.remainOnRosterBonusMap) {
        for (let key in details.addendumB.openingDayRosterBonusMap) {
          let bonus = details.addendumB.openingDayRosterBonusMap[key];

          odrBonuses.push(
            <Collapse
              key={"odr_" + key}
              title={
                <span>
                  <BestLabel>Level of Play: </BestLabel> {bonus.levelOfPlay}
                </span>
              }
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText=" "
            >
              <table>
                <tbody>
                  <tr>
                    <td>
                      Amount:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0
                      }).format(bonus.amount)}
                    </td>
                  </tr>
                  <tr>
                    <td>Club Names: {bonus.clubNames}</td>
                  </tr>
                </tbody>
              </table>
            </Collapse>
          );
        }
      }

      if (details.addendumB.remainOnRosterBonusMap) {
        for (let key in details.addendumB.remainOnRosterBonusMap) {
          let bonus = details.addendumB.remainOnRosterBonusMap[key];

          rorBonuses.push(
            <Collapse
              key={"ror_" + key}
              title={
                <span>
                  <BestLabel>Bonus ID: </BestLabel> {bonus.bonusId}
                </span>
              }
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText=" "
            >
              <table>
                <tbody>
                  <tr>
                    <td>Level of Play: {bonus.levelOfPlay}</td>
                  </tr>
                  <tr>
                    <td>Consecutive Days: {bonus.consecutiveDays}</td>
                  </tr>
                  <tr>
                    <td>
                      Amount:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0
                      }).format(bonus.amount)}
                    </td>
                  </tr>
                  <tr>
                    <td>Club Names: {bonus.clubNames}</td>
                  </tr>
                </tbody>
              </table>
            </Collapse>
          );
        }
      }

      if (details.addendumB.foreignTeamInquiryMap) {
        for (let key in details.addendumB.foreignTeamInquiryMap) {
          let inquiry = details.addendumB.foreignTeamInquiryMap[key];

          foreignInquiries.push(
            <Collapse
              key={"inquiry_" + key}
              title={<BestLabel>Team Inquiry {inquiry.id}</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText=" "
            >
              <table>
                <tbody>
                  <tr>
                    <td>ID: {inquiry.id}</td>
                  </tr>
                  <tr>
                    <td>
                      Deadline Date: <DateTimeFormatter value={inquiry.deadlineDate} />
                    </td>
                  </tr>
                  <tr>
                    <td>Country: {inquiry.country}</td>
                  </tr>
                  <tr>
                    <td>Roster Type: {inquiry.rosterType}</td>
                  </tr>
                  <tr>
                    <td>
                      Acquisition Amount:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0
                      }).format(inquiry.acquisitionAmount)}
                    </td>
                  </tr>
                  <tr>
                    <td>Deadline Unit Type: {inquiry.deadlineUnitType}</td>
                  </tr>
                  <tr>
                    <td>Deadline Units: {inquiry.deadlineUnits}</td>
                  </tr>
                  <tr>
                    <td>Date Type: {inquiry.dateType}</td>
                  </tr>
                  <tr>
                    <td>
                      Deadline To Date: <DateTimeFormatter value={inquiry.deadlineToDate} />
                    </td>
                  </tr>
                  <tr>
                    <td>Acqt Amount Type: {inquiry.acqtAmountType}</td>
                  </tr>
                  <tr>
                    <td>Acqt To Amount: {inquiry.acqtToAmount}</td>
                  </tr>
                </tbody>
              </table>
            </Collapse>
          );
        }
      }

      if (details.addendumB.majorLeagueBaseSalaryMap) {
        for (let key in details.addendumB.majorLeagueBaseSalaryMap) {
          let salary = details.addendumB.majorLeagueBaseSalaryMap[key];

          mlbSalaries.push(
            <tr key={"salary_" + key}>
              <td>
                {salary.year}:{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0
                }).format(salary.amount)}
              </td>
            </tr>
          );
        }
      }

      if (details.addendumB.minorLeagueAssignmentSalaryMap) {
        for (let key in details.addendumB.minorLeagueAssignmentSalaryMap) {
          let salary = details.addendumB.minorLeagueAssignmentSalaryMap[key];
          mnaSalaries.push(
            <Collapse
              key={"salary_" + key}
              title={
                <span>
                  <BestLabel>Level of Play: </BestLabel> {salary.levelOfPlay}
                </span>
              }
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText=" "
            >
              <table>
                <tbody>
                  <tr>
                    <td>Frequency: {salary.frequency}</td>
                  </tr>
                  <tr>
                    <td>Year: {salary.year}</td>
                  </tr>
                  <tr>
                    <td>
                      Amount:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0
                      }).format(salary.amount)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Collapse>
          );
        }
      }

      if (details.addendumB.contingencyBonusMap) {
        for (let key in details.addendumB.contingencyBonusMap) {
          let bonus = details.addendumB.contingencyBonusMap[key];

          contingencyBonuses.push(
            <Collapse
              key={"contingency_" + key}
              title={
                <span>
                  <BestLabel>Contingency Bonus {bonus.id}</BestLabel>
                </span>
              }
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText=" "
            >
              <table>
                <tbody>
                  <tr>
                    <td>ID: {bonus.id}</td>
                  </tr>
                  <tr>
                    <td>
                      Amount:{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0
                      }).format(bonus.amount)}
                    </td>
                  </tr>
                  <tr>
                    <td>Days To Be Retained: {bonus.daysToBeRetained}</td>
                  </tr>
                  <tr>
                    <td>Level of Play: {bonus.levelOfPlay}</td>
                  </tr>
                  <tr>
                    <td>Club Names: {bonus.clubNames}</td>
                  </tr>
                </tbody>
              </table>
            </Collapse>
          );
        }
      }

      if (details.addendumB.dualSportMap) {
        for (let key in details.addendumB.dualSportMap) {
          let sport = details.addendumB.dualSportMap[key];

          dualSports.push(
            <Collapse
              key={"sport_" + key}
              title={
                <span>
                  <BestLabel>Sport: </BestLabel> {sport.sportName}
                </span>
              }
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText=" "
            >
              <table>
                <tbody>
                  <tr>
                    <td>Participation: {sport.participationFlag ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Comments: {sport.comments}</td>
                  </tr>
                </tbody>
              </table>
            </Collapse>
          );
        }
      }

      if (details.addendumB.releaseClauseMap) {
        for (let key in details.addendumB.releaseClauseMap) {
          let clause = details.addendumB.releaseClauseMap[key];
          releaseClauses.push(
            <Collapse
              key={"release_" + key}
              title={<BestLabel>{clause.id}</BestLabel>}
              iconAfterTitle={true}
              expandIcon="fa-chevron-down"
              collapseIcon="fa-chevron-up"
              emptyText=" "
            >
              <table>
                <tbody>
                  <tr>
                    <td>ID: {clause.id}</td>
                  </tr>
                  <tr>
                    <td>Type: {clause.type}</td>
                  </tr>
                  <tr>
                    <td>
                      From Date: <DateTimeFormatter value={clause.fromDate} />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      To Date: <DateTimeFormatter value={clause.toDate} />
                    </td>
                  </tr>
                  <tr>
                    <td>Unit Type: {clause.unitType}</td>
                  </tr>
                  <tr>
                    <td>Units: {clause.units}</td>
                  </tr>
                  <tr>
                    <td>Roster Affected: {clause.rosterAffected}</td>
                  </tr>
                </tbody>
              </table>
            </Collapse>
          );
        }
      }
    }

    if (details.addendumDList && details.addendumDList.length) {
      details.addendumDList.forEach((addD, index) => {
        addDs.push(
          <Collapse
            key={"addendumD_" + index}
            title={<BestLabel>{addD.year}</BestLabel>}
            iconAfterTitle={true}
            expandIcon="fa-chevron-down"
            collapseIcon="fa-chevron-up"
            emptyText=" "
          >
            <table>
              <tbody>
                <tr>
                  <td>ID: {addD.id}</td>
                </tr>
                <tr>
                  <td>Year: {addD.year}</td>
                </tr>
                <tr>
                  <td>
                    Monthly Salary:{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0
                    }).format(addD.monthlySalary)}
                  </td>
                </tr>
                <tr>
                  <td>Assigned Org Shortname: {addD.assignedOrgShortname}</td>
                </tr>
                <tr>
                  <td>Signing Org Shortname: {addD.signingOrgShortname}</td>
                </tr>
                <tr>
                  <td>League: {addD.league}</td>
                </tr>
                <tr>
                  <td>Assigned Club Shortname: {addD.assignedClubShortname}</td>
                </tr>
                <tr>
                  <td>
                    Tender Date: <DateTimeFormatter value={addD.tenderDate} />
                  </td>
                </tr>
                <tr>
                  <td>
                    Signing Date: <DateTimeFormatter value={addD.signingDate} />
                  </td>
                </tr>
                <tr>
                  <td>Status: {addD.status}</td>
                </tr>
              </tbody>
            </table>
          </Collapse>
        );
      });
    }

    return (
      <React.Fragment>
        <div className="row mx-0">
          <div className="col-4 pl-0">{this.getMnContractDetailTable(details)}</div>

          <div className="col-8 pr-0">
            {details.addendumB ? (
              <React.Fragment>
                {details.addendumB.signingBonusFlag ? (
                  <Collapse
                    title={
                      <span>
                        <BestLabel>Signing Bonus: </BestLabel>{" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0
                        }).format(details.addendumB.signingBonusAmount)}
                      </span>
                    }
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.openingDayRosterBonusFlag ? (
                  <Collapse
                    title={<BestLabel>Opening Day Roster Bonus</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {odrBonuses}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.remainOnRosterBonusFlag ? (
                  <Collapse
                    title={<BestLabel>Remain On Roster Bonus</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {rorBonuses}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.foreignTeamInquiryFlag ? (
                  <Collapse
                    title={<BestLabel>Foreign Team Inquiry</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {foreignInquiries}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.miscFlag ? (
                  <Collapse
                    title={<BestLabel>Misc</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.termPaySpringTrainingFlag ? (
                  <Collapse
                    title={
                      <span>
                        <BestLabel>Term Pay Spring Training: </BestLabel>{" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0
                        }).format(details.addendumB.termPaySpringTrainingAmount)}
                      </span>
                    }
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.termPayOffsetSalaryEarnedFlag ? (
                  <Collapse
                    title={
                      <span>
                        <BestLabel>Term Pay Offset Salary Earned: </BestLabel>{" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0
                        }).format(details.addendumB.termPayOffsetSalaryEarnedAmount)}
                      </span>
                    }
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.termPayFixedLumpSumFlag ? (
                  <Collapse
                    title={
                      <span>
                        <BestLabel>Term Pay Fixed Lump Sum: </BestLabel>{" "}
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0
                        }).format(details.addendumB.termPayFixedLumpSumAmount)}
                      </span>
                    }
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.termPayUnearnedSalaryFlag ? (
                  <Collapse
                    title={<BestLabel>Unearned Salary</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.majorLeagueBaseSalaryFlag ? (
                  <Collapse
                    title={<BestLabel>Major League Base Salary</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {mlbSalaries.length > 0 ? (
                      <table>
                        <tbody>{mlbSalaries}</tbody>
                      </table>
                    ) : (
                      mlbSalaries
                    )}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.majorLeagueMiscFlag ? (
                  <Collapse
                    title={<BestLabel>Major League Misc</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.springTrainingInviteFlag ? (
                  <Collapse
                    title={<BestLabel>Spring Training Invite</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.payrollDeductionClauseFlag ? (
                  <Collapse
                    title={<BestLabel>Payroll Deduction Clause</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.mlrProvisionFlag ? (
                  <Collapse
                    title={<BestLabel>MLR 9(c) Provision</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.minorLeagueAssignmentSalaryFlag ? (
                  <Collapse
                    title={<BestLabel>Minor League Assignment Salary</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {mnaSalaries}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.contingencyBonusFlag ? (
                  <Collapse
                    title={<BestLabel>Contingency Bonus</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {contingencyBonuses}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.performanceBonusFlag ? (
                  <Collapse
                    title={<BestLabel>Performance Bonus</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.awardBonusFlag ? (
                  <Collapse
                    title={<BestLabel>Award Bonus</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.dualSportBonusFlag ? (
                  <Collapse
                    title={<BestLabel>Dual Sport Bonus</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {dualSports}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.releaseClauseFlag ? (
                  <Collapse
                    title={<BestLabel>Release Clause</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {releaseClauses}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.ibpFlag ? (
                  <Collapse
                    title={<BestLabel>IBP</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.salaryEscalatorFlag ? (
                  <Collapse
                    title={<BestLabel>Salary Escalator</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.collegeScholarshipPlanFlag ? (
                  <Collapse
                    title={<BestLabel>College Scholarship Plan</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.drugTestingFlag ? (
                  <Collapse
                    title={<BestLabel>Drug Testing</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.signingBonusForfeitureFlag ? (
                  <Collapse
                    title={<BestLabel>Signing Bonus Forfeiture</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}

                {details.addendumB.dualSportForfeitureFlag ? (
                  <Collapse
                    title={<BestLabel>Dual Sport Forfeiture</BestLabel>}
                    iconAfterTitle={true}
                    expandIcon="fa-chevron-down"
                    collapseIcon="fa-chevron-up"
                    emptyText=" "
                  >
                    {[]}
                  </Collapse>
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              ""
            )}

            {addDs.length > 0 ? (
              <Collapse
                title={<BestLabel>Addendum D</BestLabel>}
                iconAfterTitle={true}
                expandIcon="fa-chevron-down"
                collapseIcon="fa-chevron-up"
                emptyText=" "
              >
                {addDs}
              </Collapse>
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    let { lookupStore, playerStore } = this.props.rootStore;
    let contractTypes = this.getContractTypes();
    let contractsData = this.getContractsData(playerStore.profileContractTypeId);
    return (
      <CardContainer>
        <CardContainer.Body>
          <ContractContainer>
            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <TitleCard>
                  <div className="d-flex flex-fill justify-content-between align-items-center">
                    <span>Contract List</span>
                    <div className="item-row h-inherit col-3 p-0">
                      <div className="d-inline-flex w-75 m-0 item">
                        <div className="col-12">
                          <BestSelect
                            name="ppcContractTypeId"
                            value={lookupStore.getDropdownItem(contractTypes, playerStore.profileContractTypeId)}
                            onChange={option => {
                              playerStore.profileContractTypeId = option.value;
                              playerStore.player.currentContractDetails = null;
                              playerStore.player.mnContractDetails = null;
                            }}
                            options={contractTypes}
                            portal={document.body}
                            styles={FormSelectStyles}
                          />
                        </div>
                      </div>
                      <div className="item card-item-separator"></div>
                      <div className="item">
                        <Icon
                          iconName="fa-file-download"
                          onClick={() => {
                            playerStore.exportProp(playerStore.player, contractsData.listExportProp);
                          }}
                          styles={{ size: "1.6em" }}
                        />
                      </div>
                    </div>
                  </div>
                </TitleCard>
              </div>
            </div>

            <div className="row mx-0">
              <div className="col-12 p-0">
                {0 === playerStore.profileContractTypeId &&
                playerStore.player.contracts &&
                playerStore.player.contracts.length > 0 ? (
                  <PiaTable
                    data={playerStore.player.contracts}
                    columns={this.getListCols(playerStore)}
                    height={playerStore.calculateTableHeight(playerStore.player.contracts.length, 336)}
                  />
                ) : 1 === playerStore.profileContractTypeId &&
                  playerStore.player.mncontracts &&
                  playerStore.player.mncontracts.length > 0 ? (
                  <PiaTable
                    data={playerStore.player.mncontracts}
                    columns={this.getMnListCols(playerStore)}
                    height={playerStore.calculateTableHeight(playerStore.player.mncontracts.length, 336)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>

            {playerStore.player.currentContractDetails || playerStore.player.mnContractDetails ? (
              <React.Fragment>
                <div className="row mx-0">
                  <div className="col-12 pl-0">
                    <div className="row mx-0 mt-4 mb-2">
                      <div className="col-12 p-0">
                        <TitleCard>
                          <div className="d-flex flex-fill justify-content-between align-items-center">
                            <span>{contractsData.detailsTitle}</span>
                            <div className="item-row h-inherit col-3 p-0">
                              <div className="item card-item-separator"></div>
                              <div className="item">
                                <Icon
                                  iconName="fa-file-download"
                                  onClick={() => {
                                    if (0 === playerStore.profileContractTypeId) {
                                      playerStore.exportProp(playerStore.player, "currentContractDetails");
                                    } else {
                                      playerStore.exportProp(playerStore.player.mnContractDetails, "addendumCAsList");
                                    }
                                  }}
                                  styles={{ size: "1.6em" }}
                                />
                              </div>
                            </div>
                          </div>
                        </TitleCard>
                      </div>
                    </div>

                    <div className="row mx-0">
                      <div className="col-12 p-0" style={{ overflowX: "scroll" }}>
                        {0 === playerStore.profileContractTypeId && playerStore.player.contractDetailsAndProvisions
                          ? this.getContractCompTable(playerStore.player.contractDetailsAndProvisions)
                          : 1 === playerStore.profileContractTypeId
                          ? this.getAddendumCDetails(playerStore.player.mnContractDetails)
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="col-5 pr-0">
                    {0 === playerStore.profileContractTypeId &&
                    playerStore.player.contractDetailsAndProvisions &&
                    playerStore.player.contractDetailsAndProvisions.currentPaySchedule ? (
                      <React.Fragment>
                        <div className="row mx-0 mt-4 mb-2">
                          <div className="col-12 p-0">
                            <TitleCard>
                              <span>Payment Schedule</span>
                            </TitleCard>
                          </div>
                        </div>

                        <div className="row mx-0">
                          <div className="col-12 p-0">
                            <PiaTable
                              data={playerStore.player.contractDetailsAndProvisions.currentPaySchedule}
                              columns={this.getPayScheduleCols()}
                              height={playerStore.calculateTableHeight(
                                playerStore.player.contractDetailsAndProvisions.currentPaySchedule.length,
                                336
                              )}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    ) : 0 === playerStore.profileContractTypeId &&
                      playerStore.player.contractDetailsAndProvisions &&
                      playerStore.player.contractDetailsAndProvisions.currentSplits ? (
                      <React.Fragment>
                        <div className="row mx-0 mt-4 mb-2">
                          <div className="col-12 p-0">
                            <TitleCard>
                              <span>Splits</span>
                            </TitleCard>
                          </div>
                        </div>

                        <div className="row mx-0">
                          <div className="col-12 p-0">
                            <PiaTable
                              data={playerStore.player.contractDetailsAndProvisions.currentSplits}
                              columns={this.getSplitCols()}
                              height={playerStore.calculateTableHeight(
                                playerStore.player.contractDetailsAndProvisions.currentSplits.length,
                                336
                              )}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    ) : 1 === playerStore.profileContractTypeId &&
                      playerStore.player.mnContractDetails.currentSalary ? (
                      <React.Fragment>
                        <div className="row mx-0 mt-4 mb-2">
                          <div className="col-12 p-0">
                            <TitleCard>
                              <span>Salary Details</span>
                            </TitleCard>
                          </div>
                        </div>

                        <div className="row mx-0">
                          <div className="col-12 p-0">
                            <PiaTable
                              data={playerStore.player.mnContractDetails.currentSalary}
                              columns={this.getMnSalaryCols()}
                              height={playerStore.calculateTableHeight(
                                playerStore.player.mnContractDetails.currentSalary.length,
                                336
                              )}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row mx-0 mt-4 mb-2">
                  <div className="col-4 pl-0">
                    <TitleCard>
                      <div className="d-flex flex-fill justify-content-between align-items-center">
                        <span>Additional Contract Details</span>
                      </div>
                    </TitleCard>
                  </div>
                  <div className="col-8 pr-0">
                    <TitleCard>
                      <div className="d-flex flex-fill justify-content-between align-items-center">
                        <span>{contractsData.provisionsTitle}</span>
                      </div>
                    </TitleCard>
                  </div>
                </div>
                {0 === playerStore.profileContractTypeId && playerStore.player.contractDetailsAndProvisions
                  ? this.getAdditionalDetailsAndProvisions(playerStore.player)
                  : 1 === playerStore.profileContractTypeId && playerStore.player.mnContractDetails
                  ? this.getAdditionalMnDetails(playerStore.player.mnContractDetails)
                  : ""}
              </React.Fragment>
            ) : (
              ""
            )}
          </ContractContainer>
        </CardContainer.Body>
      </CardContainer>
    );
  }
}

Contracts.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(Contracts));
