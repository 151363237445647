import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import _ from "lodash";
import { BestSelect, DateTimeFormatter, Icon } from "best-common-react";
import { FormSelectStyles, InlineHeader } from "../../Theme";
import IconTip from "../widgets/IconTip";
import PiaMatTable from "../widgets/PiaMatTable";
import { PlayerConstants } from "../../constants/PlayerConstants";
import { StatConstants } from "../../constants/StatConstants";
import TitleCard from "../widgets/TitleCard";

class StatLine extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statLineLoaded: false,
      statSplitType: null
    };
  }

  async componentDidMount() {
    this.checkStatLine();
  }

  async componentDidUpdate() {
    this.checkStatLine();
  }

  async checkStatLine() {
    let { playerStore } = this.props.rootStore;

    if (!this.state.statLineLoaded) {
      let statCols = this.getStatCols(playerStore);
      let statLineLoaded = statCols.length > 0;

      if (statLineLoaded) {
        this.setState({ statLineLoaded: statLineLoaded, statSplitType: playerStore.playerStatSplitType });
      }
    } else if (playerStore.playerStatSplitType !== this.state.statSplitType) {
      this.setState({ statLineLoaded: false });
    }
  }

  getStatLineData(playerStore) {
    let data = {
      title: this.props.type + " Stats",
      statLines: [],
      selectedStatLine: "",
      exportProp: null
    };

    if (StatConstants.TYPES.BATTING === this.props.type) {
      data.statLines = [
        { value: "D_BATTING", label: "Default Batting" },
        { value: "C_BATTING", label: "Custom Batting" }
      ];
      data.selectedStatLine = playerStore.player.playerStatsBean.selectedSplitBattingStatline;

      if (PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.battingPostseason;
        data.totals = playerStore.player.playerStatsBean.battingPostseasonTotals;
        data.exportProp = "qstatsBattingPostSeason";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.MN_CAREER === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.mnBattingCareer;
        data.totals = playerStore.player.playerStatsBean.mnBattingCareerTotals;
        data.exportProp = "qstatsMnBattingCareer";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.MN_POST_SEASON === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.mnBattingPostseason;
        data.totals = playerStore.player.playerStatsBean.mnBattingPostseasonTotals;
        data.exportProp = "qstatsMnBattingPostSeason";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === playerStore.playerStatSplitType) {
        data.title += " - " + playerStore.player.playerStatsBean.selectedSeason;
        data.details = playerStore.player.playerStatsBean.battingStatsBeansGameLog;
        data.totals = playerStore.player.playerStatsBean.battingGameLogTotals;
        data.exportProp = "qstatsBattingGameLog";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.battingSplits;
        data.exportProp = "qstatsBattingSplits";
      } else {
        data.details = playerStore.player.playerStatsBean.battingCareer;
        data.totals = playerStore.player.playerStatsBean.battingCareerTotals;
        data.exportProp = "qstatsBattingCareer";
      }
    } else if (StatConstants.TYPES.PITCHING === this.props.type) {
      data.statLines = [
        { value: "D_STARTING", label: "Default Starting" },
        { value: "C_STARTING", label: "Custom Starting" },
        { value: "D_RELIEF", label: "Default Relief" },
        { value: "C_RELIEF", label: "Custom Relief" },
        { value: "D_SWING", label: "Default Swing" },
        { value: "C_SWING", label: "Custom Swing" }
      ];
      data.selectedStatLine = playerStore.player.playerStatsBean.selectedSplitPitchingStatline;

      if (PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.pitchingPostseason;
        data.totals = playerStore.player.playerStatsBean.pitchingPostseasonTotals;
        data.exportProp = "qstatsPitchingPostSeason";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.MN_CAREER === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.mnPitchingCareer;
        data.totals = playerStore.player.playerStatsBean.mnPitchingCareerTotals;
        data.exportProp = "qstatsMnPitchingCareer";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.MN_POST_SEASON === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.mnPitchingPostseason;
        data.totals = playerStore.player.playerStatsBean.mnPitchingPostseasonTotals;
        data.exportProp = "qstatsMnPitchingPostSeason";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === playerStore.playerStatSplitType) {
        data.title += " - " + playerStore.player.playerStatsBean.selectedSeason;
        data.details = playerStore.player.playerStatsBean.pitchingStatsBeansGameLog;
        data.totals = playerStore.player.playerStatsBean.pitchingGameLogTotals;
        data.exportProp = "qstatsPitchingGameLog";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.pitchingSplits;
        data.exportProp = "qstatsPitchingSplits";
      } else {
        data.details = playerStore.player.playerStatsBean.pitchingCareer;
        data.totals = playerStore.player.playerStatsBean.pitchingCareerTotals;
        data.exportProp = "qstatsPitchingCareer";
      }
    } else if (StatConstants.TYPES.FIELDING === this.props.type) {
      data.statLines = [
        { value: "D_FIELDING", label: "Default Fielding" },
        { value: "C_FIELDING", label: "Custom Fielding" },
        { value: "D_CATCHING", label: "Default Catching" },
        { value: "C_CATCHING", label: "Custom Catching" }
      ];
      data.selectedStatLine = playerStore.player.playerStatsBean.selectedSplitFieldingStatline;

      if (PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.fieldingPostseason;
        data.totals = playerStore.player.playerStatsBean.fieldingPostseasonTotals;
        data.exportProp = "qstatsFieldingPostSeason";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.MN_CAREER === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.mnFieldingCareer;
        data.totals = playerStore.player.playerStatsBean.mnFieldingCareerTotals;
        data.exportProp = "qstatsMnFieldingCareer";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.MN_POST_SEASON === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.mnFieldingPostseason;
        data.totals = playerStore.player.playerStatsBean.mnFieldingPostseasonTotals;
        data.exportProp = "qstatsMnFieldingPostSeason";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === playerStore.playerStatSplitType) {
        data.title += " - " + playerStore.player.playerStatsBean.selectedSeason;
        data.details = playerStore.player.playerStatsBean.fieldingStatsBeansGameLog;
        data.totals = playerStore.player.playerStatsBean.fieldingGameLogTotals;
        data.exportProp = "qstatsFieldingGameLog";
      } else if (PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType) {
        data.details = playerStore.player.playerStatsBean.fieldingSplits;
        data.exportProp = "qstatsFieldingSplits";
      } else {
        data.details = playerStore.player.playerStatsBean.fieldingCareer;
        data.totals = playerStore.player.playerStatsBean.fieldingCareerTotals;
        data.exportProp = "qstatsFieldingCareer";
      }
    }

    return data;
  }

  getBaseCols(playerStore) {
    if (
      PlayerConstants.STAT_SPLIT_TYPES.CAREER === playerStore.playerStatSplitType ||
      PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON === playerStore.playerStatSplitType
    ) {
      return [
        { key: "year", name: "Season", width: 80 },
        { key: "club", name: "Club", width: 120 }
      ];
    } else if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === playerStore.playerStatSplitType) {
      return [
        {
          key: "gameLogDateAsString",
          name: "Date",
          width: 120,
          formatter: row =>
            row.totalsRow ? (
              <span>Season</span>
            ) : (
              <a
                href="/#"
                onClick={event => {
                  event.preventDefault();
                  let prop =
                    StatConstants.TYPES.PITCHING === this.props.type
                      ? "gameLogPitchingStatsBeans"
                      : "gameLogBattingStatsBeans";
                  playerStore.getGameLogDetail(row.row.gameId, row.row.gameLogDateAsString, prop);
                }}
              >
                <DateTimeFormatter value={row.value} />
              </a>
            ),
          format: row =>
            row.totalsRow ? (
              <span>Season</span>
            ) : (
              <a
                href="/#"
                onClick={event => {
                  event.preventDefault();
                  let prop =
                    StatConstants.TYPES.PITCHING === this.props.type
                      ? "gameLogPitchingStatsBeans"
                      : "gameLogBattingStatsBeans";
                  playerStore.getGameLogDetail(row.gameId, row.gameLogDateAsString, prop);
                }}
              >
                <DateTimeFormatter value={row.gameLogDate} />
              </a>
            )
        },
        {
          key: "gameLogOpponent",
          name: "Opponent",
          width: 120,
          formatter: row => (
            <span>{row.totalsRow ? "Season" : !row.value ? "" : (row.row.awayGame ? "@ " : "") + row.value}</span>
          ),
          format: row => (
            <span>
              {row.totalsRow ? "Season" : !row.gameLogOpponent ? "" : (row.awayGame ? "@ " : "") + row.gameLogOpponent}
            </span>
          )
        }
      ];
    } else if (PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType) {
      return [{ key: "splitType", name: "Split", width: 250 }];
    } else {
      return [
        { key: "year", name: "Season", width: 80 },
        { key: "organization", name: "Org", width: 80 },
        { key: "club", name: "Club", width: 120 },
        { key: "level", name: "Level", width: 120 }
      ];
    }
  }

  getStatCols(playerStore) {
    let statCols = [];

    if (StatConstants.TYPES.BATTING === this.props.type) {
      statCols = playerStore.getStatCols(playerStore.displayPlayerBattingStatLine);
    } else if (StatConstants.TYPES.PITCHING === this.props.type) {
      statCols = playerStore.getStatCols(playerStore.displayPlayerPitchingStatLine);
    } else if (StatConstants.TYPES.FIELDING === this.props.type) {
      statCols = playerStore.getStatCols(playerStore.displayPlayerFieldingStatLine);
    }

    return statCols;
  }

  getTableData(details, totals) {
    let data = [];

    if (details) {
      data = details.map(statLine => {
        return {
          ...statLine,
          expandable: statLine.year && statLine.multipleOrgs && statLine.multipleOrgs.length > 0,
          expandedListProp: "multipleOrgs",
          rowExpanded: !!statLine.rowExpanded,
          rowHighlighted: !!statLine.rowHighlighted,
          totalsRow: false
        };
      });
    }

    if (totals) {
      let totalsData = {
        ...totals,
        year: "Career",
        organization: "Career",
        club: "Career",
        level: "Career",
        totalsRow: true
      };
      data = data.concat([totalsData]);
    }
    return data;
  }

  onRowClick(playerStore, index, statLineData) {
    // Ignore invalid or nested rows
    if (
      !statLineData.details[index] ||
      statLineData.details[index].treeDepth ||
      PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType
    ) {
      return;
    }

    statLineData.details[index].rowHighlighted = !statLineData.details[index].rowHighlighted;

    let highlightedRows = _.filter(statLineData.details, row => row.rowHighlighted);

    if (highlightedRows.length > 1) {
      statLineData.details.forEach(detail => {
        detail.rowHighlighted = false;
      });

      if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === playerStore.playerStatSplitType) {
        if (highlightedRows[0].gameLogDate < highlightedRows[1].gameLogDate) {
          playerStore.getCombinedStats(
            this.props.type,
            highlightedRows[0].gameId,
            highlightedRows[1].gameId,
            highlightedRows[0].gameLogDateAsString,
            highlightedRows[1].gameLogDateAsString
          );
        } else {
          playerStore.getCombinedStats(
            this.props.type,
            highlightedRows[1].gameId,
            highlightedRows[0].gameId,
            highlightedRows[1].gameLogDateAsString,
            highlightedRows[0].gameLogDateAsString
          );
        }
      } else {
        if (highlightedRows[0].year < highlightedRows[1].year) {
          playerStore.getCombinedStats(this.props.type, highlightedRows[0].year, highlightedRows[1].year);
        } else {
          playerStore.getCombinedStats(this.props.type, highlightedRows[1].year, highlightedRows[0].year);
        }
      }
    }
  }

  render() {
    let { lookupStore, playerStore } = this.props.rootStore;
    let statLineData = this.getStatLineData(playerStore);
    let baseCols = this.getBaseCols(playerStore);
    let statCols = this.getStatCols(playerStore);
    let cols = baseCols.concat(statCols);
    let tableData = this.getTableData(statLineData.details, statLineData.totals);

    return (StatConstants.TYPES.PITCHING === this.props.type ||
      PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === playerStore.playerStatSplitType ||
      PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType) &&
      (!this.state.statLineLoaded || !tableData.length) ? (
      ""
    ) : (
      <React.Fragment>
        <div className="row mx-0 mb-2">
          <div className="col-12 p-0">
            <TitleCard>
              <div className="d-flex flex-fill justify-content-between align-items-center">
                <div>
                  <span>{statLineData.title}</span>&nbsp;
                  {PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType ? (
                    ""
                  ) : (
                    <IconTip iconName="fa-question-circle" tipHtml={<span>Click rows to sum</span>} />
                  )}
                </div>

                <div className="item-row h-inherit col-3 p-0">
                  {PlayerConstants.STAT_SPLIT_TYPES.CAREER === playerStore.playerStatSplitType ||
                  PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON === playerStore.playerStatSplitType ||
                  PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType ? (
                    <React.Fragment>
                      <InlineHeader className="item">Stat Line</InlineHeader>
                      <div className="d-inline-flex w-50 m-0 item">
                        <div className="col-12">
                          <BestSelect
                            name="qvSelectedSplitStatline"
                            value={lookupStore.getDropdownItem(statLineData.statLines, statLineData.selectedStatLine)}
                            onChange={option => {
                              statLineData.selectedStatLine = option.value;

                              if (PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType) {
                                playerStore.getSplitStatLines(option.value, this.props.type);
                              } else {
                                playerStore.switchStatLine(option.value, this.props.type);
                              }
                            }}
                            options={statLineData.statLines}
                            portal={document.body}
                            styles={FormSelectStyles}
                          />
                        </div>
                      </div>
                      {statLineData.selectedStatLine && statLineData.selectedStatLine.indexOf("D_") > -1 ? (
                        ""
                      ) : (
                        <div className="item">
                          <Icon
                            iconName="fa-pen-square"
                            onClick={() => {
                              playerStore.openStatLineModal(statLineData.selectedStatLine);
                            }}
                            styles={{ size: "1.8em" }}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                  <div className="item card-item-separator"></div>

                  <div className="item">
                    <Icon
                      iconName="fa-file-download"
                      onClick={() => {
                        playerStore.exportProp(playerStore.player, statLineData.exportProp);
                      }}
                      styles={{ size: "1.6em" }}
                    />
                  </div>
                </div>
              </div>
            </TitleCard>
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-12 p-0 mat-table-container">
            {this.state.statLineLoaded ? (
              <React.Fragment>
                <PiaMatTable
                  rows={tableData}
                  columns={cols}
                  onRowClick={rowId => {
                    this.onRowClick(playerStore, rowId, statLineData);
                  }}
                />
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

StatLine.propTypes = {
  rootStore: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired
};

export default inject("rootStore")(observer(StatLine));
