import React from "react";
import { action, autorun, extendObservable } from "mobx";
import _ from "lodash";
import { DateTimeFormatter, Icon } from "best-common-react";
import { AlertConstants } from "../../constants/AlertConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import WidgetStore from "../WidgetStore";

class ReportStore extends WidgetStore {
  constructor(authStore, routerStore, alertStore, piaApi) {
    super();
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.alertStore = alertStore;
    this.piaApi = piaApi;

    this.launchReport = this.launchReport.bind(this);

    this.defaults = {
      openReports: {},
      foldersLoaded: false,
      reportName: "",
      cannedReports: [],
      savedReports: [],
      selectedReports: [],
      displayFolderId: -1,
      folders: [],
      moveToFolderId: 0,
      openFolderModal: false,
      helpfulLinks: []
    };

    extendObservable(this, {
      openReports: this.defaults["openReports"],
      foldersLoaded: this.defaults["foldersLoaded"],
      reportName: this.defaults["reportName"],
      cannedReports: this.defaults["cannedReports"],
      savedReports: this.defaults["savedReports"],
      selectedReports: this.defaults["selectedReports"],
      displayFolderId: this.defaults["displayFolderId"],
      folders: this.defaults["folders"],
      moveToFolderId: this.defaults["moveToFolderId"],
      openFolderModal: this.defaults["openFolderModal"],
      helpfulLinks: this.defaults["helpfulLinks"],
      cancel: action(reportName => {
        return this.cancelReport(reportName);
      }),
      resetStore: action(() => {
        this.openReports = this.defaults["openReports"];
        this.report = this.defaults["report"];
        this.cannedReports = this.defaults["cannedReports"];
        this.savedReports = this.defaults["savedReports"];
        this.selectedReports = this.defaults["selectedReports"];
        this.displayFolderId = this.defaults["displayFolderId"];
        this.folders = this.defaults["folders"];
        this.moveToFolderId = this.defaults["moveToFolderId"];
        this.openFolderModal = this.defaults["openFolderModal"];
        this.helpfulLinks = this.defaults["helpfulLinks"];
      })
    });

    autorun(() => {
      if (this.routerStore.isLoadingReportPage && this.authStore.loggedIn) {
        this.runReport();
      } else if (this.routerStore.isCannedReportsPage && this.authStore.loggedIn) {
        this.loadCannedReports();
      } else if (this.routerStore.isMySavedReportsPage && this.authStore.loggedIn) {
        this.onLoadSavedReports();
      } else if (this.routerStore.isSharingPage && this.authStore.loggedIn) {
        this.getSavedReports();
      } else if (this.routerStore.isLinksPage && this.authStore.loggedIn) {
        this.getHelpfulLinks();
      } else {
        this.resetStore();
      }
    });
  }

  launchReport(type, options, quick = false, fromProfile = false) {
    if (!type) {
      return;
    }
    //once token refreshes, carry on with report execution
    this.piaApi.initReport(type, options, quick, fromProfile).then(initResponse => {
      if (!!initResponse) {
        localStorage.setItem(
          "REPORT_INFO",
          JSON.stringify({
            type: type,
            quick: quick,
            fromProfile: fromProfile,
            reportName: initResponse
          })
        );

        var newWindow = this.routerStore.openInNewTab(
          RouteConstants.LOADING_REPORT.route + "/" + encodeURIComponent(initResponse)
        );
        newWindow.options = options;
      }
    });
  }

  runReport() {
    this.loading = true;
    this.reportInfo = JSON.parse(localStorage.getItem("REPORT_INFO"));
    this.reportName = this.reportInfo.reportName;
    this.openReports[this.reportName] = { loading: true };
    this.options = window.options;

    this.piaApi
      .runReport(
        this.reportInfo.reportName,
        this.reportInfo.type,
        this.options,
        this.reportInfo.quick,
        this.reportInfo.fromProfile
      )
      .then(
        runResponse => {
          this.openReports[this.reportName] = undefined;
          if (!!runResponse) {
            window.location = runResponse;
          }
          localStorage.removeItem("REPORT_INFO");
        },
        runError => {
          this.openReports[this.reportName] = { loading: false, error: true };
          localStorage.removeItem("REPORT_INFO");
        }
      );
  }

  cancelReport(reportName) {
    return this.piaApi.cancelReport(reportName);
  }

  loadCannedReports() {
    this.piaApi.getCannedReports().then(response => {
      if (!!response) {
        this.cannedReports = response;
      }
    });
  }

  onLoadSavedReports = () => {
    this.getSavedReports();
    this.getReportFolders();
  };

  getSavedReportCols(showEditReportFlag = true) {
    let cols = [
      {
        key: "reportName",
        name: "Report Name",
        width: 600,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                window.open(row.row.url);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "folderName", name: "Folder", width: 180 },
      {
        key: "lastUpdated",
        name: "Last Updated",
        width: 150,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      },
      { key: "comments", name: "Comments" }
    ];

    if (showEditReportFlag) {
      cols.push({
        key: "editReport",
        name: "",
        width: 60,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return (
            <div className="d-flex justify-content-center align-items-center">
              <Icon
                iconName="fa-pen-square"
                onClick={() => {
                  window.open(row.row.adHocUrl);
                }}
                styles={{ size: "2em" }}
              />
            </div>
          );
        }
      });
    }

    return cols;
  }

  getSavedReports() {
    this.piaApi.getSavedReports().then(response => {
      if (!!response) {
        this.savedReports = response;
      }
    });
  }

  get displaySavedReports() {
    if (!this.savedReports) {
      return [];
    }

    let filteredReports = _.filter(this.savedReports, report => {
      if (-1 === this.displayFolderId) {
        return true;
      } else {
        return report.folderId === this.displayFolderId;
      }
    });

    let selectedIds = this.selectedReports.map(report => report.reportId);

    return filteredReports.map(report => {
      return {
        ...report,
        isSelected: _.includes(selectedIds, report.reportId)
      };
    });
  }

  addSelectedReports(rows) {
    rows.forEach(row => {
      if (row.reportId) {
        this.selectedReports.push(row);
      }
    });
  }

  removeUnselectedReports(rows) {
    this.selectedReports = _.differenceWith(
      this.selectedReports,
      rows,
      (selected, remove) => selected.reportId === remove.reportId
    );
  }

  getReportFolders = () => {
    this.foldersLoaded = false;
    this.piaApi.getReportFolders().then(response => {
      if (!!response) {
        this.folders = response;

        response.forEach(folder => {
          if ("Favorites" === folder.folderName) {
            this.moveToFolderId = folder.folderId;
          }
        });

        this.foldersLoaded = true;
      }
    });
  };

  moveReports() {
    this.piaApi.moveReports(this.moveToFolderId, this.selectedReports).then(response => {
      if (!!response) {
        this.resetStore();
        this.onLoadSavedReports();
      }
    });
  }

  deleteReports() {
    this.piaApi.deleteReports(this.selectedReports).then(response => {
      if (!!response) {
        this.resetStore();
        this.onLoadSavedReports();
      }
    });
  }

  shareReports(users, comments) {
    let sharing = {
      reports: this.selectedReports,
      users: users,
      comments: comments
    };
    this.piaApi.shareReports(sharing).then(response => {
      if (!!response) {
        this.selectedReports = [];
        this.getSavedReports();
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The reports you selected were successfully shared."
        });
      }
    });
  }

  getHelpfulLinks() {
    this.piaApi.getHelpfulLinks().then(response => {
      if (!!response) {
        this.helpfulLinks = response;
      }
    });
  }
}

export default ReportStore;
