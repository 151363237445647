import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, DangerButton, FormInput, LightButton, Modal, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";
import _ from "lodash";
import { AlertConstants } from "../../constants/AlertConstants";
import TitleCard from "../widgets/TitleCard";

class ManageFoldersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      newFolderName: "",
      folders: [],
      confirm: {}
    };
  }

  async componentDidMount() {
    this.checkFolders();
  }

  async componentDidUpdate() {
    this.checkFolders();
  }

  async checkFolders() {
    if (this.props.foldersLoaded && !this.state.loaded) {
      this.setState(state => {
        state.folders = _.cloneDeep(this.props.folders);
        state.loaded = true;
        return { loaded: state.loaded, folders: state.folders };
      });
    }
  }

  getFolderRows() {
    if (!this.props.foldersLoaded) {
      return "";
    }

    let rows = [];

    this.state.folders.forEach((folder, index) => {
      let row = (
        <TitleCard key={"currentFolder_" + index} className={"row" + (rows.length > 0 ? " mt-1" : "")}>
          <div className="col-12 p-0 d-flex align-items-center justify-content-between">
            <BestLabel>{folder.folderName || folder.description}</BestLabel>
            <DangerButton
              onClick={() => {
                this.removeFolder(folder, false);
              }}
            >
              Remove
            </DangerButton>
          </div>
        </TitleCard>
      );

      if (folder.folderId > 0) {
        rows.push(row);
      }
    });

    return <React.Fragment>{rows}</React.Fragment>;
  }

  addFolder() {
    let { alertStore } = this.props.rootStore;
    if (!this.state.newFolderName || "" === this.state.newFolderName) {
      return;
    }
    this.props.createFolder(this.state.newFolderName).then(response => {
      if (!!response) {
        this.reload();
        alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Folder added successfully"
        });
      }
    });
  }

  removeFolder(folder, override) {
    this.props.deleteFolder(folder, override).then(response => {
      if (!!response) {
        if (response.length) {
          // If this is an array, there are comps in the folder
          this.setState({ confirm: { number: response.length, folder: folder } });
        } else {
          this.reload();
        }
      }
    });
  }

  reload() {
    this.props.beforeReload();
    this.setState({ loaded: false, newFolderName: "", confirm: { number: null, folder: null } });
  }

  close() {
    this.setState({ loaded: false });
    this.props.onClose();
  }

  renderFolders() {
    return (
      <Modal show={this.props.isOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Manage Folders"
          close={() => {
            this.close();
          }}
        />
        <Modal.Body>
          <div className="row modal-section">
            <div className="col-9 pr-0">
              <FormInput
                name="mfFolderName"
                type="text"
                value={this.state.newFolderName}
                onChangeFn={value => {
                  this.setState(state => {
                    state.newFolderName = value;

                    return {
                      newFolderName: state.newFolderName
                    };
                  });
                }}
                editable
              />
            </div>
            <div className="col-3 d-flex flex-fill form-group">
              <PrimaryButton
                className="w-100"
                onClick={() => {
                  this.addFolder();
                }}
              >
                Add New
              </PrimaryButton>
            </div>
          </div>

          <div className="row mx-0 mt-3">
            <div className="col-12">
              <React.Fragment>{this.getFolderRows()}</React.Fragment>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            <PrimaryButton
              onClick={() => {
                this.close();
              }}
            >
              Done
            </PrimaryButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  renderConfirm() {
    return (
      <Modal show={this.props.isOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Confirm Remove"
          close={() => {
            this.close();
          }}
        />
        <Modal.Body>
          {
            <span>
              This folder contains {this.state.confirm.number} {this.props.confirmType}. Removing the folder will move
              those {this.props.confirmType} to the default &quot;{this.props.defaultFolderName}&quot; folder.
              <br />
              <br />
              Do you wish to continue?
            </span>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            <PrimaryButton
              onClick={() => {
                this.removeFolder(this.state.confirm.folder, true);
              }}
            >
              Yes
            </PrimaryButton>
            <LightButton
              onClick={() => {
                this.setState({ confirm: { number: null, folder: null } });
              }}
            >
              No
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    return this.state.confirm.folder ? this.renderConfirm() : this.renderFolders();
  }
}

ManageFoldersModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  foldersLoaded: PropTypes.bool.isRequired,
  folders: PropTypes.array.isRequired,
  defaultFolderName: PropTypes.string.isRequired,
  createFolder: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  beforeReload: PropTypes.func.isRequired,
  confirmType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default inject("rootStore")(observer(ManageFoldersModal));
