import React from "react";
import { inject, observer } from "mobx-react";
import { BestQuickSearch, BestSelect, LightButton, Modal } from "best-common-react";
import PropTypes from "prop-types";
import Moment from "moment";
import { FormSearchStyles, FormSelectStyles } from "../../Theme";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";

class AddPlayerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      playerId: 0,
      playerName: "",
      year: Moment(new Date())
        .year()
        .toString()
    };
  }

  close(playerStore) {
    this.setState(this.getDefaultState());
    playerStore.playerList.addPlayerModalOpen = false;
  }

  render() {
    let { commonStore, lookupStore, playerStore, piaApi } = this.props.rootStore;
    let years = commonStore.getListOfYears(1988, 1);

    return (
      <Modal show={!!playerStore.playerList.addPlayerModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Add Player"
          close={() => {
            this.close(playerStore);
          }}
        />
        <Modal.Body>
          <div className="row">
            <div className="col-8">
              <BestQuickSearch
                options={lookupStore.autocompletePlayers}
                renderSuggestion={suggestion => {
                  return <PlayerAutocompleteRow player={suggestion} />;
                }}
                getSuggestionValue={player => {
                  return playerStore.getAutocompleteText(player);
                }}
                getSuggestions={(players, searchTerm) => {
                  if (searchTerm.indexOf(")") === -1) {
                    this.setState({ playerId: 0, playerName: playerStore.formatForComparison(searchTerm) });
                  }
                  return playerStore.filterBySearchTerm(players, searchTerm);
                }}
                onSuggestionSelected={(event, { suggestion }) => {
                  this.setState({ playerId: suggestion.playerId, playerName: suggestion.playerName });
                }}
                styles={FormSearchStyles}
              />
            </div>
            <div className="col-2 pl-0">
              <BestSelect
                name="addPlayerYear"
                value={lookupStore.getDropdownItem(years, this.state.year * 1)}
                onChange={option => {
                  this.setState({ year: option.value + "" });
                }}
                options={years}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-2 pl-0 d-flex flex-fill">
              <LightButton
                className="flex-fill"
                onClick={() => {
                  if (!this.state.playerId || !playerStore.playerList.plDetails) {
                    return;
                  }

                  let criteria = {
                    ...playerStore.defaults.listCriteria,
                    positionCustomFlg: false,
                    forceaddPlayerId: this.state.playerId,
                    forceaddPlatformYear: this.state.year,
                    useNextYearForContractCriteria: playerStore.listCriteria.useNextYearForContractCriteria,
                    displayFirstContract: playerStore.listCriteria.displayFirstContract,
                    displayPyplusone: playerStore.listCriteria.displayPyplusone,
                    displaySalaryType: playerStore.listCriteria.displaySalaryType
                  };

                  piaApi.getPlayersForList(criteria, "NEW").then(response => {
                    if (response && response.plDetails && response.plDetails.length > 0) {
                      let row = response.plDetails[0];
                      row.manuallyAddedFlg = true;

                      playerStore.addPlayersToList([row]);
                    }
                  });
                }}
              >
                Add
              </LightButton>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            <LightButton
              onClick={() => {
                this.close(playerStore);
              }}
            >
              Close
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddPlayerModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(AddPlayerModal));
