import React from "react";
import { action, autorun, extendObservable } from "mobx";
import _ from "lodash";
import Moment from "moment";
import { DateTimeFormatter } from "best-common-react";
import { AlertConstants } from "../../constants/AlertConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import WidgetStore from "../WidgetStore";

class StatStore extends WidgetStore {
  constructor(authStore, routerStore, lookupStore, alertStore, piaApi) {
    super();
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.lookupStore = lookupStore;
    this.alertStore = alertStore;
    this.piaApi = piaApi;

    this.getAutocompleteText = this.getAutocompleteText.bind(this);
    this.filterBySearchTerm = this.filterBySearchTerm.bind(this);

    this.defaults = {
      statSearch: {
        statName: "",
        statId: null,
        searchType: "All",
        searchCategory: "All",
        searchStandardStatFlg: true,
        searchUserDefinedFlg: true,
        lastUpdatedFromDte: Moment(new Date())
          .subtract(1, "years")
          .format("MM/DD/YYYY")
          .toString(),
        lastUpdatedThroughDte: Moment(new Date())
          .format("MM/DD/YYYY")
          .toString(),
        supportingStatCodes: []
      },
      statTypeCategories: [{ category: "All", stats: [] }],
      supportingStats: [],
      listSearch: {
        statId: null,
        statListName: "",
        statName: "",
        lastUpdatedFromDte: Moment(new Date()).subtract(1, "years"),
        lastUpdatedThroughDte: Moment(new Date())
      },
      statResults: [],
      selectedStats: [],
      statListResults: [],
      standardStatListIds: [],
      selectedStatLists: [],
      stat: {
        className: "com.mlb.piaplus.statistic.model.StatisticSearchResultBean",
        statId: 0,
        statName: "",
        statCode: "",
        decimalPlaces: 0,
        showLeadingZeroFlg: "0",
        higherValuesBetterFlg: "H",
        canBeAveragedFlg: "1"
      },
      statFormula: {
        statCode: null,
        objectName: null,
        constant: null,
        parts: [],
        selectedIndex: -1
      },
      statList: {
        className: "com.mlb.piaplus.db.SlMaster",
        statlistId: 0,
        statlistname: "Untitled Stat List",
        slDetails: []
      },
      statListSearchDetails: []
    };

    extendObservable(this, {
      statSearch: this.defaults["statSearch"],
      statTypeCategories: this.defaults["statTypeCategories"],
      supportingStats: this.defaults["supportingStats"],
      listSearch: this.defaults["listSearch"],
      statResults: this.defaults["statResults"],
      selectedStats: this.defaults["selectedStats"],
      statListResults: this.defaults["statListResults"],
      standardStatListIds: this.defaults["standardStatListIds"],
      selectedStatLists: this.defaults["selectedStatLists"],
      stat: this.defaults["stat"],
      statFormula: this.defaults["statFormula"],
      statList: this.defaults["statList"],
      statListSearchDetails: this.defaults["statListSearchDetails"],
      resetStore: action(() => {
        this.statSearch = this.defaults["statSearch"];
        this.statTypeCategories = this.defaults["statTypeCategories"];
        this.supportingStats = this.defaults["supportingStats"];
        this.listSearch = this.defaults["listSearch"];
        this.statResults = this.defaults["statResults"];
        this.selectedStats = this.defaults["selectedStats"];
        this.statListResults = this.defaults["statListResults"];
        this.standardStatListIds = this.defaults["standardStatListIds"];
        this.selectedStatLists = this.defaults["selectedStatLists"];
        this.stat = this.defaults["stat"];
        this.statFormula = this.defaults["statFormula"];
        this.statList = this.defaults["statList"];
        this.statListSearchDetails = this.defaults["statListSearchDetails"];
      })
    });

    autorun(() => {
      if (this.routerStore.isStatisticsPage && this.authStore.loggedIn) {
        this.parseStatUrl();
      } else if (this.routerStore.isEditStatisticPage && this.authStore.loggedIn) {
        this.resetStore();
        this.parseUrlAndLoadStat();
      } else if (this.routerStore.isEditStatListPage && this.authStore.loggedIn) {
        this.resetStore();
        this.getDefaultLists(false, true);
        this.parseAndLoadEditStatListUrl();
      } else if (this.routerStore.isStatListsPage && this.authStore.loggedIn) {
        this.parseStatListUrl();
      } else if (this.routerStore.isEditComparisonPage && this.authStore.loggedIn) {
        this.getDefaultLists(false, true);
      } else if (this.routerStore.isSharingPage && this.authStore.loggedIn) {
        this.getDefaultStats();
        this.getDefaultLists(false);
      } else if (this.routerStore.isPlayerProfilePage && this.authStore.loggedIn) {
        this.resetStore();
        this.getDefaultLists(false, true);
      } else if (!(this.routerStore.isPlayersPage && this.authStore.loggedIn)) {
        this.resetStore();
      }
    });
  }

  parseStatUrl() {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.STATISTICS.route + RouteConstants.STATISTICS.pathParams
    );

    if (!!pathParams["statName"]) {
      this.statSearch.statName = pathParams["statName"];
      this.findStats();
    } else {
      this.getDefaultStats();
    }
  }

  goToStatSearch(statName) {
    this.resetStore();
    this.routerStore.pushHistory(RouteConstants.STATISTICS.route + "/" + statName, false);
  }

  getStatTypeOptions() {
    let options = [{ type: "All", categories: [] }];
    let types = this.lookupStore.getCollection("stattypes");

    for (let key in types) {
      options.push(types[key]);
    }

    return options;
  }

  getStatCols = () => {
    return [
      {
        key: "statName",
        name: "Stat Name",
        width: 300,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                this.openStat(row.row.statId);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "type", name: "Type", width: 120 },
      { key: "category", name: "Category", width: 180 },
      { key: "supportingStats", name: "Supporting Stats", width: 200 },
      { key: "formula", name: "Formula", width: 300 },
      { key: "sourceExcel", name: "Source", width: 200 },
      {
        key: "lastupdatedDte",
        name: "Last Updated",
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      }
    ];
  };

  getDefaultStats() {
    this.supportingStats = [];
    this.piaApi.findStats({}, true).then(response => {
      if (!!response) {
        this.statResults = response;
      }
    });
  }

  updateStatType(option) {
    this.statSearch.searchType = option.type;
    this.statSearch.searchCategory = "All";

    if ("All" === option.type) {
      this.statTypeCategories = this.defaults["statTypeCategories"];
    } else {
      this.statTypeCategories = this.defaults["statTypeCategories"].concat(option.categories);
    }
  }

  findStats() {
    this.selectedStats = [];
    this.supportingStats = [];
    this.piaApi.findStats(this.statSearch, false).then(response => {
      if (!!response) {
        this.statResults = response;
      }
    });
  }

  findStatsByName() {
    this.piaApi.findStatsByName(this.statSearch).then(response => {
      if (!!response) {
        this.statListSearchDetails = response;
      }
    });
  }

  get displayStats() {
    if (!this.statResults) {
      return [];
    }

    let selectedIds = this.selectedStats.map(stat => stat.statId);

    return this.statResults.map(stat => {
      return {
        ...stat,
        isSelected: _.includes(selectedIds, stat.statId)
      };
    });
  }

  addSelectedStats(rows) {
    rows.forEach(row => {
      if (row.statId) {
        this.selectedStats.push(row);
      }
    });
  }

  removeUnselectedStats(rows) {
    this.selectedStats = _.differenceWith(
      this.selectedStats,
      rows,
      (selected, remove) => selected.statId === remove.statId
    );
  }

  deleteStats() {
    this.piaApi.deleteStats(this.selectedStats).then(response => {
      if (!!response) {
        this.resetStore();
        this.getDefaultStats();
      }
    });
  }

  shareStats(users, comments) {
    let sharing = {
      stats: this.selectedStats,
      users: users,
      comments: comments
    };
    this.piaApi.shareStats(sharing).then(response => {
      if (!!response) {
        this.selectedStats = [];
        this.getDefaultStats();
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The stats you selected were successfully shared."
        });
      }
    });
  }

  parseStatListUrl() {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.STAT_LISTS.route + RouteConstants.STAT_LISTS.pathParams
    );

    if (!!pathParams["listName"]) {
      this.listSearch.statListName = pathParams["listName"];
      this.findLists();
    } else {
      this.getDefaultLists();
    }
  }

  goToStatListSearch(listName) {
    this.resetStore();
    this.routerStore.pushHistory(RouteConstants.STAT_LISTS.route + "/" + listName, false);
  }

  getListCols() {
    return [
      {
        key: "statlistname",
        name: "Stat List Name",
        width: 300,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                this.openStatList(row.row.statlistId);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "numberofstats", name: "# in List", width: 100 },
      { key: "source", name: "Source", width: 300 },
      {
        key: "lastchDtstmpAmericaNY",
        name: "Last Updated",
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      }
    ];
  }

  getDefaultLists(limitResultsFlag = true, sortByNameFlag) {
    this.piaApi.getDefaultStatLists(limitResultsFlag, sortByNameFlag).then(response => {
      if (!!response) {
        this.statListResults = response;
      }
    });
  }

  findLists() {
    this.selectedStatLists = [];
    this.piaApi.findStatLists(this.listSearch, false).then(response => {
      if (!!response) {
        this.statListResults = response;
      }
    });
  }

  get displayLists() {
    if (!this.statListResults) {
      return [];
    }

    let selectedIds = this.selectedStatLists.map(list => list.statlistId);

    return this.statListResults.map(list => {
      return {
        ...list,
        isSelected: _.includes(selectedIds, list.statlistId),
        isStandard: _.includes(this.standardStatListIds, list.statlistId)
      };
    });
  }

  addSelectedLists(rows) {
    rows.forEach(row => {
      if (row.statlistId) {
        this.selectedStatLists.push(row);
      }
    });
  }

  removeUnselectedLists(rows) {
    this.selectedStatLists = _.differenceWith(
      this.selectedStatLists,
      rows,
      (selected, remove) => selected.statlistId === remove.statlistId
    );
  }

  deleteLists() {
    let selectedIds = this.selectedStatLists.map(list => list.statlistId);
    this.piaApi.deleteStatLists(selectedIds).then(response => {
      if (!!response) {
        this.resetStore();
        this.getDefaultLists();
      }
    });
  }

  updateStandardStatLists() {
    this.piaApi.updateStandardStatLists(this.standardStatListIds).then(response => {
      if (!!response) {
        this.getDefaultLists(false);
      }
    });
  }

  shareLists(users, comments) {
    let sharing = {
      statLists: this.selectedStatLists,
      users: users,
      comments: comments
    };
    this.piaApi.shareStatLists(sharing).then(response => {
      if (!!response) {
        this.selectedStatLists = [];
        this.getDefaultLists(false);
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The stat lists you selected were successfully shared."
        });
      }
    });
  }

  getAutocompleteText(stat) {
    if (!stat || !stat.statName) {
      return null;
    } else {
      return stat.statName + " (" + stat.statCode + ") - " + stat.category;
    }
  }

  filterBySearchTerm(stats, searchTerm) {
    if (searchTerm.length < 3) {
      return [];
    } else {
      return _.filter(stats, stat => {
        return this.searchTermInField(stat.statName, searchTerm);
      });
    }
  }

  openStat(statId) {
    this.routerStore.pushHistory(RouteConstants.EDIT_STATISTIC.route + "/" + statId, false);
  }

  parseUrlAndLoadStat() {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.EDIT_STATISTIC.route + RouteConstants.EDIT_STATISTIC.pathParams
    );
    let statId = pathParams["statId"] || this.defaults["stat"].statId;
    statId = statId * 1;

    if (!!statId) {
      this.piaApi.getStat(statId).then(response => {
        if (!!response) {
          this.stat = response;

          if (this.stat.formula && "" !== this.stat.formula) {
            this.statFormula.parts = this.parseFormulaString(this.stat.formula);
          }
        }
      });
    }
  }

  parseFormulaString(string) {
    let parsedFormula = [];
    let str = string.trim();
    let skip = false;

    for (let i = 0; i < str.length; i++) {
      if (str.charAt(i) !== " ") {
        if (str.charAt(i) === "{") {
          // Record index, skip to second bracket
          skip = true;
          let index = i;
          while (skip === true) {
            if (str.charAt(i) !== "}" && i !== str.length - 1) {
              i++;
            } else {
              parsedFormula.push(str.substring(index, i + 1) + " ");
              skip = false;
            }
          }
        } else if (
          str.charAt(i) === "+" ||
          str.charAt(i) === "-" ||
          str.charAt(i) === "*" ||
          str.charAt(i) === "/" ||
          str.charAt(i) === "(" ||
          str.charAt(i) === ")"
        ) {
          //add it to the array list
          parsedFormula.push(str.charAt(i) + " ");
        } else if ((str.charAt(i) <= "9" && str.charAt(i) >= "0") || str.charAt(i) === ".") {
          //record index, skip to the last number
          skip = true;
          let index = i;
          while (skip === true) {
            if (((str.charAt(i) <= "9" && str.charAt(i) >= "0") || str.charAt(i) === ".") && i !== str.length - 1) {
              i++;
            } else {
              if (i === str.length - 1) {
                parsedFormula.push(str.substring(index, i + 1) + " ");
                skip = false;
              } else {
                parsedFormula.push(str.substring(index, i) + " ");
                skip = false;
                i = i - 1;
              }
            }
          }
        }
      }
    }
    return parsedFormula;
  }

  addToNewStatFormula(string) {
    if (!string) {
      return;
    }

    if (this.statFormula.selectedIndex > -1) {
      this.statFormula.parts.splice(this.statFormula.selectedIndex + 1, 0, string);
    } else {
      this.statFormula.parts.push(string);
    }
  }

  deleteSelectedFormulaPart() {
    if (this.statFormula.selectedIndex <= -1) {
      return;
    }

    this.statFormula.parts.splice(this.statFormula.selectedIndex, 1);
    this.statFormula.selectedIndex = -1;
  }

  saveStat(overrideFlag = false) {
    this.stat.formula = this.statFormula.parts.join(" ");
    this.piaApi.saveStat(this.stat, overrideFlag).then(response => {
      if (!!response) {
        if (response.overrideMessage) {
          this.stat.overrideMessage = response.overrideMessage;
          this.stat.showOverride = true;
        } else if (response.payload) {
          // Reload autocomplete
          this.lookupStore.getAutocompleteStats();
          this.openStat(response.payload);
        }
      }
    });
  }

  deleteStat() {
    this.piaApi.deleteStats([this.stat]).then(response => {
      if (!!response) {
        this.openStat(0);
      }
    });
  }

  openStatList(statListId, comparisononlyFlg = false, slDetails) {
    localStorage.setItem("EDIT_STAT_LIST_DETAILS", JSON.stringify(slDetails));

    this.routerStore.pushHistory(RouteConstants.EDIT_STAT_LIST.route + "/" + statListId, false, {
      comparisononlyFlg: comparisononlyFlg
    });
  }

  parseAndLoadEditStatListUrl() {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.EDIT_STAT_LIST.route + RouteConstants.EDIT_STAT_LIST.pathParams
    );
    let statListId = pathParams["statListId"] || this.defaults["statList"].statlistId;
    statListId = statListId * 1;

    if (!!statListId) {
      this.piaApi.getStatList(statListId).then(response => {
        if (!!response) {
          this.statList = response;
          this.postLoadStatList();
        }
      });
    } else {
      this.postLoadStatList();
    }
  }

  getStatList(id, updateList = true) {
    this.piaApi.getStatList(id).then(response => {
      if (!!response) {
        if (updateList) {
          this.statList = response;
        } else {
          let list = response.slDetails || [];
          this.statListSearchDetails = list.map((stat, index) => {
            return {
              ...stat,
              index,
              sourceExcel: stat.source,
              selectedoncompFlg: !!stat.selectedoncompFlg ? stat.selectedoncompFlg : false
            };
          });
        }
      }
    });
  }

  postLoadStatList() {
    if (this.routerStore.location.state && this.routerStore.location.state.comparisononlyFlg) {
      this.statList.comparisononlyFlg = true;
    }

    try {
      let details = JSON.parse(localStorage.getItem("EDIT_STAT_LIST_DETAILS"));

      if (details) {
        this.statList.slDetails = details;
      }
    } catch (error) {}

    localStorage.removeItem("EDIT_STAT_LIST_DETAILS");
  }

  get displayStatListDetails() {
    if (!this.statList || !this.statList.slDetails) {
      return [];
    }

    return this.statList.slDetails.map((stat, index) => {
      return {
        ...stat,
        index,
        selectedoncompFlg: !!stat.selectedoncompFlg ? stat.selectedoncompFlg : false
      };
    });
  }

  saveStatList(overrideFlag = false) {
    this.piaApi.saveStatList(this.statList, overrideFlag).then(response => {
      if (response) {
        if (response.overrideMessage) {
          this.statList.overrideMessage = response.overrideMessage;
          this.statList.overrideFn = () => {
            this.statList.overrideMessage = null;
            this.statList.showOverride = false;
            this.saveStatList(true);
          };
          this.statList.showOverride = true;
        } else if (response.payload) {
          this.openStatList(response.payload);
        }
      }
    });
  }
}

export default StatStore;
