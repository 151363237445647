import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import {
  BestDropdown,
  BestLabel,
  BestQuickSearch,
  BestSelect,
  CardContainer,
  Checkbox,
  DateTimeFormatter,
  FormInput,
  Icon,
  LightButton,
  PrimaryButton
} from "best-common-react";
import { FormSearchStyles, FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";
import PlayerListCriteriaTable from "./PlayerListCriteriaTable";
import TitleCard from "../widgets/TitleCard";
import ReactToolTip from "react-tooltip";

class EditPlayerListCriteria extends React.Component {
  resetFocusPlayerFields(playerStore) {
    playerStore.listCriteria.focusplayerId = null;
    playerStore.listCriteria.positionFocusFlg = false;
    playerStore.listCriteria.mlsFocusCustomFlg = "C";
  }

  getFocusPositionDetails() {
    return [
      { value: "P", label: "Same as Focus - Primary" },
      { value: "S", label: "Same as Focus - Secondary" },
      { value: "PS", label: "Same as Focus - Primary or Secondary" },
      { value: "R", label: "Same as Focus - Regular" }
    ];
  }

  getSplitCols(playerStore) {
    return [
      { key: "splitCategory", name: "Category", width: 180 },
      {
        key: "splitName",
        name: "Name",
        formatter: row => {
          return <span>{row.value + ("View All" === row.value ? "*" : "")}</span>;
        }
      },
      {
        key: "deleteSplit",
        name: "",
        width: 40,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Icon
                className="mlb-red-icon"
                iconName="fa-trash-alt"
                onClick={() => {
                  let index = playerStore.listCriteria.splits.indexOf(row.row);
                  if (index > -1) {
                    playerStore.listCriteria.splits.splice(index, 1);
                  }
                }}
              />
            </div>
          );
        }
      }
    ];
  }

  getCustomPositionDetails() {
    return [
      { value: "", label: "None" },
      { value: "P", label: "Primary Only" },
      { value: "S", label: "Secondary Only" },
      { value: "PS", label: "Primary or Secondary" },
      { value: "R", label: "Regular" }
    ];
  }

  getMlsTypeOptions() {
    return [
      { value: "F", label: "Focus" },
      { value: "C", label: "Custom" }
    ];
  }

  getFocusServiceDetails() {
    return [
      { value: "sf", label: "Same as Focus" },
      { value: "sfa", label: "Same as Focus + Class Above" },
      { value: "caf", label: "Class Above Focus" },
      { value: "cbf", label: "Class Below Focus" }
    ];
  }

  getTimeFrameUnitOptions(playerStore, addPlatformYears = true) {
    let units = [
      { value: "Years", label: "Years" },
      { value: "Season Numbers", label: "Season Numbers" }
    ];

    if (addPlatformYears && playerStore.playerList.plDetails && playerStore.playerList.plDetails.length) {
      units.push({ value: "Platform Years", label: "Platform Years" });
    }

    return units;
  }

  getTimeFrameTypeOptions(playerStore, focusOnly = false) {
    if (playerStore.listCriteria.avplFlg) {
      if (focusOnly) {
        return [
          { value: "SIN", label: "Single Season" },
          { value: "CAR", label: "Career" },
          { value: "CUM", label: "Cumulative" },
          { value: "CBD", label: "Cumulative Dates" }
        ];
      } else {
        return [
          { value: "SIN", label: "Single Season" },
          { value: "CUM", label: "Multiple Seasons - Cumulative" },
          { value: "ANY", label: "Multiple Seasons - In Any Year" },
          { value: "EAC", label: "Multiple Seasons - In Each Year" },
          { value: "INS", label: "Multiple Seasons - Instances" },
          { value: "CRT", label: "Career - Totals" },
          { value: "CAR", label: "Career - Through Year" },
          { value: "CRE", label: "Career - In Each Year during Career" },
          { value: "CET", label: "Career - In Each Year Through" },
          { value: "CBD", label: "Date Range - Cumulative" }
        ];
      }
    } else {
      return [
        { value: "SIN", label: "Single Year" },
        { value: "ANY", label: "In Any Year From" },
        { value: "EAC", label: "In Each Year From" },
        { value: "CUM", label: "Cumulative Years From" },
        { value: "CRT", label: "Career Totals" }
      ];
    }
  }

  getSeasonOptions() {
    return [
      { value: "1", label: "1st Season" },
      { value: "2", label: "2nd Season" },
      { value: "3", label: "3rd Season" },
      { value: "4", label: "4th Season" },
      { value: "5", label: "5th Season" },
      { value: "6", label: "6th Season" },
      { value: "7", label: "7th Season" },
      { value: "8", label: "8th Season" },
      { value: "9", label: "9th Season" },
      { value: "10", label: "10th Season" },
      { value: "11", label: "11th Season" },
      { value: "12", label: "12th Season" },
      { value: "13", label: "13th Season" },
      { value: "14", label: "14th Season" },
      { value: "15", label: "15th Season" },
      { value: "16", label: "16th Season" },
      { value: "17", label: "17th Season" },
      { value: "18", label: "18th Season" },
      { value: "19", label: "19th Season" },
      { value: "20", label: "20th Season" },
      { value: "21", label: "21st Season" },
      { value: "22", label: "22nd Season" },
      { value: "23", label: "23rd Season" },
      { value: "24", label: "24th Season" },
      { value: "25", label: "25th Season" },
      { value: "26", label: "26th Season" },
      { value: "27", label: "27th Season" },
      { value: "28", label: "28th Season" },
      { value: "29", label: "29th Season" },
      { value: "30", label: "30th Season" },
      { value: "31", label: "31st Season" },
      { value: "32", label: "32nd Season" },
      { value: "33", label: "33rd Season" },
      { value: "34", label: "34th Season" },
      { value: "35", label: "35th Season" },
      { value: "36", label: "36th Season" },
      { value: "37", label: "37th Season" },
      { value: "38", label: "38th Season" },
      { value: "39", label: "39th Season" }
    ];
  }

  getPlatformYearOptions() {
    return [
      { value: "-9", label: "PY-9" },
      { value: "-8", label: "PY-8" },
      { value: "-7", label: "PY-7" },
      { value: "-6", label: "PY-6" },
      { value: "-5", label: "PY-5" },
      { value: "-4", label: "PY-4" },
      { value: "-3", label: "PY-3" },
      { value: "-2", label: "PY-2" },
      { value: "-1", label: "PY-1" },
      { value: "0", label: "PY" },
      { value: "1", label: "PY+1" },
      { value: "2", label: "PY+2" },
      { value: "3", label: "PY+3" },
      { value: "4", label: "PY+4" },
      { value: "5", label: "PY+5" },
      { value: "6", label: "PY+6" },
      { value: "7", label: "PY+7" },
      { value: "8", label: "PY+8" },
      { value: "9", label: "PY+9" }
    ];
  }

  getInstanceOptions() {
    return [
      { value: true, label: "First" },
      { value: false, label: "Last" }
    ];
  }

  getLeagueOptions() {
    return [
      { value: "ANY", label: "Any" },
      { value: "playing in the AL", label: "AL" },
      { value: "playing in the NL", label: "NL" }
    ];
  }

  get leagueTeamDropdownsEnabled() {
    let { playerStore } = this.props.rootStore;
    let enabled = false;

    if (playerStore.listCriteria.criteriaForQuery) {
      for (let i = 0; i < playerStore.listCriteria.criteriaForQuery.length; i++) {
        if ("Statistics" === playerStore.listCriteria.criteriaForQuery[i].criteriaType) {
          enabled = true;
          break;
        }
      }
    }

    return enabled;
  }

  getYearTimeFrameFragment(lookupStore, playerStore, years, timeZone) {
    return (
      <React.Fragment>
        {"SIN" === playerStore.listCriteria.timeframeType ? (
          <div className="col-3 col-xl-6">
            <BestDropdown
              name="timeframeSingleYear"
              title="From"
              value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeSingleYear)}
              onChange={option => {
                playerStore.listCriteria.timeframeSingleYear = option.value;
              }}
              options={years}
              styles={FormSelectStyles}
            />
          </div>
        ) : "ANY" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3 col-xl-6">
              <BestDropdown
                name="timeframeAnyyearFromYear"
                title="From"
                value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeAnyyearFromYear)}
                onChange={option => {
                  playerStore.listCriteria.timeframeAnyyearFromYear = option.value;
                }}
                options={years}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3 col-xl-6">
              <BestDropdown
                name="timeframeAnyyearToYear"
                title="To"
                value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeAnyyearToYear)}
                onChange={option => {
                  playerStore.listCriteria.timeframeAnyyearToYear = option.value;
                }}
                options={years}
                styles={FormSelectStyles}
              />
            </div>
          </React.Fragment>
        ) : "EAC" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3 col-xl-6">
              <BestDropdown
                name="timeframeEachyearFromYear"
                title="From"
                value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeEachyearFromYear)}
                onChange={option => {
                  playerStore.listCriteria.timeframeEachyearFromYear = option.value;
                }}
                options={years}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3 col-xl-6">
              <BestDropdown
                name="timeframeEachyearToYear"
                title="To"
                value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeEachyearToYear)}
                onChange={option => {
                  playerStore.listCriteria.timeframeEachyearToYear = option.value;
                }}
                options={years}
                styles={FormSelectStyles}
              />
            </div>
          </React.Fragment>
        ) : "CUM" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3 col-xl-6">
              <BestDropdown
                name="timeframeCumulativeFromYear"
                title="From"
                value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeCumulativeFromYear)}
                onChange={option => {
                  playerStore.listCriteria.timeframeCumulativeFromYear = option.value;
                }}
                options={years}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3 col-xl-6">
              <BestDropdown
                name="timeframeCumulativeToYear"
                title="To"
                value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeCumulativeToYear)}
                onChange={option => {
                  playerStore.listCriteria.timeframeCumulativeToYear = option.value;
                }}
                options={years}
                styles={FormSelectStyles}
              />
            </div>
          </React.Fragment>
        ) : "INS" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3 col-xl-6">
              <div className="row">
                <div className="col-6">
                  <BestDropdown
                    name="timeframeInstancesFromYear"
                    title="From"
                    value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeInstancesFromYear)}
                    onChange={option => {
                      playerStore.listCriteria.timeframeInstancesFromYear = option.value;
                    }}
                    options={years}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-6">
                  <BestDropdown
                    name="timeframeInstancesToYear"
                    title="To"
                    value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeInstancesToYear)}
                    onChange={option => {
                      playerStore.listCriteria.timeframeInstancesToYear = option.value;
                    }}
                    options={years}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>
            </div>
            {this.getTimeFrameInstancesFragment(playerStore)}
          </React.Fragment>
        ) : "CAR" === playerStore.listCriteria.timeframeType ? (
          <div className="col-3 col-xl-6">
            <BestDropdown
              name="timeframeCareerToYear"
              title="From"
              value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeCareerToYear)}
              onChange={option => {
                playerStore.listCriteria.timeframeCareerToYear = option.value;
              }}
              options={years}
              styles={FormSelectStyles}
            />
          </div>
        ) : "CET" === playerStore.listCriteria.timeframeType ? (
          <div className="col-3 col-xl-6">
            <BestDropdown
              name="timeframeEachYearCareerToYear"
              title="From"
              value={lookupStore.getDropdownItem(years, playerStore.listCriteria.timeframeEachYearCareerToYear)}
              onChange={option => {
                playerStore.listCriteria.timeframeEachYearCareerToYear = option.value;
              }}
              options={years}
              styles={FormSelectStyles}
            />
          </div>
        ) : "CBD" === playerStore.listCriteria.timeframeType ? (
          this.getCumulativeDateFragment(
            playerStore,
            "timeframeCumulativeFromDate",
            "timeframeCumulativeToDate",
            timeZone
          )
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  getTimeFrameInstancesFragment(playerStore) {
    return (
      <div className="col-3">
        <div className="row">
          <div className="col-6">
            <FormInput
              name="timeframeInstances"
              type="number"
              label="Instances"
              value={playerStore.listCriteria.timeframeInstances}
              onChangeFn={value => {
                playerStore.listCriteria.timeframeInstances = value;
              }}
              editable
            />
          </div>
          <div className="col-6 d-flex align-items-end">
            <div className="form-group">
              <Checkbox
                id="timeframeInstancesConsecutiveFlg"
                label="Consecutive"
                onChange={event => {
                  playerStore.listCriteria.timeframeInstancesConsecutiveFlg = event.target.checked;
                }}
                checked={!!playerStore.listCriteria.timeframeInstancesConsecutiveFlg}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  getCumulativeDateFragment(playerStore, fromProp, toProp, timeZone) {
    return (
      <React.Fragment>
        <div className="col-3">
          <FormInput
            name={fromProp}
            label="From"
            type="date"
            value={playerStore.listCriteria[fromProp]}
            onChangeFn={date => {
              playerStore.listCriteria[fromProp] = date;
            }}
            formatter={DateTimeFormatter}
            timeZone={timeZone}
            editable
          />
        </div>
        <div className="col-3">
          <FormInput
            name={toProp}
            label="To"
            type="date"
            value={playerStore.listCriteria[toProp]}
            onChangeFn={date => {
              playerStore.listCriteria[toProp] = date;
            }}
            formatter={DateTimeFormatter}
            timeZone={timeZone}
            editable
          />
        </div>
      </React.Fragment>
    );
  }

  getSeasonTimeFrameFragment(lookupStore, playerStore, seasons, timeZone) {
    return (
      <React.Fragment>
        {"SIN" === playerStore.listCriteria.timeframeType ? (
          <div className="col-3">
            <BestDropdown
              name="timeframeSingleSeason"
              title="From"
              value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeSingleSeason)}
              onChange={option => {
                playerStore.listCriteria.timeframeSingleSeason = option.value;
              }}
              options={seasons}
              styles={FormSelectStyles}
            />
          </div>
        ) : "ANY" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3">
              <BestDropdown
                name="timeframeAnyyearFromSeason"
                title="From"
                value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeAnyyearFromSeason)}
                onChange={option => {
                  playerStore.listCriteria.timeframeAnyyearFromSeason = option.value;
                }}
                options={seasons}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3">
              <BestDropdown
                name="timeframeAnyyearToSeason"
                title="To"
                value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeAnyyearToSeason)}
                onChange={option => {
                  playerStore.listCriteria.timeframeAnyyearToSeason = option.value;
                }}
                options={seasons}
                styles={FormSelectStyles}
              />
            </div>
          </React.Fragment>
        ) : "EAC" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3">
              <BestDropdown
                name="timeframeEachyearFromSeason"
                title="From"
                value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeEachyearFromSeason)}
                onChange={option => {
                  playerStore.listCriteria.timeframeEachyearFromSeason = option.value;
                }}
                options={seasons}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3">
              <BestDropdown
                name="timeframeEachyearToSeason"
                title="To"
                value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeEachyearToSeason)}
                onChange={option => {
                  playerStore.listCriteria.timeframeEachyearToSeason = option.value;
                }}
                options={seasons}
                styles={FormSelectStyles}
              />
            </div>
          </React.Fragment>
        ) : "CUM" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3">
              <BestDropdown
                name="timeframeCumulativeFromSeason"
                title="From"
                value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeCumulativeFromSeason)}
                onChange={option => {
                  playerStore.listCriteria.timeframeCumulativeFromSeason = option.value;
                }}
                options={seasons}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3">
              <BestDropdown
                name="timeframeCumulativeToSeason"
                title="To"
                value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeCumulativeToSeason)}
                onChange={option => {
                  playerStore.listCriteria.timeframeCumulativeToSeason = option.value;
                }}
                options={seasons}
                styles={FormSelectStyles}
              />
            </div>
          </React.Fragment>
        ) : "INS" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3">
              <div className="row">
                <div className="col-6">
                  <BestDropdown
                    name="timeframeInstancesFromSeason"
                    title="From"
                    value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeInstancesFromSeason)}
                    onChange={option => {
                      playerStore.listCriteria.timeframeInstancesFromSeason = option.value;
                    }}
                    options={seasons}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-6">
                  <BestDropdown
                    name="timeframeInstancesToSeason"
                    title="To"
                    value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeInstancesToSeason)}
                    onChange={option => {
                      playerStore.listCriteria.timeframeInstancesToSeason = option.value;
                    }}
                    options={seasons}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>
            </div>
            {this.getTimeFrameInstancesFragment(playerStore)}
          </React.Fragment>
        ) : "CAR" === playerStore.listCriteria.timeframeType ? (
          <div className="col-3">
            <BestDropdown
              name="timeframeCareerToSeason"
              title="From"
              value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeCareerToSeason)}
              onChange={option => {
                playerStore.listCriteria.timeframeCareerToSeason = option.value;
              }}
              options={seasons}
              styles={FormSelectStyles}
            />
          </div>
        ) : "CET" === playerStore.listCriteria.timeframeType ? (
          <div className="col-3">
            <BestDropdown
              name="timeframeEachYearCareerToSeason"
              title="From"
              value={lookupStore.getDropdownItem(seasons, playerStore.listCriteria.timeframeEachYearCareerToSeason)}
              onChange={option => {
                playerStore.listCriteria.timeframeEachYearCareerToSeason = option.value;
              }}
              options={seasons}
              styles={FormSelectStyles}
            />
          </div>
        ) : "CBD" === playerStore.listCriteria.timeframeType ? (
          this.getCumulativeDateFragment(
            playerStore,
            "timeframeCumulativeFromDate",
            "timeframeCumulativeToDate",
            timeZone
          )
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  getPlatformYearTimeFrameFragment(lookupStore, playerStore, timeZone) {
    let platformYears = this.getPlatformYearOptions();
    return (
      <React.Fragment>
        {"SIN" === playerStore.listCriteria.timeframeType ? (
          <div className="col-3">
            <BestDropdown
              name="timeframeSinglePlatformYear"
              title="From"
              value={lookupStore.getDropdownItem(platformYears, playerStore.listCriteria.timeframeSinglePlatformYear)}
              onChange={option => {
                playerStore.listCriteria.timeframeSinglePlatformYear = option.value;
              }}
              options={platformYears}
              styles={FormSelectStyles}
            />
          </div>
        ) : "ANY" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3">
              <BestDropdown
                name="timeframeAnyyearFromPlatformYear"
                title="From"
                value={lookupStore.getDropdownItem(
                  platformYears,
                  playerStore.listCriteria.timeframeAnyyearFromPlatformYear
                )}
                onChange={option => {
                  playerStore.listCriteria.timeframeAnyyearFromPlatformYear = option.value;
                }}
                options={platformYears}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3">
              <BestDropdown
                name="timeframeAnyyearToPlatformYear"
                title="To"
                value={lookupStore.getDropdownItem(
                  platformYears,
                  playerStore.listCriteria.timeframeAnyyearToPlatformYear
                )}
                onChange={option => {
                  playerStore.listCriteria.timeframeAnyyearToPlatformYear = option.value;
                }}
                options={platformYears}
                styles={FormSelectStyles}
              />
            </div>
          </React.Fragment>
        ) : "EAC" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3">
              <BestDropdown
                name="timeframeEachyearFromPlatformYear"
                title="From"
                value={lookupStore.getDropdownItem(
                  platformYears,
                  playerStore.listCriteria.timeframeEachyearFromPlatformYear
                )}
                onChange={option => {
                  playerStore.listCriteria.timeframeEachyearFromPlatformYear = option.value;
                }}
                options={platformYears}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3">
              <BestDropdown
                name="timeframeEachyearToPlatformYear"
                title="To"
                value={lookupStore.getDropdownItem(
                  platformYears,
                  playerStore.listCriteria.timeframeEachyearToPlatformYear
                )}
                onChange={option => {
                  playerStore.listCriteria.timeframeEachyearToPlatformYear = option.value;
                }}
                options={platformYears}
                styles={FormSelectStyles}
              />
            </div>
          </React.Fragment>
        ) : "CUM" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3">
              <BestDropdown
                name="timeframeCumulativeFromPlatformYear"
                title="From"
                value={lookupStore.getDropdownItem(
                  platformYears,
                  playerStore.listCriteria.timeframeCumulativeFromPlatformYear
                )}
                onChange={option => {
                  playerStore.listCriteria.timeframeCumulativeFromPlatformYear = option.value;
                }}
                options={platformYears}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3">
              <BestDropdown
                name="timeframeCumulativeToPlatformYear"
                title="To"
                value={lookupStore.getDropdownItem(
                  platformYears,
                  playerStore.listCriteria.timeframeCumulativeToPlatformYear
                )}
                onChange={option => {
                  playerStore.listCriteria.timeframeCumulativeToPlatformYear = option.value;
                }}
                options={platformYears}
                styles={FormSelectStyles}
              />
            </div>
          </React.Fragment>
        ) : "INS" === playerStore.listCriteria.timeframeType ? (
          <React.Fragment>
            <div className="col-3">
              <div className="row">
                <div className="col-6">
                  <BestDropdown
                    name="timeframeInstancesFromPlatformYear"
                    title="From"
                    value={lookupStore.getDropdownItem(
                      platformYears,
                      playerStore.listCriteria.timeframeInstancesFromPlatformYear
                    )}
                    onChange={option => {
                      playerStore.listCriteria.timeframeInstancesFromPlatformYear = option.value;
                    }}
                    options={platformYears}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-6">
                  <BestDropdown
                    name="timeframeInstancesToPlatformYear"
                    title="To"
                    value={lookupStore.getDropdownItem(
                      platformYears,
                      playerStore.listCriteria.timeframeInstancesToPlatformYear
                    )}
                    onChange={option => {
                      playerStore.listCriteria.timeframeInstancesToPlatformYear = option.value;
                    }}
                    options={platformYears}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>
            </div>
            {this.getTimeFrameInstancesFragment(playerStore)}
          </React.Fragment>
        ) : "CAR" === playerStore.listCriteria.timeframeType ? (
          <div className="col-3">
            <BestDropdown
              name="timeframeCareerToPlatformYear"
              title="From"
              value={lookupStore.getDropdownItem(platformYears, playerStore.listCriteria.timeframeCareerToPlatformYear)}
              onChange={option => {
                playerStore.listCriteria.timeframeCareerToPlatformYear = option.value;
              }}
              options={platformYears}
              styles={FormSelectStyles}
            />
          </div>
        ) : "CET" === playerStore.listCriteria.timeframeType ? (
          <div className="col-3">
            <BestDropdown
              name="timeframeEachYearCareerToPlatformYear"
              title="From"
              value={lookupStore.getDropdownItem(
                platformYears,
                playerStore.listCriteria.timeframeEachYearCareerToPlatformYear
              )}
              onChange={option => {
                playerStore.listCriteria.timeframeEachYearCareerToPlatformYear = option.value;
              }}
              options={platformYears}
              styles={FormSelectStyles}
            />
          </div>
        ) : "CBD" === playerStore.listCriteria.timeframeType ? (
          this.getCumulativeDateFragment(
            playerStore,
            "timeframeCumulativeFromDate",
            "timeframeCumulativeToDate",
            timeZone
          )
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  getInstanceOnlyFragment(lookupStore, playerStore) {
    let instances = this.getInstanceOptions();

    return (
      <div className="row col-12">
        <input
          type="checkbox"
          id="instanceCheckbox"
          onChange={event => {
            playerStore.listCriteria.instanceCheckbox = event.target.checked;
          }}
          checked={playerStore.listCriteria.instanceCheckbox}
        />
        <label className="ml-1 form-check-label w-75">
          {
            <div className="item-row h-inherit justify-content-start col-12 p-0">
              <span className="item">Return</span>

              <div className="d-inline-flex w-25 item">
                <div className="col-12 p-0">
                  <BestSelect
                    name="firstInstanceOnly"
                    options={instances}
                    onChange={option => {
                      playerStore.listCriteria.firstInstanceOnly = option.value;
                    }}
                    value={lookupStore.getDropdownItem(instances, playerStore.listCriteria.firstInstanceOnly)}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>

              <span className="item">Instance Only</span>
            </div>
          }
        </label>
      </div>
    );
  }

  renderBasic() {
    let { commonStore, lookupStore, playerStore } = this.props.rootStore;
    let years = commonStore.getListOfYears(1871);
    let focusPosDetails = this.getFocusPositionDetails();
    let customPosDetails = this.getCustomPositionDetails();
    let additionalPositions = playerStore.getListCriteriaPositionOptions();
    let mlsTypes = this.getMlsTypeOptions();
    let focusServiceDetails = this.getFocusServiceDetails();
    let tfTypes = this.getTimeFrameTypeOptions(playerStore);
    let timeZone = commonStore.getTimeZone();
    let leagues = this.getLeagueOptions();
    let teams = [{ teamLk: "ANY", label: "Any" }].concat(lookupStore.getCollection("teams"));
    let teamsOnly = lookupStore.getCollection("teams");

    return (
      <React.Fragment>
        <CardContainer>
          <CardContainer.Body>
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-12 col-xl-6">
                  <TitleCard>
                    <span>Focus Player</span>
                  </TitleCard>

                  <div className="row mb-2">
                    <div className="col-4 col-xl-6">
                      <div className="form-group mb-0">
                        <BestLabel>Focus Player Name</BestLabel>

                        <BestQuickSearch
                          options={lookupStore.autocompletePlayers}
                          renderSuggestion={suggestion => {
                            return <PlayerAutocompleteRow player={suggestion} />;
                          }}
                          getSuggestionValue={player => {
                            return playerStore.getAutocompleteText(player);
                          }}
                          getSuggestions={(players, searchTerm) => {
                            if (searchTerm.indexOf(")") === -1) {
                              playerStore.listCriteria.focusplayerName = playerStore.formatForComparison(searchTerm);
                              this.resetFocusPlayerFields(playerStore);
                            }
                            return playerStore.filterBySearchTerm(players, searchTerm);
                          }}
                          onSuggestionSelected={(event, { suggestion }) => {
                            playerStore.listCriteria.focusplayerId = suggestion.playerId;
                            playerStore.listCriteria.focusplayerName = suggestion.playerName;
                            playerStore.listCriteria.positionFocusFlg = true;
                            playerStore.listCriteria.positionCustomFlg = false;
                          }}
                          onChange={event => {
                            console.log(event);
                          }}
                          onClear={event => {
                            playerStore.listCriteria.positionFocusFlg = false;
                            playerStore.listCriteria.positionCustomFlg = true;
                          }}
                          searchValue={playerStore.listCriteria.focusplayerName || ""}
                          styles={FormSearchStyles}
                        />
                      </div>
                    </div>

                    <div className="col-4 col-xl-6">
                      <BestDropdown
                        name="focusplayerYear"
                        title="Platform Year"
                        value={lookupStore.getDropdownItem(years, playerStore.listCriteria.focusplayerYear)}
                        onChange={option => {
                          playerStore.listCriteria.focusplayerYear = option.value;
                        }}
                        options={years}
                        styles={FormSelectStyles}
                        isDisabled={
                          !playerStore.listCriteria.positionFocusFlg || !playerStore.listCriteria.focusplayerName
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-6">
                  <TitleCard>
                    <span>Position</span>
                  </TitleCard>

                  <div className="row mb-2">
                    <div className="col-4 col-xl-6 d-inline-flex">
                      <input
                        type="checkbox"
                        id="positionFocusFlg"
                        className="align-self-end mr-3"
                        onChange={event => {
                          playerStore.listCriteria.positionFocusFlg = event.target.checked;
                        }}
                        checked={playerStore.listCriteria.positionFocusFlg}
                        disabled={!playerStore.listCriteria.focusplayerId}
                      />
                      <div className="flex-fill">
                        <BestDropdown
                          name="positionFocusDtl"
                          title="Focus"
                          value={lookupStore.getDropdownItem(
                            focusPosDetails,
                            playerStore.listCriteria.positionFocusDtl
                          )}
                          onChange={option => {
                            playerStore.listCriteria.positionFocusDtl = option.value;
                          }}
                          options={focusPosDetails}
                          styles={FormSelectStyles}
                          isDisabled={!playerStore.listCriteria.positionFocusFlg}
                        />
                      </div>
                    </div>

                    <div className="col-4 col-xl-6 d-inline-flex">
                      <input
                        type="checkbox"
                        id="positionCustomFlg"
                        className="align-self-end mr-3"
                        onChange={event => {
                          playerStore.listCriteria.positionCustomFlg = event.target.checked;
                        }}
                        checked={playerStore.listCriteria.positionCustomFlg}
                      />

                      <div className="flex-fill">
                        <BestDropdown
                          name="positionCustomDtl"
                          title="Custom"
                          value={lookupStore.getDropdownItem(
                            customPosDetails,
                            playerStore.listCriteria.positionCustomDtl
                          )}
                          onChange={option => {
                            playerStore.listCriteria.positionCustomFlg = "" !== option.value;
                            playerStore.listCriteria.positionCustomDtl = option.value;
                          }}
                          options={customPosDetails}
                          styles={FormSelectStyles}
                        />
                      </div>
                    </div>

                    {playerStore.listCriteria.positionCustomFlg ? (
                      <React.Fragment>
                        <div className="col-6 col-xl-12">
                          <div className="form-group mb-0 player-list-positions">
                            <BestLabel>Additional Positions</BestLabel>
                            <BestSelect
                              name="positionFlags"
                              options={additionalPositions}
                              value={playerStore.listCriteria.positions}
                              onChange={value => {
                                if (!value) {
                                  value = [];
                                }

                                playerStore.updateListPositions(value);
                              }}
                              styles={FormSelectStyles}
                              isMulti
                            />
                          </div>
                        </div>

                        <div className="col-6 col-xl-12 d-flex mt-4 mt-xl-2 justify-content-between justify-content-xl-end">
                          <span className="pr-xl-4">
                            <LightButton
                              onClick={event => {
                                event.preventDefault();
                                playerStore.updateListPositions(additionalPositions);
                              }}
                            >
                              Select All
                            </LightButton>
                          </span>

                          <span className="pr-xl-4">
                            <LightButton
                              onClick={event => {
                                event.preventDefault();
                                playerStore.updateListPositions([]);
                              }}
                            >
                              Remove All
                            </LightButton>
                          </span>

                          <span className="pr-xl-4">
                            <LightButton
                              onClick={event => {
                                event.preventDefault();
                                let positions = [
                                  { value: "position1BFlg", label: "1B" },
                                  { value: "position2BFlg", label: "2B" },
                                  { value: "position3BFlg", label: "3B" },
                                  { value: "positionSSFlg", label: "SS" },
                                  { value: "positionCFlg", label: "C" },
                                  { value: "positionDHFlg", label: "DH" },
                                  { value: "positionOFFlg", label: "OF" }
                                ];
                                playerStore.updateListPositions(positions);
                              }}
                            >
                              All Hitting
                            </LightButton>
                          </span>

                          <LightButton
                            onClick={event => {
                              event.preventDefault();
                              let positions = [
                                { value: "positionSPFlg", label: "SP" },
                                { value: "positionRPFlg", label: "RP" }
                              ];
                              playerStore.updateListPositions(positions);
                            }}
                          >
                            All Pitching
                          </LightButton>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-xl-6">
                  <TitleCard>
                    <span>Major League Service</span>
                  </TitleCard>

                  <div className="row mb-2">
                    <div className="col-3 col-xl-6 justify-content-xl-start">
                      <BestDropdown
                        className="w-50"
                        name="mlsFocusCustomFlg"
                        title="Service"
                        value={lookupStore.getDropdownItem(mlsTypes, playerStore.listCriteria.mlsFocusCustomFlg)}
                        onChange={option => {
                          playerStore.listCriteria.mlsFocusCustomFlg = option.value;
                        }}
                        options={mlsTypes}
                        styles={FormSelectStyles}
                        isDisabled={!playerStore.listCriteria.positionFocusFlg}
                      />
                    </div>

                    <div className="d-none d-xl-flex col-xl-6"></div>

                    {"C" === playerStore.listCriteria.mlsFocusCustomFlg ? (
                      ""
                    ) : (
                      <div className="col-4 col-xl-6">
                        <BestDropdown
                          name="mlsFocusDtl"
                          title="Level"
                          value={lookupStore.getDropdownItem(focusServiceDetails, playerStore.listCriteria.mlsFocusDtl)}
                          onChange={option => {
                            playerStore.listCriteria.mlsFocusDtl = option.value;
                          }}
                          options={focusServiceDetails}
                          styles={FormSelectStyles}
                        />
                      </div>
                    )}

                    {"C" === playerStore.listCriteria.mlsFocusCustomFlg ? (
                      <React.Fragment>
                        <div className="col-3 col-xl-6">
                          <div>
                            <BestLabel>From (Years/Days)</BestLabel>
                          </div>
                          <div className="d-flex">
                            <div className="pr-1 pr-xl-5">
                              <FormInput
                                name="mlsCustomFromYears"
                                type="number"
                                onChangeFn={value => {
                                  playerStore.listCriteria.mlsCustomFromYears = value + "";
                                }}
                                value={playerStore.listCriteria.mlsCustomFromYears * 1}
                                editable
                              />
                            </div>
                            <div>
                              <FormInput
                                name="mlsCustomFromDays"
                                type="number"
                                onChangeFn={value => {
                                  playerStore.listCriteria.mlsCustomFromDays = value + "";
                                }}
                                value={playerStore.listCriteria.mlsCustomFromDays * 1}
                                editable
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-3 col-xl-6">
                          <div>
                            <BestLabel>To (Years/Days)</BestLabel>
                          </div>
                          <div className="d-flex">
                            <div className="pr-1 pr-xl-5">
                              <FormInput
                                name="mlsCustomToYears"
                                type="number"
                                onChangeFn={value => {
                                  playerStore.listCriteria.mlsCustomToYears = value + "";
                                }}
                                value={playerStore.listCriteria.mlsCustomToYears * 1}
                                editable
                              />
                            </div>
                            <div>
                              <FormInput
                                name="mlsCustomToDays"
                                type="number"
                                onChangeFn={value => {
                                  playerStore.listCriteria.mlsCustomToDays = value + "";
                                }}
                                value={playerStore.listCriteria.mlsCustomToDays * 1}
                                editable
                              />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-12 col-xl-6">
                  <TitleCard>
                    <span>Time Frame for Comparables</span>
                  </TitleCard>

                  <div className="row mb-2">
                    <div className="col-3 col-xl-6">
                      <BestDropdown
                        name="timeframeType"
                        title="Time Frame"
                        value={lookupStore.getDropdownItem(tfTypes, playerStore.listCriteria.timeframeType)}
                        onChange={option => {
                          playerStore.listCriteria.timeframeType = option.value;
                        }}
                        options={tfTypes}
                        styles={FormSelectStyles}
                      />
                    </div>

                    <div className="d-none d-xl-flex col-xl-6"></div>

                    {this.getYearTimeFrameFragment(lookupStore, playerStore, years, timeZone)}
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12 col-xl-6">
                  <TitleCard>
                    <span>Additional Query Options</span>
                  </TitleCard>

                  <div className="row mb-2">
                    <div className="col-6 col-xl-8">
                      <div className="row col-12">
                        <Checkbox
                          id="useNextYearForContractCriteria"
                          label="Use Platform Year + 1 for Contract Information"
                          onChange={event => {
                            playerStore.listCriteria.useNextYearForContractCriteria = event.target.checked;
                          }}
                          checked={playerStore.listCriteria.useNextYearForContractCriteria}
                        />
                      </div>

                      <div className="row col-6 col-xl-8">
                        <Checkbox
                          id="mlsInPlatformYearOnly"
                          label="Apply MLS Criteria to Platform Year Only"
                          onChange={event => {
                            playerStore.listCriteria.mlsInPlatformYearOnly = event.target.checked;
                          }}
                          checked={playerStore.listCriteria.mlsInPlatformYearOnly}
                        />
                      </div>
                      {this.getInstanceOnlyFragment(lookupStore, playerStore)}
                    </div>

                    <div className="col-6 col-xl-4">
                      <div className="form-group">
                        <ReactToolTip effect="solid" place="bottom" />
                        <BestDropdown
                          name="playingInLeague"
                          value={lookupStore.getDropdownItem(leagues, playerStore.listCriteria.playingInLeague)}
                          onChange={option => {
                            playerStore.listCriteria.playingInLeague = option.value;
                          }}
                          title={
                            <span data-tip="Filters all statistical criteria chosen in the table below to stats accrued in the selected league.">
                              <BestLabel>Playing in League</BestLabel>
                            </span>
                          }
                          options={leagues}
                          styles={FormSelectStyles}
                          isDisabled={!this.leagueTeamDropdownsEnabled}
                        />
                      </div>

                      <div className="form-group">
                        <ReactToolTip effect="solid" place="bottom" />
                        <span data-tip="Filters all statistical criteria chosen in the table below to stats accrued while playing on the selected team.">
                          <BestLabel>Playing on Team</BestLabel>
                        </span>
                        <BestSelect
                          name="firstInstanceOnly"
                          options={teams}
                          getOptionValue={option => option.teamLk}
                          getOptionLabel={option => option.label || option.teamLk}
                          onChange={option => {
                            playerStore.listCriteria.playingOnTeam = option.teamLk;
                          }}
                          value={lookupStore.getDropdownItem(
                            teams,
                            playerStore.listCriteria.playingOnTeam,
                            "teamLk",
                            "teamLk"
                          )}
                          styles={FormSelectStyles}
                          isDisabled={!this.leagueTeamDropdownsEnabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-xl-6">
                  <TitleCard>
                    <span>40-Man Roster</span>
                  </TitleCard>

                  <div className="row mb-2">
                    <div className="col-12 col-lg-4 d-flex flex-column">
                      <span>Players on 40-man roster of</span>
                      <span className="d-flex">
                        <BestDropdown
                          name="40ManRoster"
                          title="Org:"
                          options={teamsOnly}
                          getOptionValue={option => option.teamLk}
                          getOptionLabel={option => option.teamLk}
                          value={lookupStore.getDropdownItem(
                            teamsOnly,
                            playerStore.listCriteria.on40ManRosterOfTeam,
                            "teamLk",
                            "teamLk"
                          )}
                          onChange={option => {
                            playerStore.listCriteria.on40ManRosterOfTeam = option.teamLk;
                          }}
                          styles={FormSelectStyles}
                        />
                        <span className="ml-2 pt-4">
                          <LightButton
                            disabled={
                              !playerStore.listCriteria.on40ManRosterOfTeam ||
                              playerStore.listCriteria.on40ManRosterOfTeam === null ||
                              playerStore.listCriteria.on40ManRosterOfTeam === ""
                            }
                            onClick={() => {
                              playerStore.listCriteria.criteriaForQuery = [];
                              playerStore.getPlayersForList("NEW");
                            }}
                          >
                            Go
                          </LightButton>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12">
                  <TitleCard>
                    <span>Criteria</span>
                  </TitleCard>
                </div>
              </div>
              {playerStore.listCriteria.criteriaForQuery ? (
                <PlayerListCriteriaTable
                  thresholds={playerStore.listCriteria.criteriaForQuery}
                  comp={false}
                  advanced={playerStore.listCriteria.avplFlg}
                />
              ) : (
                ""
              )}
            </div>
          </CardContainer.Body>
          <CardContainer.Footer>
            <div className="header-item-row">
              <PrimaryButton
                onClick={() => {
                  playerStore.listCriteria.on40ManRosterOfTeam = null;
                  playerStore.getPlayersForList("NEW");
                }}
              >
                Run New Query
              </PrimaryButton>
              <LightButton
                onClick={() => {
                  playerStore.listCriteria.on40ManRosterOfTeam = null;
                  playerStore.getPlayersForList("APPEND");
                }}
              >
                Run Query and Append Results
              </LightButton>
              <LightButton
                onClick={() => {
                  playerStore.listCriteria.on40ManRosterOfTeam = null;
                  playerStore.getPlayersForList("EXISTING");
                }}
                disabled={!playerStore.playerList.plDetails || playerStore.playerList.plDetails.length === 0}
              >
                Search Existing Player List
              </LightButton>
              <PrimaryButton
                onClick={() => {
                  playerStore.playerList.saveQueryModalOpen = true;
                }}
              >
                Save Query
              </PrimaryButton>
              <LightButton
                onClick={() => {
                  playerStore.playerList.loadQueryModalOpen = true;
                }}
              >
                Load Query
              </LightButton>
            </div>
          </CardContainer.Footer>
        </CardContainer>
      </React.Fragment>
    );
  }

  renderAdvanced() {
    let { commonStore, lookupStore, playerStore } = this.props.rootStore;
    let years = commonStore.getListOfYears(1871);
    let seasons = this.getSeasonOptions();
    let focusTfTypes = this.getTimeFrameTypeOptions(playerStore, true);
    let focusTfUnits = this.getTimeFrameUnitOptions(playerStore, false);
    let tfTypes = this.getTimeFrameTypeOptions(playerStore);
    let tfUnits = this.getTimeFrameUnitOptions(playerStore);
    let timeZone = commonStore.getTimeZone();

    return (
      <React.Fragment>
        <CardContainer>
          <CardContainer.Header>
            <span className="header-title">Search Criteria</span>
          </CardContainer.Header>

          <CardContainer.Body>
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-12">
                  <TitleCard>
                    <span>Focus Player</span>
                  </TitleCard>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-3">
                  <div className="form-group">
                    <BestLabel>Focus Player Name</BestLabel>

                    <BestQuickSearch
                      options={lookupStore.autocompletePlayers}
                      renderSuggestion={suggestion => {
                        return <PlayerAutocompleteRow player={suggestion} />;
                      }}
                      getSuggestionValue={player => {
                        return playerStore.getAutocompleteText(player);
                      }}
                      getSuggestions={(players, searchTerm) => {
                        if (searchTerm.indexOf(")") === -1) {
                          playerStore.listCriteria.focusplayerName = playerStore.formatForComparison(searchTerm);
                          this.resetFocusPlayerFields(playerStore);
                        }
                        return playerStore.filterBySearchTerm(players, searchTerm);
                      }}
                      onSuggestionSelected={(event, { suggestion }) => {
                        playerStore.listCriteria.focusplayerId = suggestion.playerId;
                        playerStore.listCriteria.focusplayerName = suggestion.playerName;
                        playerStore.listCriteria.positionFocusFlg = true;
                        playerStore.listCriteria.positionCustomFlg = false;
                      }}
                      onChange={event => {
                        console.log(event);
                      }}
                      onClear={event => {
                        playerStore.listCriteria.positionFocusFlg = false;
                        playerStore.listCriteria.positionCustomFlg = true;
                      }}
                      searchValue={playerStore.listCriteria.focusplayerName || ""}
                      styles={FormSearchStyles}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-3">
                  <BestDropdown
                    name="focusTimeframeDropdownValues"
                    title="Time Frame Units"
                    value={lookupStore.getDropdownItem(
                      focusTfUnits,
                      playerStore.listCriteria.focusTimeframeDropdownValues
                    )}
                    onChange={option => {
                      playerStore.listCriteria.focusTimeframeDropdownValues = option.value;
                    }}
                    options={focusTfUnits}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-3">
                  <BestDropdown
                    name="focusTimeframeType"
                    title="Time Frame"
                    value={lookupStore.getDropdownItem(focusTfTypes, playerStore.listCriteria.focusTimeframeType)}
                    onChange={option => {
                      playerStore.listCriteria.focusTimeframeType = option.value;
                    }}
                    options={focusTfTypes}
                    styles={FormSelectStyles}
                  />
                </div>

                {"Years" === playerStore.listCriteria.focusTimeframeDropdownValues ? (
                  <React.Fragment>
                    {"SIN" === playerStore.listCriteria.focusTimeframeType ? (
                      <div className="col-3">
                        <BestDropdown
                          name="focusTimeframeSingle"
                          title="From"
                          value={lookupStore.getDropdownItem(years, playerStore.listCriteria.focusTimeframeSingle)}
                          onChange={option => {
                            playerStore.listCriteria.focusTimeframeSingle = option.value;
                          }}
                          options={years}
                          styles={FormSelectStyles}
                        />
                      </div>
                    ) : "CUM" === playerStore.listCriteria.focusTimeframeType ? (
                      <React.Fragment>
                        <div className="col-3">
                          <BestDropdown
                            name="focusTimeframeCumulativeFrom"
                            title="From"
                            value={lookupStore.getDropdownItem(
                              years,
                              playerStore.listCriteria.focusTimeframeCumulativeFrom
                            )}
                            onChange={option => {
                              playerStore.listCriteria.focusTimeframeCumulativeFrom = option.value;
                            }}
                            options={years}
                            styles={FormSelectStyles}
                          />
                        </div>
                        <div className="col-3">
                          <BestDropdown
                            name="focusTimeframeCumulativeTo"
                            title="To"
                            value={lookupStore.getDropdownItem(
                              years,
                              playerStore.listCriteria.focusTimeframeCumulativeTo
                            )}
                            onChange={option => {
                              playerStore.listCriteria.focusTimeframeCumulativeTo = option.value;
                            }}
                            options={years}
                            styles={FormSelectStyles}
                          />
                        </div>
                      </React.Fragment>
                    ) : "CAR" === playerStore.listCriteria.focusTimeframeType ? (
                      <div className="col-3">
                        <BestDropdown
                          name="focusTimeframeCareer"
                          title="From"
                          value={lookupStore.getDropdownItem(years, playerStore.listCriteria.focusTimeframeCareer)}
                          onChange={option => {
                            playerStore.listCriteria.focusTimeframeCareer = option.value;
                          }}
                          options={years}
                          styles={FormSelectStyles}
                        />
                      </div>
                    ) : "CBD" === playerStore.listCriteria.focusTimeframeType ? (
                      this.getCumulativeDateFragment(
                        playerStore,
                        "focusTimeframeCumulativeFromDate",
                        "focusTimeframeCumulativeToDate",
                        timeZone
                      )
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ) : "Season Numbers" === playerStore.listCriteria.focusTimeframeDropdownValues ? (
                  <React.Fragment>
                    {"SIN" === playerStore.listCriteria.focusTimeframeType ? (
                      <div className="col-3">
                        <BestDropdown
                          name="focusTimeframeSingleSeason"
                          title="From"
                          value={lookupStore.getDropdownItem(
                            seasons,
                            playerStore.listCriteria.focusTimeframeSingleSeason
                          )}
                          onChange={option => {
                            playerStore.listCriteria.focusTimeframeSingleSeason = option.value;
                          }}
                          options={seasons}
                          styles={FormSelectStyles}
                        />
                      </div>
                    ) : "CUM" === playerStore.listCriteria.focusTimeframeType ? (
                      <React.Fragment>
                        <div className="col-3">
                          <BestDropdown
                            name="focusTimeframeCumulativeFromSeason"
                            title="From"
                            value={lookupStore.getDropdownItem(
                              seasons,
                              playerStore.listCriteria.focusTimeframeCumulativeFromSeason
                            )}
                            onChange={option => {
                              playerStore.listCriteria.focusTimeframeCumulativeFromSeason = option.value;
                            }}
                            options={seasons}
                            styles={FormSelectStyles}
                          />
                        </div>
                        <div className="col-3">
                          <BestDropdown
                            name="focusTimeframeCumulativeToSeason"
                            title="To"
                            value={lookupStore.getDropdownItem(
                              seasons,
                              playerStore.listCriteria.focusTimeframeCumulativeToSeason
                            )}
                            onChange={option => {
                              playerStore.listCriteria.focusTimeframeCumulativeToSeason = option.value;
                            }}
                            options={seasons}
                            styles={FormSelectStyles}
                          />
                        </div>
                      </React.Fragment>
                    ) : "CAR" === playerStore.listCriteria.focusTimeframeType ? (
                      <div className="col-3">
                        <BestDropdown
                          name="focusTimeframeCareerSeason"
                          title="From"
                          value={lookupStore.getDropdownItem(
                            seasons,
                            playerStore.listCriteria.focusTimeframeCareerSeason
                          )}
                          onChange={option => {
                            playerStore.listCriteria.focusTimeframeCareerSeason = option.value;
                          }}
                          options={seasons}
                          styles={FormSelectStyles}
                        />
                      </div>
                    ) : "CBD" === playerStore.listCriteria.focusTimeframeType ? (
                      this.getCumulativeDateFragment(
                        playerStore,
                        "focusTimeframeCumulativeFromDate",
                        "focusTimeframeCumulativeToDate",
                        timeZone
                      )
                    ) : (
                      ""
                    )}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </div>

              <div className="row mb-2">
                <div className="col-12">
                  <TitleCard>
                    <span>Splits</span>
                  </TitleCard>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-3">
                  <BestSelect
                    name="selectedSplitCategory"
                    placeholder="Select a split category..."
                    options={playerStore.displayListSplitCategories}
                    onChange={option => {
                      playerStore.listCriteria.selectedSplitCategory = option;
                      playerStore.listCriteria.selectedSplitName = "";
                    }}
                    value={lookupStore.getDropdownItem(
                      playerStore.displayListSplitCategories,
                      playerStore.listCriteria.selectedSplitCategory.splitType,
                      "splitType",
                      "splitType"
                    )}
                    getOptionValue={option => option.splitType}
                    getOptionLabel={option => option.splitType}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-3">
                  <BestSelect
                    name="splitName"
                    placeholder="Select a split..."
                    options={playerStore.listCriteria.selectedSplitCategory.splits}
                    onChange={option => {
                      playerStore.listCriteria.selectedSplitName = option.splitName;
                    }}
                    value={lookupStore.getDropdownItem(
                      playerStore.listCriteria.selectedSplitCategory.splits,
                      playerStore.listCriteria.selectedSplitName,
                      "splitName",
                      "splitName"
                    )}
                    getOptionValue={option => option.splitName}
                    getOptionLabel={option => option.splitName}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-1 d-flex flex-fill">
                  <PrimaryButton
                    className="flex-fill"
                    onClick={() => {
                      if (
                        playerStore.listCriteria.selectedSplitCategory.splitType &&
                        playerStore.listCriteria.selectedSplitName &&
                        "" !== playerStore.listCriteria.selectedSplitName
                      ) {
                        playerStore.listCriteria.splits.push({
                          splitCategory: playerStore.listCriteria.selectedSplitCategory.splitType,
                          splitName: playerStore.listCriteria.selectedSplitName
                        });
                        playerStore.listCriteria.selectedSplitCategory.splitType = "";
                        playerStore.listCriteria.selectedSplitName = "";
                      }
                    }}
                  >
                    Add
                  </PrimaryButton>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-12">
                  <PiaTable
                    data={playerStore.listCriteria.splits}
                    columns={this.getSplitCols(playerStore)}
                    height={playerStore.calculateTableHeight(playerStore.listCriteria.splits.length, 336)}
                    minColumnWidth={40}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12">
                  <TitleCard>
                    <span>Time Frame for Comparables</span>
                  </TitleCard>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-3">
                  <BestDropdown
                    name="compTimeframeDropdownValues"
                    title="Time Frame Units"
                    value={lookupStore.getDropdownItem(tfUnits, playerStore.listCriteria.compTimeframeDropdownValues)}
                    onChange={option => {
                      playerStore.listCriteria.compTimeframeDropdownValues = option.value;
                    }}
                    options={tfUnits}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-3">
                  <BestDropdown
                    name="timeframeType"
                    title="Time Frame"
                    value={lookupStore.getDropdownItem(tfTypes, playerStore.listCriteria.timeframeType)}
                    onChange={option => {
                      playerStore.listCriteria.timeframeType = option.value;
                    }}
                    options={tfTypes}
                    styles={FormSelectStyles}
                  />
                </div>

                {"Years" === playerStore.listCriteria.compTimeframeDropdownValues
                  ? this.getYearTimeFrameFragment(lookupStore, playerStore, years, timeZone)
                  : "Season Numbers" === playerStore.listCriteria.compTimeframeDropdownValues
                  ? this.getSeasonTimeFrameFragment(lookupStore, playerStore, seasons, timeZone)
                  : "Platform Years" === playerStore.listCriteria.compTimeframeDropdownValues
                  ? this.getPlatformYearTimeFrameFragment(lookupStore, playerStore, timeZone)
                  : ""}
              </div>

              <div className="row mb-2">
                <div className="col-12">
                  <TitleCard>
                    <span>Additional Query Options</span>
                  </TitleCard>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-6">
                  <div className="row col-12">
                    <Checkbox
                      id="useNextYearForContractCriteria"
                      label="Use Platform Year + 1 for Contract Information"
                      onChange={event => {
                        playerStore.listCriteria.useNextYearForContractCriteria = event.target.checked;
                      }}
                      checked={playerStore.listCriteria.useNextYearForContractCriteria}
                    />
                  </div>

                  <div className="row col-12">
                    <Checkbox
                      id="mlsInPlatformYearOnly"
                      label="Apply MLS Criteria to Platform Year Only"
                      onChange={event => {
                        playerStore.listCriteria.mlsInPlatformYearOnly = event.target.checked;
                      }}
                      checked={playerStore.listCriteria.mlsInPlatformYearOnly}
                    />
                  </div>
                </div>

                <div className="col-6">
                  <div className="row col-12">
                    <Checkbox
                      id="deferContractCriteria"
                      label="Apply Contract Criteria to Any Year of Platform Year Contract"
                      onChange={event => {
                        playerStore.listCriteria.deferContractCriteria = event.target.checked;
                      }}
                      checked={playerStore.listCriteria.deferContractCriteria}
                    />
                  </div>
                  {this.getInstanceOnlyFragment(lookupStore, playerStore)}
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-12">
                  <TitleCard>
                    <span>Criteria</span>
                  </TitleCard>
                </div>
              </div>
              {playerStore.listCriteria.criteriaForQuery ? (
                <PlayerListCriteriaTable
                  thresholds={playerStore.listCriteria.criteriaForQuery}
                  comp={false}
                  advanced={playerStore.listCriteria.avplFlg}
                />
              ) : (
                ""
              )}
            </div>
          </CardContainer.Body>
          <CardContainer.Footer>
            <div className="header-item-row">
              <PrimaryButton
                onClick={() => {
                  playerStore.getPlayersForList("NEW");
                }}
              >
                Run New Query
              </PrimaryButton>
              <LightButton
                onClick={() => {
                  playerStore.getPlayersForList("APPEND");
                }}
              >
                Run Query and Append Results
              </LightButton>
              <LightButton
                onClick={() => {
                  playerStore.getPlayersForList("EXISTING");
                }}
                disabled={!playerStore.playerList.plDetails || playerStore.playerList.plDetails.length === 0}
              >
                Search Existing Player List
              </LightButton>
              <PrimaryButton
                onClick={() => {
                  playerStore.playerList.saveQueryModalOpen = true;
                }}
              >
                Save Query
              </PrimaryButton>
              <LightButton
                onClick={() => {
                  playerStore.playerList.loadQueryModalOpen = true;
                }}
              >
                Load Query
              </LightButton>
            </div>
          </CardContainer.Footer>
        </CardContainer>
      </React.Fragment>
    );
  }

  render() {
    let { playerStore } = this.props.rootStore;
    return playerStore.listCriteria.avplFlg ? this.renderAdvanced() : this.renderBasic();
  }
}

EditPlayerListCriteria.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(EditPlayerListCriteria)));
