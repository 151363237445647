import { action, autorun, extendObservable } from "mobx";
import Parser from "rss-parser";
import { AlertConstants } from "../../constants/AlertConstants";

class NewsFeedStore {
  constructor(authStore, routerStore, alertStore, piaApi) {
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.alertStore = alertStore;
    this.piaApi = piaApi;

    this.defaults = {
      loaded: false,
      feeds: [],
      feedOptions: [],
      displayFeedId: -1,
      feedData: {},
      openModal: false
    };

    extendObservable(this, {
      loaded: this.defaults["loaded"],
      feeds: this.defaults["feeds"],
      feedOptions: this.defaults["feedOptions"],
      displayFeedId: this.defaults["displayFeedId"],
      feedData: this.defaults["feedData"],
      openModal: this.defaults["openModal"],
      resetStore: action(() => {
        this.loaded = this.defaults["loaded"];
        this.feeds = this.defaults["feeds"];
        this.feedOptions = this.defaults["feedOptions"];
        this.displayFeedId = this.defaults["displayFeedId"];
        this.feedData = this.defaults["feedData"];
        this.openModal = this.defaults["openModal"];
      })
    });

    autorun(() => {
      if (this.routerStore.isMyNewsFeedPage && this.authStore.loggedIn) {
        this.updateNewsFeeds();
      } else {
        this.resetStore();
      }
    });
  }

  updateNewsFeeds() {
    this.piaApi.getNewsFeeds().then(response => {
      if (!!response) {
        let parser = new Parser();
        let loadCount = 0;
        this.feeds = response;
        let feedDropdown = [{ value: -1, label: "Show All" }];

        response.forEach(feed => {
          let feedUrl = feed.feedURL.replace(/.+:\/\//, ""); // Remove http(s):// from string
          feedDropdown.push({ value: feed.newsFeedId, label: feed.feedName });
          parser.parseURL(this.piaApi.proxyUrl + feedUrl).then(
            response => {
              if (!!response) {
                this.feedData[feed.newsFeedId] = response;
              } else {
                this.feedData[feed.newsFeedId] = {};
              }

              ++loadCount;
              this.loaded = loadCount === this.feeds.length;
            },
            error => {
              this.alertStore.addAlert({
                type: AlertConstants.TYPES.DANGER,
                text: "Could not load feed " + feed.feedURL
              });

              this.feedData[feed.newsFeedId] = {};
              ++loadCount;
              this.loaded = loadCount === this.feeds.length;
            }
          );
        });

        if (response.length === 0) {
          this.loaded = true;
        }

        this.feedOptions = feedDropdown;
      }
    });
  }
}

export default NewsFeedStore;
