import { action, extendObservable } from "mobx";
import Moment from "moment";

class CommonStore {
  constructor() {
    this.defaults = {};

    extendObservable(this, {
      resetStore: action(() => {})
    });
  }

  getOrgName(orgName) {
    if (orgName !== undefined && orgName !== "") {
      return orgName;
    } else {
      return "FA";
    }
  }

  getListOfYears(startYear, futureYears = 0) {
    let currentYear = new Date().getFullYear();
    let years = [];

    for (let i = currentYear + futureYears; i >= startYear; i--) {
      years.push({ label: i, value: i });
    }

    return years;
  }

  getTimeZone() {
    return Moment.tz !== undefined ? Moment.tz.guess() : "America/New_York";
  }
}

export default CommonStore;
