import _ from "lodash";
// Helper functions for tables

class TableHelper {
  constructor() {
    this.sort = this.sort.bind(this);
    this.sortObj = this.sortObj.bind(this);
  }

  sort(list, keys, direction) {
    if (!keys) {
      return list;
    }

    if (!Array.isArray(keys)) {
      keys = [keys];
    }

    // Sort list by keys and direction
    const sorted = _.sortBy(
      list,
      keys.map(key => {
        return item => this.sortObj(item, key, direction);
      })
    );

    // Reverse list for descending
    if (typeof direction === "string" && direction.toUpperCase() === "DESC") {
      sorted.reverse();
    }
    return sorted;
  }

  sortObj(item, key, direction) {
    if (!isNaN(Number(item[key]))) {
      // If numeric key, or string representation of a number, sort by number
      if (item[key] === null) {
        return Infinity;
      }
      return Number(item[key]);
    } else if (typeof item[key] === "string") {
      // If string key, sort by lowercase for case insensitivity
      return item[key].toLowerCase();
    }

    // Sort by the key
    return item[key];
  }
}

export default TableHelper;
