import _ from "lodash";
import styled from "styled-components";
import { AttributeHeader, Input, Theme as BestTheme } from "best-common-react";
import styles from "./variables.scss";

const Theme = {
  ...BestTheme,
  ...styles,
  link: {
    color: styles["link-primary"],
    hover: styles.white
  },
  // icons
  icon: {
    color: styles.primary,
    hover: styles.white,
    size: styles["size-icon"]
  },
  "focus-color": "#fbf8cb",
  // notifications
  success: {
    background: "#c3e6cb",
    color: "#155724",
    border: "#155724"
  },
  danger: {
    background: styles.redDanger,
    color: styles.redDangerTwo,
    border: styles.redDangerTwo
  },
  warning: {
    background: styles.yellowWarning,
    color: styles.yellowWarningTwo,
    border: styles.yellowWarningTwo
  },
  border: {
    valid: "#80bdff",
    invalid: "#dc3545"
  },
  boxShadow: {
    valid: "rgba(0,123,255,.25)",
    invalid: "rgba(220,53,69,.25)"
  },
  placeholder: {
    color: styles["blue-grey"],
    fontWeight: "normal"
  },
  // hardcoded pixel values
  lineHeight: {
    eventGrid: "21px"
  },
  environment: {
    dev: {
      background: "#bf0d3e"
    },
    stg: {
      background: "#EDB707"
    },
    qa: {
      background: "#1F74BE"
    }
  }
};

export default Theme;

export const FormSelectStyles = {
  container: {
    ...styles,
    width: "100%"
  },
  control: {
    ...styles,
    minHeight: "2em",
    height: "2em",
    lineHeight: "normal",
    borderRadius: "4px",
    border: "solid 1px " + Theme["blue-grey"]
  },
  option: {
    ...styles,
    color: "#000000"
  },
  valueContainer: {
    ...styles,
    height: "2em",
    alignContent: "center"
  },
  indicatorsContainer: {
    ...styles,
    height: "2em"
  },
  placeholder: {
    ...styles,
    fontSize: "1em",
    fontStyle: "normal",
    color: Theme["blue-grey"]
  },
  singleValue: {
    ...styles,
    fontSize: "1em",
    fontStyle: "normal",
    color: "#000000 !important"
  },
  multiValue: {
    ...styles,
    height: "1.5em",
    fontSize: "1em",
    backgroundColor: Theme.primary
  },
  multiValueLabel: {
    ...styles,
    color: "#ffffff"
  },
  multiValueRemove: {
    ...styles,
    backgroundColor: Theme.primary,
    color: "#ffffff"
  }
};

export const FormSelectButtonStyles = _.cloneDeep(FormSelectStyles);
FormSelectButtonStyles.control.backgroundColor = styles.primary;
FormSelectButtonStyles.control.border = "solid 1px";
FormSelectButtonStyles.control.borderColor = styles.primary + " !important";
FormSelectButtonStyles.control.color = "#ffffff";
FormSelectButtonStyles.control["#best-icon"] = {
  color: "#ffffff !important"
};
FormSelectButtonStyles.placeholder.color = "#ffffff";
FormSelectButtonStyles.placeholder.fontWeight = "bold";
FormSelectButtonStyles.singleValue.color = "#ffffff !important";

export const FooterSelectStyles = _.cloneDeep(FormSelectButtonStyles);
FooterSelectStyles.menu = {
  minWidth: "250px"
};

export const FooterLightSelectStyles = _.cloneDeep(FooterSelectStyles);
FooterLightSelectStyles.control.border = "solid 1px";
FooterLightSelectStyles.control.borderColor = styles["blue-grey-two"] + " !important";
FooterLightSelectStyles.control.backgroundColor = styles["blue-grey-two"] + " !important";

export const FormMiniSelectButtonStyles = _.cloneDeep(FooterSelectStyles);
FormMiniSelectButtonStyles.control.minWidth = "50px";

export const FooterWarningSelectStyles = _.cloneDeep(FooterSelectStyles);
FooterWarningSelectStyles.control.border = "solid 1px";
FooterWarningSelectStyles.control.borderColor = Theme["mlb-red"] + " !important";
FooterWarningSelectStyles.control.backgroundColor = Theme["mlb-red"] + " !important";

export const FormSearchStyles = {
  input: {
    width: "100%",
    height: "2em",
    paddingLeft: "10px",
    fontFamily: "Roboto, sans-serif",
    fontSize: "inherit"
  },
  placeholder: {
    color: Theme["blue-grey"] + " !important"
  }
};

export const SearchBarStyles = _.cloneDeep(FormSearchStyles);
SearchBarStyles.input.border = "1px solid " + Theme["blue-grey"];
SearchBarStyles.input.borderRadius = "4px 0 0 4px";
SearchBarStyles.input.borderBottomRightRadius = "0";

export const HeaderSelectStyles = _.cloneDeep(FormSelectButtonStyles);
HeaderSelectStyles.control.height = "3em";
HeaderSelectStyles.control.backgroundColor = Theme["blue-grey"];
HeaderSelectStyles.control.border = "solid 1px" + Theme["blue-grey"];
HeaderSelectStyles.control.borderColor = Theme["blue-grey"] + " !important";
HeaderSelectStyles.indicatorsContainer.height = "3em";

export const HeaderSearchSelectStyles = _.cloneDeep(HeaderSelectStyles);
HeaderSearchSelectStyles.control.borderRadius = "4px 0 0 4px";
HeaderSearchSelectStyles.singleValue.fontWeight = "bold";

export const HeaderSearchStyles = _.cloneDeep(FormSearchStyles);
HeaderSearchStyles.input.height = "3em";
HeaderSearchStyles.input.border = "1px solid " + Theme["blue-grey"];
HeaderSearchStyles.input.borderRadius = "0";
HeaderSearchStyles.input.borderBottomLeftRadius = "0";
HeaderSearchStyles.input.borderBottomRightRadius = "0";

export const TabSwitcherStyles = {
  tab: {
    activeColor: Theme["white"],
    backgroundColor: Theme["primary"],
    borderColor: Theme["grey-three"],
    defaultColor: Theme["primary"]
  },
  container: {
    backgroundColor: Theme["white"]
  }
};

export const InlineHeader = styled(AttributeHeader).attrs(() => ({
  className: "m-0"
}))``;
