export const RouteConstants = {
  ADMIN_TOOLS: { title: "Admin Tools", route: "/admin-tools" },
  BASE: { title: "", route: "/" },
  CANNED_REPORTS: { title: "Canned Reports", route: "/canned-reports" },
  COMP_FINDER: { title: "Comp Finder", route: "/comp-finder", tabs: ["Search Criteria", "Saved Searches", "Setup"] },
  EDIT_COMP_FINDER: {
    title: "Comp Finder",
    route: "/edit-comp-finder",
    pathParams: "/:compFinderId",
    tabs: ["Search Criteria", "Saved Searches", "Setup"]
  },
  COMPARISONS: { title: "Comparisons", route: "/comparisons", pathParams: "/:compName" },
  EDIT_COMPARISON: {
    title: "Edit Comparison",
    route: "/edit-comparison",
    pathParams: "/:comparisonId",
    tabs: [
      "One-on-One",
      "Award",
      "Rankings",
      "Stat Summary",
      "Industry",
      "Performance",
      "Player",
      "Contract",
      "Report History",
      "Comments"
    ]
  },
  EDIT_PLAYER_LIST: { title: "Edit Player List", route: "/edit-player-list", pathParams: "/:playerListId" },
  EDIT_STAT_LIST: { title: "Edit Stat List", route: "/edit-stat-list", pathParams: "/:statListId" },
  EDIT_STATISTIC: { title: "Edit Statistic", route: "/edit-statistic", pathParams: "/:statId" },
  HOME_PLATE: { title: "Home Plate", route: "/home-plate" },
  IMPLICIT_CALLBACK: { title: "", route: "/implicit/callback" },
  LINKS: { title: "Links", route: "/links" },
  LOADING_REPORT: { title: "Loading Report", route: "/loading-report", pathParams: "/:reportName" },
  LOOKUPS: { title: "Lookups", route: "/lookups" },
  LOGIN: { title: "Login", route: "/login" },
  MY_NEWS_FEED: { title: "My News Feed", route: "/my-news-feed" },
  MY_SAVED_REPORTS: { title: "My Saved Reports", route: "/my-saved-reports" },
  PLAYER_LISTS: { title: "Player Lists", route: "/player-lists", pathParams: "/:listName" },
  PLAYER_PROFILE: { title: "Player Profile", route: "/player", pathParams: "/:playerId" },
  PLAYER_QUERIES: { title: "Player Queries", route: "/player-queries", pathParams: "/:queryName" },
  PLAYERS: { title: "Players", route: "/players", pathParams: "/:playerName" },
  SHARING: { title: "Sharing", route: "/sharing", pathParams: "/:typeId", tabs: ["Share Items", "Sharing History"] },
  STAT_LISTS: { title: "Stat Lists", route: "/stat-lists", pathParams: "/:listName" },
  STATISTICS: { title: "Statistics", route: "/statistics", pathParams: "/:statName" }
};
