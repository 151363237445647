import { computed, decorate } from "mobx";
import { RouterStore } from "mobx-react-router";
import { RouteConstants } from "../constants/RouteConstants";

class PiaRouterStore extends RouterStore {
  previousPathName = "";
  truePreviousPathName = "";

  get isAdminToolsPage() {
    return this.location.pathname === RouteConstants.ADMIN_TOOLS.route;
  }

  get isCannedReportsPage() {
    return this.location.pathname === RouteConstants.CANNED_REPORTS.route;
  }

  get isCompFinderPage() {
    return this.location.pathname === RouteConstants.COMP_FINDER.route;
  }

  get isEditCompFinderPage() {
    return this.location.pathname.includes(RouteConstants.EDIT_COMP_FINDER.route);
  }

  get isComparisonsPage() {
    return !!this.location.pathname.includes(RouteConstants.COMPARISONS.route);
  }

  get isEditComparisonPage() {
    return !!this.location.pathname.includes(RouteConstants.EDIT_COMPARISON.route);
  }

  get isEditPlayerListPage() {
    return !!this.location.pathname.includes(RouteConstants.EDIT_PLAYER_LIST.route);
  }

  get isEditStatListPage() {
    return !!this.location.pathname.includes(RouteConstants.EDIT_STAT_LIST.route);
  }

  get isEditStatisticPage() {
    return !!this.location.pathname.includes(RouteConstants.EDIT_STATISTIC.route);
  }

  get isHomePlatePage() {
    return this.location.pathname === RouteConstants.HOME_PLATE.route;
  }

  get isLinksPage() {
    return this.location.pathname === RouteConstants.LINKS.route;
  }

  get isLoadingReportPage() {
    return this.location.pathname.includes(RouteConstants.LOADING_REPORT.route);
  }

  get isLoginPage() {
    return this.location.pathname === RouteConstants.LOGIN.route;
  }

  get isMyNewsFeedPage() {
    return this.location.pathname === RouteConstants.MY_NEWS_FEED.route;
  }

  get isMySavedReportsPage() {
    return this.location.pathname === RouteConstants.MY_SAVED_REPORTS.route;
  }

  get isPlayerListsPage() {
    return !!this.location.pathname.includes(RouteConstants.PLAYER_LISTS.route);
  }

  get isPlayerQueriesPage() {
    return !!this.location.pathname.includes(RouteConstants.PLAYER_QUERIES.route);
  }

  get isPlayerProfilePage() {
    return (
      !!this.location.pathname.includes(RouteConstants.PLAYER_PROFILE.route) &&
      !this.isPlayerListsPage &&
      !this.isPlayerQueriesPage &&
      !this.isPlayersPage
    );
  }

  get isPlayersPage() {
    return !!this.location.pathname.includes(RouteConstants.PLAYERS.route);
  }

  get isSharingPage() {
    return !!this.location.pathname.includes(RouteConstants.SHARING.route);
  }

  get isStatListsPage() {
    return !!this.location.pathname.includes(RouteConstants.STAT_LISTS.route);
  }

  get isStatisticsPage() {
    return !!this.location.pathname.includes(RouteConstants.STATISTICS.route);
  }

  getPathParams(path) {
    let pathSplit = path.split("/");
    let urlSplit = this.location.pathname.split("/");

    let values = {};
    pathSplit.forEach((i, index) => {
      if (i.includes(":")) {
        let key = i.substr(1);
        values[key] = urlSplit[index];
      }
    });
    return values;
  }

  refresh() {
    this.truePreviousPathName = this.location.pathname;
    this.history.replace(this.location.pathname);
  }

  pushHistory(route, replace, state) {
    if (!route) {
      return;
    }

    if (route !== this.location.pathname) {
      // Moving to a different url
      // Track the current url as previousPathName
      // and move to the new one
      this.previousPathName = this.location.pathname;
    }
    this.truePreviousPathName = this.location.pathname;

    if (replace) {
      this.history.replace(route, state);
    } else {
      this.history.push(route, state);
    }
  }

  openInNewTab(routeObj) {
    if (!routeObj) {
      return;
    }

    return window.open(window.location.origin + routeObj);
  }
}

decorate(PiaRouterStore, {
  isLoginPage: computed
});

export default PiaRouterStore;
