import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import PiaWidget from "../../components/widgets/PiaWidget";
import SharingTabs from "../../components/sharing/SharingTabs";
import { RouteConstants } from "../../constants/RouteConstants";

class SharingContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.SHARING.title);
  }

  render() {
    return (
      <div className="container-fluid mb-4">
        <div className="row m-0">
          <PiaWidget className="tab-container col-12">
            <SharingTabs />
          </PiaWidget>
        </div>
      </div>
    );
  }
}

SharingContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(SharingContainer));
