import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import AddPlayerModal from "../../components/players/AddPlayerModal";
import EditPlayerListCriteria from "../../components/players/EditPlayerListCriteria";
import EditPlayerListResults from "../../components/players/EditPlayerListResults";
import EditPlayerListTabs from "../../components/players/EditPlayerListTabs";
import ExportPlayersModal from "../../components/players/ExportPlayersModal";
import ImportPlayersModal from "../../components/players/ImportPlayersModal";
import LoadQueryModal from "../../components/players/LoadQueryModal";
import OverrideModal from "../../components/alerts/OverrideModal";
import PiaWidget from "../../components/widgets/PiaWidget";
import SaveQueryModal from "../../components/players/SaveQueryModal";
import { RouteConstants } from "../../constants/RouteConstants";

class EditPlayerListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false };
  }

  async componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.EDIT_PLAYER_LIST.title);

    this.reloadPlayerList();
  }

  async componentDidUpdate(old) {
    let { history } = this.props.rootStore;

    if (old.location.state !== history.location.state) {
      await this.setState({ loaded: false });
      this.reloadPlayerList();
    }
  }

  async reloadPlayerList() {
    let { authStore, playerStore } = this.props.rootStore;
    if (authStore.loggedIn) {
      playerStore.resetStore();
      playerStore.parseAndLoadEditPlayerListUrl();
      await this.setState({ loaded: true });
    }
  }

  render() {
    let { playerStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <AddPlayerModal />
        <ExportPlayersModal />
        <ImportPlayersModal />
        <LoadQueryModal />
        <OverrideModal
          isOpen={!!playerStore.listCriteria.showOverride}
          title="Warning"
          text={playerStore.listCriteria.overrideMessage || ""}
          onConfirm={() => {
            if (playerStore.listCriteria.overrideFn) {
              playerStore.listCriteria.overrideFn();
            }
          }}
          onCancel={() => {
            playerStore.listCriteria.showOverride = undefined;
          }}
        />
        <OverrideModal
          isOpen={!!playerStore.playerList.showOverride}
          title="Warning"
          text={playerStore.playerList.overrideMessage || ""}
          onConfirm={() => {
            if (playerStore.playerList.overrideFn) {
              playerStore.playerList.overrideFn();
            }
          }}
          onCancel={() => {
            playerStore.playerList.showOverride = undefined;
          }}
        />
        <SaveQueryModal />
        {this.state.loaded ? (
          <div className="container-fluid">
            {playerStore.playerList.avplFlg ? (
              <PiaWidget className="col-12">
                <EditPlayerListCriteria />
              </PiaWidget>
            ) : (
              <PiaWidget className="tab-container col-12">
                <EditPlayerListTabs />
              </PiaWidget>
            )}
            <PiaWidget className="table-container col-12 mb-4 player-list-criteria-table">
              <EditPlayerListResults />
            </PiaWidget>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

EditPlayerListContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(EditPlayerListContainer));
