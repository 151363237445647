import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { BestLabel, Modal } from "best-common-react";
import PiaTable from "../widgets/PiaTable";
import TitleCard from "../widgets/TitleCard";
import ToFixedFormatter from "../utilities/ToFixedFormatter";

class AgentModal extends React.Component {
  getColleagueLinks(playerStore) {
    let colleagues = playerStore.agent.colleagueList || [];
    let links = [];
    colleagues.forEach((c, ci) => {
      links.push(
        <span>
          {ci > 0 ? ", " : ""}
          <a
            key={"c_" + ci}
            href="/#"
            onClick={event => {
              event.preventDefault();
              playerStore.getAgent(c);
            }}
          >
            {c}
          </a>
        </span>
      );
    });
    return links;
  }
  getClientCols(playerStore) {
    return [
      {
        key: "fullName",
        name: "Player Name",
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                playerStore.openPlayerProfile(row.row.playerId);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "club", name: "Club", width: 200 },
      { key: "currentMls", name: "MLS", width: 70, formatter: row => <ToFixedFormatter value={row.value} /> }
    ];
  }
  render() {
    let { playerStore } = this.props.rootStore;
    let colleagues = this.getColleagueLinks(playerStore);
    let clients = playerStore.agent.clientList || [];
    let clientCols = this.getClientCols(playerStore);

    return (
      <Modal show={!!playerStore.agent.showAgentModal} size="lg">
        <Modal.HeaderWithTitleAndClose
          title={playerStore.agent.agentName}
          close={() => {
            playerStore.agent.showAgentModal = false;
          }}
        />
        <Modal.Body>
          <div className="row mx-0 mb-2">
            <div className="col-12 p-0">
              <TitleCard>
                <BestLabel>Agency</BestLabel>
              </TitleCard>
            </div>
          </div>

          <div className="row mx-0 mb-2">
            <div className="col-12 p-0">
              <span>{playerStore.agent.agencyName}</span>
            </div>
          </div>

          <div className="row mx-0 mb-2">
            <div className="col-12 p-0">
              <TitleCard>
                <BestLabel>Colleagues</BestLabel>
              </TitleCard>
            </div>
          </div>

          <div className="row mx-0 mb-2">
            <div className="col-12 p-0">{colleagues}</div>
          </div>

          <div className="row mx-0 mb-2">
            <div className="col-12 p-0">
              <TitleCard>
                <BestLabel>Clients</BestLabel>
              </TitleCard>
            </div>
          </div>

          <div className="row mx-0 mb-2">
            <div className="col-12 p-0">
              <PiaTable
                data={clients}
                columns={clientCols}
                height={playerStore.calculateTableHeight(clients.length, 364)}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

AgentModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(AgentModal));
