import React from "react";
import { inject, observer } from "mobx-react";
import Moment from "moment";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import {
  BestLabel,
  BestQuickSearch,
  CardContainer,
  DangerButton,
  DateTimeFormatter,
  FormInput,
  LightButton,
  PrimaryButton
} from "best-common-react";
import { FormSearchStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";
import { RouteConstants } from "../../constants/RouteConstants";
import { SharingConstants } from "../../constants/SharingConstants";
import StatAutocompleteRow from "../autocomplete/StatAutocompleteRow";

class ManageStatLists extends React.Component {
  render() {
    let { lookupStore, routerStore, statStore } = this.props.rootStore;
    let columns = statStore.getListCols();
    const onRowsSelected = rows => {
      statStore.addSelectedLists(rows.map(row => row.row));
    };
    const onRowsDeselected = rows => {
      statStore.removeUnselectedLists(rows.map(row => row.row));
    };
    return (
      <React.Fragment>
        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Stat List Search</span>
            </CardContainer.Header>
            <CardContainer.Body>
              <div className="row m-0">
                <div className="col-3">
                  <FormInput
                    name="slStatListName"
                    label="Statistic List Name"
                    type="text"
                    value={statStore.listSearch.statListName}
                    onChangeFn={value => {
                      statStore.listSearch.statListName = value;
                    }}
                    editable
                  />
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <BestLabel>Stat Name</BestLabel>

                    <BestQuickSearch
                      options={lookupStore.autocompleteStats}
                      renderSuggestion={suggestion => {
                        return <StatAutocompleteRow stat={suggestion} />;
                      }}
                      getSuggestionValue={stat => {
                        return statStore.getAutocompleteText(stat);
                      }}
                      getSuggestions={(stats, searchTerm) => {
                        statStore.listSearch.statId = null;
                        statStore.listSearch.statName = statStore.formatForComparison(searchTerm);
                        return statStore.filterBySearchTerm(stats, searchTerm);
                      }}
                      onSuggestionSelected={(event, { suggestion }) => {
                        statStore.listSearch.statId = suggestion.statId;
                        statStore.listSearch.statName = suggestion.statName;
                      }}
                      styles={FormSearchStyles}
                      onClear={() => {
                        statStore.listSearch.statId = null;
                        statStore.listSearch.statName = null;
                      }}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <FormInput
                    name="slLastUpdatedFrom"
                    label="Last Updated From"
                    type="date"
                    value={
                      statStore.listSearch.lastUpdatedFromDte
                        ? Moment(statStore.listSearch.lastUpdatedFromDte, "MM/DD/YYYY")
                        : statStore.listSearch.lastUpdatedFromDte
                    }
                    onChangeFn={date => {
                      statStore.listSearch.lastUpdatedFromDte = date ? Moment(date) : date;
                    }}
                    formatter={DateTimeFormatter}
                    editable
                  />
                </div>

                <div className="col-3">
                  <FormInput
                    name="slLastUpdatedTo"
                    label="Last Updated To"
                    type="date"
                    value={
                      statStore.listSearch.lastUpdatedThroughDte
                        ? Moment(statStore.listSearch.lastUpdatedThroughDte, "MM/DD/YYYY")
                        : statStore.listSearch.lastUpdatedThroughDte
                    }
                    onChangeFn={date => {
                      statStore.listSearch.lastUpdatedThroughDte = date ? Moment(date) : date;
                    }}
                    formatter={DateTimeFormatter}
                    editable
                  />
                </div>
              </div>
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row">
                <PrimaryButton
                  onClick={() => {
                    statStore.findLists();
                  }}
                >
                  Search
                </PrimaryButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>

        <PiaWidget className="col-12 table-container">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Search Results</span>
            </CardContainer.Header>
            <CardContainer.Body>
              {statStore.displayLists.length > 0 ? (
                <PiaTable
                  data={statStore.displayLists}
                  columns={columns}
                  rowSelection={{
                    showCheckbox: true,
                    onRowsSelected: onRowsSelected,
                    onRowsDeselected: onRowsDeselected,
                    selectBy: {
                      isSelectedKey: "isSelected"
                    },
                    enableShiftSelect: true
                  }}
                  height={statStore.calculateTableHeight(statStore.displayLists.length, 308)}
                />
              ) : (
                <div className="col-12">No Results</div>
              )}
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row">
                <LightButton
                  onClick={() => {
                    statStore.exportList(statStore.statListResults);
                  }}
                >
                  Export to Excel
                </LightButton>
                <LightButton
                  onClick={() => {
                    routerStore.pushHistory(
                      RouteConstants.SHARING.route + "/" + SharingConstants.TYPE.STAT_LISTS,
                      false
                    );
                  }}
                >
                  Share
                </LightButton>
                <DangerButton
                  onClick={() => {
                    statStore.deleteLists();
                  }}
                >
                  Delete
                </DangerButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>
      </React.Fragment>
    );
  }
}

ManageStatLists.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(ManageStatLists)));
