import React from "react";
import { inject, observer } from "mobx-react";
import { BestSelect, LightButton, Modal, TextArea } from "best-common-react";
import PropTypes from "prop-types";
import { FormSelectStyles } from "../../Theme";

class ExportPlayersModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.textArea = React.createRef();
  }

  getDefaultState() {
    return {
      type: 1
    };
  }

  close(playerStore) {
    this.setState(this.getDefaultState());
    playerStore.playerList.exportPlayersModalOpen = false;
  }

  getListTypes() {
    return [
      { value: 0, label: "Comma-separated" },
      { value: 1, label: "Semicolon-separated" }
    ];
  }

  getListAsString(playerStore) {
    let ids = playerStore.displayPlayerListDetails.map(player => player.playerid);
    return ids.join(this.state.type ? ";" : ",");
  }

  render() {
    let { lookupStore, playerStore } = this.props.rootStore;
    let listTypes = this.getListTypes();
    let listAsString = this.getListAsString(playerStore);

    return (
      <Modal show={!!playerStore.playerList.exportPlayersModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Players by ID"
          close={() => {
            this.close(playerStore);
          }}
        />
        <Modal.Body>
          <div className="row mb-2">
            <div className="col-4">
              <BestSelect
                name="listType"
                value={lookupStore.getDropdownItem(listTypes, this.state.type)}
                onChange={option => {
                  this.setState({ type: option.value });
                }}
                options={listTypes}
                styles={FormSelectStyles}
              />
            </div>
          </div>
          <div className="row col-12">
            <span>Select the contents of the text box, then press Ctrl-C or right-click and select Copy. </span>
          </div>
          <div className="row">
            <div className="col-12">
              <TextArea id="listAsString" ref={this.textArea} onChange={event => {}} value={listAsString} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            <LightButton
              onClick={() => {
                this.textArea.current.select();
              }}
            >
              Select All
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

ExportPlayersModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ExportPlayersModal));
