import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { CardContainer, Icon } from "best-common-react";
import PiaTable from "../widgets/PiaTable";
import TitleCard from "../widgets/TitleCard";

class Service extends React.Component {
  getCols() {
    return [
      { key: "year", name: "Year", width: 60 },
      { key: "club", name: "Club", width: 60 },
      { key: "serviceDays", name: "Service Days", width: 100 },
      { key: "activeDays", name: "Active Days", width: 100 },
      { key: "dlDays", name: "IL Days", width: 80 },
      { key: "optionDays", name: "Option Days", width: 100 },
      { key: "outrightDays", name: "Outright Days", width: 100 },
      {
        key: "percentActiveAsPercent",
        name: "Active %",
        width: 80,
        formatter: row => <span>{row.value ? row.value.toFixed(1) + "%" : "0.0%"}</span>
      },
      { key: "brokenServiceYesNo", name: "Broken Service", width: 120 },
      { key: "totalService", name: "MLS" }
    ];
  }

  render() {
    let { playerStore } = this.props.rootStore;
    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="container-fluid">
            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <TitleCard>
                  <div className="d-flex flex-fill justify-content-between align-items-center">
                    <span>Service</span>
                    <div className="item-row h-inherit col-3 p-0">
                      <div className="item card-item-separator"></div>
                      <div className="item">
                        <Icon
                          iconName="fa-file-download"
                          onClick={() => {
                            playerStore.exportProp(playerStore.player, "service");
                          }}
                          styles={{ size: "1.6em" }}
                        />
                      </div>
                    </div>
                  </div>
                </TitleCard>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-12 p-0">
                {playerStore.player.service ? (
                  <PiaTable
                    data={playerStore.player.service}
                    columns={this.getCols()}
                    height={playerStore.calculateTableHeight(playerStore.player.service.length, 336)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </CardContainer.Body>
      </CardContainer>
    );
  }
}

Service.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(Service));
