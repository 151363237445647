import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { CardContainer, DateTimeFormatter } from "best-common-react";
import PiaTable from "../widgets/PiaTable";
import TitleCard from "../widgets/TitleCard";

class ReportHistory extends React.Component {
  getCols() {
    return [
      {
        key: "createDtstmp",
        name: "Report Created",
        width: 160,
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      },
      {
        key: "reportType",
        name: "Report Type",
        width: 300,
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                window.open(row.row.reportUrl);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "playersInList", name: "Players In List", width: 150 },
      { key: "statsInList", name: "Stats In List" }
    ];
  }
  render() {
    let { compStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <CardContainer>
          <CardContainer.Body>
            <div className="container-fluid">
              <div className="row mx-0 my-4">
                <div className="col-12 p-0">
                  <TitleCard>
                    <span>Reports Run From This Comparison in the Past 60 Days</span>
                  </TitleCard>
                </div>
              </div>

              <div className="row mx-0">
                <div className="col-12 p-0">
                  <PiaTable
                    data={compStore.comp.cmpReportsRunSorted}
                    columns={this.getCols()}
                    height={compStore.calculateTableHeight(compStore.comp.cmpReportsRunSorted.length, 700)}
                  />
                </div>
              </div>
            </div>
          </CardContainer.Body>
          <CardContainer.Footer></CardContainer.Footer>
        </CardContainer>
      </React.Fragment>
    );
  }
}

ReportHistory.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ReportHistory));
