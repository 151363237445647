import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

const NewsListItem = styled.div`
  margin: ${props => props.theme["size-gutter-num"] * 2 + "px"} ${props => props.theme["size-gutter"]}
    ${props => props.theme["size-gutter-num"] * 2 + "px"} ${props => props.theme["size-gutter"]};
  border: 1px solid ${props => props.theme["blue-grey"]};

  & > div {
    background-color: white;
    color: ${props => props.theme["blue-grey"]};

    h5 {
      color: black;
      margin: 0;
    }
  }
`;

class NewsFeeds extends React.Component {
  getLeagueOptions() {
    return [
      { value: "ML", label: "ML" },
      { value: "AL", label: "AL" },
      { value: "NL", label: "NL" }
    ];
  }

  getSortOptions() {
    return [
      { value: true, label: "Best" },
      { value: false, label: "Worst" }
    ];
  }

  getFeedData(newsFeedStore) {
    let feedData = [];

    if (-1 === newsFeedStore.displayFeedId) {
      newsFeedStore.feeds.forEach(feed => {
        feedData.push(newsFeedStore.feedData[feed.newsFeedId]);
      });
    } else {
      feedData.push(newsFeedStore.feedData[newsFeedStore.displayFeedId]);
    }

    return feedData;
  }

  getListItems(newsFeedStore) {
    let feedData = this.getFeedData(newsFeedStore);
    let gridItems = [];

    feedData.forEach((feed, feedIndex) => {
      if (feed.items) {
        feed.items.forEach((item, itemIndex) => {
          let gridItem = (
            <NewsListItem key={feedIndex + "_" + itemIndex} className="row">
              <div className="col-12">
                <div className="row col-12 pt-2 pb-1">
                  <h5>{item.title}</h5>
                </div>
                <div className="row col-12 pt-1 pb-2">
                  {item["dc:creator"]}&nbsp;--&nbsp;{item.pubDate}&nbsp;--&nbsp;<a href={feed.link}>{feed.title}</a>
                </div>
              </div>
            </NewsListItem>
          );
          gridItems.push(gridItem);
        });
      }
    });

    return <React.Fragment>{gridItems}</React.Fragment>;
  }

  render() {
    let { newsFeedStore } = this.props.rootStore;
    return <React.Fragment>{!newsFeedStore.loaded ? "" : this.getListItems(newsFeedStore)}</React.Fragment>;
  }
}

NewsFeeds.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(NewsFeeds)));
