import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { CardContainer, DateTimeFormatter, Icon, PriceFormatter, PrimaryButton } from "best-common-react";
import PiaMatTable from "../widgets/PiaMatTable";
import StatLine from "./StatLine";
import { StatConstants } from "../../constants/StatConstants";
import { ReportConstants } from "../../constants/ReportConstants";
import TitleCard from "../widgets/TitleCard";

class QuickView extends React.Component {
  getTransactionCols() {
    return [
      { key: "txDate", name: "Date", width: 120, format: row => <DateTimeFormatter value={row.txDate} /> },
      { key: "txDescription", name: "Description" }
    ];
  }

  getContractCols() {
    return [
      {
        key: "confirmedDate",
        name: "Date",
        width: 120,
        format: row => <DateTimeFormatter value={row.confirmedDate} />
      },
      { key: "club", name: "Org", width: 60 },
      { key: "contractLength", name: "Years", width: 60 },
      { key: "aav", name: "AAV", format: row => <PriceFormatter value={row.aav} /> }
    ];
  }

  getAwardCols() {
    const VoteOrPointsFormatter = row => {
      return (
        <span>
          {1 === row.place &&
          ((row.votesOrPoints === 1 && row.totalVotesOrPoints === 1) ||
            (row.votesOrPoints === 0 && row.totalVotesOrPoints === 0))
            ? "-"
            : row.votesOrPoints}
        </span>
      );
    };
    const PlaceFormatter = row => {
      return (
        <span>
          {1 === row.place &&
          ((row.votesOrPoints === 1 && row.totalVotesOrPoints === 1) ||
            (row.votesOrPoints === 0 && row.totalVotesOrPoints === 0))
            ? "-"
            : row.place}
        </span>
      );
    };
    return [
      { key: "year", name: "Year", width: 60 },
      { key: "awardName", name: "Award", width: 200 },
      { key: "votesOrPoints", name: "Votes", width: 60, format: VoteOrPointsFormatter },
      { key: "place", name: "Place", format: PlaceFormatter }
    ];
  }

  render() {
    let { playerStore, reportStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <CardContainer>
          <CardContainer.Body>
            <div className="container-fluid">
              <StatLine
                type={playerStore.player.pitcher ? StatConstants.TYPES.PITCHING : StatConstants.TYPES.BATTING}
              />

              <div className="row mx-0 mt-4">
                <div className="col-4 pl-0">
                  <div className="row mx-0 mb-2">
                    <div className="col-12 p-0">
                      <TitleCard>
                        <div className="d-flex flex-fill justify-content-between align-items-center">
                          <span>Transactions</span>
                          <div className="item-row h-inherit col-3 p-0">
                            <div className="item card-item-separator"></div>
                            <div className="item">
                              <Icon
                                iconName="fa-file-download"
                                onClick={() => {
                                  playerStore.exportList(playerStore.player.transactions);
                                }}
                                styles={{ size: "1.6em" }}
                              />
                            </div>
                          </div>
                        </div>
                      </TitleCard>
                    </div>
                  </div>

                  <div className="row mx-0">
                    <div className="col-12 p-0 mat-table-container">
                      {playerStore.player.qvTransactions && playerStore.player.qvTransactions.length > 0 ? (
                        <PiaMatTable rows={playerStore.player.qvTransactions} columns={this.getTransactionCols()} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-4 p-0">
                  <div className="row mx-0 mb-2">
                    <div className="col-12 p-0">
                      <TitleCard>
                        <div className="d-flex flex-fill justify-content-between align-items-center">
                          <span>Contracts</span>
                          <div className="item-row h-inherit col-3 p-0">
                            <div className="item card-item-separator"></div>
                            <div className="item">
                              <Icon
                                iconName="fa-file-download"
                                onClick={() => {
                                  playerStore.exportList(playerStore.player.contracts);
                                }}
                                styles={{ size: "1.6em" }}
                              />
                            </div>
                          </div>
                        </div>
                      </TitleCard>
                    </div>
                  </div>

                  <div className="row mx-0">
                    <div className="col-12 p-0 mat-table-container">
                      {playerStore.player.qvContracts && playerStore.player.qvContracts.length > 0 ? (
                        <PiaMatTable rows={playerStore.player.qvContracts} columns={this.getContractCols()} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-4 pr-0">
                  <div className="row mx-0 mb-2">
                    <div className="col-12 p-0">
                      <TitleCard>
                        <div className="d-flex flex-fill justify-content-between align-items-center">
                          <span>Awards History</span>
                          <div className="item-row h-inherit col-3 p-0">
                            <div className="item card-item-separator"></div>
                            <div className="item">
                              <Icon
                                iconName="fa-file-download"
                                onClick={() => {
                                  playerStore.exportList(playerStore.player.awards);
                                }}
                                styles={{ size: "1.6em" }}
                              />
                            </div>
                          </div>
                        </div>
                      </TitleCard>
                    </div>
                  </div>

                  <div className="row mx-0">
                    <div className="col-12 p-0 mat-table-container">
                      {playerStore.player.qvAwards && playerStore.player.qvAwards.length > 0 ? (
                        <PiaMatTable rows={playerStore.player.qvAwards} columns={this.getAwardCols()} />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContainer.Body>
          <CardContainer.Footer>
            <div className="header-item-row">
              <PrimaryButton
                onClick={() => {
                  reportStore.launchReport(
                    ReportConstants.REPORT_PLAYERQUICKVIEW,
                    {
                      playerBean: playerStore.player
                    },
                    false,
                    true
                  );
                }}
              >
                Print Quick View
              </PrimaryButton>
            </div>
          </CardContainer.Footer>
        </CardContainer>
      </React.Fragment>
    );
  }
}

QuickView.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(QuickView));
