import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { CardContainer, DateTimeFormatter, LightButton } from "best-common-react";
import PiaTable from "../../components/widgets/PiaTable";
import PiaWidget from "../../components/widgets/PiaWidget";

class StandardPlayerLists extends Component {
  render() {
    let { homePlateStore, playerStore } = this.props.rootStore;
    let classes = this.props.className ? this.props.className : "";
    const listLength = homePlateStore.standardPlayerLists ? homePlateStore.standardPlayerLists.length : 0;
    const tableHeight = playerStore.calculateTableHeight(listLength, 308);
    return (
      <PiaWidget className={classes}>
        <CardContainer>
          <CardContainer.Header>
            <span className="header-title">Standard Player Lists</span>
          </CardContainer.Header>
          <CardContainer.Body>
            <PiaTable
              data={homePlateStore.standardPlayerLists}
              columns={[
                {
                  key: "playerlistname",
                  name: "List Name",
                  width: 200,
                  formatter: row => {
                    return (
                      <a
                        href="/#"
                        onClick={event => {
                          event.preventDefault();
                          playerStore.openPlayerList(row.row.playerlistId);
                        }}
                      >
                        {row.value}
                      </a>
                    );
                  }
                },
                { key: "focusplayername", name: "Focus Player", width: 160 },
                { key: "numberofplayers", name: "# of Players", width: 100 },
                {
                  key: "source",
                  name: "Source",
                  value: "Standard",
                  width: 120,
                  formatter: () => {
                    return <span>Standard</span>;
                  }
                },
                {
                  key: "lastchDtstmpAmericaNY",
                  name: "Last Updated",
                  formatter: row => {
                    return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
                  }
                }
              ]}
              height={tableHeight}
            />
          </CardContainer.Body>
          <CardContainer.Footer>
            <div className="header-item-row">
              <LightButton
                onClick={() => {
                  playerStore.openPlayerList(0);
                }}
              >
                Create New Player List
              </LightButton>
            </div>
          </CardContainer.Footer>
        </CardContainer>
      </PiaWidget>
    );
  }
}

StandardPlayerLists.propTypes = {
  rootStore: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default inject("rootStore")(observer(StandardPlayerLists));
