import React from "react";
import * as OktaSignIn from "@okta/okta-signin-widget/dist/js/okta-sign-in-no-jquery";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { AuthConstants } from "../../constants/AuthConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import bottomBars from "../../assets/login/bottom-bars.png";
import bottomBars2 from "../../assets/login/bottom-bars@2x.png";
import bottomBars3 from "../../assets/login/bottom-bars@3x.png";
import playerStats from "../../assets/login/players-stats.png";
import playerStats2 from "../../assets/login/players-stats@2x.png";
import playerStats3 from "../../assets/login/players-stats@3x.png";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import "../../styles/okta-login-overrides.css";

//const environment = process.env.REACT_APP_ENV;

const LoginRoot = styled.div`
  top: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #02132b, #183256);
`;

const LoginFlex = styled.div.attrs({
  className: "d-flex justify-content-between align-items-stretch"
})`
  width: 100%;
  height: 100%;
`;

const LoginWrapper = styled.div.attrs({
  className: "d-flex flex-column"
})`
  flex: 0 0 18%;
  background-color: ${props => props.theme["dark-blue"]};
  border-right: solid 3px #39465e;
`;

const LoginWidgetContainer = styled.div.attrs({
  className: "d-flex flex-column justify-content-center"
})`
  height: 100%;
`;

const LoginWidget = styled.div.attrs({
  id: "sign-in-widget"
})`
  background-color: ${props => props.theme["dark-blue"]};
`;

const LoginAccent = styled.div`
  height: 10px;
  background-color: ${props => props.theme["mlb-red"]};
`;

const LoginBG = styled.div.attrs({
  className: "d-flex flex-column-reverse align-items-end"
})`
  flex: 0 0 82%;
  width: 100%;
  height: 100%;
`;

const LoginBars = styled.img.attrs({
  className: "d-flex",
  src: bottomBars,
  srcset: bottomBars2 + ", " + bottomBars3
})`
  object-fit: contain;
  mix-blend-mode: overlay;
  width: 100%;
`;

const LoginPlayers = styled.img.attrs({
  className: "d-flex",
  src: playerStats,
  srcset: playerStats2 + ", " + playerStats3
})`
  object-fit: contain;
  width: 95%;
`;

class Login extends React.Component {
  constructor(props) {
    let added = false;
    super(props);

    document.title = "PIA Plus";

    this.preLogin = this.preLogin.bind(this);

    this.signIn = new OktaSignIn({
      baseUrl: process.env.REACT_APP_OIDC_ISSUER.split("/oauth2")[0],
      clientId: process.env.REACT_APP_OIDC_CLIENTID,
      redirectUri: window.location.origin + RouteConstants.IMPLICIT_CALLBACK.route,
      logo: "images/pia-logo@2x.png",
      authParams: {
        issuer: process.env.REACT_APP_OIDC_ISSUER,
        responseType: ["id_token", "token"],
        display: "page",
        scopes: process.env.REACT_APP_OIDC_SCOPE.split(" ")
      },
      i18n: {
        en: {
          "oform.errorbanner.title": "email or password is incorrect",
          "primaryauth.password.placeholder": "password",
          "primaryauth.password.tooltip": "password",
          "primaryauth.submit": "Login",
          "primaryauth.username.placeholder": "email",
          "primaryauth.username.tooltip": "email"
        }
      },
      processCreds: this.preLogin
    });
    this.signIn.on("pageRendered", function(data) {
      if (!added) {
        added = true;

        let forgotLink = document.createElement("a");
        forgotLink.href = "https://inside.mlb.com/UserRegistrationPassword/";
        forgotLink.target = "_blank";
        forgotLink.innerText = "Forgot Username or Password?";
        forgotLink.style = "font-family: Helvetica; font-size: 12px; color: #ffffff";

        let pwContainer = document.getElementsByClassName("o-form-fieldset")[1];
        pwContainer.parentNode.insertBefore(forgotLink, pwContainer.nextSibling);

        /*
        let accent = document.createElement("div");
        let container = document.createElement("div");
        let line = document.createElement("div");
        let env = document.createElement("div");

        container.id = "pia-title-container";
        container.backgroundColor = "#000000";

        if (environment === "stg") {
          accent.id = "accent-stg";
          env.id = "environment-stg";
        } else {
          accent.id = "accent-dev";
          env.id = "environment-dev";
        }

        env.innerHTML = environment;

        container.appendChild(line);
        document.getElementsByClassName("okta-sign-in-header auth-header")[0].appendChild(container);
        if (environment !== "prod" && environment !== "production") {
          let mainContainer = document.getElementsByClassName("auth-container main-container no-beacon")[0];
          document.getElementsByClassName("auth-container main-container no-beacon")[0].prepend(accent);
          mainContainer.insertBefore(env, mainContainer.childNodes[3]);
        }
        */
      }
    });
    this.signIn.on("afterError", function(context, error) {
      if (401 === error.statusCode) {
        const { trackingHelper } = props.rootStore;
        trackingHelper.track("Login failure: " + error.message);
      }
    });
  }
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;

    trackingHelper.trackPageView(RouteConstants.LOGIN.title);

    this.signIn.renderEl(
      { el: "#sign-in-widget" },
      () => {
        /**
         * In this flow, the success handler will not be called beacuse we redirect
         * to the Okta org for the authentication workflow.
         */
      },
      err => {
        console.log(err);
      }
    );
  }
  preLogin(creds, callback) {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.track("Login Click");

    localStorage.clear();
    localStorage.setItem(AuthConstants.KEYS.PIA_BO_ENCODED_AUTH, btoa(creds.password));
    callback();
  }
  render() {
    return (
      <LoginRoot>
        <LoginFlex>
          <LoginWrapper>
            <LoginWidgetContainer>
              <LoginWidget />
            </LoginWidgetContainer>
            <LoginAccent />
          </LoginWrapper>
          <LoginBG>
            <LoginBars />
            <LoginPlayers />
          </LoginBG>
        </LoginFlex>
      </LoginRoot>
    );
  }
  static propTypes = {
    rootStore: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
  };
}

export default inject("rootStore")(withTheme(observer(Login)));
