import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";
import ReactToolTip from "react-tooltip";
class IconTip extends Component {
  getTipId() {
    return (
      "icontip_" +
      Math.random()
        .toString(36)
        .substr(2, 9)
    );
  }
  render() {
    const tipId = this.getTipId();
    const { iconName, place, styles, tipHtml, offset, iconClassName, toolTipClassName } = this.props;
    let validHtml = !!tipHtml ? tipHtml : <span>TIP</span>;
    return (
      <React.Fragment>
        <span data-for={tipId} data-tip="">
          <Icon className={iconClassName} iconName={iconName} styles={styles} />
        </span>
        <ReactToolTip className={toolTipClassName} id={tipId} effect="solid" place={place} offset={offset}>
          {validHtml}
        </ReactToolTip>
      </React.Fragment>
    );
  }
}

IconTip.propTypes = {
  tipHtml: PropTypes.element.isRequired,
  iconName: PropTypes.string.isRequired,
  place: PropTypes.string,
  styles: PropTypes.object,
  offset: PropTypes.object,
  iconClassName: PropTypes.string,
  toolTipClassName: PropTypes.string
};

export default inject("rootStore")(observer(IconTip));
