import React from "react";
import { inject, observer } from "mobx-react";
import Moment from "moment";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import {
  BestLabel,
  BestQuickSearch,
  CardContainer,
  DangerButton,
  DateTimeFormatter,
  FormInput,
  LightButton,
  PrimaryButton
} from "best-common-react";
import { FormSearchStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";
import { RouteConstants } from "../../constants/RouteConstants";
import { SharingConstants } from "../../constants/SharingConstants";

class ManagePlayerLists extends React.Component {
  render() {
    let { lookupStore, playerStore, routerStore } = this.props.rootStore;
    let columns = playerStore.getPlayerListCols();
    const onRowsSelected = rows => {
      playerStore.addSelectedLists(rows.map(row => row.row));
    };
    const onRowsDeselected = rows => {
      playerStore.removeUnselectedLists(rows.map(row => row.row));
    };
    return (
      <React.Fragment>
        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Player List Search</span>
            </CardContainer.Header>
            <CardContainer.Body>
              <div className="row m-0">
                <div className="col-3">
                  <FormInput
                    name="plPlayerListName"
                    label="Player List Name"
                    type="text"
                    value={playerStore.listSearch.playerListName}
                    onChangeFn={value => {
                      playerStore.listSearch.playerListName = value;
                    }}
                    editable
                  />
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <BestLabel>Player Name</BestLabel>

                    <BestQuickSearch
                      options={lookupStore.autocompletePlayers}
                      renderSuggestion={suggestion => {
                        return <PlayerAutocompleteRow player={suggestion} />;
                      }}
                      getSuggestionValue={player => {
                        return playerStore.getAutocompleteText(player);
                      }}
                      getSuggestions={(players, searchTerm) => {
                        if (searchTerm.indexOf(")") === -1) {
                          playerStore.listSearch.playerId = 0;
                          playerStore.listSearch.fullname = playerStore.formatForComparison(searchTerm);
                        }
                        return playerStore.filterBySearchTerm(players, searchTerm);
                      }}
                      onSuggestionSelected={(event, { suggestion }) => {
                        playerStore.listSearch.playerId = suggestion.playerId;
                        playerStore.listSearch.fullname = suggestion.playerName;
                      }}
                      styles={FormSearchStyles}
                      onClear={() => {
                        playerStore.listSearch.playerId = null;
                        playerStore.listSearch.fullname = null;
                      }}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <FormInput
                    name="plLastUpdatedFrom"
                    label="Last Updated From"
                    type="date"
                    value={
                      playerStore.listSearch.lastUpdatedFromDte
                        ? Moment(playerStore.listSearch.lastUpdatedFromDte, "MM/DD/YYYY")
                        : playerStore.listSearch.lastUpdatedFromDte
                    }
                    onChangeFn={date => {
                      playerStore.listSearch.lastUpdatedFromDte = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                    formatter={DateTimeFormatter}
                    editable
                  />
                </div>

                <div className="col-3">
                  <FormInput
                    name="plLastUpdatedTo"
                    label="Last Updated To"
                    type="date"
                    value={
                      playerStore.listSearch.lastUpdatedThroughDte
                        ? Moment(playerStore.listSearch.lastUpdatedThroughDte, "MM/DD/YYYY")
                        : playerStore.listSearch.lastUpdatedThroughDte
                    }
                    onChangeFn={date => {
                      playerStore.listSearch.lastUpdatedThroughDte = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                    formatter={DateTimeFormatter}
                    editable
                  />
                </div>
              </div>
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row">
                <PrimaryButton
                  onClick={() => {
                    playerStore.findPlayerLists();
                  }}
                >
                  Search
                </PrimaryButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>

        <PiaWidget className="col-12 table-container">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Search Results</span>
            </CardContainer.Header>
            <CardContainer.Body>
              {playerStore.displayLists.length > 0 ? (
                <PiaTable
                  data={playerStore.displayLists}
                  columns={columns}
                  rowSelection={{
                    showCheckbox: true,
                    onRowsSelected: onRowsSelected,
                    onRowsDeselected: onRowsDeselected,
                    selectBy: {
                      isSelectedKey: "isSelected"
                    },
                    enableShiftSelect: true
                  }}
                  height={playerStore.calculateTableHeight(playerStore.displayLists.length, 308)}
                />
              ) : (
                <div className="col-12">No Results</div>
              )}
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row">
                <LightButton
                  onClick={() => {
                    playerStore.exportList(playerStore.playerListResults);
                  }}
                >
                  Export to Excel
                </LightButton>
                <LightButton
                  onClick={() => {
                    routerStore.pushHistory(
                      RouteConstants.SHARING.route + "/" + SharingConstants.TYPE.PLAYER_LISTS,
                      false
                    );
                  }}
                >
                  Share
                </LightButton>
                <DangerButton
                  onClick={() => {
                    playerStore.deletePlayerLists();
                  }}
                >
                  Delete
                </DangerButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>
      </React.Fragment>
    );
  }
}

ManagePlayerLists.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(ManagePlayerLists)));
