import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestQuickSearch, BestSelect, Icon, LightButton, Modal, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";
import _ from "lodash";
import Moment from "moment";
import { FormSearchStyles, FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";

class FutureYearSubstitutionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      playerListId: -1,
      compPlayer: { className: "com.mlb.piaplus.db.PlDetail", playerKey: -1 },
      subPlayerId: -1,
      subPlayerYear: Moment(new Date()).year(),
      substitutes: []
    };
  }

  async componentDidMount() {
    this.checkPlayerList();
  }

  async componentDidUpdate() {
    this.checkPlayerList();
  }

  async checkPlayerList() {
    let { compStore } = this.props.rootStore;

    let playerListId = compStore.comp.plMasters[0].playerlistId || -1;

    if (playerListId !== this.state.playerListId) {
      this.setState(state => {
        state.playerListId = playerListId;
        state.substitutes = _.filter(compStore.displayPlayerListDetails, pld => pld.substituteOnReports).map(
          pld => pld.substituteOnReports
        );
        return { substitutes: state.substitutes };
      });
    }
  }

  addPlayerSubstitute() {
    let { piaApi } = this.props.rootStore;
    piaApi
      .findComparisonPlayerSubstitute(this.state.compPlayer, this.state.subPlayerId, this.state.subPlayerYear)
      .then(response => {
        if (response) {
          this.setState(state => {
            state.substitutes.push(response);
            return { compPlayer: this.getInitialState().compPlayer, substitutes: state.substitutes };
          });
        }
      });
  }

  removePlayerSubstitute(row) {
    let index = this.state.substitutes.indexOf(row);

    if (index > -1) {
      this.setState(state => {
        state.substitutes.splice(index, 1);
        return { substitutes: state.substitutes };
      });
    }
  }

  get displayListPlayers() {
    let { compStore } = this.props.rootStore;
    return _.differenceBy(compStore.displayPlayerListDetails, this.state.substitutes, "playerKey");
  }

  getCols() {
    return [
      { key: "playerNameAndPY", name: "Substitute Player", width: 250 },
      { key: "substitutePlayerNameAndPY", name: "Substituted By" },
      {
        key: "delete",
        name: "",
        width: 40,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Icon
                className="mlb-red-icon"
                iconName="fa-trash-alt"
                onClick={() => {
                  this.removePlayerSubstitute(row.row);
                }}
              />
            </div>
          );
        }
      }
    ];
  }

  save(compStore) {
    compStore.comp.plMasters[0].plDetails.forEach(pld => {
      pld.substituteOnReports = null;

      for (let si in this.state.substitutes) {
        let sub = this.state.substitutes[si];

        if (pld.playerKey === sub.playerKey) {
          pld.substituteOnReports = sub;
          break;
        }
      }
    });
  }

  close(compStore) {
    this.setState(this.getInitialState());
    compStore.futureYearSubModalOpen = false;
  }

  render() {
    let { commonStore, compStore, lookupStore, playerStore } = this.props.rootStore;
    let years = commonStore.getListOfYears(1871);

    return (
      <Modal show={compStore.futureYearSubModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Future Year Substitutions"
          close={() => {
            this.close(compStore);
          }}
        />
        <Modal.Body>
          <div className="row m-0 col-12 p-0">
            <div className="col-4 pl-0">
              <div className="form-group">
                <BestLabel>Comparison&apos;s players</BestLabel>
                <BestSelect
                  name="fysCompPlayers"
                  value={lookupStore.getDropdownItem(
                    this.displayListPlayers,
                    this.state.compPlayer.playerKey,
                    "playerKey",
                    "playerNameAndPY"
                  )}
                  onChange={option => {
                    this.setState({ compPlayer: option });
                  }}
                  options={this.displayListPlayers}
                  getOptionValue={option => option.playerKey}
                  getOptionLabel={option => option.playerNameAndPY}
                  placeholder="Select comp player..."
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>
            <div className="col-4 pl-0">
              <div className="form-group">
                <BestLabel>Substitute</BestLabel>
                <BestQuickSearch
                  options={lookupStore.autocompletePlayers}
                  renderSuggestion={suggestion => {
                    return <PlayerAutocompleteRow player={suggestion} />;
                  }}
                  getSuggestionValue={player => {
                    return playerStore.getAutocompleteText(player);
                  }}
                  getSuggestions={playerStore.filterBySearchTerm}
                  placeholder="select a substitute player"
                  onSuggestionSelected={(event, { suggestion }) => {
                    this.setState({ subPlayerId: suggestion.playerId });
                  }}
                  styles={FormSearchStyles}
                />
              </div>
            </div>

            <div className="col-2 pl-0">
              <div className="form-group">
                <BestLabel></BestLabel>
                <BestSelect
                  name="fysSubPlayerYear"
                  value={lookupStore.getDropdownItem(years, this.state.subPlayerYear)}
                  onChange={option => {
                    this.setState({ subPlayerYear: option.value });
                  }}
                  options={years}
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>
            <div className="col-2 p-0 d-flex">
              <div className="form-group d-flex flex-fill align-items-end">
                <BestLabel></BestLabel>
                <PrimaryButton
                  className="flex-fill"
                  onClick={() => {
                    this.addPlayerSubstitute();
                  }}
                >
                  Add
                </PrimaryButton>
              </div>
            </div>
          </div>

          <div className="row mx-0 mt-4">
            <div className="col-12 p-0">
              <PiaTable
                data={this.state.substitutes}
                columns={this.getCols()}
                height={compStore.calculateTableHeight(this.state.substitutes.length, 280)}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="header-item-row">
            <PrimaryButton
              onClick={() => {
                this.save(compStore);
                this.close(compStore);
              }}
            >
              Save
            </PrimaryButton>
            <LightButton
              onClick={() => {
                this.close(compStore);
              }}
            >
              Cancel
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

FutureYearSubstitutionModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  tab: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(FutureYearSubstitutionModal));
