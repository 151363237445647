import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

function Row(rowProps) {
  const { columns, onRowClick, ri, row } = rowProps;
  const [open, setOpen] = React.useState(false);
  let rowClassName = "pia-mat-table-row";

  if (row.rowHighlighted) {
    rowClassName += "-highlighted";
  }

  return (
    <React.Fragment>
      <TableRow
        key={ri + "_row"}
        className={rowClassName}
        onClick={() => {
          if (onRowClick !== undefined) {
            onRowClick(ri);
          }
        }}
      >
        {columns.map((col, ci) => (
          <TableCell key={ri + "_" + ci} align={col.align || "left"}>
            {col.format !== undefined ? col.format(row) : row[col.key]}
            {0 === ci && row.expandable ? (
              <span className="pia-mat-table-toggle-expand">
                <Icon
                  iconName={open ? "fa-caret-down" : "fa-caret-right"}
                  onClick={event => {
                    event.stopPropagation();
                    setOpen(!open);
                  }}
                />
              </span>
            ) : (
              ""
            )}
          </TableCell>
        ))}
      </TableRow>

      {open && row.expandable && row.expandedListProp && row[row.expandedListProp]
        ? row[row.expandedListProp].map((er, eri) => (
            <TableRow key={ri + "_expandable_" + eri} className="pia-mat-table-row-expanded">
              {columns.map((col, ci) => (
                <TableCell key={ri + "_expandable_" + eri + "_" + ci} align={col.align || "left"}>
                  {col.format !== undefined ? col.format(er) : er[col.key]}
                </TableCell>
              ))}
            </TableRow>
          ))
        : ""}
    </React.Fragment>
  );
}

class PiaMatTable extends Component {
  render() {
    const { columns, onRowClick, rows } = this.props;
    return (
      <TableContainer className="pia-mat-table-container">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((col, ci) => (
                <TableCell key={"th_" + ci} align={col.align || "left"}>
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, ri) => (
              <Row key={ri} columns={columns} row={row} ri={ri} onRowClick={onRowClick} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

PiaMatTable.propTypes = {
  rootStore: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  onRowClick: PropTypes.func
};

export default inject("rootStore")(observer(PiaMatTable));
