import { createBrowserHistory } from "history";
import { syncHistoryWithStore } from "mobx-react-router";
import AdminStore from "./admin/AdminStore";
import AuthStore from "./AuthStore";
import AuthApi from "../httpClients/AuthApi";
//import CommonStore from "./CommonStore";
import HttpInterceptor from "../httpClients/HttpInterceptor";
import AlertStore from "./AlertStore";
import CommonStore from "./CommonStore";
import CompFinderStore from "./comp-finder/CompFinderStore";
import CompStore from "./comparisons/CompStore";
import LookupStore from "./lookups/LookupStore";
import HeaderStore from "./header/HeaderStore";
import HomePlateStore from "./home-plate/HomePlateStore";
import NewsFeedStore from "./news-feeds/NewsFeedStore";
import PiaApi from "../httpClients/PiaApi";
import PiaRouterStore from "./PiaRouterStore";
import PlayerStore from "./players/PlayerStore";
import ReportStore from "./reports/ReportStore";
import SharingStore from "./sharing/SharingStore";
import StatStore from "./statistics/StatStore";
import TrackingHelper from "../utilities/TrackingHelper";

class RootStore {
  constructor() {
    this.routerStore = new PiaRouterStore();
    this.history = syncHistoryWithStore(createBrowserHistory(), this.routerStore);

    this.registerApis();
    this.registerStores();
  }

  registerApis() {
    this.authApi = new AuthApi(this.routerStore);
    this.piaApi = new PiaApi();
  }

  registerStores() {
    this.alertStore = new AlertStore();
    this.authStore = new AuthStore(this.routerStore, this.authApi, this.piaApi);
    this.lookupStore = new LookupStore(this.authStore, this.alertStore, this.piaApi);
    this.commonStore = new CommonStore();
    this.headerStore = new HeaderStore();
    this.newsFeedStore = new NewsFeedStore(this.authStore, this.routerStore, this.alertStore, this.piaApi);
    this.reportStore = new ReportStore(this.authStore, this.routerStore, this.alertStore, this.piaApi);
    this.compFinderStore = new CompFinderStore(
      this.authStore,
      this.routerStore,
      this.alertStore,
      this.reportStore,
      this.piaApi
    );
    this.playerStore = new PlayerStore(
      this.authStore,
      this.routerStore,
      this.alertStore,
      this.lookupStore,
      this.commonStore,
      this.piaApi
    );
    this.statStore = new StatStore(this.authStore, this.routerStore, this.lookupStore, this.alertStore, this.piaApi);
    this.compStore = new CompStore(
      this.authStore,
      this.routerStore,
      this.alertStore,
      this.lookupStore,
      this.reportStore,
      this.playerStore,
      this.statStore,
      this.piaApi
    );
    this.homePlateStore = new HomePlateStore(
      this.authStore,
      this.routerStore,
      this.lookupStore,
      this.reportStore,
      this.compFinderStore,
      this.compStore,
      this.piaApi
    );
    this.sharingStore = new SharingStore(this.authStore, this.routerStore, this.piaApi);
    this.adminStore = new AdminStore(this.authStore, this.routerStore, this.alertStore, this.piaApi);
    this.trackingHelper = new TrackingHelper(this.routerStore, this.authStore, this.compStore);
    this.httpInterceptor = new HttpInterceptor(this.authStore, this.alertStore, this.routerStore, this.trackingHelper);
  }
}

export default RootStore;
