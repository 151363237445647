import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { BestSelect, CardContainer, Icon } from "best-common-react";
import { FormSelectStyles, InlineHeader } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import TitleCard from "../widgets/TitleCard";

class Awards extends React.Component {
  getCols() {
    const VoteOrPlaceFormatter = row => {
      return (
        <span>
          {1 === row.row.place &&
          ((row.row.votesOrPoints === 1 && row.row.totalVotesOrPoints === 1) ||
            (row.row.votesOrPoints === 0 && row.row.totalVotesOrPoints === 0))
            ? "-"
            : row.value}
        </span>
      );
    };
    return [
      { key: "year", name: "Year", width: 60 },
      { key: "awardName", name: "Award" },
      { key: "votesOrPoints", name: "Votes / Points", width: 130, formatter: VoteOrPlaceFormatter },
      { key: "totalVotesOrPoints", name: "Total Votes / Points", width: 170, formatter: VoteOrPlaceFormatter },
      { key: "place", name: "Place", formatter: VoteOrPlaceFormatter, width: 80 }
    ];
  }

  getYears(playerStore) {
    if (!playerStore.player.awdYearsFilter) {
      return [];
    }

    return playerStore.player.awdYearsFilter.map(year => {
      return {
        value: year,
        label: year
      };
    });
  }

  getAwardNames(playerStore) {
    if (!playerStore.player.awdNamesFilter) {
      return [];
    }

    return playerStore.player.awdNamesFilter.map(name => {
      return {
        value: name,
        label: name
      };
    });
  }

  render() {
    let { lookupStore, playerStore } = this.props.rootStore;
    let years = this.getYears(playerStore);
    let awardNames = this.getAwardNames(playerStore);

    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="container-fluid">
            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <TitleCard>
                  <div className="d-flex flex-fill justify-content-between align-items-center">
                    <span>Award History</span>
                    <div className="item-row h-inherit col-5 p-0">
                      <div className="item card-item-separator"></div>
                      <InlineHeader className="item">Show Year</InlineHeader>
                      <div className="d-inline-flex w-25 m-0 item">
                        <div className="col-12">
                          <BestSelect
                            name="awardYear"
                            value={lookupStore.getDropdownItem(years, playerStore.awardFilterYear)}
                            onChange={option => {
                              playerStore.awardFilterYear = option.value;
                            }}
                            options={years}
                            portal={document.body}
                            styles={FormSelectStyles}
                          />
                        </div>
                      </div>

                      <div className="item card-item-separator"></div>
                      <InlineHeader className="item">Show Award</InlineHeader>
                      <div className="d-inline-flex w-25 m-0 item">
                        <div className="col-12">
                          <BestSelect
                            name="awardName"
                            value={lookupStore.getDropdownItem(awardNames, playerStore.awardFilterName)}
                            onChange={option => {
                              playerStore.awardFilterName = option.value;
                            }}
                            options={awardNames}
                            portal={document.body}
                            styles={FormSelectStyles}
                          />
                        </div>
                      </div>
                      <div className="item card-item-separator"></div>
                      <div className="item">
                        <Icon
                          iconName="fa-file-download"
                          onClick={() => {
                            playerStore.exportProp(playerStore.player, "awards");
                          }}
                          styles={{ size: "1.6em" }}
                        />
                      </div>
                    </div>
                  </div>
                </TitleCard>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-12 p-0">
                {playerStore.displayAwards.length > 0 ? (
                  <PiaTable
                    data={playerStore.displayAwards}
                    columns={this.getCols()}
                    height={playerStore.calculateTableHeight(playerStore.displayAwards.length, 336)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </CardContainer.Body>
      </CardContainer>
    );
  }
}

Awards.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(Awards));
