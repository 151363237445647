import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import HelpfulLinks from "../../components/reports/HelpfulLinks";
import { RouteConstants } from "../../constants/RouteConstants";

class HelpfulLinkContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.LINKS.title);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="col-12 p-0">
          <HelpfulLinks />
        </div>
      </div>
    );
  }
}

HelpfulLinkContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(HelpfulLinkContainer));
