import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

class FormulaPart extends React.Component {
  render() {
    let { statStore } = this.props.rootStore;
    let { index, onClickFn, value } = this.props;
    let selected = index === statStore.statFormula.selectedIndex;
    return (
      <span
        className={"formula-part" + (selected ? " selected" : "")}
        onClick={() => {
          onClickFn(index);
        }}
      >
        {value}
      </span>
    );
  }
}

FormulaPart.propTypes = {
  rootStore: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onClickFn: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

export default inject("rootStore")(observer(FormulaPart));
