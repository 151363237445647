import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { BestSelect, CardContainer, DateTimeFormatter, Icon } from "best-common-react";
import { FormSelectStyles, InlineHeader } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import TitleCard from "../widgets/TitleCard";

class Transactions extends React.Component {
  getCols() {
    let { playerStore, piaApi } = this.props.rootStore;

    return [
      {
        key: "txDate",
        name: "Date",
        width: 100,
        formatter: row =>
          row.row.txCode.startsWith("DL") || row.row.txCode.startsWith("IL") ? (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                piaApi.getPlayerTransactionDetails(row.row.txId, row.row.txCode).then(response => {
                  if (response) {
                    playerStore.modalTxDetails = response;
                  }
                });
              }}
            >
              <DateTimeFormatter value={row.value} />
            </a>
          ) : (
            <DateTimeFormatter value={row.value} />
          )
      },
      { key: "txCode", name: "Code", width: 75 },
      { key: "txFrom", name: "From", width: 90 },
      { key: "txTo", name: "To", width: 90 },
      { key: "txDescription", name: "Description" }
    ];
  }

  getYears(playerStore) {
    if (!playerStore.player.txYearsFilter) {
      return [];
    }

    return playerStore.player.txYearsFilter.map(year => {
      return {
        value: year,
        label: year
      };
    });
  }

  getTypes(playerStore) {
    if (!playerStore.player.txTypesFilter) {
      return [];
    }

    return playerStore.player.txTypesFilter.map(name => {
      return {
        value: name,
        label: name
      };
    });
  }

  render() {
    let { lookupStore, playerStore } = this.props.rootStore;
    let years = this.getYears(playerStore);
    let types = this.getTypes(playerStore);

    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="container-fluid">
            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <TitleCard>
                  <div className="d-flex flex-fill justify-content-between align-items-center">
                    <span>Transactions</span>
                    <div className="item-row h-inherit col-5 p-0">
                      <div className="item card-item-separator"></div>
                      <InlineHeader className="item">Show Year</InlineHeader>
                      <div className="d-inline-flex w-25 m-0 item">
                        <div className="col-12">
                          <BestSelect
                            name="awardYear"
                            value={lookupStore.getDropdownItem(years, playerStore.transactionFilterYear)}
                            onChange={option => {
                              playerStore.transactionFilterYear = option.value;
                            }}
                            options={years}
                            portal={document.body}
                            styles={FormSelectStyles}
                          />
                        </div>
                      </div>

                      <div className="item card-item-separator"></div>
                      <InlineHeader className="item">Show Type</InlineHeader>
                      <div className="d-inline-flex w-25 m-0 item">
                        <div className="col-12">
                          <BestSelect
                            name="awardName"
                            value={lookupStore.getDropdownItem(types, playerStore.transactionFilterType)}
                            onChange={option => {
                              playerStore.transactionFilterType = option.value;
                            }}
                            options={types}
                            portal={document.body}
                            styles={FormSelectStyles}
                          />
                        </div>
                      </div>
                      <div className="item card-item-separator"></div>
                      <div className="item">
                        <Icon
                          iconName="fa-file-download"
                          onClick={() => {
                            playerStore.exportProp(playerStore.player, "transactions");
                          }}
                          styles={{ size: "1.6em" }}
                        />
                      </div>
                    </div>
                  </div>
                </TitleCard>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-12 p-0">
                {playerStore.displayTransactions.length > 0 ? (
                  <PiaTable
                    data={playerStore.displayTransactions}
                    columns={this.getCols()}
                    height={playerStore.calculateTableHeight(playerStore.displayTransactions.length, 336)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </CardContainer.Body>
      </CardContainer>
    );
  }
}

Transactions.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(Transactions));
