import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import _ from "lodash";
import { BestLabel, BestSelect, CardContainer, Checkbox, Input, LightButton, PrimaryButton } from "best-common-react";
import { FormSelectStyles } from "../../Theme";
import CurrencyFormatter from "../utilities/CurrencyFormatter";
import IconTip from "../widgets/IconTip";
import PiaWidget from "../widgets/PiaWidget";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";
import { RouteConstants } from "../../constants/RouteConstants";
import PiaTable from "../widgets/PiaTable";
import SearchBar from "../widgets/SearchBar";
import TitleCard from "../widgets/TitleCard";
import OverrideModal from "../../components/alerts/OverrideModal";

const ServiceSelectStyles = _.cloneDeep(FormSelectStyles);
ServiceSelectStyles.control.minWidth = "120px";

class CompFinder extends Component {
  getWarning() {
    return (
      <div>
        <b>Disclaimer:</b>
        <br />
        This utility is intended to supplement and guide your analysis rather than comprehensively conduct it.
        <br />
        The tool may omit potentially relevant comparable players and may return players who have little bearing on a
        case.
        <br />
        As always, please contact a member of the LRD with any questions.
      </div>
    );
  }
  getSearchTypes() {
    return [
      { value: "arb", label: "Arbitration" },
      { value: "all", label: "All" }
    ];
  }
  getProrateOptions() {
    return [
      { value: "actuals", label: "Use current season actuals" },
      { value: "per162", label: "Use career 162 game average" },
      { value: "prorate", label: "Prorate current season stats" }
    ];
  }
  getServiceClasses() {
    return [
      { value: 0, label: "Same as Focus" },
      { value: 1, label: "Same as Focus + Class Above" },
      { value: 2, label: "Class Above Focus" },
      { value: 3, label: "Class Below Focus" }
    ];
  }
  getFocusPlayerTableRows(compFinderStore) {
    let focusPlayer1 = _.cloneDeep(compFinderStore.cfMaster.focusPlayerOnly);
    let focusPlayer2 = _.cloneDeep(compFinderStore.cfMaster.focusPlayerOnly);
    focusPlayer1.index = 0;
    focusPlayer2.index = 1;
    return [focusPlayer1, focusPlayer2];
  }
  getResultList(compFinderStore) {
    if ("both" === compFinderStore.cfMaster.topRankedComps) {
      // Return two of each row
      return compFinderStore.cfMaster.compFinderPlayersNoFocus.concat(
        compFinderStore.cfMaster.compFinderPlayersNoFocus
      );
    } else {
      return compFinderStore.cfMaster.compFinderPlayersNoFocus;
    }
  }
  getRankTypes() {
    return [
      { value: "platformYear", label: "Platform Year Stats" },
      { value: "careerToPy", label: "Career through Platform Year Stats" },
      { value: "both", label: "Both" }
    ];
  }
  getSearchTypeFragment() {
    let { compFinderStore, lookupStore } = this.props.rootStore;
    let searchTypes = this.getSearchTypes();
    let padding = this.props.homePlate ? "pl-0" : "p-0";
    return (
      <React.Fragment>
        <div className={"row mx-0 col-12 " + padding}>
          <BestLabel>
            Search Type{" "}
            <IconTip
              iconName="fa-question-circle"
              tipHtml={
                <span>
                  If Arbitration is chosen, players returned will be limited to those who went through the arbitration
                  process.
                </span>
              }
              place="right"
              styles={{ size: ".9em" }}
            />
          </BestLabel>
        </div>
        <div className={"row mx-0 form-group " + padding}>
          <div className={"col-12 " + padding}>
            <BestSelect
              name="cfSearchType"
              value={lookupStore.getDropdownItem(searchTypes, compFinderStore.cfMaster.searchType)}
              onChange={option => {
                compFinderStore.cfMaster.searchType = option.value;
              }}
              options={searchTypes}
              styles={FormSelectStyles}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
  getCfIncLookbacksFlg(compFinderStore) {
    return (
      <Checkbox
        id="cfIncLookbacksFlg"
        label="Look-backs"
        onChange={event => {
          compFinderStore.cfMaster.incLookbacksFlg = event.target.checked;
        }}
        checked={compFinderStore.cfMaster.incLookbacksFlg}
      />
    );
  }
  getCfIncCurrentClassCompsFlg(compFinderStore) {
    return (
      <Checkbox
        id="cfIncCurrentClassCompsFlg"
        label="Same Class As Focus"
        onChange={event => {
          compFinderStore.cfMaster.incCurrentClassCompsFlg = event.target.checked;
        }}
        checked={compFinderStore.cfMaster.incCurrentClassCompsFlg}
      />
    );
  }
  getCfArbCountFlg(compFinderStore) {
    return (
      <Checkbox
        id="cfArbCountFlg"
        label="Arb Count"
        onChange={event => {
          compFinderStore.cfMaster.arbCountFlg = event.target.checked;
        }}
        checked={compFinderStore.cfMaster.arbCountFlg}
      />
    );
  }
  getCfArbCountByMLSFlg(compFinderStore) {
    return (
      <Checkbox
        id="cfArbCountByMLSFlg"
        label="Arb Count By MLS"
        onChange={event => {
          compFinderStore.cfMaster.arbCountByMLSFlg = event.target.checked;
        }}
        checked={compFinderStore.cfMaster.arbCountByMLSFlg}
      />
    );
  }
  getCfServiceClassFlg(compFinderStore) {
    return (
      <Checkbox
        id="cfServiceClassFlg"
        label="Service Class"
        onChange={event => {
          compFinderStore.cfMaster.serviceClassFlg = event.target.checked;
        }}
        checked={compFinderStore.cfMaster.serviceClassFlg}
      />
    );
  }
  getCfServiceClass(compFinderStore, lookupStore) {
    let serviceClasses = this.getServiceClasses();
    return (
      <BestSelect
        name="cfServiceClass"
        value={lookupStore.getDropdownItem(serviceClasses, compFinderStore.cfMaster.serviceClassValue)}
        onChange={option => {
          compFinderStore.cfMaster.serviceClassValue = option.value;
        }}
        options={serviceClasses}
        styles={ServiceSelectStyles}
      />
    );
  }

  getPlayerLabel() {
    let { compFinderStore, lookupStore, playerStore } = this.props.rootStore;
    let filtered = lookupStore.autocompletePlayers.filter(x => {
      return x.playerId === compFinderStore.cfMaster.focusPlayerId;
    });

    if (filtered.length > 0) {
      return playerStore.getAutocompleteText(filtered[0]);
    }

    return "";
  }

  getOptionsFragment() {
    let { commonStore, compFinderStore, lookupStore, playerStore } = this.props.rootStore;
    let years = commonStore.getListOfYears(1988);
    let andOr = lookupStore.getAndOrOptions();
    let prorateTypes = this.getProrateOptions();

    return (
      <React.Fragment>
        <div className="row m-0 form-group pl-0">
          <div className="col-4 pl-0">
            <div className="row col-12 mx-0 pl-0">
              <BestLabel>
                Focus Player{" "}
                <IconTip
                  iconName="fa-question-circle"
                  tipHtml={<span>This utility only uses data (and returns players) from 2006 onwards.</span>}
                  place="right"
                  styles={{ size: ".9em" }}
                />
              </BestLabel>
            </div>
            <div className="row mx-0 pl-0 form-group">
              <div className="col-12 col-xl-8 pl-0 mb-2 mb-xl-0">
                <SearchBar
                  options={lookupStore.autocompletePlayers}
                  renderSuggestion={suggestion => {
                    return <PlayerAutocompleteRow player={suggestion} />;
                  }}
                  getSuggestionValue={player => {
                    return playerStore.getAutocompleteText(player);
                  }}
                  getSuggestions={playerStore.filterBySearchTerm}
                  placeholder="select a focus player"
                  onSuggestionSelected={(event, { suggestion }) => {
                    compFinderStore.cfMaster.focusPlayerId = suggestion.playerId;
                    compFinderStore.cfMaster.focusPlayerName = suggestion.playerName;
                  }}
                  searchValue={this.getPlayerLabel()}
                />
              </div>

              <div className="col-12 col-xl-3 pl-0">
                <BestSelect
                  name="cfYear"
                  value={lookupStore.getDropdownItem(years, compFinderStore.cfMaster.focusPlayerYear * 1)}
                  onChange={option => {
                    compFinderStore.cfMaster.focusPlayerYear = option.value + "";
                  }}
                  options={years}
                  styles={FormSelectStyles}
                />
              </div>
            </div>

            <React.Fragment>
              <div className="row mx-0 col-12 pl-0">
                <BestLabel>
                  Include Contract Types&nbsp;
                  <IconTip
                    iconName="fa-question-circle"
                    tipHtml={
                      <span>
                        By default, players on multi-year contracts, pre-tenders, and non-tenders are excluded from the
                        Comp Finder. Use these checkboxes to include them.
                      </span>
                    }
                    place="right"
                    styles={{ size: ".9em" }}
                  />
                </BestLabel>
              </div>
              <div className="row mx-0 col-12 pl-0">
                <Checkbox
                  id="cfIncMyContractsFlg"
                  label="Multi-year Contracts"
                  onChange={event => {
                    compFinderStore.cfMaster.incMyContractsFlg = event.target.checked;
                  }}
                  checked={compFinderStore.cfMaster.incMyContractsFlg}
                />
              </div>
              <div className="row mx-0 col-12 pl-0">
                <Checkbox
                  id="cfIncPtContractsFlg"
                  label="Pre-tenders"
                  onChange={event => {
                    compFinderStore.cfMaster.incPtContractsFlg = event.target.checked;
                  }}
                  checked={compFinderStore.cfMaster.incPtContractsFlg}
                />
              </div>
              <div className="row mx-0 col-12 pl-0">
                <Checkbox
                  id="cfIncNtContractsFlg"
                  label="Non-tenders"
                  onChange={event => {
                    compFinderStore.cfMaster.incNtContractsFlg = event.target.checked;
                  }}
                  checked={compFinderStore.cfMaster.incNtContractsFlg}
                />
              </div>
            </React.Fragment>
          </div>

          <div className={"col-4 p-0"}>
            {this.getSearchTypeFragment()}

            <div className="row m-0 p-0">
              <div className="col-5 col-xl-8 pl-0">
                <BestLabel>
                  Service Level&nbsp;
                  <IconTip
                    iconName="fa-question-circle"
                    tipHtml={
                      <span>
                        Choose service level of players to be retrieved by the Comp Finder. Arb Count will retrieve
                        players with the same Arb Count as the focus player. Arb Count by MLS does the same, but is
                        based on the Arb Count by MLS rather than strict Arb Count. Service class provides some options
                        relative to the focus player. Service Range allows explicit entry of service details. Use the
                        And/Or dropdown to determine how to handle scenarios where multiple checkboxes in this section
                        are checked.
                      </span>
                    }
                    place="right"
                    styles={{ size: ".9em" }}
                  />
                </BestLabel>
              </div>
              <div className="col-7 col-xl-4 p-0">
                {this.props.homePlate ? (
                  ""
                ) : (
                  <BestSelect
                    name="cfServiceLevelOp"
                    value={lookupStore.getDropdownItem(andOr, compFinderStore.cfMaster.serviceLevelOp)}
                    onChange={option => {
                      compFinderStore.cfMaster.serviceLevelOp = option.value;
                    }}
                    options={andOr}
                    styles={ServiceSelectStyles}
                  />
                )}
              </div>
            </div>
            <div className="row mx-0 col-12 p-0">{this.getCfArbCountFlg(compFinderStore)}</div>

            <div className="row mx-0 col-12 p-0">{this.getCfArbCountByMLSFlg(compFinderStore)}</div>

            <div className="row m-0 px-0">
              <div className="col-6 px-0">{this.getCfServiceClassFlg(compFinderStore)}</div>
              <div className="col-6 px-0">{this.getCfServiceClass(compFinderStore, lookupStore)}</div>
            </div>

            <div className="row mx-0 px-0">
              <div className="col-6 p-0">
                <Checkbox
                  id="cfEosRangeFlg"
                  label="EOS Service Range"
                  onChange={event => {
                    compFinderStore.cfMaster.eosRangeFlg = event.target.checked;
                  }}
                  checked={compFinderStore.cfMaster.eosRangeFlg}
                />
              </div>

              <div className="col-6 p-0 d-flex align-items-center mt-1">
                <div>
                  <Input
                    id="cfEosServiceRange1"
                    type="number"
                    className={compFinderStore.cfMaster.eosServiceRange1Error === true ? "error" : ""}
                    onChange={event => {
                      if (event.target.value === "" || event.target.value === null) {
                        compFinderStore.cfMaster.eosServiceRange1Error = true;
                      } else {
                        compFinderStore.cfMaster.eosServiceRange1Error = false;
                      }
                      compFinderStore.cfMaster.eosServiceRangeStart = event.target.value;
                      compFinderStore.cfMaster.eosRangeLowValue = event.target.value;
                    }}
                    value={compFinderStore.cfMaster.eosServiceRangeStart}
                  />
                </div>

                <div className="ml-xl-1 mr-xl-1">
                  <span>-</span>
                </div>

                <div>
                  <Input
                    id="cfEosServiceRange2"
                    type="number"
                    className={compFinderStore.cfMaster.eosServiceRange2Error === true ? "error" : ""}
                    onChange={event => {
                      if (event.target.value === "" || event.target.value === null) {
                        compFinderStore.cfMaster.eosServiceRange2Error = true;
                      } else {
                        compFinderStore.cfMaster.eosServiceRange2Error = false;
                      }
                      compFinderStore.cfMaster.eosServiceRangeEnd = event.target.value;
                      compFinderStore.cfMaster.eosRangeHighValue = event.target.value;
                    }}
                    value={compFinderStore.cfMaster.eosServiceRangeEnd}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-4 pr-0">
            <React.Fragment>
              <div className="row mx-0 col-12 pr-0">
                <BestLabel>
                  Prorate Stats&nbsp;
                  <IconTip
                    iconName="fa-question-circle"
                    tipHtml={
                      <span>
                        Define how stats for the current season are prorated in both the calculation and display.
                        <br />
                        <br />
                        <b>Use Current Season Actuals</b> - When selected, stat totals through the current date will be
                        used.
                        <br />
                        <br />
                        <b>Use Career 162-game Average</b> (In Season Only) - When selected, stats for the current
                        season will be replaced with the player&apos;s career totals divided by 162 games.
                        <br />
                        <br />
                        <b>Prorate Current Season Statistics</b> (In Season Only) - When selected, stats for the current
                        season will be prorated based on the number of games already played by the player&apos;s team.
                      </span>
                    }
                    place="right"
                    styles={{ size: ".9em" }}
                  />
                </BestLabel>
              </div>

              <div className="row mx-0 pr-0 form-group">
                <div className="col-12 pr-0">
                  <BestSelect
                    name="cfProrateType"
                    value={lookupStore.getDropdownItem(prorateTypes, compFinderStore.cfMaster.prorateType)}
                    onChange={option => {
                      compFinderStore.cfMaster.prorateType = option.value;
                    }}
                    options={prorateTypes}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>
            </React.Fragment>

            <div className="row mx-0 col-12 pr-0">
              <BestLabel>
                Include Players&nbsp;
                <IconTip
                  iconName="fa-question-circle"
                  tipHtml={
                    <span>
                      Checking Look-backs will include all player/platform year combinations from 2006 (Arb Year), up to
                      but not including the focus player&apos;s platform year. Same Class As Focus will include the the
                      focus player&apos;s platform year. At least one of these boxes must be checked to run the Comp
                      Finder.
                    </span>
                  }
                  place="right"
                  styles={{ size: ".9em" }}
                />
              </BestLabel>
            </div>
            <div className="row mx-0 col-12 pr-0">{this.getCfIncLookbacksFlg(compFinderStore)}</div>
            <div className="row mx-0 col-12 pr-0">{this.getCfIncCurrentClassCompsFlg(compFinderStore)}</div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  getNumberOfResultsFragment() {
    let { compFinderStore, routerStore } = this.props.rootStore;

    return (
      <div className="header-item-row">
        <div className="footer-option item">
          <span>Show Top&nbsp;</span>
          <input
            id="cfNumberOfResults"
            type="number"
            onChange={event => {
              compFinderStore.cfMaster.numberOfResults = event.target.value;
            }}
            value={compFinderStore.cfMaster.numberOfResults}
            style={{ width: "50px" }}
          />
          <span>&nbsp;Results</span>
        </div>

        <PrimaryButton
          onClick={() => {
            compFinderStore.findComps(this.props.homePlate);
          }}
        >
          Find Comps
        </PrimaryButton>
        {this.props.homePlate ? (
          <LightButton
            onClick={() => {
              compFinderStore.runSearchOnLoad = true;
              routerStore.pushHistory(RouteConstants.COMP_FINDER.route, false);
            }}
          >
            Add to Comp Finder
          </LightButton>
        ) : (
          <LightButton
            onClick={() => {
              compFinderStore.saveNewModalOpen = true;
            }}
          >
            Save Query
          </LightButton>
        )}
      </div>
    );
  }
  getTopRankedFragment() {
    let { compStore, compFinderStore, lookupStore, playerStore } = this.props.rootStore;
    let rankTypes = this.getRankTypes();
    return (
      <React.Fragment>
        {compFinderStore.focusCols.length > 0 ? (
          <React.Fragment>
            <TitleCard className="row mx-0 my-4">
              <span>Top Ranked Comps</span>
            </TitleCard>
            <PiaTable
              data={this.getFocusPlayerTableRows(compFinderStore)}
              columns={compFinderStore.focusCols}
              rowGetter={i => {
                let player = _.cloneDeep(compFinderStore.cfMaster.focusPlayerOnly);
                player.rowIndex = i;

                if ((i + 1) % 2 === 0) {
                  // Even rows are career rows
                  player.career = true;
                }

                return player;
              }}
              height={120}
            />
          </React.Fragment>
        ) : (
          ""
        )}

        {compFinderStore.resultCols.length > 0 && compFinderStore.cfMaster.compFinderPlayersNoFocus.length > 0 ? (
          <React.Fragment>
            <div className="row mx-0 my-4 justify-content-between">
              <div className="col-3 p-0">
                <BestSelect
                  name="cfRankType"
                  value={lookupStore.getDropdownItem(rankTypes, compFinderStore.cfMaster.topRankedComps)}
                  onChange={option => {
                    compFinderStore.cfMaster.topRankedComps = option.value;
                  }}
                  options={rankTypes}
                  styles={FormSelectStyles}
                />
              </div>
              {this.props.homePlate ? (
                ""
              ) : (
                <div className="col-9 p-0 item-row">
                  <PrimaryButton
                    onClick={() => {
                      compFinderStore.runCompReport();
                    }}
                  >
                    Run Comp Report
                  </PrimaryButton>
                  <LightButton
                    onClick={() => {
                      compFinderStore.exportComps();
                    }}
                  >
                    Export to Excel
                  </LightButton>
                  <LightButton
                    onClick={() => {
                      let plMaster = {
                        ...playerStore.defaults.playerList,
                        details: []
                      };
                      let compState = {
                        convertFromCompFinder: true,
                        cfMaster: compFinderStore.cfMaster,
                        plMaster: plMaster
                      };
                      compStore.openComp(0, compState);
                    }}
                  >
                    Add to New Comparison
                  </LightButton>
                  <LightButton
                    onClick={() => {
                      playerStore.openPlayerList(0, false, false, null, true, compFinderStore.cfMaster);
                    }}
                  >
                    Convert to Player List
                  </LightButton>
                </div>
              )}
            </div>
            <PiaTable
              data={this.getResultList(compFinderStore)}
              columns={compFinderStore.resultCols}
              rowGetter={i => {
                if (i < 0) {
                  return null;
                }

                let index = i;

                if (i > 0 && "both" === compFinderStore.cfMaster.topRankedComps) {
                  let even = (i + 1) % 2 === 0;
                  index = Math.floor(i / 2);
                  let player = _.cloneDeep(compFinderStore.cfMaster.compFinderPlayersNoFocus[index]);
                  player.career = even;
                  player.rowIndex = index;
                  return player;
                } else {
                  let player = _.cloneDeep(compFinderStore.cfMaster.compFinderPlayersNoFocus[i]);
                  player.career = false;

                  if ("careerToPy" === compFinderStore.cfMaster.topRankedComps) {
                    player.career = true;
                  }

                  player.rowIndex = index;

                  return player;
                }
              }}
              height={compFinderStore.calculateTableHeight(
                compFinderStore.cfMaster.compFinderPlayersNoFocus.length,
                308
              )}
            />
          </React.Fragment>
        ) : compFinderStore.resultCols.length > 0 ? (
          <div className="row mx-0 col-12 justify-content-center">
            <span>No comparable players were found based on the above criteria</span>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
  getSalaryFragment(displayFlg, label, className, high, avg, median, low) {
    let el = "";

    if (displayFlg) {
      el = (
        <React.Fragment>
          <div className="row mx-0 p-0 my-4">
            <TitleCard className="col-12">
              <span>{label}</span>
            </TitleCard>
          </div>
          <div className="row mx-0 col-12 p-0">
            <table>
              <tbody>
                <tr>
                  <td>Highest Salary In Range</td>
                  <td className="text-right">
                    <CurrencyFormatter value={high} />
                  </td>
                </tr>
                <tr>
                  <td>Average Salary</td>
                  <td className="text-right">
                    <CurrencyFormatter value={avg} />
                  </td>
                </tr>
                <tr>
                  <td>Median Salary</td>
                  <td className="text-right">
                    <CurrencyFormatter value={median} />
                  </td>
                </tr>
                <tr>
                  <td>Lowest Salary In Range</td>
                  <td className="text-right">
                    <CurrencyFormatter value={low} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </React.Fragment>
      );
    }

    return <div className={className}>{el}</div>;
  }
  renderHomePlate(years, serviceClasses, andOr, prorateTypes) {
    let { compFinderStore, lookupStore, playerStore } = this.props.rootStore;
    let classes = this.props.className ? this.props.className : "";

    return (
      <PiaWidget className={classes}>
        <CardContainer>
          <CardContainer.Header>
            <div className="d-flex justify-content-between">
              <span className="header-title">Comp Finder</span>
              <IconTip iconClassName="mlb-red-icon" iconName="fa-exclamation-triangle" tipHtml={this.getWarning()} />
            </div>
          </CardContainer.Header>
          <CardContainer.Body>
            <div className="container-fluid">
              <div className="row m-0 form-group pl-0">
                <div className="col-4 pl-0">
                  <div className="row col-12 mx-0 pl-0">
                    <BestLabel>
                      Focus Player{" "}
                      <IconTip
                        iconName="fa-question-circle"
                        tipHtml={<span>This utility only uses data (and returns players) from 2006 onwards.</span>}
                        place="right"
                        styles={{ size: ".9em" }}
                      />
                    </BestLabel>
                  </div>
                  <div className="row mx-0 pl-0 form-group">
                    <div className="col-9 pl-0">
                      <SearchBar
                        options={lookupStore.autocompletePlayers}
                        renderSuggestion={suggestion => {
                          return <PlayerAutocompleteRow player={suggestion} />;
                        }}
                        getSuggestionValue={player => {
                          return playerStore.getAutocompleteText(player);
                        }}
                        getSuggestions={playerStore.filterBySearchTerm}
                        placeholder="select a focus player"
                        onSuggestionSelected={(event, { suggestion }) => {
                          compFinderStore.cfMaster.focusPlayerId = suggestion.playerId;
                          compFinderStore.cfMaster.focusPlayerName = suggestion.playerName;
                        }}
                      />
                    </div>

                    <div className="col-3 pl-0">
                      <BestSelect
                        name="cfYear"
                        value={lookupStore.getDropdownItem(years, compFinderStore.cfMaster.focusPlayerYear * 1)}
                        onChange={option => {
                          compFinderStore.cfMaster.focusPlayerYear = option.value + "";
                        }}
                        options={years}
                        styles={FormSelectStyles}
                      />
                    </div>
                  </div>
                  {this.getSearchTypeFragment()}
                </div>

                <div className="col-8 p-0 pl-5">
                  <div className="form-inline row mx-0 col-12 p-0">
                    <BestLabel>
                      Include Players&nbsp;
                      <IconTip
                        iconName="fa-question-circle"
                        tipHtml={
                          <span>
                            Checking Look-backs will include all player/platform year combinations from 2006 (Arb Year),
                            up to but not including the focus player&apos;s platform year. Same Class As Focus will
                            include the the focus player&apos;s platform year. At least one of these boxes must be
                            checked to run the Comp Finder.
                          </span>
                        }
                        place="right"
                        styles={{ size: ".9em" }}
                      />
                    </BestLabel>
                  </div>
                  <div className="form-inline form-group row mx-0 col-12 p-0">
                    <span className="form-check form-check-inline">{this.getCfIncLookbacksFlg(compFinderStore)}</span>
                    <span className="form-check form-check-inline">
                      {this.getCfIncCurrentClassCompsFlg(compFinderStore)}
                    </span>
                  </div>

                  <div className="row m-0 p-0">
                    <div className="col-8 pl-0">
                      <BestLabel>
                        Service Level&nbsp;
                        <IconTip
                          iconName="fa-question-circle"
                          tipHtml={
                            <span>
                              Choose service level of players to be retrieved by the Comp Finder. Arb Count will
                              retrieve players with the same Arb Count as the focus player. Arb Count by MLS does the
                              same, but is based on the Arb Count by MLS rather than strict Arb Count. Service class
                              provides some options relative to the focus player. Service Range allows explicit entry of
                              service details. Use the And/Or dropdown to determine how to handle scenarios where
                              multiple checkboxes in this section are checked.
                            </span>
                          }
                          place="right"
                          styles={{ size: ".9em" }}
                        />
                      </BestLabel>
                    </div>
                  </div>

                  <div className="form-inline form-group row mx-0 col-12 p-0">
                    <span className="d-flex">
                      <span className="form-check form-check-inline">{this.getCfArbCountFlg(compFinderStore)}</span>

                      <span className="form-check form-check-inline">
                        {this.getCfArbCountByMLSFlg(compFinderStore)}
                      </span>

                      <span className="form-check form-check-inline">{this.getCfServiceClassFlg(compFinderStore)}</span>

                      {!!compFinderStore.cfMaster.serviceClassFlg ? (
                        <span className="form-check form-check-inline">
                          {this.getCfServiceClass(compFinderStore, lookupStore)}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>

                    <span className="d-flex">
                      <span className="form-check form-check-inline">
                        <Checkbox
                          id="cfEosRangeFlg"
                          label="EOS Service Range"
                          onChange={event => {
                            compFinderStore.cfMaster.eosRangeFlg = event.target.checked;
                          }}
                          checked={compFinderStore.cfMaster.eosRangeFlg}
                        />
                      </span>

                      {!!compFinderStore.cfMaster.eosRangeFlg ? (
                        <React.Fragment>
                          <Input
                            id="cfEosServiceRange1"
                            type="number"
                            className={compFinderStore.cfMaster.eosServiceRange1Error === true ? "error" : ""}
                            onChange={event => {
                              if (event.target.value === "" || event.target.value === null) {
                                compFinderStore.cfMaster.eosServiceRange1Error = true;
                              } else {
                                compFinderStore.cfMaster.eosServiceRange1Error = false;
                              }

                              compFinderStore.cfMaster.eosServiceRangeStart = event.target.value;
                              compFinderStore.cfMaster.eosRangeLowValue = event.target.value;
                            }}
                            value={compFinderStore.cfMaster.eosServiceRangeStart}
                          />
                          <span>&nbsp;-&nbsp;</span>
                          <Input
                            id="cfEosServiceRange2"
                            type="number"
                            className={compFinderStore.cfMaster.eosServiceRange2Error === true ? "error" : ""}
                            onChange={event => {
                              if (event.target.value === "" || event.target.value === null) {
                                compFinderStore.cfMaster.eosServiceRange2Error = true;
                              } else {
                                compFinderStore.cfMaster.eosServiceRange2Error = false;
                              }

                              compFinderStore.cfMaster.eosServiceRangeEnd = event.target.value;
                              compFinderStore.cfMaster.eosRangeHighValue = event.target.value;
                            }}
                            value={compFinderStore.cfMaster.eosServiceRangeEnd}
                          />
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
              {this.getTopRankedFragment()}
            </div>
          </CardContainer.Body>
          <CardContainer.Footer>{this.getNumberOfResultsFragment()}</CardContainer.Footer>
        </CardContainer>
      </PiaWidget>
    );
  }
  renderFull() {
    let { compFinderStore } = this.props.rootStore;
    let salaryInfo = compFinderStore.cfMaster.salaryInfo;
    return (
      <div className="p-4">
        <OverrideModal
          isOpen={!!compFinderStore.cfMaster.showOverride}
          title="Warning"
          text={compFinderStore.cfMaster.overrideMessage || ""}
          onConfirm={() => {
            if (compFinderStore.cfMaster.overrideFn) {
              compFinderStore.cfMaster.overrideFn();
            }
          }}
          onCancel={() => {
            compFinderStore.cfMaster.showOverride = undefined;
          }}
        />

        {this.getOptionsFragment()}
        {this.getNumberOfResultsFragment()}
        {this.getTopRankedFragment()}
        {salaryInfo ? (
          <div className="row mx-0 p-0">
            {this.getSalaryFragment(
              salaryInfo.topTenSalaryExists,
              "Top Ten",
              "col-4 pl-0",
              salaryInfo.topTenHighestSalary,
              salaryInfo.topTenAverageSalary,
              salaryInfo.topTenMedianSalary,
              salaryInfo.topTenLowestSalary
            )}
            {this.getSalaryFragment(
              salaryInfo.topFiveSalaryExists,
              "Top Five",
              "col-4 p-0",
              salaryInfo.topFiveHighestSalary,
              salaryInfo.topFiveAverageSalary,
              salaryInfo.topFiveMedianSalary,
              salaryInfo.topFiveLowestSalary
            )}
            {this.getSalaryFragment(
              salaryInfo.topThreeSalaryExists,
              "Top Three",
              "col-4 pr-0",
              salaryInfo.topThreeHighestSalary,
              salaryInfo.topThreeAverageSalary,
              salaryInfo.topThreeMedianSalary,
              salaryInfo.topThreeLowestSalary
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
  render() {
    let { commonStore, lookupStore } = this.props.rootStore;
    let years = commonStore.getListOfYears(1988);
    let andOr = lookupStore.getAndOrOptions();
    let prorateTypes = this.getProrateOptions();

    if (this.props.homePlate) {
      return this.renderHomePlate(years, andOr, prorateTypes);
    } else {
      return this.renderFull();
    }
  }
}

CompFinder.defaultProps = {
  homePlate: false
};

CompFinder.propTypes = {
  rootStore: PropTypes.object.isRequired,
  className: PropTypes.string,
  homePlate: PropTypes.bool
};

export default inject("rootStore")(observer(CompFinder));
