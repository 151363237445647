import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { Modal } from "best-common-react";
import PiaTable from "../widgets/PiaTable";
import { StatConstants } from "../../constants/StatConstants";

class CombinedStatsModal extends React.Component {
  getTitle(playerStore) {
    let title = playerStore.modalCombinedData.playerName + " - " + playerStore.modalCombinedData.type;

    if (playerStore.modalCombinedData.fromDate) {
      title +=
        " Stats for Games " + playerStore.modalCombinedData.fromDate + " - " + playerStore.modalCombinedData.toDate;
    } else {
      title +=
        " Stats for Years " + playerStore.modalCombinedData.fromYear + " - " + playerStore.modalCombinedData.toYear;
    }

    return title;
  }
  getCols(playerStore) {
    let cols = [{ key: "season", name: "Season", width: 200 }];
    let statCols = [];

    if (StatConstants.TYPES.BATTING === playerStore.modalCombinedData.type) {
      statCols = playerStore.getStatCols(playerStore.displayPlayerBattingStatLine);
    } else if (StatConstants.TYPES.PITCHING === playerStore.modalCombinedData.type) {
      statCols = playerStore.getStatCols(playerStore.displayPlayerPitchingStatLine);
    } else if (StatConstants.TYPES.FIELDING === playerStore.modalCombinedData.type) {
      statCols = playerStore.getStatCols(playerStore.displayPlayerFieldingStatLine);
    }

    cols = cols.concat(statCols);
    return cols;
  }

  render() {
    let { playerStore } = this.props.rootStore;
    let title = this.getTitle(playerStore);
    let cols = this.getCols(playerStore);
    return (
      <Modal show={playerStore.combinedStatsModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title={title}
          close={() => {
            playerStore.combinedStatsModalOpen = false;
          }}
        />
        <Modal.Body>
          <div className="row m-0">
            <div className="col-12 p-0">
              <PiaTable data={playerStore.modalCombinedData.list} columns={cols} height={168} minColumnWidth={50} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

CombinedStatsModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CombinedStatsModal));
