import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PrimaryButton } from "best-common-react";

const Header = styled.div.attrs({
  className: "row align-items-center h-100 ml-0 p-0 justify-content-between"
})`
  margin-right: -12px;
`;

const Marquee = styled.div`
  animation: marquee linear ${props => (props.length || 10) * 0.2 + "s"} infinite;

  @keyframes marquee {
    from {
      transform: translate(calc(100vw - 10em), 0%);
    }
    to {
      transform: translate(-100%, 0%);
    }
  }

  a {
    white-space: nowrap;
    padding-right: 5em;
  }
`;

const ButtonWrapper = styled.div`
width: 10em;
position: absolute;
right: 0;
z-index: 2;
    background-color: white;
    padding-left: ${props => props.theme["size-gutter"]}
    padding-right: ${props => props.theme["size-gutter-num"] * 2 + "px"}
  `;

class PlayerProfileHeaderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hideNews: false
    };
  }

  getMarquee() {
    let { playerStore } = this.props.rootStore;
    let items = [];
    let charLength = 0;

    if (playerStore.player.news) {
      playerStore.player.news.forEach((newsItem, index) => {
        let title = newsItem.source + " - " + newsItem.cleanArticleName + " - " + newsItem.author;
        charLength += title.length;
        items.push(
          <a key={"news" + index} href={newsItem.url} target="_blank" rel="noopener noreferrer">
            {title}
          </a>
        );
      });
    }

    return <Marquee length={charLength}>{items}</Marquee>;
  }

  render() {
    return (
      <Header>
        {this.state.hideNews ? <React.Fragment></React.Fragment> : this.getMarquee()}

        <ButtonWrapper>
          <PrimaryButton
            className="w-100"
            onClick={() => {
              this.setState({ hideNews: !this.state.hideNews });
            }}
          >
            {this.state.hideNews ? "Show" : "Hide"} News
          </PrimaryButton>
        </ButtonWrapper>
      </Header>
    );
  }
}

PlayerProfileHeaderDetails.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(PlayerProfileHeaderDetails));
