import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import EditStat from "../../components/statistics/EditStat";
import OverrideModal from "../../components/alerts/OverrideModal";
import { RouteConstants } from "../../constants/RouteConstants";

class EditStatContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.EDIT_STATISTIC.title);
  }

  render() {
    let { statStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <OverrideModal
          isOpen={!!statStore.stat.showOverride}
          title="Stat Exists"
          text={statStore.stat.overrideMessage}
          onConfirm={() => {
            statStore.saveStat(true);
          }}
          onCancel={() => {
            statStore.stat.showOverride = undefined;
          }}
        />
        <div className="container-fluid">
          <div className="col-12 p-0 mb-4">
            <EditStat />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EditStatContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(EditStatContainer));
