import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import _ from "lodash";
import PropTypes from "prop-types";
import {
  BestLabel,
  BestSelect,
  Checkbox,
  CardContainer,
  FormInput,
  Icon,
  PrimaryButton,
  LightButton
} from "best-common-react";
import { FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import { SharingConstants } from "../../constants/SharingConstants";
import TitleCard from "../widgets/TitleCard";

class ShareItems extends React.Component {
  getShareTypeOptions() {
    return [
      { value: 0, label: "Comparisons" },
      { value: 1, label: "Player Lists" },
      { value: 2, label: "Stat Lists" },
      { value: 3, label: "Stats" },
      { value: 4, label: "Player Queries" },
      { value: 5, label: "Reports" }
    ];
  }

  getUserGroupOptions(lookupStore) {
    let groups = [
      { teamLk: "All", teamName: "All Users" },
      { teamLk: "BOC", teamName: "BOC" },
      { teamLk: "OTH", teamName: "Other" }
    ];
    return groups.concat(lookupStore.getCollection("teams"));
  }

  getUsers(lookupStore, sharingStore) {
    let users = lookupStore.systemUsers;

    if ("All" !== sharingStore.userGroupLk) {
      return _.filter(users, user => user.groupName === sharingStore.userGroupLk);
    }

    return users;
  }

  getCompCols(authStore, compStore) {
    var cols = compStore.getCompCols();

    if (authStore.userData.admin) {
      cols.push({
        key: "standardCheck",
        name: "Standard",
        width: 80,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Checkbox
                label=""
                onChange={event => {
                  if (event.target.checked) {
                    compStore.standardCompIds.push(row.row.comparisonId);
                  } else {
                    let index = compStore.standardCompIds.indexOf(row.row.comparisonId);
                    if (index > -1) {
                      compStore.standardCompIds.splice(index, 1);
                    }
                  }
                }}
                checked={row.row.isStandard}
              />
            </div>
          );
        }
      });
    }

    return cols;
  }

  getPlayerListCols(authStore, playerStore) {
    var cols = playerStore.getPlayerListCols();

    if (authStore.userData.admin) {
      cols.push({
        key: "standardCheck",
        name: "Standard",
        width: 80,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Checkbox
                label=""
                onChange={event => {
                  if (event.target.checked) {
                    playerStore.standardPlayerListIds.push(row.row.playerlistId);
                  } else {
                    let index = playerStore.standardPlayerListIds.indexOf(row.row.playerlistId);
                    if (index > -1) {
                      playerStore.standardPlayerListIds.splice(index, 1);
                    }
                  }
                }}
                checked={row.row.isStandard}
              />
            </div>
          );
        }
      });
    }

    return cols;
  }

  getStatListCols(authStore, statStore) {
    var cols = statStore.getListCols();

    if (authStore.userData.admin) {
      cols.push({
        key: "standardCheck",
        name: "Standard",
        width: 80,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Checkbox
                label=""
                onChange={event => {
                  if (event.target.checked) {
                    statStore.standardStatListIds.push(row.row.statlistId);
                  } else {
                    let index = statStore.standardStatListIds.indexOf(row.row.statlistId);
                    if (index > -1) {
                      statStore.standardStatListIds.splice(index, 1);
                    }
                  }
                }}
                checked={row.row.isStandard}
              />
            </div>
          );
        }
      });
    }

    return cols;
  }

  getTable() {
    let { sharingStore } = this.props.rootStore;

    switch (sharingStore.typeId) {
      case SharingConstants.TYPE.COMPARISONS:
        return this.getCompTable();
      case SharingConstants.TYPE.PLAYER_LISTS:
        return this.getPlayerListTable();
      case SharingConstants.TYPE.STAT_LISTS:
        return this.getStatListTable();
      case SharingConstants.TYPE.STATS:
        return this.getStatTable();
      case SharingConstants.TYPE.PLAYER_QUERIES:
        return this.getPlayerQueryTable();
      case SharingConstants.TYPE.REPORTS:
        return this.getReportTable();
      default:
        return "";
    }
  }

  getCompTable() {
    let { authStore, compStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <div className="row mt-4">
          <div className="col-12">
            <PiaTable
              data={compStore.displayComps}
              columns={this.getCompCols(authStore, compStore)}
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: rows => {
                  compStore.addSelectedComps(rows.map(row => row.row));
                },
                onRowsDeselected: rows => {
                  compStore.removeUnselectedComps(rows.map(row => row.row));
                },
                selectBy: {
                  isSelectedKey: "isSelected"
                },
                enableShiftSelect: true
              }}
              height={compStore.calculateTableHeight(compStore.displayComps.length, 308)}
            />
          </div>
        </div>

        {authStore.userData.admin ? (
          <div className="row mx-0 mt-2 col-12 p-0 justify-content-end">
            <LightButton
              onClick={() => {
                compStore.updateStandardComps();
              }}
            >
              Update Standard
            </LightButton>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  getPlayerListTable() {
    let { authStore, playerStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <div className="row mt-4">
          <div className="col-12">
            <PiaTable
              data={playerStore.displayLists}
              columns={this.getPlayerListCols(authStore, playerStore)}
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: rows => {
                  playerStore.addSelectedLists(rows.map(row => row.row));
                },
                onRowsDeselected: rows => {
                  playerStore.removeUnselectedLists(rows.map(row => row.row));
                },
                selectBy: {
                  isSelectedKey: "isSelected"
                },
                enableShiftSelect: true
              }}
              height={playerStore.calculateTableHeight(playerStore.displayLists.length, 308)}
            />
          </div>
        </div>

        {authStore.userData.admin ? (
          <div className="row mx-0 mt-2 col-12 p-0 justify-content-end">
            <LightButton
              onClick={() => {
                playerStore.updateStandardPlayerLists();
              }}
            >
              Update Standard
            </LightButton>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  getStatListTable() {
    let { authStore, statStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <div className="row mt-4">
          <div className="col-12">
            <PiaTable
              data={statStore.displayLists}
              columns={this.getStatListCols(authStore, statStore)}
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: rows => {
                  statStore.addSelectedLists(rows.map(row => row.row));
                },
                onRowsDeselected: rows => {
                  statStore.removeUnselectedLists(rows.map(row => row.row));
                },
                selectBy: {
                  isSelectedKey: "isSelected"
                },
                enableShiftSelect: true
              }}
              height={statStore.calculateTableHeight(statStore.displayLists.length, 308)}
            />
          </div>
        </div>

        {authStore.userData.admin ? (
          <div className="row mx-0 mt-2 col-12 p-0 justify-content-end">
            <LightButton
              onClick={() => {
                statStore.updateStandardStatLists();
              }}
            >
              Update Standard
            </LightButton>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  getStatTable() {
    let { statStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <div className="row mt-4">
          <div className="col-12">
            <PiaTable
              data={statStore.displayStats}
              columns={statStore.getStatCols()}
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: rows => {
                  statStore.addSelectedStats(rows.map(row => row.row));
                },
                onRowsDeselected: rows => {
                  statStore.removeUnselectedStats(rows.map(row => row.row));
                },
                selectBy: {
                  isSelectedKey: "isSelected"
                },
                enableShiftSelect: true
              }}
              height={statStore.calculateTableHeight(statStore.displayStats.length, 308)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  getPlayerQueryTable() {
    let { playerStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <div className="row mt-4">
          <div className="col-12">
            <PiaTable
              data={playerStore.displayQueries}
              columns={playerStore.getQueryCols()}
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: rows => {
                  playerStore.addSelectedQueries(rows.map(row => row.row));
                },
                onRowsDeselected: rows => {
                  playerStore.removeUnselectedQueries(rows.map(row => row.row));
                },
                selectBy: {
                  isSelectedKey: "isSelected"
                },
                enableShiftSelect: true
              }}
              height={playerStore.calculateTableHeight(playerStore.displayQueries.length, 308)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  getReportTable() {
    let { reportStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <div className="row mt-4">
          <div className="col-12">
            <PiaTable
              data={reportStore.displaySavedReports}
              columns={reportStore.getSavedReportCols(false)}
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: rows => {
                  reportStore.addSelectedReports(rows.map(row => row.row));
                },
                onRowsDeselected: rows => {
                  reportStore.removeUnselectedReports(rows.map(row => row.row));
                },
                selectBy: {
                  isSelectedKey: "isSelected"
                },
                enableShiftSelect: true
              }}
              height={reportStore.calculateTableHeight(reportStore.displaySavedReports.length, 308)}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  getShareRows(sharingStore) {
    let rows = [];

    sharingStore.shareUsers.forEach((user, index) => {
      let el = (
        <div className="row mt-2" key={"share_" + index}>
          <div className="col-12 p-0">
            <TitleCard>
              <div className="d-flex flex-fill justify-content-between">
                <div>{user.name}</div>
                <Icon
                  className="mlb-red-icon"
                  iconName="fa-trash-alt"
                  onClick={() => {
                    sharingStore.shareUsers.splice(index, 1);
                  }}
                ></Icon>
              </div>
            </TitleCard>
          </div>
        </div>
      );
      rows.push(el);
    });

    return <React.Fragment>{rows}</React.Fragment>;
  }

  shareItems() {
    let { compStore, playerStore, reportStore, sharingStore, statStore } = this.props.rootStore;
    switch (sharingStore.typeId) {
      case SharingConstants.TYPE.COMPARISONS:
        compStore.shareComps(sharingStore.shareUsers, sharingStore.comments);
        break;
      case SharingConstants.TYPE.PLAYER_LISTS:
        playerStore.sharePlayerLists(sharingStore.shareUsers, sharingStore.comments);
        break;
      case SharingConstants.TYPE.STAT_LISTS:
        statStore.shareLists(sharingStore.shareUsers, sharingStore.comments);
        break;
      case SharingConstants.TYPE.STATS:
        statStore.shareStats(sharingStore.shareUsers, sharingStore.comments);
        break;
      case SharingConstants.TYPE.PLAYER_QUERIES:
        playerStore.shareQueries(sharingStore.shareUsers, sharingStore.comments);
        break;
      case SharingConstants.TYPE.REPORTS:
        reportStore.shareReports(sharingStore.shareUsers, sharingStore.comments);
        break;
      default:
        break;
    }
  }

  render() {
    let { lookupStore, sharingStore, authStore } = this.props.rootStore;
    let shareTypes = this.getShareTypeOptions();
    let userGroups = this.getUserGroupOptions(lookupStore);
    let userData = authStore.userData;
    let shareUsers = this.getUsers(lookupStore, sharingStore).filter(x => x.name !== userData.username);

    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-4">
                <BestSelect
                  name="siType"
                  value={lookupStore.getDropdownItem(shareTypes, sharingStore.typeId)}
                  onChange={option => {
                    sharingStore.typeId = option.value;
                  }}
                  options={shareTypes}
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>

            {this.getTable()}

            <div className="row mt-4">
              <div className="col-12">
                <TitleCard>
                  <span>Share Selected Items</span>
                </TitleCard>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-6">
                <div className="row m-0">
                  <div className="col-5 pl-0">
                    <div className="form-group mb-0">
                      <BestLabel>User Group</BestLabel>
                      <BestSelect
                        name="siUserGroup"
                        value={lookupStore.getDropdownItem(userGroups, sharingStore.userGroupLk, "teamLk", "teamName")}
                        onChange={option => {
                          sharingStore.userGroupLk = option.teamLk;
                        }}
                        options={userGroups}
                        getOptionValue={option => option.teamLk}
                        getOptionLabel={option => option.teamName}
                        portal={document.body}
                        styles={FormSelectStyles}
                      />
                    </div>
                  </div>

                  <div className="col-5 pl-0">
                    <div className="form-group mb-0">
                      <BestLabel>Available Users</BestLabel>
                      <BestSelect
                        name="siUsers"
                        value={lookupStore.getDropdownItem(
                          shareUsers,
                          sharingStore.selectedUser.displayname,
                          "displayname",
                          "displayname"
                        )}
                        onChange={option => {
                          sharingStore.selectedUser = option;
                        }}
                        options={shareUsers}
                        getOptionValue={option => option.displayname}
                        getOptionLabel={option => option.displayname}
                        portal={document.body}
                        styles={FormSelectStyles}
                      />
                    </div>
                  </div>

                  <div className="col-2 d-flex align-items-end p-0">
                    <PrimaryButton
                      className="flex-fill"
                      onClick={() => {
                        if (!_.includes(sharingStore.shareUsers, sharingStore.selectedUser)) {
                          sharingStore.shareUsers.push(sharingStore.selectedUser);
                        }
                      }}
                      disabled={!sharingStore.selectedUser || sharingStore.selectedUser.displayname === ""}
                    >
                      Add
                    </PrimaryButton>
                  </div>
                </div>

                {this.getShareRows(sharingStore)}
              </div>

              <div className="col-6">
                <FormInput
                  name="siComments"
                  label="Comments"
                  type="textarea"
                  onChangeFn={event => {
                    sharingStore.comments = event.target.value;
                  }}
                  value={sharingStore.comments}
                  editable
                />
              </div>
            </div>
          </div>
        </CardContainer.Body>
        <CardContainer.Footer>
          <div className="header-item-row">
            <PrimaryButton
              onClick={() => {
                this.shareItems();
              }}
            >
              Share
            </PrimaryButton>
          </div>
        </CardContainer.Footer>
      </CardContainer>
    );
  }
}

ShareItems.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(ShareItems)));
