import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { CardContainer, DateTimeFormatter } from "best-common-react";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";

class HelpfulLinks extends React.Component {
  getCols() {
    return [
      {
        key: "reportName",
        name: "Link Name",
        width: 400,
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                window.open(row.row.url);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "reportType", name: "Type", width: 200 },
      {
        key: "lastUpdated",
        name: "Last Updated",
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      },
      { key: "comments", name: "Comments", width: 500 }
    ];
  }

  render() {
    let { reportStore } = this.props.rootStore;

    return (
      <PiaWidget className="col-12 table-container">
        <CardContainer>
          <CardContainer.Header>
            <span className="header-title">System Links</span>
          </CardContainer.Header>
          <CardContainer.Body>
            {reportStore.helpfulLinks.length > 0 ? (
              <PiaTable
                data={reportStore.helpfulLinks}
                columns={this.getCols()}
                height={reportStore.calculateTableHeight(reportStore.helpfulLinks.length, 280)}
              />
            ) : (
              <div className="col-12">No Results</div>
            )}
          </CardContainer.Body>
          <CardContainer.Footer></CardContainer.Footer>
        </CardContainer>
      </PiaWidget>
    );
  }
}

HelpfulLinks.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(HelpfulLinks)));
