import axios from "axios/index";

/**
 * This is where the API endpoints
 * for PIA are stored.
 */

const SERVICES_URL = process.env.REACT_APP_SERVICES_URL;
const PROXY_URL = SERVICES_URL + "/proxy/";

class PiaApi {
  getBackEndVersion() {
    let url = SERVICES_URL + "/actuator/info";
    return axios.get(url).then(response => response.data);
  }

  /**
   * ADMIN
   */

  getFangraphPlayers() {
    let url = SERVICES_URL + "/admin/fangraph-players";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  saveFangraphPlayers(players) {
    let url = SERVICES_URL + "/admin/fangraph-players";
    return axios.post(url, players).then(response => (response.data ? response.data.payload : null));
  }

  /**
   * BATCH
   */

  loadPlayerCache(playerIds) {
    let url = SERVICES_URL + "/batch/player-cache";
    return axios.post(url, playerIds).then(response => (response.data ? response.data.payload : null));
  }

  loadContractDetails(playerIds) {
    let url = SERVICES_URL + "/batch/contract-details";
    return axios.post(url, playerIds).then(response => (response.data ? response.data.payload : null));
  }

  syncStats() {
    let url = SERVICES_URL + "/batch/stat-sync";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  /**
   * COMP FINDER
   */

  loadCompFinder(compFinderId) {
    let url = SERVICES_URL + "/comp-finder/" + compFinderId;
    return axios.get(url).then(response => response.data);
  }

  getCompFinderUserGuideUrl() {
    let url = SERVICES_URL + "/comp-finder/user-guide";
    return axios.get(url).then(response => response.data);
  }

  findCfComps(compFinder, homePlateFlg) {
    let url = SERVICES_URL + "/comp-finder/find/" + homePlateFlg;
    return axios.post(url, compFinder).then(response => (response.data ? response.data.payload : null));
  }

  exportCfComps(compFinder) {
    let url = SERVICES_URL + "/comp-finder/export/";
    return axios.post(url, compFinder, { responseType: "blob" }).then(response => response.data);
  }

  getInitialSavedSearches() {
    let url = SERVICES_URL + "/comp-finder/saved-searches/init/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  saveSearch(compFinder, overrideFlag = false) {
    let url = SERVICES_URL + "/comp-finder/save/";
    return axios.post(url, compFinder, { params: { overrideFlag: overrideFlag } }).then(response => response.data);
  }

  findSavedSearches(search) {
    let url = SERVICES_URL + "/comp-finder/saved-searches/";
    return axios.post(url, search).then(response => (response.data ? response.data.payload : null));
  }

  deleteSavedSearches(searchIds) {
    let url = SERVICES_URL + "/comp-finder/saved-searches/delete/";
    return axios.post(url, searchIds).then(response => (response.data ? response.data.payload : null));
  }

  getPositionWeights() {
    let url = SERVICES_URL + "/comp-finder/weight/position/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getReliefWeights() {
    let url = SERVICES_URL + "/comp-finder/weight/relief/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getStartingWeights() {
    let url = SERVICES_URL + "/comp-finder/weight/starting/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  addStatWeight(weight) {
    let url = SERVICES_URL + "/comp-finder/weight/stat/";
    return axios.post(url, weight).then(response => (response.data ? response.data.payload : null));
  }

  updateStatWeight(weight) {
    let url = SERVICES_URL + "/comp-finder/weight/stat/update";
    return axios.post(url, weight).then(response => (response.data ? response.data.payload : null));
  }

  deleteStatWeight(weight) {
    let url = SERVICES_URL + "/comp-finder/weight/stat/delete";
    return axios.post(url, weight).then(response => (response.data ? response.data.payload : null));
  }

  addConditionalWeight(weight) {
    let url = SERVICES_URL + "/comp-finder/weight/conditional/";
    return axios.post(url, weight).then(response => (response.data ? response.data.payload : null));
  }

  updateConditionalWeight(weight) {
    let url = SERVICES_URL + "/comp-finder/weight/conditional/update";
    return axios.post(url, weight).then(response => (response.data ? response.data.payload : null));
  }

  deleteConditionalWeight(weight) {
    let url = SERVICES_URL + "/comp-finder/weight/conditional/delete";
    return axios.post(url, weight).then(response => (response.data ? response.data.payload : null));
  }

  updatePyvcWeight(weight) {
    let url = SERVICES_URL + "/comp-finder/weight/pyvc/update";
    return axios.post(url, weight).then(response => (response.data ? response.data.payload : null));
  }

  /**
   * COMPARISONS
   */

  getDefaultComparisons(limitResultsFlag) {
    let url = SERVICES_URL + "/comparison/default/" + limitResultsFlag;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  findComparisons(search) {
    let url = SERVICES_URL + "/comparison/search/";
    return axios.post(url, search).then(response => (response.data ? response.data.payload : null));
  }

  moveComparisons(folderId, ids) {
    let url = SERVICES_URL + "/comparison/move/" + folderId;
    return axios.post(url, ids).then(response => (response.data ? response.data.payload : null));
  }

  deleteComparisons(ids) {
    let url = SERVICES_URL + "/comparison/delete/";
    return axios.post(url, ids).then(response => (response.data ? response.data.payload : null));
  }

  getComparisonFolders() {
    let url = SERVICES_URL + "/comparison/folder/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  createComparisonFolder(name) {
    let url = SERVICES_URL + "/comparison/folder/create/" + name;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  deleteComparisonFolder(folder, overrideFlag = false) {
    let url = SERVICES_URL + "/comparison/folder/delete/";
    return axios
      .post(url, folder, { params: { overrideFlag: overrideFlag } })
      .then(response => (response.data ? response.data.payload : null));
  }

  loadComparison(id) {
    let url = SERVICES_URL + "/comparison/" + id;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  saveComparison(comp, overrideFlag = false) {
    let url = SERVICES_URL + "/comparison/" + (comp.comparisonId || 0) + "/save";
    return axios.post(url, comp, { params: { overrideFlag: overrideFlag } }).then(response => response.data);
  }

  getComparisonTimeFrameDetailTemplate() {
    let url = SERVICES_URL + "/comparison/time-frame/detail/template/";
    return axios.get(url).then(response => response.data);
  }

  validateComparisonTimeFrameDetail(tfDetail) {
    let url = SERVICES_URL + "/comparison/time-frame/detail/validate/";
    return axios.post(url, tfDetail).then(response => response.data);
  }

  saveComparisonTimeFrame(tfMaster, tab) {
    let url = SERVICES_URL + "/comparison/time-frame/save";
    return axios
      .post(url, tfMaster, { params: { tab: tab } })
      .then(response => (response.data ? response.data.payload : null));
  }

  deleteComparisonTimeFrame(tfMaster) {
    let url = SERVICES_URL + "/comparison/time-frame/delete";
    return axios.post(url, tfMaster).then(response => (response.data ? response.data.payload : null));
  }

  resetTfDefaults(currentTab) {
    let url = SERVICES_URL + "/comparison/time-frame/" + currentTab + "/reset-default";
    return axios.post(url).then(response => (response.data ? response.data.payload : null));
  }

  findComparisonPlayerSubstitute(originalPlayer, subPlayerId, year) {
    let url = SERVICES_URL + "/comparison/player-sub/find";
    return axios
      .post(url, originalPlayer, { params: { subPlayerId: subPlayerId, year: year } })
      .then(response => (response.data ? response.data.payload : null));
  }

  addCompFinderToComparison(converterBean) {
    let url = SERVICES_URL + "/comparison/convertFromCompFinder";
    return axios.post(url, converterBean).then(response => (response.data ? response.data.payload : null));
  }

  /**
   * EXPORT
   */

  exportProp(parent, prop) {
    let url = SERVICES_URL + "/excel/export/prop/" + prop;
    return axios.post(url, parent, { responseType: "blob" }).then(response => response.data);
  }

  exportList(list) {
    let url = SERVICES_URL + "/excel/export/list/";
    return axios.post(url, list, { responseType: "blob" }).then(response => response.data);
  }

  /**
   * LOGIN/LOGOUT
   */

  postlogin() {
    let url = SERVICES_URL + "/post-login/";
    return axios.post(url, {}).then(response => response.data);
  }

  logout() {
    let url = SERVICES_URL + "/logout/";
    return axios.post(url, {}).then(response => response.data);
  }

  /**
   * HOME PLATE
   */

  getHomePlatePlayer(playerId, year) {
    let url = SERVICES_URL + "/home-plate/player/" + playerId + "/" + year;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  createOneOnOneComp(options) {
    let url = SERVICES_URL + "/home-plate/one-on-one/create";
    return axios.post(url, options).then(response => response.data);
  }

  getLeagueLeaders(event) {
    let url = SERVICES_URL + "/home-plate/league-leaders/";
    return axios.post(url, event).then(response => (response.data ? response.data.payload : null));
  }

  /**
   * LOOKUPS
   */

  getUserLookups() {
    let url = SERVICES_URL + "/lookups/user";
    return axios.get(url).then(response => response.data);
  }

  getLookup(lookup) {
    // TODO:
    return new Promise(function(resolve, reject) {
      resolve([]);
    });
  }

  loadAutocompletes() {
    let url = SERVICES_URL + "/lookups/autocompletes/load";
    return axios.get(url).then(response => response.data);
  }

  getCollections() {
    let url = SERVICES_URL + "/lookups/cached-collections";
    return axios.get(url).then(response => response.data);
  }

  loadCollections() {
    let url = SERVICES_URL + "/lookups/cached-collections/load";
    return axios.get(url).then(response => response.data);
  }

  getSystemUsers() {
    let url = SERVICES_URL + "/lookups/system-users";
    return axios.get(url).then(response => response.data);
  }

  getUniverseObjects() {
    let url = SERVICES_URL + "/lookups/universe-objects";
    return axios.get(url).then(response => response.data);
  }

  loadUniverseObjects() {
    let url = SERVICES_URL + "/lookups/universe-objects/load";
    return axios.get(url).then(response => response.data);
  }

  /**
   * NEWS FEEDS
   */

  getNewsFeeds() {
    let url = SERVICES_URL + "/news-feed/";
    return axios.get(url).then(response => response.data);
  }

  getSuggestedNewsFeeds() {
    let url = SERVICES_URL + "/news-feed/suggested";
    return axios.get(url).then(response => response.data);
  }

  saveNewsFeeds(feeds) {
    let url = SERVICES_URL + "/news-feed/save/";
    return axios.post(url, feeds).then(response => (response.data ? response.data.payload : null));
  }

  /**
   * PLAYERS
   */

  getPlayer(playerId, ignoreCache = false) {
    let url = SERVICES_URL + "/player/" + playerId;
    return axios
      .get(url, { params: { ignoreCache: ignoreCache } })
      .then(response => (response.data ? response.data.payload : null));
  }

  getPlayerAgent(agentName) {
    let url = SERVICES_URL + "/player/agent/" + agentName;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  switchPlayerStatLine(player, name, type) {
    let url = SERVICES_URL + "/player/stat-line/" + name;
    return axios
      .post(url, player, { params: { type: type } })
      .then(response => (response.data ? response.data.payload : null));
  }

  getPlayerStatLineDetailFromStat(stat, type) {
    let url = SERVICES_URL + "/player/stat-line/detail/" + type;
    return axios.post(url, stat).then(response => response.data);
  }

  savePlayerStatLine(statLine) {
    let url = SERVICES_URL + "/player/stat-line/";
    return axios.post(url, statLine).then(response => (response.data ? response.data.payload : null));
  }

  getCombinedPlayerStats(player, type, splitType, fromYear, toYear) {
    let url = SERVICES_URL + "/player/stat-line/combine/" + type;
    return axios
      .post(url, player, { params: { splitType: splitType, fromYear: fromYear, toYear: toYear } })
      .then(response => response.data);
  }

  getPlayerContractDetails(playerId, contractNumber, forceLoad = false) {
    let url = SERVICES_URL + "/player/" + playerId + "/contract/" + contractNumber;
    return axios
      .get(url, { params: { forceLoad: forceLoad } })
      .then(response => (response.data ? response.data.payload : null));
  }

  getPlayerCompSchedule(contractNumber, contractYear) {
    let url = SERVICES_URL + "/player/contract/" + contractNumber + "/comp-schedule/" + contractYear;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerContractSplits(contractNumber, contractYear) {
    let url = SERVICES_URL + "/player/contract/" + contractNumber + "/splits/" + contractYear;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerSigningBonusSchedule(contractNumber, contractYear) {
    let url = SERVICES_URL + "/player/contract/" + contractNumber + "/signing-bonus-schedule/" + contractYear;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerBuyoutSchedule(contractNumber, optionId) {
    let url = SERVICES_URL + "/player/contract/" + contractNumber + "/buyout-schedule/" + optionId;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerMnContractDetails(playerId, contractNumber) {
    let url = SERVICES_URL + "/player/" + playerId + "/mn-contract/" + contractNumber;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerGameLog(player, year, type) {
    let url = SERVICES_URL + "/player/game-log/" + year + "/" + type;
    return axios.post(url, player).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerGameLogDetail(player, gameId, gameDateAsString) {
    let url = SERVICES_URL + "/player/game-log/game/" + gameId;
    return axios
      .post(url, player, { params: { gameDateAsString: gameDateAsString } })
      .then(response => (response.data ? response.data.payload : null));
  }

  getPlayerGameLogPitchByPitchPitcher(player, gameId) {
    let url = SERVICES_URL + "/player/game-log/game/" + gameId + "/pitch-by-pitch/pitcher";
    return axios.post(url, player).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerGameLogPitchByPitchBatter(player, gameId) {
    let url = SERVICES_URL + "/player/game-log/game/" + gameId + "/pitch-by-pitch/batter";
    return axios.post(url, player).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerSplitStatLines(event) {
    let url = SERVICES_URL + "/player/stat-line/split";
    return axios.post(url, event).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerTransactionDetails(id, code) {
    let url = SERVICES_URL + "/player/tx/" + id + "/details/" + code;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getAutocompletePlayers() {
    let url = SERVICES_URL + "/player/autocomplete/";
    return axios.get(url).then(response => response.data);
  }

  findPlayers(search) {
    let url = SERVICES_URL + "/player/search/";
    return axios.post(url, search).then(response => (response.data ? response.data.payload : null));
  }

  getStandardPlayerLists() {
    let url = SERVICES_URL + "/player/lists/standard";
    return axios.get(url).then(response => response.data);
  }

  getDefaultPlayerLists(limitResultsFlag = true, sortByNameFlag = false) {
    let url = SERVICES_URL + "/player/lists/default/" + limitResultsFlag;
    return axios
      .get(url, { params: { sortByNameFlag: sortByNameFlag } })
      .then(response => (response.data ? response.data.payload : null));
  }

  findPlayerLists(search) {
    let url = SERVICES_URL + "/player/lists/";
    return axios.post(url, search).then(response => (response.data ? response.data.payload : null));
  }

  getPlayersForList(criteria, searchType) {
    let url = SERVICES_URL + "/player/lists/criteria/" + searchType;
    return axios.post(url, criteria).then(response => (response.data ? response.data.payload : null));
  }

  convertCompFinderToPlayerList(converterBean) {
    let url = SERVICES_URL + "/player/plList/convertFromCompFinder/";
    return axios.post(url, converterBean).then(response => (response.data ? response.data.payload : null));
  }

  deletePlayerLists(ids) {
    let url = SERVICES_URL + "/player/lists/delete/";
    return axios.post(url, ids).then(response => (response.data ? response.data.payload : null));
  }

  getPlayerList(id) {
    let url = SERVICES_URL + "/player/lists/" + id;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  parsePlayerList(formData, playerImportHeadingsFlg = false) {
    let url = SERVICES_URL + "/player/lists/parse";
    return axios
      .post(url, formData, { params: { playerImportHeadingsFlg: playerImportHeadingsFlg } })
      .then(response => (response.data ? response.data.payload : null));
  }

  importPlayerList(importBean) {
    let url = SERVICES_URL + "/player/lists/import";
    return axios.post(url, importBean).then(response => (response.data ? response.data.payload : null));
  }

  savePlayerList(plMaster, overrideFlag = false) {
    let url = SERVICES_URL + "/player/lists/" + (plMaster.playerlistId || 0) + "/save";
    return axios.post(url, plMaster, { params: { overrideFlag: overrideFlag } }).then(response => response.data);
  }

  refreshPlayerListPlayerInfo(plMaster) {
    let url = SERVICES_URL + "/player/lists/refresh/";
    return axios.post(url, plMaster).then(response => (response.data ? response.data.payload : null));
  }

  getDefaultPlayerQueries(limitResultsFlag = true, sortByNameFlag = false) {
    let url = SERVICES_URL + "/player/queries/default/" + limitResultsFlag;
    return axios
      .get(url, { params: { sortByNameFlag: sortByNameFlag } })
      .then(response => (response.data ? response.data.payload : null));
  }

  getPlayerQuery(id) {
    let url = SERVICES_URL + "/player/queries/" + id;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  findPlayerQueries(search) {
    let url = SERVICES_URL + "/player/queries/";
    return axios.post(url, search).then(response => (response.data ? response.data.payload : null));
  }

  savePlayerQuery(criteria, overrideFlag = false) {
    let url = SERVICES_URL + "/player/queries/" + (criteria.queryId || 0) + "/save";
    return axios.post(url, criteria, { params: { overrideFlag: overrideFlag } }).then(response => response.data);
  }

  deletePlayerQueries(ids) {
    let url = SERVICES_URL + "/player/queries/delete/";
    return axios.post(url, ids).then(response => (response.data ? response.data.payload : null));
  }

  /**
   * PROXY
   */
  get proxyUrl() {
    return PROXY_URL;
  }

  /**
   * REPORTS
   */

  getAdHocReport(template) {
    let url = SERVICES_URL + "/report/ad-hoc/" + template;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getCannedReports() {
    let url = SERVICES_URL + "/report/canned/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getSavedReports() {
    let url = SERVICES_URL + "/report/saved";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  moveReports(folderId, reports) {
    let url = SERVICES_URL + "/report/move/" + folderId;
    return axios.post(url, reports).then(response => (response.data ? response.data.payload : null));
  }

  deleteReports(reports) {
    let url = SERVICES_URL + "/report/delete/";
    return axios.post(url, reports).then(response => (response.data ? response.data.payload : null));
  }

  getReportFolders() {
    let url = SERVICES_URL + "/report/folder/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  createReportFolder(name) {
    let url = SERVICES_URL + "/report/folder/create/" + name;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  deleteReportFolder(folder, overrideFlag = false) {
    let url = SERVICES_URL + "/report/folder/delete/";
    return axios
      .post(url, folder, { params: { overrideFlag: overrideFlag } })
      .then(response => (response.data ? response.data.payload : null));
  }

  getHelpfulLinks() {
    let url = SERVICES_URL + "/report/helpful-links/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  validateReport(type, comp) {
    let url = SERVICES_URL + "/report/validate/";
    return axios.post(url, comp, { params: { type: type } }).then(response => response.data);
  }

  initReport(type, options, quick = false, fromProfile = false) {
    let url = SERVICES_URL + "/report/init/";
    return axios
      .post(url, options, { params: { type: type, quick: quick, fromProfile: fromProfile } })
      .then(response => response.data);
  }

  runReport(reportName, type, options, quick, fromProfile) {
    let url = SERVICES_URL + "/report/run/";
    return axios
      .post(url, options, { params: { reportName: reportName, type: type, quick: quick, fromProfile: fromProfile } })
      .then(response => (response.data ? response.data.payload : null));
  }

  cancelReport(reportName) {
    let url = SERVICES_URL + "/report/cancel/";
    return axios.get(url, { params: { reportName: reportName } }).then(response => response.data);
  }

  /**
   * SHARING
   */

  updateStandardComps(ids) {
    let url = SERVICES_URL + "/sharing/comp/standard/";
    return axios.post(url, ids).then(response => (response.data ? response.data.payload : null));
  }

  shareComps(sharing) {
    let url = SERVICES_URL + "/sharing/comp/";
    return axios.post(url, sharing).then(response => (response.data ? response.data.payload : null));
  }

  updateStandardPlayerLists(ids) {
    let url = SERVICES_URL + "/sharing/playerlist/standard/";
    return axios.post(url, ids).then(response => (response.data ? response.data.payload : null));
  }

  sharePlayerLists(sharing) {
    let url = SERVICES_URL + "/sharing/playerlist/";
    return axios.post(url, sharing).then(response => (response.data ? response.data.payload : null));
  }

  updateStandardStatLists(ids) {
    let url = SERVICES_URL + "/sharing/statlist/standard/";
    return axios.post(url, ids).then(response => (response.data ? response.data.payload : null));
  }

  shareStatLists(sharing) {
    let url = SERVICES_URL + "/sharing/statlist/";
    return axios.post(url, sharing).then(response => (response.data ? response.data.payload : null));
  }

  shareStats(sharing) {
    let url = SERVICES_URL + "/sharing/stat/";
    return axios.post(url, sharing).then(response => (response.data ? response.data.payload : null));
  }

  sharePlayerQueries(sharing) {
    let url = SERVICES_URL + "/sharing/playerquery/";
    return axios.post(url, sharing).then(response => (response.data ? response.data.payload : null));
  }

  shareReports(sharing) {
    let url = SERVICES_URL + "/sharing/report/";
    return axios.post(url, sharing).then(response => (response.data ? response.data.payload : null));
  }

  getSharingHistoryWithOthers() {
    let url = SERVICES_URL + "/sharing/history/others/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getSharingHistoryWithUser() {
    let url = SERVICES_URL + "/sharing/history/user/";
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  /**
   * STATS
   */

  getStat(id) {
    let url = SERVICES_URL + "/stat/" + id;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getAutocompleteStats() {
    let url = SERVICES_URL + "/stat/autocomplete/";
    return axios.get(url).then(response => response.data);
  }

  saveStat(stat, overrideFlag) {
    let url = SERVICES_URL + "/stat/" + stat.statId;
    return axios
      .post(url, stat, { params: { overrideFlag: overrideFlag } })
      .then(response => (response.data ? response.data : null));
  }

  findStats(search, defaultFlag) {
    let url = SERVICES_URL + "/stat/search/" + defaultFlag;
    return axios.post(url, search).then(response => (response.data ? response.data.payload : null));
  }

  findStatsByName(search) {
    let url = SERVICES_URL + "/stat/search-by-name/";
    return axios.post(url, search).then(response => (response.data ? response.data.payload : null));
  }

  deleteStats(stats) {
    let url = SERVICES_URL + "/stat/delete/";
    return axios.post(url, stats).then(response => (response.data ? response.data.payload : null));
  }

  getDefaultStatLists(limitResultsFlag, sortByNameFlag = false) {
    let url = SERVICES_URL + "/stat/lists/default/" + limitResultsFlag;
    return axios
      .get(url, { params: { sortByNameFlag: sortByNameFlag } })
      .then(response => (response.data ? response.data.payload : null));
  }

  findStatLists(search) {
    let url = SERVICES_URL + "/stat/lists/";
    return axios.post(url, search).then(response => (response.data ? response.data.payload : null));
  }

  deleteStatLists(ids) {
    let url = SERVICES_URL + "/stat/lists/delete/";
    return axios.post(url, ids).then(response => (response.data ? response.data.payload : null));
  }

  getStatList(id) {
    let url = SERVICES_URL + "/stat/lists/" + id;
    return axios.get(url).then(response => (response.data ? response.data.payload : null));
  }

  getStatFromResult(result) {
    let url = SERVICES_URL + "/stat/lists/stat/";
    return axios.post(url, result).then(response => response.data);
  }

  getStatsFromResultList(results) {
    let url = SERVICES_URL + "/stat/lists/stats/";
    return axios.post(url, results).then(response => response.data);
  }

  saveStatList(slMaster, overrideFlag = false) {
    let url = SERVICES_URL + "/stat/lists/" + (slMaster.statlistId || 0) + "/save";
    return axios.post(url, slMaster, { params: { overrideFlag: overrideFlag } }).then(response => response.data);
  }

  keepBoAlive() {
    let url = SERVICES_URL + "/bo/keepalive";
    return axios.get(url).then(response => response.data);
  }
}

export default PiaApi;
