import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { CardContainer, FormInput } from "best-common-react";

class Comments extends React.Component {
  render() {
    let { compStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <CardContainer>
          <CardContainer.Body>
            <div className="container-fluid">
              <div className="row mx-0 my-2">
                <div className="col-12 p-0">
                  <FormInput
                    name="cmpComments"
                    type="textarea"
                    onChangeFn={event => {
                      compStore.comp.comments = event.target.value;
                    }}
                    value={compStore.comp.comments}
                    editable
                  />
                </div>
              </div>
            </div>
          </CardContainer.Body>
          <CardContainer.Footer></CardContainer.Footer>
        </CardContainer>
      </React.Fragment>
    );
  }
}

Comments.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(Comments));
