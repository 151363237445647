import React from "react";
import { action, autorun, extendObservable } from "mobx";
import _ from "lodash";
import Moment from "moment";
import { DateTimeFormatter } from "best-common-react";
import { AlertConstants } from "../../constants/AlertConstants";
import { PlayerConstants } from "../../constants/PlayerConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import TableHelper from "../../components/utilities/TableHelper";
import WidgetStore from "../WidgetStore";

class PlayerStore extends WidgetStore {
  constructor(authStore, routerStore, alertStore, lookupStore, commonStore, piaApi) {
    super();
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.alertStore = alertStore;
    this.lookupStore = lookupStore;
    this.commonStore = commonStore;
    this.piaApi = piaApi;

    this.getAutocompleteText = this.getAutocompleteText.bind(this);
    this.filterBySearchTerm = this.filterBySearchTerm.bind(this);

    this.tableHelper = new TableHelper();

    this.defaults = {
      playerSearch: {
        playerId: null,
        fullname: "",
        currentPlayersOnlyFlg: true,
        positionPrimary: "1B,2B,SS,3B,C,LF,RF,CF,SP,RP",
        positionSecondary: "1B,2B,SS,3B,C,LF,RF,CF,SP,RP",
        clubLk: "",
        mlsFromYears: "0",
        mlsFromDays: "0",
        mlsToYears: "32",
        mlsToDays: "0"
      },
      listSearch: {
        playerId: 0,
        fullname: "",
        playerListName: "",
        lastUpdatedFromDte: Moment(new Date())
          .subtract(1, "years")
          .format("MM/DD/YYYY")
          .toString(),
        lastUpdatedThroughDte: Moment(new Date())
          .format("MM/DD/YYYY")
          .toString()
      },
      querySearch: {
        queryName: "",
        queryType: "BOTH",
        lastUpdatedFromDte: Moment(new Date())
          .subtract(1, "years")
          .format("MM/DD/YYYY")
          .toString(),
        lastUpdatedThroughDte: Moment(new Date())
          .format("MM/DD/YYYY")
          .toString()
      },
      playerResults: [],
      playerListResults: [],
      standardPlayerListIds: [],
      selectedPlayerLists: [],
      queryResults: [],
      selectedQueries: [],
      player: {
        playerId: 0,
        playerStatsBean: {
          selectedSeason: 0
        },
        contractDetailsAndProvisions: {
          contractDetails: {}
        },
        mnContractDetails: {}
      },
      agent: {},
      playerStatSplitType: PlayerConstants.STAT_SPLIT_TYPES.CAREER,
      statLineModalOpen: false,
      modalStatLine: {
        userStatlineDetails: []
      },
      combinedStatsModalOpen: false,
      modalCombinedData: { playerName: "", type: "", fromYear: 0, toYear: 0, list: [] },
      profileContractTypeId: 0,
      profileArbitrationDetails: {},
      gameLogSplit: "Regular Season",
      statSplitCriteria: {
        statsplit1: "",
        statsplit2: "",
        statsplit3: "",
        statsplit4: "",
        statsplit5: "",
        statsplitTimeframeType: "Single Year",
        statsplitTimeframeSingleYear: Moment(new Date())
          .subtract(1, "years")
          .year()
          .toString(),
        statsplitTimeframeYear1: Moment(new Date())
          .subtract(1, "years")
          .year()
          .toString(),
        statsplitTimeframeYear2: Moment(new Date())
          .subtract(1, "years")
          .year()
          .toString()
      },
      transactionFilterYear: "All",
      transactionFilterType: "All",
      modalTxDetails: null,
      awardFilterYear: "All",
      awardFilterName: "All",
      reportCriteria: {
        grouping: "Totals",
        prorateType: "prorate",
        timeframeType: "Single Year",
        timeframeSingleYear: Moment(new Date())
          .year()
          .toString(),
        timeframeFromYear: Moment(new Date())
          .subtract(1, "years")
          .year()
          .toString(),
        timeframeFromDate: Moment(new Date())
          .subtract(1, "years")
          .toDate(),
        timeframeToYear: Moment(new Date())
          .year()
          .toString(),
        timeframeToDate: Moment(new Date()).toDate(),
        stats: [],
        selectedSplitCategory: {
          splits: []
        },
        splits: [{ splitCategory: "Part of Season", splitName: "Regular Season" }]
      },
      playerList: {
        className: "com.mlb.piaplus.db.PlMaster",
        playerlistname: "Untitled Player List",
        comparisononlyFlg: false,
        displayFirstContract: false,
        displayPyplusone: true,
        displaySalaryType: "LRD Salary",
        avplFlg: false,
        plDetails: []
      },
      playerListDetailSortColumn: ["playername"],
      playerListDetailSortDirection: "ASC",
      listCriteria: {
        className: "com.mlb.piaplus.player.model.PlayerListCriteriaBean",
        avplFlg: false,
        focusplayerName: "",
        focusplayerYear: Moment(new Date()).year(),
        positionFocusFlg: false,
        positionFocusDtl: "P",
        positionCustomFlg: true,
        positionCustomDtl: "PS",
        positions: [],
        on40ManRosterOfTeam: "",
        mlsFocusCustomFlg: "C",
        mlsFocusDtl: "sfa",
        mlsCustomFromYears: "0",
        mlsCustomFromDays: "0",
        mlsCustomToYears: "32",
        mlsCustomToDays: "171",
        timeframeType: "SIN",
        timeframeSingleYear: Moment(new Date()).year(),
        timeframeSingleSeason: "1",
        timeframeSinglePlatformYear: "0",
        timeframeAnyyearFromYear: Moment(new Date())
          .subtract(1, "years")
          .year(),
        timeframeAnyyearFromSeason: "1",
        timeframeAnyyearFromPlatformYear: "0",
        timeframeAnyyearToYear: Moment(new Date()).year(),
        timeframeAnyyearToSeason: "2",
        timeframeAnyyearToPlatformYear: "0",
        timeframeEachyearFromYear: Moment(new Date())
          .subtract(1, "years")
          .year(),
        timeframeEachyearFromSeason: "1",
        timeframeEachyearFromPlatformYear: "0",
        timeframeEachyearToYear: Moment(new Date()).year(),
        timeframeEachyearToSeason: "2",
        timeframeEachyearToPlatformYear: "0",
        timeframeCumulativeFromYear: Moment(new Date())
          .subtract(1, "years")
          .year(),
        timeframeCumulativeFromSeason: "1",
        timeframeCumulativeFromPlatformYear: "0",
        timeframeCumulativeToYear: Moment(new Date()).year(),
        timeframeCumulativeToSeason: "2",
        timeframeCumulativeToPlatformYear: "0",
        timeframeInstancesFromYear: Moment(new Date())
          .subtract(1, "years")
          .year(),
        timeframeInstancesFromSeason: "1",
        timeframeInstancesFromPlatformYear: "0",
        timeframeInstancesToYear: Moment(new Date()).year(),
        timeframeInstancesToSeason: "2",
        timeframeInstancesToPlatformYear: "0",
        timeframeCareerToYear: Moment(new Date()).year(),
        timeframeCareerToSeason: "1",
        timeframeCareerToPlatformYear: "0",
        timeframeEachYearCareerToYear: Moment(new Date()).year(),
        timeframeEachYearCareerToSeason: "1",
        timeframeEachYearCareerToPlatformYear: "0",
        timeframeCumulativeFromDate: Moment(new Date())
          .subtract(1, "years")
          .toDate(),
        timeframeCumulativeToDate: Moment(new Date()).toDate(),
        timeframeInstances: 2,
        useNextYearForContractCriteria: true,
        mlsInPlatformYearOnly: false,
        instanceCheckbox: false,
        firstInstanceOnly: true,
        playingInLeague: "ANY",
        playingOnTeam: "ANY",
        enterCriteriaFlg: "Yes",
        criteriaForQuery: [],
        displayFirstContract: false,
        displayPyplusone: true,
        displaySalaryType: "LRD Salary",
        deferContractCriteria: false,
        selectedSplitCategory: {
          splits: []
        },
        splits: [],
        existingList: {
          className: "com.mlb.piaplus.db.PlMaster",
          plDetails: []
        }
      },
      playerNameError: false
    };

    extendObservable(this, {
      playerSearch: this.defaults["playerSearch"],
      listSearch: this.defaults["listSearch"],
      querySearch: this.defaults["querySearch"],
      playerResults: this.defaults["playerResults"],
      playerListResults: this.defaults["playerListResults"],
      standardPlayerListIds: this.defaults["standardPlayerListIds"],
      selectedPlayerLists: this.defaults["selectedPlayerLists"],
      queryResults: this.defaults["queryResults"],
      selectedQueries: this.defaults["selectedQueries"],
      player: this.defaults["player"],
      agent: this.defaults["agent"],
      playerStatSplitType: this.defaults["playerStatSplitType"],
      statLineModalOpen: this.defaults["statLineModalOpen"],
      modalStatLine: this.defaults["modalStatLine"],
      combinedStatsModalOpen: this.defaults["combinedStatsModalOpen"],
      modalCombinedData: this.defaults["modalCombinedData"],
      profileContractTypeId: this.defaults["profileContractTypeId"],
      profileArbitrationDetails: this.defaults["profileArbitrationDetails"],
      gameLogSplit: this.defaults["gameLogSplit"],
      statSplitCriteria: this.defaults["statSplitCriteria"],
      transactionFilterYear: this.defaults["transactionFilterYear"],
      transactionFilterType: this.defaults["transactionFilterType"],
      modalTxDetails: this.defaults["modalTxDetails"],
      awardFilterYear: this.defaults["awardFilterYear"],
      awardFilterName: this.defaults["awardFilterName"],
      reportCriteria: this.defaults["reportCriteria"],
      playerList: this.defaults["playerList"],
      listCriteria: this.defaults["listCriteria"],
      playerNameError: this.defaults["playerNameError"],
      resetStore: action(() => {
        this.playerSearch = this.defaults["playerSearch"];
        this.listSearch = this.defaults["listSearch"];
        this.playerResults = this.defaults["playerResults"];
        this.playerListResults = this.defaults["playerListResults"];
        this.standardPlayerListIds = this.defaults["standardPlayerListIds"];
        this.selectedPlayerLists = this.defaults["selectedPlayerLists"];
        this.queryResults = this.defaults["queryResults"];
        this.selectedQueries = this.defaults["selectedQueries"];
        this.player = this.defaults["player"];
        this.agent = this.defaults["agent"];
        this.playerStatSplitType = this.defaults["playerStatSplitType"];
        this.statLineModalOpen = this.defaults["statLineModalOpen"];
        this.modalStatLine = this.defaults["modalStatLine"];
        this.combinedStatsModalOpen = this.defaults["combinedStatsModalOpen"];
        this.modalCombinedData = this.defaults["modalCombinedData"];
        this.profileContractTypeId = this.defaults["profileContractTypeId"];
        this.profileArbitrationDetails = this.defaults["profileArbitrationDetails"];
        this.gameLogSplit = this.defaults["gameLogSplit"];
        this.statSplitCriteria = this.defaults["statSplitCriteria"];
        this.transactionFilterYear = this.defaults["transactionFilterYear"];
        this.transactionFilterType = this.defaults["transactionFilterType"];
        this.modalTxDetails = this.defaults["modalTxDetails"];
        this.awardFilterYear = this.defaults["awardFilterYear"];
        this.awardFilterName = this.defaults["awardFilterName"];
        this.reportCriteria = this.defaults["reportCriteria"];
        this.playerList = this.defaults["playerList"];
        this.listCriteria = this.defaults["listCriteria"];
        this.playerNameError = this.defaults["playerNameError"];
      }),
      resetSearch: action(() => {
        this.listSearch = this.defaults["listSearch"];
      }),
      resetQuerySearch: action(() => {
        this.querySearch = this.defaults["querySearch"];
      })
    });

    autorun(() => {
      if (!this.authStore.loggedIn) {
        this.resetStore();
      } else if (this.routerStore.isPlayerListsPage) {
        this.parsePlayerListUrl();
        this.resetSearch();
      } else if (this.routerStore.isPlayerQueriesPage) {
        this.parseQueryUrl();
        this.resetQuerySearch();
      } else if (this.routerStore.isPlayersPage) {
        this.parsePlayerUrl();
      } else if (this.routerStore.isPlayerProfilePage) {
        this.parsePlayerProfileUrl();
      } else if (this.routerStore.isEditComparisonPage) {
        this.resetStore();
        this.getDefaultPlayerLists(false, true);
      } else if (this.routerStore.isSharingPage) {
        this.getDefaultPlayerLists(false);
        this.getDefaultQueries(false);
      }
    });
  }

  parsePlayerUrl() {
    const pathParams = this.routerStore.getPathParams(RouteConstants.PLAYERS.route + RouteConstants.PLAYERS.pathParams);

    if (!!pathParams["playerName"]) {
      this.playerSearch.fullname = pathParams["playerName"];
      this.findPlayers();
    }
  }

  goToPlayerSearch(playerName) {
    this.resetStore();
    this.routerStore.pushHistory(RouteConstants.PLAYERS.route + "/" + playerName, false);
  }

  findPlayers() {
    this.piaApi.findPlayers(this.playerSearch).then(response => {
      if (!!response) {
        this.playerResults = response;
      }
    });
  }

  parsePlayerListUrl() {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.PLAYER_LISTS.route + RouteConstants.PLAYER_LISTS.pathParams
    );

    if (!!pathParams["listName"]) {
      this.listSearch.playerListName = pathParams["listName"];
      this.findPlayerLists();
    } else {
      this.getDefaultPlayerLists();
    }
  }

  goToPlayerListSearch(listName) {
    this.resetStore();
    this.routerStore.pushHistory(RouteConstants.PLAYER_LISTS.route + "/" + listName, false);
  }

  getPlayerListCols() {
    return [
      {
        key: "playerlistname",
        name: "Player List Name",
        width: 300,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                this.openPlayerList(row.row.playerlistId);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "focusplayername", name: "Focus Player Name", width: 300 },
      { key: "numberofplayers", name: "# in List", width: 80 },
      { key: "source", name: "Source", width: 300 },
      {
        key: "lastchDtstmpAmericaNY",
        name: "Last Updated",
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      }
    ];
  }

  getDefaultPlayerLists(limitResultsFlag = true, sortByNameFlag) {
    this.piaApi.getDefaultPlayerLists(limitResultsFlag, sortByNameFlag).then(response => {
      if (!!response) {
        this.playerListResults = response;
        this.updateStandardPlayerListIds();
      }
    });
  }

  findPlayerLists() {
    this.selectedPlayerLists = [];
    this.piaApi.findPlayerLists(this.listSearch).then(response => {
      if (!!response) {
        this.playerListResults = response;
        this.updateStandardPlayerListIds();
      }
    });
  }

  updateStandardPlayerListIds() {
    this.standardPlayerListIds = _.filter(this.playerListResults, list => list.standardFlg).map(
      list => list.playerlistId
    );
  }

  get displayLists() {
    if (!this.playerListResults) {
      return [];
    }

    let selectedIds = this.selectedPlayerLists.map(list => list.playerlistId);

    return this.playerListResults.map(list => {
      return {
        ...list,
        isSelected: _.includes(selectedIds, list.playerlistId),
        isStandard: _.includes(this.standardPlayerListIds, list.playerlistId)
      };
    });
  }

  addSelectedLists(rows) {
    rows.forEach(row => {
      if (row.playerlistId) {
        this.selectedPlayerLists.push(row);
      }
    });
  }

  removeUnselectedLists(rows) {
    this.selectedPlayerLists = _.differenceWith(
      this.selectedPlayerLists,
      rows,
      (selected, remove) => selected.playerlistId === remove.playerlistId
    );
  }

  deletePlayerLists() {
    let selectedIds = this.selectedPlayerLists.map(list => list.playerlistId);
    this.piaApi.deletePlayerLists(selectedIds).then(response => {
      if (!!response) {
        this.resetStore();
        this.getDefaultPlayerLists();
      }
    });
  }

  updateStandardPlayerLists() {
    this.piaApi.updateStandardPlayerLists(this.standardPlayerListIds).then(response => {
      if (!!response) {
        this.getDefaultPlayerLists(false);
      }
    });
  }

  sharePlayerLists(users, comments) {
    let sharing = {
      playerLists: this.selectedPlayerLists,
      users: users,
      comments: comments
    };
    this.piaApi.sharePlayerLists(sharing).then(response => {
      if (!!response) {
        this.selectedPlayerLists = [];
        this.getDefaultPlayerLists(false);
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The player lists you selected were successfully shared."
        });
      }
    });
  }

  getPlayerList(id) {
    this.piaApi.getPlayerList(id).then(response => {
      if (!!response) {
        this.playerList = response;
        this.postLoadPlayerList();
      }
    });
  }

  setPlayerList(response) {
    this.playerList.plDetails = response;
  }

  postLoadPlayerList() {
    if (this.routerStore.location.state) {
      if (this.routerStore.location.state.comparisononlyFlg) {
        this.playerList.comparisononlyFlg = true;
      }

      this.listCriteria.focusplayerId = this.playerList.focusplayerId;
      this.listCriteria.focusplayerName = this.playerList.focusplayername || this.defaults.listCriteria.focusplayerName;
      this.listCriteria.focusplayerYear = this.playerList.focusplayerYear || this.defaults.listCriteria.focusplayerYear;

      if (this.routerStore.location.state.avplFlg) {
        this.playerList.avplFlg = true;
        this.listCriteria.avplFlg = true;
        this.listCriteria.positionCustomFlg = false;
        this.listCriteria.focusplayerYear = null;

        if (!this.playerList.playerlistId) {
          this.playerList.playerlistname = "Untitled Advanced Player List";
          this.listCriteria.focusTimeframeDropdownValues = "Years";
          this.listCriteria.focusTimeframeType = "SIN";
          this.listCriteria.focusTimeframeSingle = Moment(new Date()).year();
          this.listCriteria.focusTimeframeSingleSeason = "1";
          this.listCriteria.focusTimeframeCareer = Moment(new Date()).year();
          this.listCriteria.focusTimeframeCareerSeason = "1";
          this.listCriteria.focusTimeframeCumulativeFrom = Moment(new Date())
            .subtract(1, "years")
            .year();
          this.listCriteria.focusTimeframeCumulativeFromSeason = "1";
          this.listCriteria.focusTimeframeCumulativeTo = Moment(new Date()).year();
          this.listCriteria.focusTimeframeCumulativeToSeason = "2";
          this.listCriteria.focusTimeframeCumulativeFromDate = Moment(new Date())
            .subtract(1, "years")
            .toDate();
          this.listCriteria.focusTimeframeCumulativeToDate = Moment(new Date()).toDate();
          this.listCriteria.compTimeframeDropdownValues = "Years";
          this.listCriteria.splits = [{ splitCategory: "Part of Season", splitName: "Regular Season" }];
          this.listCriteria.criteriaForQuery = [{ selectedFlg: true, types: [], andor: "AND" }];
        }
      }

      if (this.routerStore.location.state.queryId) {
        this.getQuery(this.routerStore.location.state.queryId);
      }

      //if we came from the comp finder, send that to the conversion endpoint
      if (this.routerStore.location.state.convertFromCompFinder) {
        let cfMaster = JSON.parse(localStorage.getItem("CF_MASTER"));
        localStorage.removeItem("CF_MASTER");
        let converter = {
          compFinder: cfMaster,
          plMaster: this.playerList
        };

        this.piaApi.convertCompFinderToPlayerList(converter).then(response => this.setPlayerList(response));
      }
    }

    try {
      let details = JSON.parse(localStorage.getItem("EDIT_PLAYER_LIST_DETAILS"));

      if (details) {
        this.playerList.plDetails = details;
      }
    } catch (error) {}

    localStorage.removeItem("EDIT_PLAYER_LIST_DETAILS");
  }

  get displayPlayerListDetails() {
    if (!this.playerList || !this.playerList.plDetails) {
      return [];
    }

    return this.playerList.plDetails.map(player => {
      return {
        ...player,
        selectedoncompFlg: !!player.selectedoncompFlg ? player.selectedoncompFlg : false
      };
    });
  }

  changePlayerListDetailSort = (col, dir) => {
    this.playerListDetailSortColumn = col;
    this.playerListDetailSortDirection = dir;
    this.playerList.plDetails = this.tableHelper.sort(
      this.playerList.plDetails,
      this.playerListDetailSortColumn,
      this.playerListDetailSortDirection
    );
  };

  setFocusPlayer(player) {
    this.playerList.focusplayerId = player.playerid;
    this.playerList.focusplayername = player.playername;
    this.playerList.focusplayerYear = player.platformYear;
    this.listCriteria.focusplayerId = player.playerid;
    this.listCriteria.focusplayerName = player.playername;

    if (!this.listCriteria.avplFlg) {
      this.listCriteria.focusplayerYear = player.platformYear;
    }

    this.playerList.plDetails.forEach(pld => {
      if (pld.playerid === player.playerid) {
        pld.focusplayerFlg = !pld.focusplayerFlg;
      } else {
        pld.focusplayerFlg = false;
      }
    });
  }

  parseQueryUrl() {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.PLAYER_QUERIES.route + RouteConstants.PLAYER_QUERIES.pathParams
    );

    if (!!pathParams["queryName"]) {
      this.querySearch.queryName = pathParams["queryName"];
      this.findQueries();
    } else {
      this.getDefaultQueries();
    }
  }

  goToQuerySearch(queryName) {
    this.resetStore();
    this.routerStore.pushHistory(RouteConstants.PLAYER_QUERIES.route + "/" + queryName, false);
  }

  getQueryCols() {
    return [
      {
        key: "playerQueryName",
        name: "Player Query Name",
        width: 300,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                this.openQuery(0, row.row.queryId, row.row.avplFlg);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "searchType", name: "Search Type", width: 200 },
      { key: "source", name: "Source", width: 300 },
      {
        key: "lastchDtstmpAmericaNY",
        name: "Last Updated",
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      }
    ];
  }

  getDefaultQueries(limitResultsFlag = true, sortByNameFlag) {
    this.piaApi.getDefaultPlayerQueries(limitResultsFlag, sortByNameFlag).then(response => {
      if (!!response) {
        this.queryResults = response;
      }
    });
  }

  findQueries() {
    this.selectedQueries = [];
    this.piaApi.findPlayerQueries(this.querySearch).then(response => {
      if (!!response) {
        this.queryResults = response;
      }
    });
  }

  get displayQueries() {
    if (!this.queryResults) {
      return [];
    }

    let selectedIds = this.selectedQueries.map(query => query.queryId);

    return this.queryResults.map(query => {
      return {
        ...query,
        isSelected: _.includes(selectedIds, query.queryId)
      };
    });
  }

  addSelectedQueries(rows) {
    rows.forEach(row => {
      if (row.queryId) {
        this.selectedQueries.push(row);
      }
    });
  }

  removeUnselectedQueries(rows) {
    this.selectedQueries = _.differenceWith(
      this.selectedQueries,
      rows,
      (selected, remove) => selected.queryId === remove.queryId
    );
  }

  deleteQueries() {
    let selectedIds = this.selectedQueries.map(query => query.queryId);
    this.piaApi.deletePlayerQueries(selectedIds).then(response => {
      if (!!response) {
        this.resetStore();
        this.getDefaultQueries();
      }
    });
  }

  shareQueries(users, comments) {
    let sharing = {
      playerQueries: this.selectedQueries,
      users: users,
      comments: comments
    };
    this.piaApi.sharePlayerQueries(sharing).then(response => {
      if (!!response) {
        this.selectedQueries = [];
        this.getDefaultQueries(false);
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The player queries you selected were successfully shared."
        });
      }
    });
  }

  openQuery(playerListId, queryId, avplFlg) {
    if (
      playerListId === this.playerList.playerlistId &&
      this.playerList.plDetails &&
      this.playerList.plDetails.length
    ) {
      localStorage.setItem("EDIT_PLAYER_LIST_DETAILS", this.playerList.plDetails);
    }

    this.routerStore.pushHistory(RouteConstants.EDIT_PLAYER_LIST.route + "/" + playerListId, false, {
      queryId: queryId,
      avplFlg: avplFlg,
      comparisononlyFlg: this.playerList.comparisononlyFlg || false
    });
  }

  getQuery(id) {
    this.piaApi.getPlayerQuery(id).then(response => {
      if (response) {
        this.loadListCriteria(response);
      }
    });
  }

  loadListCriteria(criteria) {
    this.listCriteria.criteriaForQuery = null;
    criteria.existingList = this.defaults["listCriteria"].existingList;

    criteria.positions = [];
    let positions = this.getListCriteriaPositionOptions();

    positions.forEach(position => {
      if (criteria[position.value]) {
        criteria.positions.push(position);
      }
    });

    if (!criteria.playingInLeague) {
      criteria.playingInLeague = "ANY";
    }

    if (!criteria.playingOnTeam) {
      criteria.playingOnTeam = "ANY";
    }

    if (!criteria.selectedSplitCategory) {
      criteria.selectedSplitCategory = this.defaults["listCriteria"].selectedSplitCategory;
    }

    if (criteria.storedSplits && criteria.storedSplits.length > 0) {
      criteria.splits = criteria.storedSplits;
    }

    this.listCriteria = criteria;
  }

  saveQuery(overrideFlag = false) {
    this.piaApi.savePlayerQuery(this.listCriteria, overrideFlag).then(response => {
      if (response) {
        if (response.overrideMessage) {
          this.listCriteria.overrideMessage = response.overrideMessage;
          this.listCriteria.overrideFn = () => {
            this.listCriteria.overrideMessage = null;
            this.listCriteria.showOverride = false;
            this.saveQuery(true);
          };
          this.listCriteria.showOverride = true;
        } else if (response.payload) {
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Query saved successfully"
          });
          this.loadListCriteria(response.payload);
        }
      }
    });
  }

  openPlayerProfile(playerId) {
    this.agent.showAgentModal = false;
    this.routerStore.pushHistory(RouteConstants.PLAYER_PROFILE.route + "/" + playerId, false);
  }

  parsePlayerProfileUrl() {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.PLAYER_PROFILE.route + RouteConstants.PLAYER_PROFILE.pathParams
    );
    let playerId = pathParams["playerId"] || this.defaults["player"].playerId;
    playerId = playerId * 1;

    this.getPlayer(playerId);
  }

  getPlayer(playerId, ignoreCache) {
    this.profileArbitrationDetails = this.defaults["profileArbitrationDetails"];
    this.piaApi.getPlayer(playerId, ignoreCache).then(response => {
      if (response) {
        response.qvTransactions = (response.transactions || []).slice(0, 6);
        response.qvContracts = (response.contracts || []).slice(0, 6);
        response.qvAwards = (response.awards || []).slice(0, 6);
        this.player = response;
      }
    });
  }

  getAgent(agentName) {
    this.piaApi.getPlayerAgent(agentName).then(response => {
      if (response) {
        this.agent = response;
        this.agent.showAgentModal = true;
      }
    });
  }

  get displayPlayerBattingStatLine() {
    let list = [];

    if (this.player.playerStatsBean) {
      if (
        PlayerConstants.STAT_SPLIT_TYPES.CAREER === this.playerStatSplitType ||
        PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON === this.playerStatSplitType
      ) {
        if (
          this.player.playerStatsBean.battingStatline &&
          this.player.playerStatsBean.battingStatline.userStatlineDetailsNoSupportingStats
        ) {
          list = this.player.playerStatsBean.battingStatline.userStatlineDetailsNoSupportingStats;
        }
      } else if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === this.playerStatSplitType) {
        if (
          this.player.playerStatsBean.battingStatlineGameLog &&
          this.player.playerStatsBean.battingStatlineGameLog.userStatlineDetailsNoSupportingStats
        ) {
          list = this.player.playerStatsBean.battingStatlineGameLog.userStatlineDetailsNoSupportingStats;
        }
      } else if (PlayerConstants.STAT_SPLIT_TYPES.SPLITS === this.playerStatSplitType) {
        if (
          this.player.playerStatsBean.battingSplitStatline &&
          this.player.playerStatsBean.battingSplitStatline.userStatlineDetailsNoSupportingStats
        ) {
          list = _.filter(
            this.player.playerStatsBean.battingSplitStatline.userStatlineDetailsNoSupportingStats,
            stat =>
              !this.player.playerStatsBean.battingSplitStatline.excludedSplitStatsAsObjectNames ||
              !this.player.playerStatsBean.battingSplitStatline.excludedSplitStatsAsObjectNames.includes(
                stat.objectname
              )
          );
        }
      }

      if (
        !list.length &&
        this.player.playerStatsBean.battingStatlineDefault &&
        this.player.playerStatsBean.battingStatlineDefault.userStatlineDetailsNoSupportingStats
      ) {
        list = this.player.playerStatsBean.battingStatlineDefault.userStatlineDetailsNoSupportingStats;
      }
    }

    return list;
  }

  get displayPlayerPitchingStatLine() {
    let list = [];

    if (this.player.playerStatsBean) {
      if (
        PlayerConstants.STAT_SPLIT_TYPES.CAREER === this.playerStatSplitType ||
        PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON === this.playerStatSplitType
      ) {
        if (
          this.player.playerStatsBean.pitchingStatline &&
          this.player.playerStatsBean.pitchingStatline.userStatlineDetailsNoSupportingStats
        ) {
          list = this.player.playerStatsBean.pitchingStatline.userStatlineDetailsNoSupportingStats;
        }
      } else if (PlayerConstants.STAT_SPLIT_TYPES.SPLITS === this.playerStatSplitType) {
        if (
          this.player.playerStatsBean.pitchingSplitStatline &&
          this.player.playerStatsBean.pitchingSplitStatline.userStatlineDetailsNoSupportingStats
        ) {
          list = _.filter(
            this.player.playerStatsBean.pitchingSplitStatline.userStatlineDetailsNoSupportingStats,
            stat =>
              !this.player.playerStatsBean.pitchingSplitStatline.excludedSplitStatsAsObjectNames ||
              !this.player.playerStatsBean.pitchingSplitStatline.excludedSplitStatsAsObjectNames.includes(
                stat.objectname
              )
          );
        }
      } else if (this.player.reliefPitcher) {
        if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === this.playerStatSplitType) {
          if (
            this.player.playerStatsBean.reliefStatlineGameLog &&
            this.player.playerStatsBean.reliefStatlineGameLog.userStatlineDetailsNoSupportingStats
          ) {
            list = this.player.playerStatsBean.reliefStatlineGameLog.userStatlineDetailsNoSupportingStats;
          }
        } else if (
          this.player.playerStatsBean.reliefStatlineDefault &&
          this.player.playerStatsBean.reliefStatlineDefault.userStatlineDetailsNoSupportingStats
        ) {
          list = this.player.playerStatsBean.reliefStatlineDefault.userStatlineDetailsNoSupportingStats;
        }
      } else if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === this.playerStatSplitType) {
        if (
          this.player.playerStatsBean.startingStatlineGameLog &&
          this.player.playerStatsBean.startingStatlineGameLog.userStatlineDetailsNoSupportingStats
        ) {
          list = this.player.playerStatsBean.startingStatlineGameLog.userStatlineDetailsNoSupportingStats;
        }
      } else if (
        this.player.playerStatsBean.startingStatlineDefault &&
        this.player.playerStatsBean.startingStatlineDefault.userStatlineDetailsNoSupportingStats
      ) {
        list = this.player.playerStatsBean.startingStatlineDefault.userStatlineDetailsNoSupportingStats;
      }
    }

    return list;
  }

  get displayPlayerFieldingStatLine() {
    let list = [];

    if (this.player.playerStatsBean) {
      if (
        PlayerConstants.STAT_SPLIT_TYPES.CAREER === this.playerStatSplitType ||
        PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON === this.playerStatSplitType
      ) {
        if (
          this.player.playerStatsBean.fieldingStatline &&
          this.player.playerStatsBean.fieldingStatline.userStatlineDetailsNoSupportingStats
        ) {
          list = this.player.playerStatsBean.fieldingStatline.userStatlineDetailsNoSupportingStats;
        }
      } else if (this.player.catcher) {
        if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === this.playerStatSplitType) {
          if (
            this.player.playerStatsBean.catchingStatlineGameLog &&
            this.player.playerStatsBean.catchingStatlineGameLog.userStatlineDetailsNoSupportingStats
          ) {
            list = this.player.playerStatsBean.catchingStatlineGameLog.userStatlineDetailsNoSupportingStats;
          }
        } else if (
          this.player.playerStatsBean.catchingStatlineDefault &&
          this.player.playerStatsBean.catchingStatlineDefault.userStatlineDetailsNoSupportingStats
        ) {
          list = this.player.playerStatsBean.catchingStatlineDefault.userStatlineDetailsNoSupportingStats;
        }
      } else if (PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === this.playerStatSplitType) {
        if (
          this.player.playerStatsBean.fieldingStatlineGameLog &&
          this.player.playerStatsBean.fieldingStatlineGameLog.userStatlineDetailsNoSupportingStats
        ) {
          list = this.player.playerStatsBean.fieldingStatlineGameLog.userStatlineDetailsNoSupportingStats;
        }
      } else if (
        this.player.playerStatsBean.fieldingStatlineDefault &&
        this.player.playerStatsBean.fieldingStatlineDefault.userStatlineDetailsNoSupportingStats
      ) {
        list = this.player.playerStatsBean.fieldingStatlineDefault.userStatlineDetailsNoSupportingStats;
      }
    }

    return list;
  }

  switchStatLine(name, type) {
    this.piaApi.switchPlayerStatLine(this.player, name, this.playerStatSplitType).then(response => {
      if (response) {
        response["selectedSplit" + type + "Statline"] = name;
        this.player.playerStatsBean = response;
      }
    });
  }

  openStatLineModal(statLineName) {
    let statLineToEdit, statLineToEditDefault;

    // Set the proper statline for editing
    switch (statLineName) {
      case PlayerConstants.STAT_LINES.BATTING_CUSTOM:
        statLineToEdit = this.player.playerStatsBean.battingStatlineCustom;
        statLineToEditDefault = this.player.playerStatsBean.battingStatlineDefault;
        break;
      case PlayerConstants.STAT_LINES.STARTING_CUSTOM:
        statLineToEdit = this.player.playerStatsBean.startingStatlineCustom;
        statLineToEditDefault = this.player.playerStatsBean.startingStatlineDefault;
        break;
      case PlayerConstants.STAT_LINES.RELIEF_CUSTOM:
        statLineToEdit = this.player.playerStatsBean.reliefStatlineCustom;
        statLineToEditDefault = this.player.playerStatsBean.reliefStatlineDefault;
        break;
      case PlayerConstants.STAT_LINES.SWING_CUSTOM:
        statLineToEdit = this.player.playerStatsBean.swingStatlineCustom;
        statLineToEditDefault = this.player.playerStatsBean.swingStatlineDefault;
        break;
      case PlayerConstants.STAT_LINES.FIELDING_CUSTOM:
        statLineToEdit = this.player.playerStatsBean.fieldingStatlineCustom;
        statLineToEditDefault = this.player.playerStatsBean.fieldingStatlineDefault;
        break;
      case PlayerConstants.STAT_LINES.CATCHING_CUSTOM:
        statLineToEdit = this.player.playerStatsBean.catchingStatlineCustom;
        statLineToEditDefault = this.player.playerStatsBean.catchingStatlineDefault;
        break;
      default:
        break;
    }

    // If user doesn't have this type of statline yet, setup a default one
    if (statLineToEdit) {
      this.modalStatLine = _.cloneDeep(statLineToEdit);
    } else {
      this.modalStatLine = _.cloneDeep(statLineToEditDefault);
    }

    this.statLineModalOpen = true;
  }

  saveStatLine() {
    this.statLineModalOpen = false;

    let statLineName = "C_" + this.modalStatLine.statlineType;

    switch (statLineName) {
      case PlayerConstants.STAT_LINES.BATTING_CUSTOM:
        this.player.playerStatsBean.battingStatlineCustom = this.modalStatLine;
        break;
      case PlayerConstants.STAT_LINES.STARTING_CUSTOM:
        this.player.playerStatsBean.startingStatlineCustom = this.modalStatLine;
        break;
      case PlayerConstants.STAT_LINES.RELIEF_CUSTOM:
        this.player.playerStatsBean.reliefStatlineCustom = this.modalStatLine;
        break;
      case PlayerConstants.STAT_LINES.SWING_CUSTOM:
        this.player.playerStatsBean.swingStatlineCustom = this.modalStatLine;
        break;
      case PlayerConstants.STAT_LINES.FIELDING_CUSTOM:
        this.player.playerStatsBean.fieldingStatlineCustom = this.modalStatLine;
        break;
      case PlayerConstants.STAT_LINES.CATCHING_CUSTOM:
        this.player.playerStatsBean.catchingStatlineCustom = this.modalStatLine;
        break;
      default:
        break;
    }

    this.piaApi.savePlayerStatLine(this.modalStatLine).then(response => {
      if (response) {
        this.switchStatLine(statLineName);
      }
    });
  }

  getStatCols(statLine) {
    let cols = [];
    statLine.forEach((stat, index) => {
      let col = {
        key: index + "",
        name: stat.statisticshortname,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <span>{row.row.stats && row.row.stats.length > index ? row.row.stats[index].value : ""}</span>;
        },
        // eslint-disable-next-line react/display-name
        format: row => {
          return <span>{row.stats && row.stats.length > index ? row.stats[index].value : ""}</span>;
        }
      };
      cols.push(col);
    });
    return cols;
  }

  getCombinedStats(type, fromYear, toYear, fromDate, toDate) {
    this.piaApi.getCombinedPlayerStats(this.player, type, this.playerStatSplitType, fromYear, toYear).then(response => {
      if (response) {
        response[0].season = (fromDate || fromYear) + " - " + (toDate || toYear);
        response[1].season = "Average";
        response[2].season = "Per 162 Games";

        this.modalCombinedData = {
          playerName: this.player.fullName,
          type: type,
          fromYear: fromYear,
          fromDate: fromDate,
          toYear: toYear,
          toDate: toDate,
          list: response
        };

        this.combinedStatsModalOpen = true;
      }
    });
  }

  getContractDetails(contractNumber, forceLoad = false) {
    this.player.currentContract = this.player.contractMap[contractNumber];

    this.piaApi.getPlayerContractDetails(this.player.playerId, contractNumber, forceLoad).then(response => {
      if (response) {
        this.player.contractDetailsAndProvisions = response;
        this.player.currentContractDetails = response.contractDetails;
      }
    });
  }

  getMnContractDetails(contractNumber) {
    this.piaApi.getPlayerMnContractDetails(this.player.playerId, contractNumber).then(response => {
      if (response) {
        this.player.mnContractDetails = response;
      }
    });
  }

  getGameLog() {
    this.piaApi
      .getPlayerGameLog(this.player, this.player.playerStatsBean.selectedSeason, this.gameLogSplit)
      .then(response => {
        if (response) {
          this.player.playerStatsBean.battingStatlineGameLog = response.battingStatlineDefault;
          this.player.playerStatsBean.battingStatsBeansGameLog = response.battingStatsBeansGameLog;
          this.player.playerStatsBean.battingGameLogTotals = response.battingGameLogTotals;
          this.player.playerStatsBean.startingStatlineGameLog = response.startingStatlineDefault;
          this.player.playerStatsBean.reliefStatlineGameLog = response.reliefStatlineDefault;
          this.player.playerStatsBean.pitchingStatsBeansGameLog = response.pitchingStatsBeansGameLog;
          this.player.playerStatsBean.pitchingGameLogTotals = response.pitchingGameLogTotals;
          this.player.playerStatsBean.catchingStatlineGameLog = response.catchingStatlineDefault;
          this.player.playerStatsBean.fieldingStatlineGameLog = response.fieldingStatlineDefault;
          this.player.playerStatsBean.fieldingStatsBeansGameLog = response.fieldingStatsBeansGameLog;
          this.player.playerStatsBean.fieldingGameLogTotals = response.fieldingGameLogTotals;
        }
      });
  }

  getGameLogDetail(gameId, gameDateAsString, prop) {
    this.piaApi.getPlayerGameLogDetail(this.player, gameId, gameDateAsString).then(response => {
      if (response) {
        this.player.playerStatsBean.gameId = gameId;
        this.player.playerStatsBean.gameDate = response.gameDate;
        this.player.playerStatsBean.gameLogBattingStatsBeans = null;
        this.player.playerStatsBean.gameLogPitchingStatsBeans = null;
        this.player.playerStatsBean[prop] = response[prop];
      }
    });
  }

  getGameLogPitchByPitchPitcher(gameId) {
    this.piaApi.getPlayerGameLogPitchByPitchPitcher(this.player, gameId).then(response => {
      if (response) {
        this.player.playerStatsBean.pitchByPitch = true;
        this.player.playerStatsBean.gameLogPitchByPitchPitching = response;
      }
    });
  }

  getGameLogPitchByPitchBatter(gameId) {
    this.piaApi.getPlayerGameLogPitchByPitchBatter(this.player, gameId).then(response => {
      if (response) {
        this.player.playerStatsBean.pitchByPitch = true;
        this.player.playerStatsBean.gameLogPitchByPitchBatting = response;
      }
    });
  }

  getSplitStatLines(name, type) {
    let wrapper = { playerBean: this.player, statSplitCritBean: this.statSplitCriteria };

    if (name) {
      wrapper.switchToStatline = name;
    }

    this.piaApi.getPlayerSplitStatLines(wrapper).then(response => {
      if (response) {
        if (name && type) {
          response["selectedSplit" + type + "Statline"] = name;
        }

        this.player.playerStatsBean = response;
      }
    });
  }

  get displayTransactions() {
    if (!this.player.transactions) {
      return [];
    }

    return _.filter(
      this.player.transactions,
      tx =>
        ("All" === this.transactionFilterYear ||
          new Date(tx.txDate).getFullYear() + "" === this.transactionFilterYear) &&
        ("All" === this.transactionFilterType || tx.txCode === this.transactionFilterType)
    );
  }

  get displayAwards() {
    if (!this.player.awards) {
      return [];
    }

    return _.filter(
      this.player.awards,
      award =>
        ("All" === this.awardFilterYear || award.year + "" === this.awardFilterYear) &&
        ("All" === this.awardFilterName || award.awardName === this.awardFilterName)
    );
  }

  get displaySplitCategories() {
    if (!this.reportCriteria) {
      return [];
    }

    let cachedSplits = this.lookupStore.getCollection("splits");
    let addedCategories = this.reportCriteria.splits.map(split => split.splitCategory);

    return _.filter(cachedSplits, category => !_.includes(addedCategories, category.splitType)).map(category => {
      return {
        ...category,
        splits: [{ splitName: "View All" }].concat(category.splits)
      };
    });
  }

  openPlayerList(
    playerListId,
    comparisononlyFlg = false,
    avplFlg = false,
    plDetails,
    convertFromCompFinder = false,
    cfMaster
  ) {
    localStorage.setItem("EDIT_PLAYER_LIST_DETAILS", JSON.stringify(plDetails));
    localStorage.setItem("CF_MASTER", JSON.stringify(cfMaster));

    this.routerStore.pushHistory(RouteConstants.EDIT_PLAYER_LIST.route + "/" + playerListId, false, {
      comparisononlyFlg: comparisononlyFlg,
      avplFlg: avplFlg,
      convertFromCompFinder: convertFromCompFinder
    });
  }

  parseAndLoadEditPlayerListUrl() {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.EDIT_PLAYER_LIST.route + RouteConstants.EDIT_PLAYER_LIST.pathParams
    );
    let playerListId = pathParams["playerListId"] || this.defaults["playerList"].playerlistId;
    playerListId = playerListId * 1;

    if (!!playerListId) {
      this.getPlayerList(playerListId);
    } else {
      this.postLoadPlayerList();
    }

    this.getDefaultPlayerLists(false);
    this.getDefaultQueries(false, true);
  }

  getListCriteriaPositionOptions() {
    return [
      { value: "position1BFlg", label: "1B" },
      { value: "position2BFlg", label: "2B" },
      { value: "position3BFlg", label: "3B" },
      { value: "positionSSFlg", label: "SS" },
      { value: "positionCFlg", label: "C" },
      { value: "positionSPFlg", label: "SP" },
      { value: "positionRPFlg", label: "RP" },
      { value: "positionDHFlg", label: "DH" },
      { value: "positionLFFlg", label: "LF" },
      { value: "positionCFFlg", label: "CF" },
      { value: "positionRFFlg", label: "RF" },
      { value: "positionOFFlg", label: "OF" }
    ];
  }

  updateListPositions(positions) {
    // Reset position flags
    this.listCriteria.position1BFlg = false;
    this.listCriteria.position2BFlg = false;
    this.listCriteria.position3BFlg = false;
    this.listCriteria.positionSSFlg = false;
    this.listCriteria.positionCFlg = false;
    this.listCriteria.positionSPFlg = false;
    this.listCriteria.positionRPFlg = false;
    this.listCriteria.positionDHFlg = false;
    this.listCriteria.positionLFFlg = false;
    this.listCriteria.positionCFFlg = false;
    this.listCriteria.positionRFFlg = false;
    this.listCriteria.positionOFFlg = false;

    // Update position flags
    positions.forEach(position => {
      this.listCriteria[position.value] = true;
    });

    // Update list
    this.listCriteria.positions = positions;
  }

  flattenCriteriaGroups(group) {
    let result = [];

    for (let child of group) {
      if (child.groupHeader) {
        let children = child.children;
        child.children = null;
        result = _.concat(result, this.flattenCriteriaGroups(children));
      } else {
        result.push(child);
      }
    }

    return result;
  }

  getPlayersForList(type) {
    this.listCriteria.existingList = this.playerList;

    //flatten groups
    let tempList = _.cloneDeep(this.listCriteria);
    let flattened = this.flattenCriteriaGroups(tempList.criteriaForQuery);
    tempList.criteriaForQuery = flattened;

    this.piaApi.getPlayersForList(tempList, type).then(response => {
      if (response) {
        if (!response.playerlistId) {
          response.playerlistId = 0;
        }

        this.playerList = response;
        this.listCriteria.existingList = this.defaults["listCriteria"].existingList;
      }
    });
  }

  playerListContainsPlayer(playerId, year) {
    let hasPlayer = false;

    for (let pi = 0; pi < this.playerList.plDetails.length; pi++) {
      let player = this.playerList.plDetails[pi];
      if (playerId === player.playerid && year === player.platformYear) {
        hasPlayer = true;
        break;
      }
    }

    return hasPlayer;
  }

  addPlayersToList(players = []) {
    let playerKeys = [];
    let hasFocus = false;

    this.playerList.plDetails.forEach(player => {
      player.recentlyAddedFlg = false;
      playerKeys.push(player.playerKey);

      if (player.focusplayerFlg) {
        hasFocus = true;
      }
    });

    players.forEach(player => {
      if (!playerKeys.includes(player.playerKey)) {
        player.recentlyAddedFlg = true;
        playerKeys.push(player.playerKey);

        if (player.focusplayerFlg && hasFocus) {
          player.focusplayerFlg = false;
        }

        this.playerList.plDetails.push(player);
      }
    });
  }

  savePlayerList(overrideFlag = false) {
    this.piaApi.savePlayerList(this.playerList, overrideFlag).then(response => {
      if (response) {
        if (response.overrideMessage) {
          this.playerList.overrideMessage = response.overrideMessage;
          this.playerList.overrideFn = () => {
            this.playerList.overrideMessage = null;
            this.playerList.showOverride = false;
            this.savePlayerList(true);
          };
          this.playerList.showOverride = true;
        } else if (response.payload) {
          this.alertStore.addAlert({
            type: AlertConstants.TYPES.SUCCESS,
            text: "Player list saved successfully"
          });
          this.openPlayerList(response.payload);
        } else if (response.alerts) {
          if (response.alerts[0].message === "Please enter a Player List name.") {
            this.playerNameError = true;
          }
        }
      }
    });
  }

  refreshPlayerListPlayerInfo() {
    this.piaApi.refreshPlayerListPlayerInfo(this.playerList).then(response => {
      if (response) {
        this.playerList = response;
      }
    });
  }

  get displayListSplitCategories() {
    if (!this.listCriteria) {
      return [];
    }

    let cachedSplits = this.lookupStore.getCollection("splits");
    let addedCategories = this.listCriteria.splits.map(split => split.splitCategory);

    return _.filter(cachedSplits, category => !_.includes(addedCategories, category.splitType)).map(category => {
      return {
        ...category,
        splits: [{ splitName: "View All" }].concat(category.splits)
      };
    });
  }

  getAutocompleteText(player) {
    return player.playerName + " (" + this.commonStore.getOrgName(player.org) + " - " + player.position + ")";
  }

  filterBySearchTerm(players, searchTerm) {
    if (searchTerm.length < 3) {
      return [];
    } else {
      return _.filter(players, player => {
        return this.searchTermStartsField(player.playerName, searchTerm);
      });
    }
  }
}

export default PlayerStore;
