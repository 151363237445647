import React from "react";
import { inject, observer } from "mobx-react";
import Moment from "moment";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import {
  BestLabel,
  BestSelect,
  BestQuickSearch,
  CardContainer,
  DangerButton,
  DateTimeFormatter,
  FormInput,
  LightButton,
  PrimaryButton
} from "best-common-react";
import { FormSearchStyles, FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";
import { RouteConstants } from "../../constants/RouteConstants";
import { SharingConstants } from "../../constants/SharingConstants";
import StatAutocompleteRow from "../autocomplete/StatAutocompleteRow";

class ManageStats extends React.Component {
  render() {
    let { lookupStore, routerStore, statStore } = this.props.rootStore;
    let columns = statStore.getStatCols();
    let types = statStore.getStatTypeOptions();
    const onRowsSelected = rows => {
      statStore.addSelectedStats(rows.map(row => row.row));
    };
    const onRowsDeselected = rows => {
      statStore.removeUnselectedStats(rows.map(row => row.row));
    };
    return (
      <React.Fragment>
        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Stat Search</span>
            </CardContainer.Header>
            <CardContainer.Body>
              <div className="row m-0">
                <div className="col-3">
                  <div className="form-group">
                    <BestLabel>Statistic Name</BestLabel>
                    <BestQuickSearch
                      options={lookupStore.autocompleteStats}
                      renderSuggestion={suggestion => {
                        return <StatAutocompleteRow stat={suggestion} />;
                      }}
                      getSuggestionValue={stat => {
                        return statStore.getAutocompleteText(stat);
                      }}
                      getSuggestions={(stats, searchTerm) => {
                        statStore.statSearch.statId = null;
                        statStore.statSearch.statName = statStore.formatForComparison(searchTerm);
                        return statStore.filterBySearchTerm(stats, searchTerm);
                      }}
                      onSuggestionSelected={(event, { suggestion }) => {
                        statStore.statSearch.statId = suggestion.statId;
                        statStore.statSearch.statName = suggestion.statName;
                      }}
                      styles={FormSearchStyles}
                      onClear={() => {
                        statStore.statSearch.statId = null;
                        statStore.statSearch.statName = null;
                      }}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <FormInput
                    name="ssLastUpdatedFrom"
                    label="Last Updated From"
                    type="date"
                    value={
                      statStore.statSearch.lastUpdatedFromDte
                        ? Moment(statStore.statSearch.lastUpdatedFromDte, "MM/DD/YYYY")
                        : statStore.statSearch.lastUpdatedFromDte
                    }
                    onChangeFn={date => {
                      statStore.statSearch.lastUpdatedFromDte = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                    formatter={DateTimeFormatter}
                    editable
                  />
                </div>

                <div className="col-3">
                  <FormInput
                    name="ssLastUpdatedTo"
                    label="Last Updated To"
                    type="date"
                    value={
                      statStore.statSearch.lastUpdatedThroughDte
                        ? Moment(statStore.statSearch.lastUpdatedThroughDte, "MM/DD/YYYY")
                        : statStore.statSearch.lastUpdatedThroughDte
                    }
                    onChangeFn={date => {
                      statStore.statSearch.lastUpdatedThroughDte = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                    formatter={DateTimeFormatter}
                    editable
                  />
                </div>
              </div>

              <div className="row m-0">
                <div className="col-3">
                  <div className="form-group">
                    <BestLabel>Type</BestLabel>
                    <BestSelect
                      name="ssType"
                      options={types}
                      onChange={option => {
                        statStore.updateStatType(option);
                      }}
                      value={lookupStore.getDropdownItem(types, statStore.statSearch.searchType, "type", "type")}
                      getOptionValue={option => option.type}
                      getOptionLabel={option => option.type}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <div className="form-group">
                    <BestLabel>Category</BestLabel>
                    <BestSelect
                      name="ssCategory"
                      options={statStore.statTypeCategories}
                      onChange={option => {
                        statStore.statSearch.searchCategory = option.category;
                      }}
                      value={lookupStore.getDropdownItem(
                        statStore.statTypeCategories,
                        statStore.statSearch.searchCategory,
                        "category",
                        "category"
                      )}
                      getOptionValue={option => option.category}
                      getOptionLabel={option => option.category}
                      isDisabled={"All" === statStore.statSearch.searchType}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
              </div>

              <div className="row m-0">
                <div className="col-12">
                  <div className="form-group">
                    <BestLabel>Supporting Stats</BestLabel>
                    <BestSelect
                      name="ssSupportingStats"
                      options={lookupStore.autocompleteStats}
                      onChange={value => {
                        if (!value) {
                          value = [];
                        }
                        statStore.supportingStats = value;
                        statStore.statSearch.supportingStatCodes = value.map(v => v.statCode);
                      }}
                      value={statStore.supportingStats}
                      getOptionValue={option => option.statId}
                      getOptionLabel={option => statStore.getAutocompleteText(option)}
                      isMulti
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
              </div>
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row">
                <PrimaryButton
                  onClick={() => {
                    statStore.statSearch.searchStandardStatFlg = true;
                    statStore.statSearch.searchUserDefinedFlg = true;
                    statStore.findStats();
                  }}
                >
                  Search
                </PrimaryButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>

        <PiaWidget className="col-12 table-container">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Search Results</span>
            </CardContainer.Header>
            <CardContainer.Body>
              {statStore.displayStats.length > 0 ? (
                <PiaTable
                  data={statStore.displayStats}
                  columns={columns}
                  rowSelection={{
                    showCheckbox: true,
                    onRowsSelected: onRowsSelected,
                    onRowsDeselected: onRowsDeselected,
                    selectBy: {
                      isSelectedKey: "isSelected"
                    },
                    enableShiftSelect: true
                  }}
                  height={statStore.calculateTableHeight(statStore.displayStats.length, 308)}
                />
              ) : (
                <div className="col-12">No Results</div>
              )}
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row">
                <LightButton
                  onClick={() => {
                    statStore.exportList(statStore.statResults);
                  }}
                >
                  Export to Excel
                </LightButton>
                <LightButton
                  onClick={() => {
                    routerStore.pushHistory(RouteConstants.SHARING.route + "/" + SharingConstants.TYPE.STATS, false);
                  }}
                >
                  Share
                </LightButton>
                <DangerButton
                  onClick={() => {
                    statStore.deleteStats();
                  }}
                >
                  Delete
                </DangerButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>
      </React.Fragment>
    );
  }
}

ManageStats.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(ManageStats)));
