export const StatConstants = {
  TYPES: {
    BATTING: "Batting",
    BATTING_ADV: "Batting Adv",
    CATCHING: "Catching",
    FIELDING: "Fielding",
    PITCHING: "Pitching",
    PITCHING_ADV: "Pitching Adv",
    RUNNING: "Running",
    WINSHARES: "WinShares"
  }
};
