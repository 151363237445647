export const PlayerConstants = {
  STAT_LINES: {
    BATTING_CUSTOM: "C_BATTING",
    CATCHING_CUSTOM: "C_CATCHING",
    FIELDING_CUSTOM: "C_FIELDING",
    RELIEF_CUSTOM: "C_RELIEF",
    STARTING_CUSTOM: "C_STARTING",
    SWING_CUSTOM: "C_SWING"
  },
  STAT_SPLIT_TYPES: {
    CAREER: "Career",
    GAME_LOG: "Game Log",
    MN_CAREER: "Minor League Career",
    MN_POST_SEASON: "Minor League Post-Season",
    POST_SEASON: "Post-Season",
    SPLITS: "Splits"
  }
};
