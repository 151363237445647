import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { PrimaryButton } from "best-common-react";

class ManageStatsHeaderDetails extends React.Component {
  render() {
    let { statStore } = this.props.rootStore;
    return (
      <div className="row align-items-center h-100 m-0 p-0">
        <div className="col-7">
          <h5>Manage Stats</h5>
        </div>
        <div className="col-5">
          <div className="row m-0 align-items-center justify-content-end">
            <PrimaryButton
              onClick={() => {
                statStore.openStat(0);
              }}
            >
              Create New Statistic
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

ManageStatsHeaderDetails.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ManageStatsHeaderDetails));
