import { RouteConstants } from "../constants/RouteConstants";

/**
 * All Tracking items should go here
 */

class TrackingHelper {
  constructor(routerStore, authStore, compStore) {
    this.routerStore = routerStore;
    this.authStore = authStore;
    this.compStore = compStore;
  }

  track(text) {
    this.trackClickOnPage(null, text);
  }

  trackClickOnPage(pageName, clickText) {
    let { s_account, s_gi } = window;

    var s = s_gi(s_account);
    s.prop12 = "PIA+";
    s.prop13 = "MLB PIA+: " + (pageName || s.pageName);
    s.prop14 = clickText;

    if (this.authStore.userData) {
      s.eVar11 = this.authStore.userData.uid;
      s.eVar50 = this.authStore.userData.org;
    }

    // Send s.tl() call
    var s_code = s.tl();
    if (s_code) {
      document.write(s_code);
    }
  }

  trackPageView(pageName) {
    let { s_account, s_gi } = window;

    var s = s_gi(s_account);
    s.pageName = "MLB PIA+: " + pageName;
    s.prop12 = undefined;
    s.prop13 = undefined;
    s.prop14 = undefined;

    if (this.authStore.userData) {
      s.eVar11 = this.authStore.userData.uid;
      s.eVar50 = this.authStore.userData.org;
    }

    // Send s.t() call
    var s_code = s.t();
    if (s_code) {
      document.write(s_code);
    }
  }

  pushHistoryAndTrack(routeObj, replace, state) {
    if (!routeObj) {
      return;
    }

    this.routerStore.pushHistory(routeObj.route, replace, state);
    this.trackPageView(routeObj.title);
  }

  trackClickAndRunComp(type, text) {
    this.trackClickOnPage(RouteConstants.EDIT_COMPARISON.title, text);
    this.compStore.runReport(type);
  }
}

export default TrackingHelper;
