import React from "react";
import { inject, observer } from "mobx-react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { BestLabel, CardContainer, DateTimeFormatter, PriceFormatter } from "best-common-react";
import PiaWidget from "../widgets/PiaWidget";
import ToFixedFormatter from "../utilities/ToFixedFormatter";

const ImageColumn = styled.div`
  flex: 0 0 108px;
`;

const InfoColumn = styled.div`
  flex: 1;
`;

const IdContainer = styled.span`
  padding-left: 0.5em;
  color: transparent;
`;

const LogoContainer = styled.span`
  padding-left: 0.2em;
  width: 64px;
`;

class ProfileHeader extends React.Component {
  render() {
    let { playerStore } = this.props.rootStore;
    let logoPath =
      "--" !== playerStore.player.currentStatus && "--" !== playerStore.player.org
        ? "/images/logos/" + playerStore.player.org + ".png"
        : "/images/logos/mlblogo.png";

    let serviceUrl = process.env.REACT_APP_SERVICES_URL;

    return (
      <React.Fragment>
        <div className="row m-0">
          <div className="col-9 d-flex">
            <ImageColumn>
              {playerStore.player.imageURL ? (
                <img className="player-image" src={serviceUrl + playerStore.player.imageURL} />
              ) : (
                <div className="player-placeholder" />
              )}
            </ImageColumn>
            <InfoColumn>
              <div className="row m-0 col-12 pl-4">
                <h3>
                  {playerStore.player.fullName} - {playerStore.player.currentPosition}
                </h3>
                <LogoContainer>
                  <img src={logoPath} width="32px" alt="" />
                </LogoContainer>
                <h3>
                  <IdContainer>
                    PIA: {playerStore.player.playerId} | EBIS: {playerStore.player.ebisId}
                  </IdContainer>
                </h3>
              </div>

              <div className="row m-0">
                <div className="col-6 pl-4">
                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>
                        Birth
                        <span
                          onDoubleClick={() => {
                            playerStore.getPlayer(playerStore.player.playerId, true);
                          }}
                        >
                          d
                        </span>
                        ate
                      </BestLabel>
                    </div>
                    <div className="col-7">
                      <span>
                        <DateTimeFormatter value={playerStore.player.dob} />
                      </span>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>Current MLS</BestLabel>
                    </div>
                    <div className="col-7">
                      <span>
                        <ToFixedFormatter value={playerStore.player.currentMls} />
                      </span>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>Height / Weight</BestLabel>
                    </div>
                    <div className="col-7">
                      <span>
                        {playerStore.player.height} / {playerStore.player.weight}
                      </span>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>Bats / Throws</BestLabel>
                    </div>
                    <div className="col-7">
                      <span>
                        {playerStore.player.battingSide} / {playerStore.player.throwingSide}
                      </span>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>Agent</BestLabel>
                    </div>
                    <div className="col-7">
                      <a
                        href="/#"
                        onClick={event => {
                          event.preventDefault();
                          playerStore.getAgent(playerStore.player.agent);
                        }}
                      >
                        {playerStore.player.agent}
                      </a>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>Club</BestLabel>
                    </div>
                    <div className="col-7">
                      <span>{playerStore.player.club}</span>
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>Age</BestLabel>
                    </div>
                    <div className="col-7">
                      <span>{playerStore.player.age}</span>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>OD MLS</BestLabel>
                    </div>
                    <div className="col-7">
                      <span>
                        <ToFixedFormatter value={playerStore.player.odMls} />
                      </span>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>Options Used</BestLabel>
                    </div>
                    <div className="col-7">
                      <span>
                        {playerStore.player.optionsUsed} of {playerStore.player.optionsTotal}
                      </span>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>Prior Outright</BestLabel>
                    </div>
                    <div className="col-7">
                      <span>{playerStore.player.priorOutRightFlag}</span>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-5 pl-0">
                      <BestLabel>Current Status</BestLabel>
                    </div>
                    <div className="col-7">
                      <span>{playerStore.player.currentStatus}</span>
                    </div>
                  </div>
                </div>
              </div>
            </InfoColumn>
          </div>
          <div className="col-3">
            {playerStore.player.currentContract ? (
              <PiaWidget className="p-0">
                <CardContainer>
                  <CardContainer.Header>
                    <span className="header-title">Most Recent Contract Info</span>
                  </CardContainer.Header>
                  <CardContainer.Body>
                    <div className="row m-0">
                      <div className="col-7 pl-0">
                        <BestLabel>Confirmed Terms Date</BestLabel>
                      </div>
                      <div className="col-5">
                        <DateTimeFormatter value={playerStore.player.currentContract.confirmedDate} />
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-7 pl-0">
                        <BestLabel>Start Year</BestLabel>
                      </div>
                      <div className="col-5">
                        <span>{playerStore.player.currentContract.startYear}</span>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-7 pl-0">
                        <BestLabel>Length</BestLabel>
                      </div>
                      <div className="col-5">
                        <span>{playerStore.player.currentContract.contractLength} years</span>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-7 pl-0">
                        <BestLabel>Type</BestLabel>
                      </div>
                      <div className="col-5">
                        <span>{playerStore.player.currentContract.contractType}</span>
                      </div>
                    </div>
                    <div className="row m-0">
                      <div className="col-7 pl-0">
                        <BestLabel>AAV</BestLabel>
                      </div>
                      <div className="col-5">
                        <PriceFormatter value={playerStore.player.currentContract.aav} />
                      </div>
                    </div>
                  </CardContainer.Body>
                </CardContainer>
              </PiaWidget>
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProfileHeader.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(ProfileHeader)));
