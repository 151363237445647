import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { BestLabel, NumberInput, PrimaryButton, Icon } from "best-common-react";
import TitleCard from "../widgets/TitleCard";

class Setup extends React.Component {
  getStatWeightTable(compFinderStore, weight, onAddOrRemove) {
    let rows = [];
    weight.cfWeights.forEach((cfWeight, index) => {
      let el = (
        <tr key={"sw_" + cfWeight.type + "_" + index}>
          <td>{cfWeight.shortName}</td>
          <td>
            {!!cfWeight.editMode ? (
              <NumberInput
                name={"sw_" + cfWeight.type + "_" + index + "_PW"}
                onChange={value => {
                  cfWeight.platformWeight = value;
                }}
                value={cfWeight.platformWeight}
                disabled={!cfWeight.editMode}
              />
            ) : (
              <span>{cfWeight.platformWeight}</span>
            )}
          </td>
          <td>
            {!cfWeight.careerAvailableFlag ? (
              "N/A"
            ) : !!cfWeight.editMode ? (
              <NumberInput
                name={"sw_" + cfWeight.type + "_" + index + "_CW"}
                onChange={value => {
                  cfWeight.careerWeight = value;
                }}
                value={cfWeight.careerWeight}
                disabled={!cfWeight.editMode}
              />
            ) : (
              <span>{cfWeight.careerWeight}</span>
            )}
          </td>

          <td>
            {!!cfWeight.editMode ? (
              <Icon
                iconName="fa-check-square"
                onClick={() => {
                  compFinderStore.updateStatWeight(cfWeight);
                }}
              />
            ) : (
              <Icon
                iconName="fa-pen-square"
                onClick={() => {
                  cfWeight.editMode = true;
                }}
              />
            )}
          </td>

          <td>
            {cfWeight.careerAvailableFlag ? (
              <Icon
                className="mlb-red-icon"
                iconName="fa-trash-alt"
                onClick={() => {
                  compFinderStore.deleteStatWeight(cfWeight, onAddOrRemove);
                }}
              />
            ) : (
              ""
            )}
          </td>
        </tr>
      );
      rows.push(el);
    });

    return (
      <div className="col-4">
        <div className="row mx-0 my-2">
          <div className="col-10 d-flex align-items-center pl-0">
            <BestLabel>{weight.description}</BestLabel>
          </div>
          <div className="col-2 d-flex pr-0">
            <PrimaryButton
              className="flex-fill"
              onClick={() => {
                compFinderStore.openAddWeightModal(weight.type, null, onAddOrRemove);
              }}
            >
              Add
            </PrimaryButton>
          </div>
        </div>
        <table className="text-center bordered">
          <thead>
            <tr>
              <th>Statistic</th>
              <th>Platform Weight</th>
              <th>Career Weight</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }

  getPyvcWeightTable(compFinderStore, weight) {
    let editEl = editProp => {
      return (
        <td>
          {!!weight[editProp] ? (
            <Icon
              iconName="fa-check-square"
              onClick={() => {
                compFinderStore.updatePyvcWeight(weight, editProp);
              }}
            />
          ) : (
            <Icon
              iconName="fa-pen-square"
              onClick={() => {
                weight[editProp] = true;
              }}
            />
          )}
        </td>
      );
    };
    return (
      <div className="col-4">
        <div className="row mx-0 my-2">
          <div className="col-10 d-flex align-items-center pl-0">
            <BestLabel>{weight.description}</BestLabel>
          </div>
        </div>
        <table className="text-center bordered">
          <thead>
            <tr>
              <th>Statistic</th>
              <th>Platform Weight</th>
              <th>Career Weight</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>FTE PY vs. Career</td>
              <td>
                {!!weight.editMode0 ? (
                  <NumberInput
                    name={"pyvcw_" + weight.type + "FTE_W"}
                    onChange={value => {
                      weight.fteWeight = value;
                      weight.fteCareer = 100 - (weight.fteWeight || 0);
                    }}
                    value={weight.fteWeight}
                    disabled={!weight.editMode0}
                  />
                ) : (
                  <span>{weight.fteWeight}</span>
                )}
              </td>

              <td>{weight.fteCareer}</td>
              {editEl("editMode0")}
            </tr>

            <tr>
              <td>2TE PY vs. Career</td>
              <td>
                {!!weight.editMode1 ? (
                  <NumberInput
                    name={"pyvcw_" + weight.type + "STE_W"}
                    onChange={value => {
                      weight.steWeight = value;
                      weight.steCareer = 100 - (weight.steWeight || 0);
                    }}
                    value={weight.steWeight}
                    disabled={!weight.editMode1}
                  />
                ) : (
                  <span>{weight.steWeight}</span>
                )}
              </td>

              <td>{weight.steCareer}</td>
              {editEl("editMode1")}
            </tr>

            <tr>
              <td>&gt;=3TE PY vs. Career</td>

              <td>
                {!!weight.editMode2 ? (
                  <NumberInput
                    name={"pyvcw_" + weight.type + "TTE_W"}
                    onChange={value => {
                      weight.tteWeight = value;
                      weight.tteCareer = 100 - (weight.tteWeight || 0);
                    }}
                    value={weight.tteWeight}
                    disabled={!weight.editMode2}
                  />
                ) : (
                  <span>{weight.tteWeight}</span>
                )}
              </td>

              <td>{weight.tteCareer}</td>
              {editEl("editMode2")}
            </tr>

            <tr>
              <td>Not Arb Eligible</td>

              <td>
                {!!weight.editMode3 ? (
                  <NumberInput
                    name={"pyvcw_" + weight.type + "NTE_W"}
                    onChange={value => {
                      weight.nteWeight = value;
                      weight.nteCareer = 100 - (weight.nteWeight || 0);
                    }}
                    value={weight.nteWeight}
                    disabled={!weight.editMode3}
                  />
                ) : (
                  <span>{weight.nteWeight}</span>
                )}
              </td>

              <td>{weight.nteCareer}</td>
              {editEl("editMode3")}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  getConditionalWeightTable(compFinderStore, weight, onAddOrRemove) {
    let rows = [];
    weight.cfConditionalWeights.forEach((cfConditionalWeight, index) => {
      let el = (
        <tr key={"cw_" + cfConditionalWeight.type + "_" + index}>
          <td>{cfConditionalWeight.shortName}</td>
          <td>
            {!!cfConditionalWeight.editMode ? (
              <NumberInput
                name={"cw_" + cfConditionalWeight.type + "_" + index + "_T"}
                onChange={value => {
                  cfConditionalWeight.threshold = value;
                }}
                value={cfConditionalWeight.threshold}
                disabled={!cfConditionalWeight.editMode}
              />
            ) : (
              <span>{cfConditionalWeight.threshold}</span>
            )}
          </td>
          <td>
            {!!cfConditionalWeight.editMode ? (
              <NumberInput
                name={"cw_" + cfConditionalWeight.type + "_" + index + "_PW"}
                onChange={value => {
                  cfConditionalWeight.platformWeight = value;
                }}
                value={cfConditionalWeight.platformWeight}
                disabled={!cfConditionalWeight.editMode}
              />
            ) : (
              <span>{cfConditionalWeight.platformWeight}</span>
            )}
          </td>
          <td>
            {-1 === cfConditionalWeight.careerWeight ? (
              "N/A"
            ) : !!cfConditionalWeight.editMode ? (
              <NumberInput
                name={"cw_" + cfConditionalWeight.type + "_" + index + "_CW"}
                onChange={value => {
                  cfConditionalWeight.careerWeight = value;
                }}
                value={cfConditionalWeight.careerWeight}
                disabled={!cfConditionalWeight.editMode}
              />
            ) : (
              <span>{cfConditionalWeight.careerWeight}</span>
            )}
          </td>

          <td>
            {!!cfConditionalWeight.editMode ? (
              <Icon
                iconName="fa-check-square"
                onClick={() => {
                  compFinderStore.updateConditionalWeight(cfConditionalWeight);
                }}
              />
            ) : (
              <Icon
                iconName="fa-pen-square"
                onClick={() => {
                  cfConditionalWeight.editMode = true;
                }}
              />
            )}
          </td>

          <td>
            {-1 !== cfConditionalWeight.careerWeight ? (
              <Icon
                className="mlb-red-icon"
                iconName="fa-trash-alt"
                onClick={() => {
                  compFinderStore.deleteConditionalWeight(cfConditionalWeight, onAddOrRemove);
                }}
              />
            ) : (
              ""
            )}
          </td>
        </tr>
      );
      rows.push(el);
    });

    return (
      <div className="col-4">
        <div className="row mx-0 my-2">
          <div className="col-10 d-flex align-items-center pl-0">
            <BestLabel>{weight.description}</BestLabel>
          </div>
          <div className="col-2 d-flex pr-0">
            <PrimaryButton
              className="flex-fill"
              onClick={() => {
                compFinderStore.openAddWeightModal(weight.type, weight.cfWeightsStatsOnly, onAddOrRemove);
              }}
            >
              Add
            </PrimaryButton>
          </div>
        </div>
        <table className="text-center bordered">
          <thead>
            <tr>
              <th>Statistic</th>
              <th>Threshold</th>
              <th>Plat. Weight</th>
              <th>Car. Weight</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
    );
  }

  render() {
    let { compFinderStore } = this.props.rootStore;
    return (
      <div className="container-fluid">
        <div className="row mx-0 pt-4">
          <div className="col-12">
            <TitleCard>
              <span>Standard Stat Weights</span>
            </TitleCard>
          </div>
        </div>

        <div className="row mx-0 pb-4">
          {this.getStatWeightTable(
            compFinderStore,
            compFinderStore.positionWeights,
            compFinderStore.getPositionWeights
          )}
          {this.getStatWeightTable(
            compFinderStore,
            compFinderStore.startingWeights,
            compFinderStore.getStartingWeights
          )}
          {this.getStatWeightTable(compFinderStore, compFinderStore.reliefWeights, compFinderStore.getReliefWeights)}
        </div>

        <div className="row mx-0 pt-2">
          <div className="col-12">
            <TitleCard>
              <span>Platform Year vs. Career Weights</span>
            </TitleCard>
          </div>
        </div>

        <div className="row mx-0 pb-4">
          {this.getPyvcWeightTable(compFinderStore, compFinderStore.positionWeights)}
          {this.getPyvcWeightTable(compFinderStore, compFinderStore.startingWeights)}
          {this.getPyvcWeightTable(compFinderStore, compFinderStore.reliefWeights)}
        </div>

        <div className="row mx-0 pt-2">
          <div className="col-12">
            <TitleCard>
              <span>Conditional Stat Weights</span>
            </TitleCard>
          </div>
        </div>

        <div className="row mx-0 pb-4">
          {this.getConditionalWeightTable(
            compFinderStore,
            compFinderStore.positionWeights,
            compFinderStore.getPositionWeights
          )}
          {this.getConditionalWeightTable(
            compFinderStore,
            compFinderStore.startingWeights,
            compFinderStore.getStartingWeights
          )}
          {this.getConditionalWeightTable(
            compFinderStore,
            compFinderStore.reliefWeights,
            compFinderStore.getStartingWeights
          )}
        </div>
      </div>
    );
  }
}

Setup.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(Setup));
