import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import ManageFoldersModal from "../../components/common/ManageFoldersModal";
import SavedReports from "../../components/reports/SavedReports";
import { RouteConstants } from "../../constants/RouteConstants";

class SavedReportContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.MY_SAVED_REPORTS.title);
  }

  render() {
    let { piaApi, reportStore } = this.props.rootStore;
    return (
      <div className="container-fluid">
        <div className="col-12 p-0 mb-4">
          <ManageFoldersModal
            isOpen={!!reportStore.openFolderModal}
            foldersLoaded={!!reportStore.foldersLoaded}
            folders={reportStore.folders}
            defaultFolderName="Favorites"
            createFolder={piaApi.createReportFolder}
            deleteFolder={piaApi.deleteReportFolder}
            beforeReload={reportStore.onLoadSavedReports}
            confirmType="reports"
            onClose={() => {
              reportStore.openFolderModal = false;
            }}
          />
          <SavedReports />
        </div>
      </div>
    );
  }
}

SavedReportContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(SavedReportContainer));
