import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestSelect } from "best-common-react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FormSelectStyles } from "../../Theme";

class SelectTimeFrame extends React.Component {
  getTfTypeOptions(lookupStore) {
    let list = lookupStore.getCollection("timeframetypes");
    list = _.filter(list, type => type.description !== "Date Range" && type.description !== "Contract Length");
    return list;
  }

  render() {
    let { lookupStore } = this.props.rootStore;
    let { statSplitCriteria, years } = this.props;
    let tfTypes = this.getTfTypeOptions(lookupStore);

    return (
      <React.Fragment>
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="form-group">
              <BestLabel>Range</BestLabel>
              <BestSelect
                name="stfType"
                value={lookupStore.getDropdownItem(
                  tfTypes,
                  statSplitCriteria.statsplitTimeframeType,
                  "description",
                  "description"
                )}
                onChange={option => {
                  statSplitCriteria.statsplitTimeframeType = option.description;
                }}
                options={tfTypes}
                getOptionValue={option => option.description}
                getOptionLabel={option => option.description}
                placeholder="Type"
                portal={document.body}
                styles={FormSelectStyles}
              />
            </div>
          </div>
        </div>

        <div className="row m-0">
          {"Career" === statSplitCriteria.statsplitTimeframeType ? (
            ""
          ) : (
            <React.Fragment>
              <div className="col-6 pl-0">
                <div className="form-group">
                  <BestLabel>{"Single Year" === statSplitCriteria.statsplitTimeframeType ? "Year" : "From"}</BestLabel>
                  <BestSelect
                    name="tfYear1"
                    value={lookupStore.getDropdownItem(years, statSplitCriteria.statsplitTimeframeYear1 * 1)}
                    onChange={option => {
                      statSplitCriteria.statsplitTimeframeYear1 = option.value + "";
                      statSplitCriteria.statsplitTimeframeSingleYear = option.value + "";
                    }}
                    options={years}
                    portal={document.body}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>
              {"Multiple Years" === statSplitCriteria.statsplitTimeframeType ? (
                <div className="col-6 pr-0">
                  <div className="form-group">
                    <BestLabel>To</BestLabel>
                    <BestSelect
                      name="tfYear2"
                      value={lookupStore.getDropdownItem(years, statSplitCriteria.statsplitTimeframeYear2 * 1)}
                      onChange={option => {
                        statSplitCriteria.statsplitTimeframeYear2 = option.value + "";
                      }}
                      options={years}
                      portal={document.body}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

SelectTimeFrame.propTypes = {
  rootStore: PropTypes.object.isRequired,
  statSplitCriteria: PropTypes.object.isRequired,
  years: PropTypes.array.isRequired
};

export default inject("rootStore")(observer(SelectTimeFrame));
