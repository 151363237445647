import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import _ from "lodash";
import Moment from "moment";
import styled from "styled-components";
import { BestLabel, BestSelect, DatePickerInput, Icon, Input, LightButton, PrimaryButton } from "best-common-react";
import Theme, { FormSelectStyles, FooterLightSelectStyles, FormMiniSelectButtonStyles } from "../../Theme";
import { CompConstants } from "../../constants/CompConstants";
import TitleCard from "../widgets/TitleCard";

const IndentSpacer = styled.div`
  width: ${props => props.width || 0};
  margin-bottom: ${props => props.marginBottom || 0};
`;

const IndentLine = styled.div`
  background-color: ${props => props.theme.fog};
  width: 20px;
  height: 100%;
`;

class PlayerListCriteriaTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { criteriaLoaded: false, criteriaTypes: [] };
  }

  async componentDidMount() {
    let { lookupStore } = this.props.rootStore;

    this.setState({ criteriaLoaded: false });

    lookupStore.getUniverseObjects().then(response => {
      this.setState({ criteriaTypes: response });
      this.loadThresholdLists();
      this.setState({ criteriaLoaded: true });
    });
  }

  loadThresholdLists() {
    if (this.props.thresholds) {
      this.props.thresholds.forEach(th => {
        th.types = this.state.criteriaTypes;
        this.updateCategories(th, null, false);
        this.updateCriteria(th, null, false);
        this.updateComparisons(th, null, false);
      });

      if (this.props.advanced) {
        this.updateGroups(this.props.thresholds, 0);
      }
    }
  }

  updateCategories(th, option, replaceAll = true) {
    if (!th.types || !th.criteriaType || "" === th.criteriaType) {
      return;
    }

    let { lookupStore } = this.props.rootStore;
    let typeOption = option || lookupStore.getDropdownItem(th.types, th.criteriaType, "name", "name");

    if (typeOption) {
      th.typeOption = typeOption;
      th.categories = typeOption.folder || [];
    } else {
      th.typeOption = {};
      th.categories = [];
    }

    if (replaceAll) {
      th.criteriaCategory = null;
      th.categoryOption = {};
      th.criteriaName = null;
      th.criteriaOption = {};
      th.comparison = null;
      th.operand1 = null;
      th.operand2 = null;
    }
  }

  updateCriteria(th, option, replaceAll = true) {
    if (!th.categories || !th.criteriaCategory || "" === th.criteriaCategory) {
      return;
    }

    let { lookupStore } = this.props.rootStore;
    let categoryOption = option || lookupStore.getDropdownItem(th.categories, th.criteriaCategory, "name", "name");

    if (categoryOption) {
      th.categoryOption = categoryOption;
      th.criteria = categoryOption.item || [];
    } else {
      th.categoryOption = {};
      th.criteria = [];
    }

    if (replaceAll) {
      th.criteriaName = null;
      th.criteriaOption = {};
      th.comparison = null;
      th.operand1 = null;
      th.operand2 = null;
    }
  }

  updateComparisons(th, option, replaceAll = true) {
    if (!th.criteria || !th.criteriaName || "" === th.criteriaName) {
      return;
    }

    let { lookupStore } = this.props.rootStore;
    let criteriaOption = option || lookupStore.getDropdownItem(th.criteria, th.criteriaName, "name", "displayName");

    if (replaceAll) {
      th.comparison = null;
    }

    if (criteriaOption) {
      th.criteriaOption = criteriaOption;
      th.criteriaDisplayName = criteriaOption.displayName;
      th.criteriaShortName = this.getStatShortName(th.criteriaType, th.criteriaCategory, th.criteriaName);
      th.precondition = "Filter" === criteriaOption["@type"];
      th.comparisons = this.getOperators(criteriaOption);
      this.updateOperand1(th, criteriaOption, replaceAll);
    } else {
      th.criteriaOption = {};
      th.precondition = false;
      th.comparisons = [];
      this.updateOperand1(th);
    }
  }

  updateGroups(thresholds, nextGroupLevel) {
    for (let i = 0; i < thresholds.length; i++) {
      let entry = thresholds[i];
      entry.groupLevel = nextGroupLevel;

      if (entry.groupHeader === true) {
        if (!entry.children || entry.children.length === 0) {
          thresholds.splice(i, 1);

          if (i == thresholds.length) {
            _.last(thresholds).trailingparens = _.last(thresholds).groupLevel;
          }
        } else {
          this.updateGroups(entry.children, nextGroupLevel + 1);
        }
      }
    }
  }

  getNumericalOperators(isRoundSelectedR4, addRankTop) {
    let list = [
      { value: CompConstants.OPERATORS.EQUAL },
      { value: CompConstants.OPERATORS.GREATER_THAN },
      { value: CompConstants.OPERATORS.GREATER_THAN_EQUAL },
      { value: CompConstants.OPERATORS.LESS_THAN },
      { value: CompConstants.OPERATORS.LESS_THAN_EQUAL },
      { value: CompConstants.OPERATORS.NOT_EQUAL }
    ];

    // subquery does not allow a "between" operator
    if (!isRoundSelectedR4) {
      list.push({ value: CompConstants.OPERATORS.BETWEEN });
    }

    list = list.concat([
      { value: CompConstants.OPERATORS.EQUAL_FOCUS },
      { value: CompConstants.OPERATORS.NOT_EQUAL_FOCUS },
      { value: CompConstants.OPERATORS.PLUS_MINUS_FROM_FOCUS },
      { value: CompConstants.OPERATORS.PLUS_MINUS_PERCENT_FROM_FOCUS },
      { value: CompConstants.OPERATORS.GREATER_THAN_FOCUS },
      { value: CompConstants.OPERATORS.GREATER_THAN_EQUAL_FOCUS },
      { value: CompConstants.OPERATORS.LESS_THAN_FOCUS },
      { value: CompConstants.OPERATORS.LESS_THAN_EQUAL_FOCUS }
    ]);

    if (addRankTop) {
      list.push({ value: CompConstants.OPERATORS.RANK_TOP });
    }

    return list;
  }

  getDateOperators() {
    return [
      { value: CompConstants.OPERATORS.EQUAL },
      { value: CompConstants.OPERATORS.GREATER_THAN },
      { value: CompConstants.OPERATORS.GREATER_THAN_EQUAL },
      { value: CompConstants.OPERATORS.LESS_THAN },
      { value: CompConstants.OPERATORS.LESS_THAN_EQUAL },
      { value: CompConstants.OPERATORS.NOT_EQUAL },
      { value: CompConstants.OPERATORS.BETWEEN },
      { value: CompConstants.OPERATORS.EQUAL_FOCUS },
      { value: CompConstants.OPERATORS.NOT_EQUAL_FOCUS },
      { value: CompConstants.OPERATORS.PLUS_MINUS_FROM_FOCUS },
      { value: CompConstants.OPERATORS.GREATER_THAN_FOCUS },
      { value: CompConstants.OPERATORS.GREATER_THAN_EQUAL_FOCUS },
      { value: CompConstants.OPERATORS.LESS_THAN_FOCUS },
      { value: CompConstants.OPERATORS.LESS_THAN_EQUAL_FOCUS }
    ];
  }

  getTextOperators() {
    return [
      { value: CompConstants.OPERATORS.EQUAL },
      { value: CompConstants.OPERATORS.NOT_EQUAL },
      { value: CompConstants.OPERATORS.EQUAL_FOCUS },
      { value: CompConstants.OPERATORS.NOT_EQUAL_FOCUS }
    ];
  }

  getOperators(criterion, avpl = false, platformYears = false) {
    if (!criterion) {
      return [];
    }

    let isRoundSelectedR4 = "Round Selected (Rule 4)" === criterion.name;

    if ("Numeric" === criterion["@dataType"] || isRoundSelectedR4) {
      // Include "rank" option on AVPL for stats
      let addRankTop = avpl && !platformYears && criterion.path.startsWith("Statistics");

      return this.getNumericalOperators(isRoundSelectedR4, addRankTop);
    } else if ("DateTime" === criterion["@dataType"]) {
      return this.getDateOperators();
    } else if ("String" === criterion["@dataType"]) {
      return this.getTextOperators();
    }
  }

  updateOperand1(th, criterion = {}, replaceOperands = true) {
    if (!th || !th.criteriaName) {
      return;
    }

    // clear out values
    th.numeric = false;
    th.date = false;
    th.text = false;
    th.haslov = false;

    if (replaceOperands) {
      th.operand1 = null;
      th.operand2 = null;
    }

    // for focus comparisons, all values should be false
    // otherwise set type of criteria chosen
    if (
      CompConstants.OPERATORS.EQUAL_FOCUS !== th.comparison &&
      CompConstants.OPERATORS.NOT_EQUAL_FOCUS !== th.comparison &&
      CompConstants.OPERATORS.GREATER_THAN_FOCUS !== th.comparison &&
      CompConstants.OPERATORS.GREATER_THAN_EQUAL_FOCUS !== th.comparison &&
      CompConstants.OPERATORS.LESS_THAN_FOCUS !== th.comparison &&
      CompConstants.OPERATORS.LESS_THAN_EQUAL_FOCUS !== th.comparison
    ) {
      // set LOV flagand LOV itself if necessary
      if (criterion.lov && criterion.lov.length > 0) {
        th.haslov = true;
        th.operandlist = _.filter(criterion.lov, op => op.lovValue && "" !== op.lovValue);
      }

      // set object type flags
      if ("Numeric" === criterion["@dataType"] || CompConstants.OPERATORS.PLUS_MINUS_FROM_FOCUS === th.comparison) {
        th.numeric = true;
      } else if ("DateTime" === criterion["@dataType"]) {
        th.date = true;

        if (CompConstants.OPERATORS.PLUS_MINUS_FROM_FOCUS !== th.comparison) {
          th.operand1 = Moment(new Date()).format("MM/DD/YYYY");
          th.operand2 = Moment(new Date()).format("MM/DD/YYYY");
        }
      } else if ("String" === criterion["@dataType"]) {
        th.text = true;
      }
    }
  }

  getStatShortName(objectType, objectCategory, objectName) {
    if (!objectType || !objectCategory || !objectName) {
      return "";
    }

    let { lookupStore } = this.props.rootStore;
    let stattypes = lookupStore.getCollection("stattypesnoblacklist");
    let type = stattypes[objectType];

    // Find the category within the type
    if (type && type.categories) {
      type.categories.forEach(category => {
        if (objectCategory === category.category && category.stats) {
          // Find the stat
          category.stats.forEach(stat => {
            if (objectName === stat.objectName) {
              return stat.statCode || stat.statDisplayName;
            }
          });
        }
      });
    }

    return objectName;
  }

  getIndentSpacers(th, index) {
    let cols = [];

    for (let ii = th.groupLevel; ii > 0; ii--) {
      let lastGroupIndex = this.getLastIndexInGroup(index, ii);

      console.log("row " + index + " ii " + ii + " lgi " + lastGroupIndex);

      let el = (
        <IndentSpacer
          key={"indent_" + index + "_" + ii}
          width={this.props.advanced && th.groupLevel ? "5%" : "0%"}
          marginBottom={index >= lastGroupIndex ? "0px" : "-" + Theme["size-gutter"]}
        >
          <IndentLine />
        </IndentSpacer>
      );
      cols.unshift(el);
    }

    return <React.Fragment>{cols}</React.Fragment>;
  }

  getLastIndexInGroup(index, groupLevel) {
    if (groupLevel) {
      while (index < this.props.thresholds.length - 1 && this.props.thresholds[index + 1].groupLevel >= groupLevel) {
        index++;
      }
    } else {
      while (index < this.props.thresholds.length - 1 && !this.props.thresholds[index].trailingparens) {
        index++;
      }
    }

    return index;
  }

  getAdvThresholdRows(thresholds) {
    let { lookupStore } = this.props.rootStore;
    let list = [];
    let andor = lookupStore.getAndOrOptions();

    thresholds.forEach((th, index) => {
      let types = th.types || [];
      let categories = th.categories || [];
      let criteria = th.criteria || [];
      let comparisons = th.comparisons || [];
      let operandlist = th.operandlist || [];

      let elAdv = (
        <div
          key={"threshold" + index}
          className={
            "criterion-wrapper row mx-0" +
            (index === 0 ? "" : " my-1") +
            (th.children && th.children.length > 0 ? " group" : "")
          }
        >
          <div className={"d-flex justify-content-start col-12 p-0 pt-2 pb-2" + (th.groupHeader ? " flex-column" : "")}>
            {/*Add dropdown section*/}
            <div className={th.groupHeader ? "group-header pl-3" : "col-2 col-xl-1"}>
              <div className={index === 0 ? "d-none" : "d-flex"}>
                <BestSelect
                  name="andor"
                  value={index > 0 ? lookupStore.getDropdownItem(andor, thresholds[index - 1].andor) : 0}
                  onChange={option => {
                    thresholds[index - 1].andor = option.value;
                  }}
                  options={andor}
                  styles={FooterLightSelectStyles}
                />
                <div className={th.groupHeader ? " d-flex" : " d-none"}>
                  <BestLabel className="pl-2 mt-1">Group</BestLabel>
                </div>
              </div>
            </div>

            {/*Criteria section*/}
            <div className={"d-flex flex-column" + (th.groupHeader ? " col-12 pt-2" : " col-10 col-xl-11")}>
              {th.groupHeader && th.children && th.children.length > 0 ? (
                this.getAdvThresholdRows(th.children)
              ) : (
                <div>
                  <div className={"mb-2" + (!!th.groupHeader ? " d-none" : " d-flex")}>
                    <div className="col-3 pl-0 d-flex align-items-center">
                      <BestSelect
                        name={"thCriteriaType" + index}
                        options={types}
                        onChange={option => {
                          th.criteriaType = option.name;
                          this.updateCategories(th, option);
                        }}
                        value={th.typeOption}
                        getOptionValue={option => option.name}
                        getOptionLabel={option => option.name}
                        portal={document.body}
                        styles={FormSelectStyles}
                      />
                    </div>

                    <div className="col-3 pl-0 d-flex align-items-center">
                      <BestSelect
                        name={"thCriteriaCategory" + index}
                        options={categories}
                        onChange={option => {
                          th.criteriaCategory = option.name;
                          this.updateCriteria(th, option);
                        }}
                        value={th.categoryOption}
                        getOptionValue={option => option.name}
                        getOptionLabel={option => option.name}
                        portal={document.body}
                        styles={FormSelectStyles}
                      />
                    </div>

                    <div className="d-flex justify-content-end control-buttons">
                      <div className={"col-10 col-xl-8 pl-0 item" + (th.groupHeader ? " d-none" : " d-flex")}>
                        <BestSelect
                          name="addOptions"
                          value={-1}
                          onChange={option => {
                            let row =
                              thresholds.groupHeader && thresholds.children && thresholds.children.length > 0
                                ? thresholds
                                : th;

                            switch (option.value) {
                              case 0:
                                this.addRow(thresholds, index + 1);
                                break;
                              case 1:
                                this.addGroup(thresholds, index + 1);
                                break;
                              default:
                                break;
                            }
                          }}
                          options={[
                            { value: 0, label: "Add Row" },
                            { value: 1, label: "Add Group" }
                          ]}
                          placeholder="ADD"
                          styles={FormMiniSelectButtonStyles}
                        />
                      </div>

                      <div className={"col-1 col-xl-2 item" + (th.groupHeader ? " d-none" : " d-flex")}>
                        <Icon
                          className="primary-icon"
                          iconName="fa-clone"
                          onClick={() => {
                            let row = _.cloneDeep(thresholds[index]);
                            thresholds.push(row);
                          }}
                        />
                      </div>

                      <div className={"col-1 col-xl-2 item" + (th.groupHeader ? " d-none" : " d-flex")}>
                        <Icon
                          className={
                            this.props.advanced && this.hideCriteriaTrash(th, index, thresholds)
                              ? "d-none"
                              : "mlb-red-icon"
                          }
                          iconName="fa-trash-alt"
                          onClick={() => {
                            this.deleteRow(th, index, thresholds);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className={!!th.groupHeader ? " d-none" : " d-flex"}>
                    <div className="col-3 pl-0 d-flex align-items-center">
                      <BestSelect
                        name={"thCriteriaName" + index}
                        options={criteria}
                        onChange={option => {
                          th.criteriaName = option.name;
                          //th.criteriaShortName = this.getStatShortName(th.criteriaType, th.criteriaCategory, th.criteriaName);
                          this.updateComparisons(th, option);
                        }}
                        value={th.criteriaOption}
                        getOptionValue={option => option.name}
                        getOptionLabel={option => option.displayName}
                        portal={document.body}
                        styles={FormSelectStyles}
                      />
                    </div>

                    <div className="col-3 pl-0 d-flex align-items-center">
                      <BestSelect
                        name={"thCriteriaComparison" + index}
                        options={comparisons}
                        onChange={option => {
                          th.comparison = option.value;
                          this.updateOperand1(th, th.criteriaOption);
                        }}
                        value={lookupStore.getDropdownItem(comparisons, th.comparison, "value")}
                        getOptionLabel={option => option.value}
                        portal={document.body}
                        styles={FormSelectStyles}
                      />
                    </div>

                    <div
                      className={
                        "col-3 pl-0 align-items-center" +
                        (!th.precondition && th.comparison && "" !== th.comparison ? " d-flex" : " d-none")
                      }
                    >
                      {th.haslov ? (
                        <BestSelect
                          name={"thOperand1" + index}
                          options={operandlist}
                          onChange={option => {
                            th.operand1 = option.lovValue;
                          }}
                          value={lookupStore.getDropdownItem(operandlist, th.operand1, "lovValue", "displayValue")}
                          getOptionValue={option => option.lovValue}
                          getOptionLabel={option => option.displayValue}
                          portal={document.body}
                          styles={FormSelectStyles}
                        />
                      ) : th.numeric || CompConstants.OPERATORS.PLUS_MINUS_FROM_FOCUS === th.comparison ? (
                        <div className="table-input">
                          <Input
                            id={"thOperand1" + index}
                            type="number"
                            onChange={event => {
                              th.operand1 = event.target.value + "";
                            }}
                            value={"" === th.operand1 ? null : th.operand1 * 1}
                          />
                        </div>
                      ) : th.date ? (
                        <div className="table-input">
                          <DatePickerInput
                            id={"thOperand1" + index}
                            value={Moment(th.operand1, "MM/DD/YYYY")}
                            onChange={date => {
                              th.operand1 = date ? Moment(date).format("MM/DD/YYYY") : date;
                            }}
                          />
                        </div>
                      ) : th.text ? (
                        <div className="table-input">
                          <Input
                            id={"thOperand1" + index}
                            onChange={event => {
                              th.operand1 = event.target.value;
                            }}
                            value={!th.operand1 ? "" : th.operand1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>

                    <div
                      className={
                        "col-3 pl-0" +
                        (!th.precondition && CompConstants.OPERATORS.BETWEEN === th.comparison ? " d-flex" : " d-none")
                      }
                    >
                      {th.numeric ? (
                        <div className="table-input">
                          <Input
                            id={"thOperand2" + index}
                            type="number"
                            onChange={event => {
                              th.operand2 = event.target.value + "";
                            }}
                            value={"" === th.operand2 ? null : th.operand2 * 1}
                          />
                        </div>
                      ) : th.date ? (
                        <div className="table-input">
                          <DatePickerInput
                            id={"thOperand2" + index}
                            value={Moment(th.operand2, "MM/DD/YYYY")}
                            onChange={date => {
                              th.operand2 = date ? Moment(date).format("MM/DD/YYYY") : date;
                            }}
                          />
                        </div>
                      ) : th.text ? (
                        <div className="table-input">
                          <Input
                            id={"thOperand2" + index}
                            onChange={event => {
                              th.operand2 = event.target.value;
                            }}
                            value={!th.operand2 ? "" : th.operand2}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/*button section*/}
          </div>
        </div>
      );

      list.push(elAdv);
    });

    return <React.Fragment>{list}</React.Fragment>;
  }

  getThresholdRows(thresholds) {
    let { lookupStore } = this.props.rootStore;
    let list = [];
    let andor = lookupStore.getAndOrOptions();

    thresholds.forEach((th, index) => {
      let types = th.types || [];
      let categories = th.categories || [];
      let criteria = th.criteria || [];
      let comparisons = th.comparisons || [];
      let operandlist = th.operandlist || [];

      let el = (
        <div key={"threshold" + index} className={"row mx-0 " + (index === 0 ? "" : " my-1")}>
          <div className="d-flex justify-content-end col-12 p-0">
            {this.props.advanced && th.groupLevel ? this.getIndentSpacers(th, index) : <span></span>}
            <div className={"w-" + (this.props.advanced ? 100 - th.groupLevel * 5 : "100")}>
              <TitleCard backgroundColor={!!th.groupHeader ? Theme.fog : Theme["light-blue"]}>
                <div className="col-12 p-0 row m-0">
                  {this.props.advanced ? (
                    <div className="row m-0 p-0 col-1 align-items-center">
                      {index > 0 ? (
                        <div className="col-12 pl-0">
                          <BestSelect
                            name="andor"
                            value={lookupStore.getDropdownItem(andor, this.props.thresholds[index - 1].andor)}
                            onChange={option => {
                              this.props.thresholds[index - 1].andor = option.value;
                            }}
                            options={andor}
                            styles={FooterLightSelectStyles}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="row m-0 p-0 col-3">
                    {!!th.groupHeader ? (
                      <div className="d-flex align-items-center">
                        <BestLabel>Group</BestLabel>
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="col-6 pl-0 d-flex align-items-center">
                          <BestSelect
                            name={"thCriteriaType" + index}
                            options={types}
                            onChange={option => {
                              th.criteriaType = option.name;
                              this.updateCategories(th, option);
                            }}
                            value={th.typeOption}
                            getOptionValue={option => option.name}
                            getOptionLabel={option => option.name}
                            portal={document.body}
                            styles={FormSelectStyles}
                          />
                        </div>
                        <div className="col-6 pl-0 d-flex align-items-center">
                          <BestSelect
                            name={"thCriteriaCategory" + index}
                            options={categories}
                            onChange={option => {
                              th.criteriaCategory = option.name;
                              this.updateCriteria(th, option);
                            }}
                            value={th.categoryOption}
                            getOptionValue={option => option.name}
                            getOptionLabel={option => option.name}
                            portal={document.body}
                            styles={FormSelectStyles}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </div>

                  <div className="col-3 pl-0 d-flex align-items-center">
                    {!!th.groupHeader ? (
                      ""
                    ) : (
                      <BestSelect
                        name={"thCriteriaName" + index}
                        options={criteria}
                        onChange={option => {
                          th.criteriaName = option.name;
                          this.updateComparisons(th, option);
                        }}
                        value={th.criteriaOption}
                        getOptionValue={option => option.name}
                        getOptionLabel={option => option.displayName}
                        portal={document.body}
                        styles={FormSelectStyles}
                      />
                    )}
                  </div>

                  <div className="row m-0 col-4 pl-0">
                    {!!th.groupHeader ? (
                      ""
                    ) : (
                      <React.Fragment>
                        <div className="col-4 pl-0 d-flex align-items-center">
                          {th.precondition ? (
                            ""
                          ) : (
                            <BestSelect
                              name={"thCriteriaComparison" + index}
                              options={comparisons}
                              onChange={option => {
                                th.comparison = option.value;
                                this.updateOperand1(th, th.criteriaOption);
                              }}
                              value={lookupStore.getDropdownItem(comparisons, th.comparison, "value")}
                              getOptionLabel={option => option.value}
                              portal={document.body}
                              styles={FormSelectStyles}
                            />
                          )}
                        </div>

                        <div className="col-4 pl-0 d-flex align-items-center">
                          {!th.precondition && th.comparison && "" !== th.comparison ? (
                            <React.Fragment>
                              {th.haslov ? (
                                <BestSelect
                                  name={"thOperand1" + index}
                                  options={operandlist}
                                  onChange={option => {
                                    th.operand1 = option.lovValue;
                                  }}
                                  value={lookupStore.getDropdownItem(
                                    operandlist,
                                    th.operand1,
                                    "lovValue",
                                    "displayValue"
                                  )}
                                  getOptionValue={option => option.lovValue}
                                  getOptionLabel={option => option.displayValue}
                                  portal={document.body}
                                  styles={FormSelectStyles}
                                />
                              ) : th.numeric || CompConstants.OPERATORS.PLUS_MINUS_FROM_FOCUS === th.comparison ? (
                                <div className="table-input">
                                  <Input
                                    id={"thOperand1" + index}
                                    type="number"
                                    onChange={event => {
                                      th.operand1 = event.target.value + "";
                                    }}
                                    value={"" === th.operand1 ? null : th.operand1 * 1}
                                  />
                                </div>
                              ) : th.date ? (
                                <div className="table-input">
                                  <DatePickerInput
                                    id={"thOperand1" + index}
                                    value={Moment(th.operand1, "MM/DD/YYYY")}
                                    onChange={date => {
                                      th.operand1 = date ? Moment(date).format("MM/DD/YYYY") : date;
                                    }}
                                  />
                                </div>
                              ) : th.text ? (
                                <div className="table-input">
                                  <Input
                                    id={"thOperand1" + index}
                                    onChange={event => {
                                      th.operand1 = event.target.value;
                                    }}
                                    value={!th.operand1 ? "" : th.operand1}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-4 pl-0">
                          {!th.precondition && CompConstants.OPERATORS.BETWEEN === th.comparison ? (
                            <React.Fragment>
                              {th.numeric ? (
                                <div className="table-input">
                                  <Input
                                    id={"thOperand2" + index}
                                    type="number"
                                    onChange={event => {
                                      th.operand2 = event.target.value + "";
                                    }}
                                    value={"" === th.operand2 ? null : th.operand2 * 1}
                                  />
                                </div>
                              ) : th.date ? (
                                <div className="table-input">
                                  <DatePickerInput
                                    id={"thOperand2" + index}
                                    value={Moment(th.operand2, "MM/DD/YYYY")}
                                    onChange={date => {
                                      th.operand2 = date ? Moment(date).format("MM/DD/YYYY") : date;
                                    }}
                                  />
                                </div>
                              ) : th.text ? (
                                <div className="table-input">
                                  <Input
                                    id={"thOperand2" + index}
                                    onChange={event => {
                                      th.operand2 = event.target.value;
                                    }}
                                    value={!th.operand2 ? "" : th.operand2}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>

                  <div className={(this.props.advanced ? "col-1 p-0 " : "") + "item-row flex-fill h-inherit"}>
                    {this.props.advanced && th.groupLevel < 5 && (
                      <div className="item flex-fill">
                        <BestSelect
                          name="addOptions"
                          value={-1}
                          onChange={option => {
                            switch (option.value) {
                              case 0:
                                this.addRow(th, index + 1);
                                break;
                              case 1:
                                this.addGroup(th, index + 1);
                                break;
                              default:
                                break;
                            }
                          }}
                          options={[
                            { value: 0, label: "Add Row" },
                            { value: 1, label: "Add Group" }
                          ]}
                          placeholder="ADD"
                          styles={FormMiniSelectButtonStyles}
                        />
                      </div>
                    )}
                    {!(this.props.comp || !!th.groupHeader) && (
                      <span className="item">
                        <Icon
                          className="primary-icon"
                          iconName="fa-clone"
                          onClick={() => {
                            let row = _.cloneDeep(this.props.thresholds[index]);
                            this.props.thresholds.push(row);
                          }}
                        />
                      </span>
                    )}
                    <span className="item">
                      <Icon
                        className={
                          this.props.advanced && this.hideCriteriaTrash(th, index, thresholds)
                            ? "d-none"
                            : "mlb-red-icon"
                        }
                        iconName="fa-trash-alt"
                        onClick={() => {
                          this.deleteRow(th, index);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </TitleCard>
            </div>
          </div>
        </div>
      );

      list.push(el);
    });

    return <React.Fragment>{list}</React.Fragment>;
  }

  hideCriteriaTrash(row, index, thresholds) {
    if ((row.groupLevel === 0 && index === 0) || (thresholds.length === 1 && row.groupLevel === 0)) {
      return true;
    }

    return false;
  }

  addRow(prevRow, index) {
    let row = { selectedFlg: true, types: this.state.criteriaTypes };

    if (this.props.advanced) {
      let selectedRow = prevRow[index - 1];
      row.andor = "AND";

      let groupLevel = selectedRow.groupLevel !== undefined ? selectedRow.groupLevel : 0;
      row.groupLevel = groupLevel;

      if (selectedRow.groupHeader) {
        let lastGroupIndex = this.getLastIndexInGroup(index);
        row.leadingparens = 0;
        row.trailingparens = selectedRow.groupLevel;
        prevRow.splice(index, 0, row);
      } else {
        if (selectedRow.trailingparens) {
          row.trailingparens = selectedRow.trailingparens;
          selectedRow.trailingparens = 0;
        }
        prevRow.splice(index, 0, row);
      }

      //this.updateGroups();
    } else {
      this.props.thresholds.push(row);
    }
  }

  addGroup(parent, index) {
    let selectedRow = parent[index - 1];

    let group = {
      selectedFlg: true,
      types: this.state.criteriaTypes,
      andor: "AND",
      groupHeader: true,
      children: []
    };

    //Add a child row to the group
    let groupLevel = selectedRow.groupLevel !== undefined ? selectedRow.groupLevel + 1 : 0;
    let row = {
      selectedFlg: true,
      types: this.state.criteriaTypes,
      andor: "AND",
      leadingparens: 1,
      trailingparens: 1,
      firstInGroup: true,
      groupLevel: groupLevel
    };

    if (selectedRow.trailingparens) {
      row.trailingparens += selectedRow.trailingparens;
      selectedRow.trailingparens = 0;
    }

    group.children.splice(0, 0, row);
    parent.splice(index, 0, group);

    //this.updateGroups();
  }

  addEligibleForBattingTitle() {
    let row = {
      selectedFlg: true,
      types: this.state.criteriaTypes,
      criteriaType: "Statistics",
      criteriaCategory: "Batting",
      criteriaName: "Batting Title Elig Flag",
      precondition: false,
      comparison: CompConstants.OPERATORS.EQUAL,
      operand1: "Y"
    };

    this.updateCategories(row, null, false);
    this.updateCriteria(row, null, false);
    this.updateComparisons(row, null, false);

    if (
      this.props.thresholds.length === 1 &&
      (!this.props.thresholds[0].criteriaType || "" === this.props.thresholds[0].criteriaType)
    ) {
      this.props.thresholds[0] = row;
    } else {
      this.props.thresholds.push(row);
    }
  }

  deleteRow(row, index, thresholds) {
    if (this.props.advanced) {
      thresholds.splice(index, 1);
      this.updateGroups(this.props.thresholds, 0);
    } else {
      this.props.thresholds.splice(index, 1);
    }
  }

  render() {
    return (
      <React.Fragment>
        {!this.state.criteriaLoaded
          ? ""
          : this.props.advanced
          ? this.getAdvThresholdRows(this.props.thresholds)
          : this.getThresholdRows(this.props.thresholds)}
        {this.props.advanced ? (
          ""
        ) : (
          <div className="mt-4 item-row h-inherit">
            <PrimaryButton
              onClick={() => {
                this.addRow();
              }}
            >
              {this.props.comp ? "Add" : "Add Criteria"}
            </PrimaryButton>
            {this.props.comp ? (
              <LightButton
                onClick={() => {
                  this.addEligibleForBattingTitle();
                }}
              >
                Add Eligible for Batting Title
              </LightButton>
            ) : (
              ""
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

PlayerListCriteriaTable.defaultProps = {
  comp: true,
  advanced: false
};

PlayerListCriteriaTable.propTypes = {
  rootStore: PropTypes.object.isRequired,
  thresholds: PropTypes.array.isRequired,
  comp: PropTypes.bool,
  advanced: PropTypes.bool
};

export default inject("rootStore")(observer(PlayerListCriteriaTable));
