import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { CardContainer, DateTimeFormatter, Icon } from "best-common-react";
import PiaTable from "../widgets/PiaTable";
import TitleCard from "../widgets/TitleCard";

class SavedPlayerLists extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      playersToAdd: []
    };
  }

  getSavedListCols() {
    let { playerStore, piaApi } = this.props.rootStore;
    return [
      {
        key: "icon-add",
        name: "",
        width: 60,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Icon
                iconName="fa-plus-square"
                onClick={() => {
                  piaApi.getPlayerList(row.row.playerlistId).then(response => {
                    if (response) {
                      playerStore.addPlayersToList(response.plDetails);
                    }
                  });
                }}
                styles={{ size: "2em" }}
              />
            </div>
          );
        }
      },
      {
        key: "playerlistname",
        name: "Player List Name",
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                piaApi.getPlayerList(row.row.playerlistId).then(response => {
                  if (response) {
                    this.setState({ playersToAdd: response.plDetails || [] });
                  }
                });
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      {
        key: "lastchDtstmpAmericaNY",
        name: "Last Updated",
        width: 160,
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      },
      {
        key: "focusplayername",
        name: "Focus Player Name",
        width: 200,
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                playerStore.openPlayerProfile(row.row.focusplayerId);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "source", name: "Source", width: 160 }
    ];
  }

  getPlayerCols(playerStore) {
    return [
      {
        key: "icon-add",
        name: "",
        width: 60,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Icon
                iconName="fa-plus-square"
                onClick={() => {
                  playerStore.addPlayersToList([row.row]);
                }}
                styles={{ size: "2em" }}
              />
            </div>
          );
        }
      },
      {
        key: "playername",
        name: "Player Name",
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                playerStore.openPlayerProfile(row.row.playerid);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "platformYear", name: "Platform Year", width: 110 },
      { key: "orgLk", name: "Club", width: 50 },
      { key: "eosmls", name: "MLS", width: 60 },
      { key: "position", name: "Pos 1", width: 50 },
      { key: "position2", name: "Pos 2", width: 50 }
    ];
  }

  render() {
    let { playerStore } = this.props.rootStore;

    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-7">
                <div className="row mb-2">
                  <div className="col-12">
                    <TitleCard>Saved Player Lists</TitleCard>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <PiaTable
                      data={playerStore.displayLists}
                      columns={this.getSavedListCols()}
                      height={playerStore.calculateTableHeight(playerStore.displayLists.length, 336)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-5">
                <div className="row mb-2">
                  <div className="col-12">
                    <TitleCard>Add Player from Saved Player List</TitleCard>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <PiaTable
                      data={this.state.playersToAdd}
                      columns={this.getPlayerCols(playerStore)}
                      height={playerStore.calculateTableHeight(this.state.playersToAdd.length, 336)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContainer.Body>
      </CardContainer>
    );
  }
}

SavedPlayerLists.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(SavedPlayerLists)));
