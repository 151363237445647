export const AlertConstants = {
  TYPES: {
    SUCCESS: "success",
    INFO: "info",
    DANGER: "danger",
    DANGER_PERSIST: "danger_persist", // Danger that does not self-close
    WARNING: "warning",
    WARNING_PERSIST: "warning_persist" // Warning that does not self-close
  }
};
