import React from "react";
import { inject, observer } from "mobx-react";
import { FormInput, Modal, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";

class SaveQueryModal extends React.Component {
  render() {
    let { playerStore } = this.props.rootStore;

    return (
      <Modal show={!!playerStore.playerList.saveQueryModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Save Query"
          close={() => {
            playerStore.playerList.saveQueryModalOpen = false;
          }}
        />
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <FormInput
                name="loadedQueryName"
                label="Query Name"
                type="text"
                value={playerStore.listCriteria.playerQueryName}
                onChangeFn={value => {
                  playerStore.listCriteria.playerQueryName = value;
                }}
                editable
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            <PrimaryButton
              onClick={() => {
                playerStore.saveQuery();
                playerStore.playerList.saveQueryModalOpen = false;
              }}
            >
              Save
            </PrimaryButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

SaveQueryModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(SaveQueryModal));
