export const CompConstants = {
  OPERATORS: {
    BETWEEN: "between",
    EQUAL: "=",
    EQUAL_FOCUS: "=focus",
    GREATER_THAN: ">",
    GREATER_THAN_EQUAL: ">=",
    GREATER_THAN_EQUAL_FOCUS: ">=focus",
    GREATER_THAN_FOCUS: ">focus",
    LESS_THAN: "<",
    LESS_THAN_EQUAL: "<=",
    LESS_THAN_EQUAL_FOCUS: "<=focus",
    LESS_THAN_FOCUS: "<focus",
    NOT_EQUAL: "not =",
    NOT_EQUAL_FOCUS: "not =focus",
    PLUS_MINUS_FROM_FOCUS: "+/- from focus",
    PLUS_MINUS_PERCENT_FROM_FOCUS: "+/- % from focus",
    RANK_TOP: "ranked in top"
  },
  TABS: {
    ONE_ON_ONE: "OneonOne",
    AWARD: "Award",
    RANKINGS: "Ranking",
    STAT_SUMMARY: "StatSummary",
    INDUSTRY: "Industry",
    PERFORMANCE: "Performance",
    PLAYER: "Player",
    CONTRACT: "Contract"
  },
  TIMEFRAME_REPORT_TYPES: {
    ONE_ON_ONE: "1"
  }
};
