export const SharingConstants = {
  TYPE: {
    COMPARISONS: 0,
    PLAYER_LISTS: 1,
    STAT_LISTS: 2,
    STATS: 3,
    PLAYER_QUERIES: 4,
    REPORTS: 5
  }
};
