import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { DateTimeFormatter, LightButton } from "best-common-react";
import PiaTable from "../widgets/PiaTable";
import TitleCard from "../widgets/TitleCard";

class SharingHistory extends React.Component {
  getOtherHistoryCols() {
    return [
      { key: "description", name: "Name", width: 300 },
      { key: "shareType", name: "Type", width: 150 },
      { key: "shareToUserId", name: "Shared With", width: 250 },
      {
        key: "shareTimestamp",
        name: "Time",
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      }
    ];
  }

  getUserHistoryCols() {
    return [
      { key: "description", name: "Name", width: 300 },
      { key: "shareType", name: "Type", width: 150 },
      { key: "shareFromUserId", name: "Shared By", width: 250 },
      {
        key: "shareTimestamp",
        name: "Time",
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      }
    ];
  }

  render() {
    let { sharingStore } = this.props.rootStore;
    return (
      <div className="container-fluid">
        <div className="row mx-0 pt-4">
          <div className="col-12">
            <TitleCard>
              <span>Shared With Others</span>
            </TitleCard>
          </div>
        </div>

        <div className="row mx-0 my-4">
          <div className="col-12">
            <PiaTable
              data={sharingStore.historyWithOthers}
              columns={this.getOtherHistoryCols()}
              height={sharingStore.calculateTableHeight(sharingStore.historyWithOthers.length, 308)}
            />
          </div>
        </div>

        <div className="row mx-0 my-4 col-12 justify-content-end">
          <LightButton
            onClick={() => {
              sharingStore.exportList(sharingStore.historyWithOthers);
            }}
          >
            Export to Excel
          </LightButton>
        </div>

        <div className="row mx-0 pt-2">
          <div className="col-12">
            <TitleCard>
              <span>Others Shared With Me</span>
            </TitleCard>
          </div>
        </div>

        <div className="row mx-0 my-4">
          <div className="col-12">
            <PiaTable
              data={sharingStore.historyWithUser}
              columns={this.getUserHistoryCols()}
              height={sharingStore.calculateTableHeight(sharingStore.historyWithUser.length, 308)}
            />
          </div>
        </div>

        <div className="row mx-0 pb-4 col-12 justify-content-end">
          <LightButton
            onClick={() => {
              sharingStore.exportList(sharingStore.historyWithUser);
            }}
          >
            Export to Excel
          </LightButton>
        </div>
      </div>
    );
  }
}

SharingHistory.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(SharingHistory)));
