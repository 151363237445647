import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import EditStatList from "../../components/statistics/EditStatList";
import OverrideModal from "../../components/alerts/OverrideModal";
import { RouteConstants } from "../../constants/RouteConstants";

class EditStatListContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.EDIT_STAT_LIST.title);
  }

  render() {
    let { statStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <OverrideModal
          isOpen={!!statStore.statList.showOverride}
          title="Warning"
          text={statStore.statList.overrideMessage || ""}
          onConfirm={() => {
            if (statStore.statList.overrideFn) {
              statStore.statList.overrideFn();
            }
          }}
          onCancel={() => {
            statStore.statList.showOverride = undefined;
          }}
        />
        <div className="container-fluid">
          <div className="col-12 p-0 mb-4">
            <EditStatList />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

EditStatListContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(EditStatListContainer));
