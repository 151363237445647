import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { CardContainer, LightButton } from "best-common-react";
import AdditionalOptions from "./AdditionalOptions";
import { CompConstants } from "../../constants/CompConstants";
import { ReportConstants } from "../../constants/ReportConstants";

class ContractComp extends React.Component {
  render() {
    let { trackingHelper } = this.props.rootStore;
    return (
      <React.Fragment>
        <CardContainer>
          <CardContainer.Body>
            <div className="container-fluid">
              <div className="row mx-0">
                <AdditionalOptions tab={CompConstants.TABS.CONTRACT} />
              </div>
            </div>
          </CardContainer.Body>
          <CardContainer.Footer>
            <div className="header-item-row">
              <LightButton
                onClick={() => {
                  trackingHelper.trackClickAndRunComp(ReportConstants.REPORT_CONTRACT, "Run Contract Report Click");
                }}
              >
                Run Contract Report
              </LightButton>
            </div>
          </CardContainer.Footer>
        </CardContainer>
      </React.Fragment>
    );
  }
}

ContractComp.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ContractComp));
