import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

const Widget = styled.div`
  padding-top: ${props => props.theme["size-gutter-num"] * 2 + "px"};

  #best-card-header {
    height: 36px;
    padding: 9px;
    padding-right: 4px;
    line-height: normal;
    color: ${props => props.theme["dark-blue"]};
    border-bottom: solid 2px ${props => props.theme["primary"]} !important;

    .header-title {
      font-size: 14px;
    }

    .btn {
      margin-right: ${props => props.theme["size-gutter"]};
      padding-top: 0;
      padding-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  #best-card-body {
    background-color: #ffffff;
    padding: ${props => props.theme["size-gutter"]};
  }

  #best-card-footer {
    background-color: ${props => props.theme["light-grey"]};
    height: 36px;
    padding: 9px;
    padding-right: 4px;

    .footer-option,
    .btn {
      margin-right: ${props => props.theme["size-gutter"]};
      padding-top: 0;
      padding-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.simple-footer {
    #best-card-footer {
      background-color: white;
    }
  }

  &.table-container {
    #best-card-body {
      padding: 0;
    }

    #best-card-footer {
      border-bottom: none;
      background-color: white;
    }

    .react-grid-Main {
      outline: none;
    }
  }

  & > div {
    border-radius: 4px;
    border: solid 1px ${props => props.theme["grey-three"]};
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  }
`;

class PiaWidget extends Component {
  render() {
    let { children, className = "" } = this.props;

    return <Widget className={className}>{children}</Widget>;
  }
}

PiaWidget.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string
};

export default inject("rootStore")(withTheme(observer(PiaWidget)));
