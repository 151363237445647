import React from "react";
import { inject, observer } from "mobx-react";
import _ from "lodash";
import Moment from "moment";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import {
  BestLabel,
  BestQuickSearch,
  BestSelect,
  CardContainer,
  DangerButton,
  DateTimeFormatter,
  FormInput,
  LightButton,
  PrimaryButton
} from "best-common-react";
import { InlineHeader, FormSearchStyles, FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";
import { RouteConstants } from "../../constants/RouteConstants";

class ManageComps extends React.Component {
  getDisplayFolderOptions(compStore) {
    let folders = _.cloneDeep(compStore.folders);
    folders.unshift({ folderId: -1, description: "All Folders" });
    return folders;
  }

  render() {
    let { commonStore, compStore, lookupStore, playerStore, routerStore } = this.props.rootStore;
    let columns = compStore.getCompCols();
    let displayFolderOptions = this.getDisplayFolderOptions(compStore);
    let timeZone = commonStore.getTimeZone();
    const onRowsSelected = rows => {
      compStore.addSelectedComps(rows.map(row => row.row));
    };
    const onRowsDeselected = rows => {
      compStore.removeUnselectedComps(rows.map(row => row.row));
    };
    const RowRenderer = ({ renderBaseRow, ...props }) => {
      return (
        <div className={compStore.showCheckBox(props.row.standardFlg) ? "" : "removeCheckBox"}>
          {renderBaseRow(props)}
        </div>
      );
    };
    return (
      <React.Fragment>
        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Comparison Search</span>
            </CardContainer.Header>
            <CardContainer.Body>
              <div className="row m-0">
                <div className="col-3">
                  <FormInput
                    name="mcCompName"
                    label="Comparison Name"
                    type="text"
                    value={compStore.compSearch.comparisonName}
                    onChangeFn={value => {
                      compStore.compSearch.comparisonName = value;
                    }}
                    editable
                  />
                </div>
                <div className="col-3">
                  <div className="form-group">
                    <BestLabel>Focus Player</BestLabel>

                    <BestQuickSearch
                      options={lookupStore.autocompletePlayers}
                      renderSuggestion={suggestion => {
                        return <PlayerAutocompleteRow player={suggestion} />;
                      }}
                      getSuggestionValue={player => {
                        return playerStore.getAutocompleteText(player);
                      }}
                      getSuggestions={(players, searchTerm) => {
                        if (searchTerm.indexOf(")") === -1) {
                          compStore.compSearch.playerId = null;
                          compStore.compSearch.focusPlayerName = playerStore.formatForComparison(searchTerm);
                        }
                        return playerStore.filterBySearchTerm(players, searchTerm);
                      }}
                      onSuggestionSelected={(event, { suggestion }) => {
                        compStore.compSearch.playerId = suggestion.playerId;
                        compStore.compSearch.focusPlayerName = suggestion.playerName;
                      }}
                      styles={FormSearchStyles}
                      onClear={() => {
                        compStore.compSearch.playerId = null;
                        compStore.compSearch.focusPlayerName = null;
                      }}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <FormInput
                    name="mcLastUpdatedFrom"
                    label="Last Updated From"
                    type="date"
                    value={
                      compStore.compSearch.lastUpdatedFromDte
                        ? Moment(compStore.compSearch.lastUpdatedFromDte, "MM/DD/YYYY")
                        : compStore.compSearch.lastUpdatedFromDte
                    }
                    onChangeFn={date => {
                      compStore.compSearch.lastUpdatedFromDte = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                    formatter={DateTimeFormatter}
                    timeZone={timeZone}
                    autocomplete="off"
                    editable
                  />
                </div>

                <div className="col-3">
                  <FormInput
                    name="mcLastUpdatedTo"
                    label="Last Updated To"
                    type="date"
                    value={
                      compStore.compSearch.lastUpdatedThroughDte
                        ? Moment(compStore.compSearch.lastUpdatedThroughDte, "MM/DD/YYYY")
                        : compStore.compSearch.lastUpdatedThroughDte
                    }
                    onChangeFn={date => {
                      compStore.compSearch.lastUpdatedThroughDte = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                    formatter={DateTimeFormatter}
                    timeZone={timeZone}
                    autocomplete="off"
                    editable
                  />
                </div>
              </div>
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="row col-12 m-0 p-0 justify-content-end">
                <PrimaryButton
                  onClick={() => {
                    compStore.findComps();
                  }}
                >
                  Search
                </PrimaryButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>

        <PiaWidget className="col-12 table-container">
          <CardContainer>
            <CardContainer.Header>
              <div className="header-item-row justify-content-between">
                <span className="header-title">Search Results</span>
                <div className="d-flex col-6 pr-0 justify-content-end align-items-center">
                  <InlineHeader className="item">Show Folder</InlineHeader>

                  <div className="d-inline-flex w-50 m-0 item">
                    <div className="col-12 p-0">
                      <BestSelect
                        name="mcDisplayFolderId"
                        value={lookupStore.getDropdownItem(
                          displayFolderOptions,
                          compStore.displayFolderId,
                          "folderId",
                          "description"
                        )}
                        onChange={option => {
                          compStore.displayFolderId = option.folderId;
                        }}
                        options={displayFolderOptions}
                        getOptionValue={option => option.folderId}
                        getOptionLabel={option => option.description}
                        styles={FormSelectStyles}
                      />
                    </div>
                  </div>
                  <PrimaryButton
                    onClick={() => {
                      compStore.openFolderModal = true;
                    }}
                  >
                    Manage Folders
                  </PrimaryButton>
                </div>
              </div>
            </CardContainer.Header>
            <CardContainer.Body>
              {compStore.displayComps.length > 0 ? (
                <PiaTable
                  data={compStore.displayComps}
                  columns={columns}
                  rowSelection={{
                    showCheckbox: true,
                    onRowsSelected: onRowsSelected,
                    onRowsDeselected: onRowsDeselected,
                    selectBy: {
                      isSelectedKey: "isSelected"
                    },
                    enableShiftSelect: true
                  }}
                  rowRenderer={RowRenderer}
                  height={compStore.calculateTableHeight(compStore.displayComps.length, 308)}
                />
              ) : (
                <div className="col-12">No Results</div>
              )}
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row justify-content-between">
                <div className="d-flex col-6 pl-0 align-items-center">
                  <InlineHeader className="item">Move To Folder</InlineHeader>

                  <div className="d-inline-flex w-50 m-0 item">
                    <div className="col-12 p-0">
                      <BestSelect
                        name="mcFolderId"
                        value={lookupStore.getDropdownItem(
                          compStore.folders,
                          compStore.moveToFolderId,
                          "folderId",
                          "description"
                        )}
                        onChange={option => {
                          compStore.moveToFolderId = option.folderId;
                        }}
                        options={compStore.folders}
                        getOptionValue={option => option.folderId}
                        getOptionLabel={option => option.description}
                        styles={FormSelectStyles}
                      />
                    </div>
                  </div>
                  <LightButton
                    onClick={() => {
                      compStore.moveComps();
                    }}
                  >
                    Move
                  </LightButton>
                </div>
                <div>
                  <LightButton
                    onClick={() => {
                      compStore.exportList(compStore.compResults);
                    }}
                  >
                    Export to Excel
                  </LightButton>
                  <LightButton
                    onClick={() => {
                      routerStore.pushHistory(RouteConstants.SHARING.route, false);
                    }}
                  >
                    Share
                  </LightButton>
                  <DangerButton
                    onClick={() => {
                      compStore.deleteComps();
                    }}
                  >
                    Delete
                  </DangerButton>
                </div>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>
      </React.Fragment>
    );
  }
}

ManageComps.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(ManageComps)));
