import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import {
  BestDropdown,
  BestLabel,
  BestQuickSearch,
  BestSelect,
  CardContainer,
  Checkbox,
  FormInput,
  PrimaryButton
} from "best-common-react";
import { FormSearchStyles, FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";
import SearchBar from "../widgets/SearchBar.js";

class PlayerSearch extends React.Component {
  getPositions() {
    return [
      { value: "1B,2B,SS,3B,C,LF,RF,CF,SP,RP", label: "All Positions" },
      { value: "1B", label: "First Base" },
      { value: "2B", label: "All Base" },
      { value: "3B", label: "Third Base" },
      { value: "SS", label: "Shortstop" },
      { value: "C", label: "Catcher" },
      { value: "RF", label: "Rightfield" },
      { value: "LF", label: "Leftfield" },
      { value: "CF", label: "Centerfield" },
      { value: "RF,LF,CF", label: "All Outfield" },
      { value: "SP", label: "Starting Pitcher" },
      { value: "RP", label: "Relief Pitcher" },
      { value: "DH", label: "Designated Hitter" },
      { value: "SP,RP", label: "All Pitchers" },
      { value: "LF,RF", label: "Corner Outfield" },
      { value: "1B,3B", label: "Corner Infield" },
      { value: "2B,SS", label: "Middle Infield" }
    ];
  }
  getCols(playerStore) {
    return [
      {
        key: "fullName",
        name: "Player Name",
        width: 400,
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                playerStore.openPlayerProfile(row.row.playerId);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "club", name: "Org", width: 200 },
      { key: "mls", name: "MLS", width: 100 },
      { key: "lastContract", name: "1st Year of Last Contract", width: 200 },
      { key: "primaryPosition", name: "Primary Position", width: 150 },
      { key: "secondaryPosition", name: "Secondary Position" }
    ];
  }
  render() {
    let { lookupStore, playerStore } = this.props.rootStore;
    let positions = this.getPositions();
    let clubs = lookupStore.getCollection("teams");
    return (
      <React.Fragment>
        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Player Search</span>
            </CardContainer.Header>
            <CardContainer.Body>
              <div className="container-fluid p-0">
                <div className="row m-0">
                  <div className="col-12 col-md-3">
                    <div className="form-group">
                      <BestLabel>Player Name</BestLabel>

                      <BestQuickSearch
                        options={lookupStore.autocompletePlayers}
                        renderSuggestion={suggestion => {
                          return <PlayerAutocompleteRow player={suggestion} />;
                        }}
                        getSuggestionValue={player => {
                          return playerStore.getAutocompleteText(player);
                        }}
                        getSuggestions={(players, searchTerm) => {
                          if (searchTerm.indexOf(")") === -1) {
                            playerStore.playerSearch.playerId = 0;
                            playerStore.playerSearch.fullname = playerStore.formatForComparison(searchTerm);
                          }
                          return playerStore.filterBySearchTerm(players, searchTerm);
                        }}
                        onClear={event => {
                          playerStore.playerSearch.playerId = null;
                          playerStore.playerSearch.fullname = null;
                        }}
                        onSuggestionSelected={(event, { suggestion }) => {
                          playerStore.playerSearch.playerId = suggestion.playerId;
                          playerStore.playerSearch.fullname = suggestion.playerName;
                        }}
                        styles={FormSearchStyles}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-3">
                    <BestDropdown
                      name="primaryPos"
                      title="Primary Position"
                      options={positions}
                      onChange={option => {
                        playerStore.playerSearch.positionPrimary = option.value;
                      }}
                      value={lookupStore.getDropdownItem(positions, playerStore.playerSearch.positionPrimary)}
                      styles={FormSelectStyles}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <BestDropdown
                      name="secondaryPos"
                      title="Secondary Position"
                      options={positions}
                      onChange={option => {
                        playerStore.playerSearch.positionSecondary = option.value;
                      }}
                      value={lookupStore.getDropdownItem(positions, playerStore.playerSearch.positionSecondary)}
                      styles={FormSelectStyles}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <div className="form-group">
                      <BestLabel>Club</BestLabel>
                      <BestSelect
                        name="club"
                        placeholder="Select a team..."
                        options={clubs}
                        onChange={option => {
                          playerStore.playerSearch.clubLk = option.teamLk;
                        }}
                        value={lookupStore.getDropdownItem(
                          clubs,
                          playerStore.playerSearch.clubLk,
                          "teamLk",
                          "teamName"
                        )}
                        getOptionValue={option => option.teamLk}
                        getOptionLabel={option => option.teamName}
                        styles={FormSelectStyles}
                      />
                    </div>
                  </div>
                </div>
                <div className="row m-0">
                  <div className="row col-12 col-md-3 m-0">
                    <div className="row col-12 col-md-6 p-md-0 m-0">
                      <div className="col-7 p-0">
                        <FormInput
                          name="mlsYearsFrom"
                          type="number"
                          label="MLS From"
                          onChangeFn={value => {
                            playerStore.playerSearch.mlsFromYears = value + "";
                          }}
                          value={playerStore.playerSearch.mlsFromYears * 1}
                          editable
                        />
                      </div>
                      <div className="form-group d-flex align-items-end col-5 pl-0">
                        <span className="col-form-label">Years</span>
                      </div>
                    </div>
                    <div className="row col-12 col-md-6 p-md-0 m-0">
                      <div className="col-7 p-0">
                        <FormInput
                          name="mlsDaysFrom"
                          type="number"
                          label=" "
                          onChangeFn={value => {
                            playerStore.playerSearch.mlsFromDays = value + "";
                          }}
                          value={playerStore.playerSearch.mlsFromDays * 1}
                          editable
                        />
                      </div>
                      <div className="form-group d-flex align-items-end col-5 pl-0">
                        <span className="col-form-label">Days</span>
                      </div>
                    </div>
                  </div>
                  <div className="row col-12 col-md-3 m-0">
                    <div className="row col-12 col-md-6 p-md-0 m-0">
                      <div className="col-7 p-0">
                        <FormInput
                          name="mlsYearsTo"
                          type="number"
                          label="MLS To"
                          onChangeFn={value => {
                            playerStore.playerSearch.mlsToYears = value + "";
                          }}
                          value={playerStore.playerSearch.mlsToYears * 1}
                          editable
                        />
                      </div>
                      <div className="form-group d-flex align-items-end col-5 pl-0">
                        <span className="col-form-label">Years</span>
                      </div>
                    </div>
                    <div className="row col-12 col-md-6 p-md-0 m-0">
                      <div className="col-7 p-0">
                        <FormInput
                          name="mlsDaysTo"
                          type="number"
                          label=" "
                          onChangeFn={value => {
                            playerStore.playerSearch.mlsToDays = value + "";
                          }}
                          value={playerStore.playerSearch.mlsToDays * 1}
                          editable
                        />
                      </div>
                      <div className="form-group d-flex align-items-end col-5 pl-0">
                        <span className="col-form-label">Days</span>
                      </div>
                    </div>
                  </div>

                  <div className="form-group d-flex align-items-end col-12 col-md-3">
                    <Checkbox
                      id="psCurrentOnly"
                      label="Current Players Only"
                      checked={playerStore.playerSearch.currentPlayersOnlyFlg}
                      onChange={event => {
                        playerStore.playerSearch.currentPlayersOnlyFlg = event.target.checked;
                      }}
                    />
                  </div>
                </div>
              </div>
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row">
                <PrimaryButton
                  onClick={() => {
                    playerStore.findPlayers();
                  }}
                >
                  Search
                </PrimaryButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>

        <PiaWidget className="col-12 table-container">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Search Results</span>
            </CardContainer.Header>
            <CardContainer.Body>
              {playerStore.playerResults.length > 0 ? (
                <PiaTable
                  data={playerStore.playerResults}
                  columns={this.getCols(playerStore)}
                  height={playerStore.calculateTableHeight(playerStore.playerResults.length, 504)}
                />
              ) : (
                <div className="col-12">No Results</div>
              )}
            </CardContainer.Body>
            <CardContainer.Footer></CardContainer.Footer>
          </CardContainer>
        </PiaWidget>
      </React.Fragment>
    );
  }
}

PlayerSearch.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(PlayerSearch)));
