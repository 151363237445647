import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { FadeLoader } from "react-spinners";
import { LightButton } from "best-common-react";
import Theme from "../../Theme";

class ReportLoadingContainer extends Component {
  render() {
    let { reportStore } = this.props.rootStore;
    let reportName = decodeURIComponent(this.props.match.params.reportName);
    let reportStatus = reportStore.openReports[reportName];

    return (
      <div className="container-fluid">
        {reportStatus && reportStatus.loading ? (
          <React.Fragment>
            <div className="row col-12 justify-content-center">Loading report...</div>
            <div className="row col-12 justify-content-center">
              <FadeLoader color={Theme["mlb-navy"]} />
            </div>
            <div className="row col-12 justify-content-center">
              <LightButton
                onClick={() => {
                  reportStore.cancel(reportName).then(resp => {
                    reportStore.openReports[reportName] = undefined;
                    window.close();
                  });
                }}
              >
                Cancel
              </LightButton>
            </div>
          </React.Fragment>
        ) : reportStatus && reportStatus.error ? (
          <div>Error loading report</div>
        ) : (
          <div>Report could not be opened - please close tab and try again</div>
        )}
      </div>
    );
  }
}

ReportLoadingContainer.propTypes = {
  rootStore: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      reportName: PropTypes.string.isRequired
    })
  })
};

export default inject("rootStore")(observer(ReportLoadingContainer));
