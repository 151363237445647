import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import AgentModal from "../../components/players/AgentModal";
import CombinedStatsModal from "../../components/players/CombinedStatsModal";
import PiaWidget from "../../components/widgets/PiaWidget";
import ProfileHeader from "../../components/players/ProfileHeader";
import ProfileTabs from "../../components/players/ProfileTabs";
import StatLineModal from "../../components/players/StatLineModal";
import TransactionModal from "../../components/players/TransactionModal";
import { RouteConstants } from "../../constants/RouteConstants";

class PlayerProfileContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.PLAYER_PROFILE.title);
  }

  render() {
    return (
      <React.Fragment>
        <AgentModal />
        <StatLineModal />
        <TransactionModal />
        <CombinedStatsModal />
        <div className="container-fluid my-4">
          <div className="col-12 p-0">
            <ProfileHeader />
          </div>
          <PiaWidget className="tab-container col-12 mb-4">
            <ProfileTabs />
          </PiaWidget>
        </div>
      </React.Fragment>
    );
  }
}

PlayerProfileContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(PlayerProfileContainer));
