import { Auth } from "@okta/okta-react";
import { RouteConstants } from "../constants/RouteConstants";

/**
 * All Authentication items should go here
 */

class AuthApi {
  constructor(routerStore) {
    this.oktaAuth = new Auth({
      issuer: process.env.REACT_APP_OIDC_ISSUER,
      client_id: process.env.REACT_APP_OIDC_CLIENTID,
      redirect_uri: window.location.origin + RouteConstants.IMPLICIT_CALLBACK.route,
      history: routerStore.history,
      onAuthRequired: () => routerStore.history.push(RouteConstants.LOGIN.route)
    });
  }

  logout() {
    this.oktaAuth.logout(RouteConstants.LOGIN.route);
  }

  getToken = () => {
    return [this.oktaAuth.getAccessToken(), this.oktaAuth.getIdToken()];
  };

  getUserInfo = () => {
    return this.oktaAuth.getUser();
  };

  getAccessToken = () => {
    return this.oktaAuth.getAccessToken();
  };

  getTokenManager = () => {
    return this.oktaAuth._oktaAuth.tokenManager;
  };
}

export default AuthApi;
