import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";

class PlayerAutocompleteRow extends React.Component {
  render() {
    let { player } = this.props;
    let { playerStore } = this.props.rootStore;
    return <div>{playerStore.getAutocompleteText(player)}</div>;
  }
}

PlayerAutocompleteRow.propTypes = {
  rootStore: PropTypes.object.isRequired,
  player: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(PlayerAutocompleteRow));
