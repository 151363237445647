import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  BestLabel,
  BestQuickSearch,
  BestSelect,
  CardContainer,
  Checkbox,
  DangerButton,
  Icon,
  LightButton,
  PrimaryButton
} from "best-common-react";
import { Row } from "react-data-grid";
import { Draggable } from "react-data-grid-addons";
import { FormSearchStyles, FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";
import StatAutocompleteRow from "../autocomplete/StatAutocompleteRow";
import TitleCard from "../widgets/TitleCard";

const { Container: DraggableContainer, DropTargetRowContainer, RowActionsCell } = Draggable;
const RowRenderer = DropTargetRowContainer(Row);

class EditStatList extends React.Component {
  getListDetailCols() {
    let { statStore, piaApi } = this.props.rootStore;

    const AddAll = () => {
      return (
        <Icon
          iconName="fa-plus-square"
          onClick={() => {
            if (statStore.statSearch.searchStatListId) {
              statStore.statList.slDetails = _.cloneDeep(statStore.statListSearchDetails);
            } else {
              piaApi.getStatsFromResultList(statStore.statListSearchDetails).then(response => {
                if (response) {
                  statStore.statList.slDetails = response;
                }
              });
            }
          }}
          styles={{ size: "2em" }}
        />
      );
    };

    return [
      { key: "statDisplayName", name: "Statistic Name", width: 280 },
      { key: "sourceExcel", name: "Source", width: 250 },
      { key: "category", name: "Category" },
      {
        key: "icon-add",
        name: "",
        width: 60,
        headerRenderer: AddAll,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Icon
                iconName="fa-plus-square"
                onClick={() => {
                  if (statStore.statSearch.searchStatListId) {
                    statStore.statList.slDetails.push(row.row);
                  } else {
                    piaApi.getStatFromResult(row.row).then(response => {
                      if (response) {
                        statStore.statList.slDetails.push(response);
                      }
                    });
                  }
                }}
                styles={{ size: "2em" }}
              />
            </div>
          );
        }
      }
    ];
  }
  getCurrentListCols() {
    return [
      { key: "statDisplayName", name: "Statistic Name", width: 280 },
      { key: "source", name: "Source", width: 250 },
      { key: "category", name: "Category", width: 150 },
      { key: "formula", name: "Formula", width: 250 },
      { key: "example", name: "Example" }
    ];
  }
  render() {
    let { lookupStore, statStore } = this.props.rootStore;
    let types = statStore.getStatTypeOptions();
    return (
      <React.Fragment>
        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Stat Search</span>
            </CardContainer.Header>
            <CardContainer.Body>
              <div className="row mx-0 mb-4">
                <div className="col-6 p-0">
                  <div className="row mx-0 mb-4">
                    <div className="col-12">
                      <TitleCard>
                        <span>Search Criteria</span>
                      </TitleCard>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-6">
                      <div className="form-group">
                        <BestLabel>Statistic Name</BestLabel>
                        <BestQuickSearch
                          options={lookupStore.autocompleteStats}
                          renderSuggestion={suggestion => {
                            return <StatAutocompleteRow stat={suggestion} />;
                          }}
                          getSuggestionValue={stat => {
                            return statStore.getAutocompleteText(stat);
                          }}
                          getSuggestions={(stats, searchTerm) => {
                            statStore.statSearch.statId = null;
                            statStore.statSearch.statName = statStore.formatForComparison(searchTerm);
                            return statStore.filterBySearchTerm(stats, searchTerm);
                          }}
                          onSuggestionSelected={(event, { suggestion }) => {
                            statStore.statSearch.statId = suggestion.statId;
                            statStore.statSearch.statName = suggestion.statName;
                          }}
                          onClear={() => {
                            statStore.statSearch.statId = null;
                            statStore.statSearch.statName = null;
                          }}
                          styles={FormSearchStyles}
                        />
                      </div>
                    </div>

                    <div className="col-6 row m-0">
                      <div className="col-12 p-0 form-group">
                        <BestLabel>Source</BestLabel>

                        <div className="row m-0">
                          <div className="col-6 p-0">
                            <Checkbox
                              id="eslSearchStandardStatFlg"
                              label="Standard"
                              onChange={event => {
                                statStore.statSearch.searchStandardStatFlg = event.target.checked;
                              }}
                              checked={!!statStore.statSearch.searchStandardStatFlg}
                            />
                          </div>

                          <div className="col-6 p-0">
                            <Checkbox
                              id="eslSearchUserDefinedFlg"
                              label="User-Defined"
                              onChange={event => {
                                statStore.statSearch.searchUserDefinedFlg = event.target.checked;
                              }}
                              checked={!!statStore.statSearch.searchUserDefinedFlg}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-6">
                      <div className="form-group">
                        <BestLabel>Type</BestLabel>
                        <BestSelect
                          name="eslStatType"
                          options={types}
                          onChange={option => {
                            statStore.updateStatType(option);
                          }}
                          value={lookupStore.getDropdownItem(types, statStore.statSearch.searchType, "type", "type")}
                          getOptionValue={option => option.type}
                          getOptionLabel={option => option.type}
                          styles={FormSelectStyles}
                        />
                      </div>
                    </div>

                    <div className="col-6">
                      <div className="form-group">
                        <BestLabel>Category</BestLabel>
                        <BestSelect
                          name="eslStatCategory"
                          options={statStore.statTypeCategories}
                          onChange={option => {
                            statStore.statSearch.searchCategory = option.category;
                          }}
                          value={lookupStore.getDropdownItem(
                            statStore.statTypeCategories,
                            statStore.statSearch.searchCategory,
                            "category",
                            "category"
                          )}
                          getOptionValue={option => option.category}
                          getOptionLabel={option => option.category}
                          isDisabled={"All" === statStore.statSearch.searchType}
                          styles={FormSelectStyles}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-6">
                      <div className="form-group">
                        <BestLabel>From Stat List</BestLabel>
                        <BestSelect
                          name="eslStatListId"
                          options={statStore.displayLists}
                          onChange={option => {
                            statStore.statSearch.searchStatListId = option.statlistId;
                            statStore.getStatList(option.statlistId, false);
                          }}
                          value={lookupStore.getDropdownItem(
                            statStore.displayLists,
                            statStore.statSearch.searchStatListId,
                            "statlistId",
                            "statlistname"
                          )}
                          getOptionValue={option => option.statlistId}
                          getOptionLabel={option => option.statlistname}
                          styles={FormSelectStyles}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row m-0 col-12 justify-content-end">
                    <PrimaryButton
                      onClick={() => {
                        statStore.statSearch.searchStatListId = undefined;
                        statStore.findStatsByName();
                      }}
                    >
                      Search
                    </PrimaryButton>
                  </div>
                </div>

                <div className="col-6 p-0">
                  <div className="row mx-0 mb-4">
                    <div className="col-12">
                      <TitleCard>
                        <span>Search Results</span>
                      </TitleCard>
                    </div>
                  </div>

                  <div className="row m-0">
                    <div className="col-12">
                      <PiaTable
                        data={statStore.statListSearchDetails}
                        columns={this.getListDetailCols()}
                        height={statStore.calculateTableHeight(statStore.statListSearchDetails.length, 336)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mx-0 mb-4">
                <div className="col-12">
                  <TitleCard>
                    <div className="d-flex flex-fill justify-content-between">
                      <span>Current Stat List</span>
                      <span>Stats in list: {statStore.displayStatListDetails.length}</span>
                    </div>
                  </TitleCard>
                </div>
              </div>

              <div className="row mx-0 mb-4">
                <div className="col-12">
                  <DraggableContainer>
                    <PiaTable
                      data={statStore.displayStatListDetails}
                      columns={this.getCurrentListCols()}
                      rowActionsCell={RowActionsCell}
                      rowRenderer={
                        <RowRenderer
                          onRowDrop={e => {
                            if (e.rowSource.idx !== e.rowTarget.idx) {
                              statStore.statList.slDetails.splice(e.rowSource.idx, 1);
                              statStore.statList.slDetails.splice(e.rowTarget.idx, 0, e.rowSource.data);
                            }
                          }}
                        />
                      }
                      rowSelection={{
                        showCheckbox: true,
                        onRowsSelected: rows => {
                          rows.forEach(row => {
                            if (row.row.id) {
                              statStore.statList.slDetails[row.rowIdx].selectedFlg = true;
                            }
                          });
                        },
                        onRowsDeselected: rows => {
                          rows.forEach(row => {
                            if (row.row.id) {
                              statStore.statList.slDetails[row.rowIdx].selectedFlg = false;
                            }
                          });
                        },
                        selectBy: {
                          isSelectedKey: "selectedFlg"
                        },
                        enableShiftSelect: true
                      }}
                      height={statStore.calculateTableHeight(statStore.displayStatListDetails.length, 336)}
                    />
                  </DraggableContainer>
                </div>
              </div>
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row">
                <LightButton
                  onClick={() => {
                    statStore.exportList(statStore.displayStatListDetails);
                  }}
                >
                  Export to Excel
                </LightButton>
                <DangerButton
                  onClick={() => {
                    statStore.statList.slDetails = _.filter(
                      statStore.displayStatListDetails,
                      stat => !stat.selectedFlg
                    );
                  }}
                >
                  Delete
                </DangerButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>
      </React.Fragment>
    );
  }
}

EditStatList.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(EditStatList)));
