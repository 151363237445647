import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestSelect, DateTimeFormatter, FormInput } from "best-common-react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FormSelectStyles } from "../../Theme";

class SelectTimeFrame extends React.Component {
  getTfTypeOptions(lookupStore) {
    let list = lookupStore.getCollection("timeframetypes");
    list = _.filter(list, type => this.props.showContract || type.description !== "Contract Length");
    return list;
  }

  updateTypes(tfDetail, option) {
    let fromType = "Platform";
    let toType = "Platform";

    if ("Multiple Years" === option.description) {
      fromType = "Delta-";
    } else if ("Date Range" === option.description) {
      fromType = "DateRange";
      toType = "DateRange";
    }

    tfDetail.luTimeframetype = option;
    tfDetail.fromType = fromType;
    tfDetail.toType = toType;
  }

  getFromToTypeOptions() {
    return [
      { value: "Platform", label: "Platform Year" },
      { value: "Delta-", label: "Platform Year Minus" },
      { value: "Delta+", label: "Platform Year Plus" },
      { value: "Year", label: "Year" }
    ];
  }

  getConstantOptions() {
    let list = [];

    for (let i = 1; i <= 50; i++) {
      list.push({ value: i, label: i });
    }

    return list;
  }

  getContractLengthOptions() {
    return [
      { value: "0", label: "Maximum" },
      { value: "1", label: "Actual" }
    ];
  }

  render() {
    let { lookupStore, commonStore } = this.props.rootStore;
    let { tfDetail } = this.props;
    let tfTypes = this.getTfTypeOptions(lookupStore);
    let fromToTypes = this.getFromToTypeOptions();
    let constantVals = this.getConstantOptions();
    let contractLengths = this.getContractLengthOptions();
    let years = commonStore.getListOfYears(1871);

    return (
      <React.Fragment>
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="form-group">
              <BestLabel>Range</BestLabel>
              <BestSelect
                name="stfType"
                value={lookupStore.getDropdownItem(
                  tfTypes,
                  tfDetail.luTimeframetype.timeframetypeLk,
                  "timeframetypeLk",
                  "description"
                )}
                onChange={option => {
                  this.updateTypes(tfDetail, option);
                }}
                options={tfTypes}
                getOptionValue={option => option.timeframetypeLk}
                getOptionLabel={option => option.description}
                placeholder="Type"
                portal={document.body}
                styles={FormSelectStyles}
              />
            </div>
          </div>
        </div>

        <div className="row m-0">
          {"Career" === tfDetail.luTimeframetype.description ? (
            ""
          ) : "Contract Length" === tfDetail.luTimeframetype.description ? (
            <div className="col-8 pl-0">
              <div className="form-group">
                <BestLabel></BestLabel>
                <BestSelect
                  name="stfFromType"
                  value={lookupStore.getDropdownItem(contractLengths, tfDetail.actualContractFlag + "")}
                  onChange={option => {
                    tfDetail.actualContractFlag = option.value * 1;
                  }}
                  options={contractLengths}
                  placeholder="Length"
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>
          ) : "Date Range" === tfDetail.luTimeframetype.description ? (
            <div className="col-8 p-0">
              <FormInput
                name="stfFromDate"
                label="From"
                type="date"
                value={tfDetail.fromDate}
                onChangeFn={date => {
                  tfDetail.fromDate = date;
                }}
                formatter={DateTimeFormatter}
                editable
              />
            </div>
          ) : (
            <React.Fragment>
              <div className="col-8 pl-0">
                <div className="form-group">
                  <BestLabel>From</BestLabel>
                  <BestSelect
                    name="stfFromType"
                    value={lookupStore.getDropdownItem(fromToTypes, tfDetail.fromType)}
                    onChange={option => {
                      tfDetail.fromType = option.value;
                    }}
                    options={fromToTypes}
                    placeholder="From Type"
                    portal={document.body}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>

              {tfDetail.fromType.indexOf("Delta") > -1 ? (
                <div className="col-4 p-0">
                  <div className="form-group">
                    <BestLabel></BestLabel>
                    <BestSelect
                      name="stfFrompyConstant"
                      value={lookupStore.getDropdownItem(constantVals, tfDetail.frompyConstant)}
                      onChange={option => {
                        tfDetail.frompyConstant = option.value;
                      }}
                      options={constantVals}
                      placeholder="Select a value..."
                      portal={document.body}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
              ) : "Year" === tfDetail.fromType ? (
                <div className="col-4 p-0">
                  <div className="form-group">
                    <BestLabel></BestLabel>
                    <BestSelect
                      name="stfFromYear"
                      value={lookupStore.getDropdownItem(years, tfDetail.fromYear)}
                      onChange={option => {
                        tfDetail.fromYear = option.value;
                      }}
                      options={years}
                      placeholder="Select a value..."
                      portal={document.body}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          )}
        </div>

        <div className="row m-0">
          {"Multiple Years" === tfDetail.luTimeframetype.description ||
          "Career" === tfDetail.luTimeframetype.description ? (
            <React.Fragment>
              <div className="col-8 pl-0">
                <div className="form-group">
                  <BestLabel>To</BestLabel>
                  <BestSelect
                    name="stfToType"
                    value={lookupStore.getDropdownItem(fromToTypes, tfDetail.toType)}
                    onChange={option => {
                      tfDetail.toType = option.value;
                    }}
                    options={fromToTypes}
                    placeholder="To Type"
                    portal={document.body}
                    styles={FormSelectStyles}
                  />
                </div>
              </div>

              {tfDetail.toType.indexOf("Delta") > -1 ? (
                <div className="col-4 p-0">
                  <div className="form-group">
                    <BestLabel></BestLabel>
                    <BestSelect
                      name="stfTopyConstant"
                      value={lookupStore.getDropdownItem(constantVals, tfDetail.topyConstant)}
                      onChange={option => {
                        tfDetail.topyConstant = option.value;
                      }}
                      options={constantVals}
                      placeholder="Select a value..."
                      portal={document.body}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
              ) : "Year" === tfDetail.toType ? (
                <div className="col-4 p-0">
                  <div className="form-group">
                    <BestLabel></BestLabel>
                    <BestSelect
                      name="stfToYear"
                      value={lookupStore.getDropdownItem(years, tfDetail.toYear)}
                      onChange={option => {
                        tfDetail.toYear = option.value;
                      }}
                      options={years}
                      placeholder="Select a value..."
                      portal={document.body}
                      styles={FormSelectStyles}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : "Date Range" === tfDetail.luTimeframetype.description ? (
            <div className="col-8 p-0">
              <FormInput
                name="stfToDate"
                label="To"
                type="date"
                value={tfDetail.toDate}
                onChangeFn={date => {
                  tfDetail.toDate = date;
                }}
                formatter={DateTimeFormatter}
                editable
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}

SelectTimeFrame.defaultProps = {
  showContract: false
};

SelectTimeFrame.propTypes = {
  rootStore: PropTypes.object.isRequired,
  tfDetail: PropTypes.object.isRequired,
  showContract: PropTypes.bool
};

export default inject("rootStore")(observer(SelectTimeFrame));
