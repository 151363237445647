import React from "react";
import PropTypes from "prop-types";

class ValueFormatter extends React.Component {
  formatValue(applicable, excludedValues, value) {
    if (applicable) {
      if (value && !excludedValues.includes(value)) {
        return value;
      } else {
        return "-";
      }
    } else {
      return "N/A";
    }
  }

  render() {
    const { applicable, excludedValues, value } = this.props;
    return this.formatValue(applicable, excludedValues, value);
  }
}

ValueFormatter.defaultProps = {
  applicable: true,
  excludedValues: []
};

ValueFormatter.propTypes = {
  value: PropTypes.any,
  excludedValues: PropTypes.array,
  applicable: PropTypes.bool
};

export default ValueFormatter;
