import { saveAs } from "file-saver";
import Theme from "../Theme";

class WidgetStore {
  constructor() {
    this.searchTermInField = this.searchTermInField.bind(this);
    this.formatForComparison = this.formatForComparison.bind(this);
    this.exportList = this.exportList.bind(this);
  }
  // Format string for comparison in searches
  formatForComparison(str) {
    return !str ? "" : str.replace("[^A-Za-z]+", "").toLowerCase();
  }

  // Returns true if field is defined and field contains search term
  // Otherwise, returns false.
  searchTermInField(field, searchTerm) {
    return !!field && !!searchTerm && this.formatForComparison(field).includes(this.formatForComparison(searchTerm));
  }

  // Returns true if field is defined and field starts with search term
  // Otherwise, returns false.
  searchTermStartsField(field, searchTerm) {
    return !!field && !!searchTerm && this.formatForComparison(field).startsWith(this.formatForComparison(searchTerm));
  }

  calculateTableHeight(numRows, maxHeight, addHeader = true) {
    const rowHeight = Theme.sizeRowHeightNum * 1;
    return Math.min(numRows * rowHeight + (addHeader ? rowHeight : 0), maxHeight);
  }

  exportList(list) {
    this.piaApi.exportList(list).then(file => {
      saveAs(file, "excel.xls");
    });
  }

  exportProp(parent, prop) {
    this.piaApi.exportProp(parent, prop).then(file => {
      saveAs(file, "excel.xls");
    });
  }
}

export default WidgetStore;
