import React from "react";
import { inject, observer } from "mobx-react";
import { ThemeContext, withTheme } from "styled-components";
import PropTypes from "prop-types";
import ReactDataGrid from "react-data-grid";
import ReactToolTip from "react-tooltip";
import { BestLabel, BestSelect, CardContainer, Icon, LightButton, NumberInput } from "best-common-react";
import Theme, { FormSelectButtonStyles, FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";
import ResultCount from "../common/ResultCount";

class EditCompLists extends React.Component {
  componentDidMount() {
    if (this.compPlayerLists && "select-all-checkbox" === this.compPlayerLists.selectAllCheckbox.id) {
      this.compPlayerLists.selectAllCheckbox.id += "-players";
      this.compPlayerLists.selectAllCheckbox.nextSibling.htmlFor += "-players";
    }

    if (this.compStatLists && "select-all-checkbox" === this.compStatLists.selectAllCheckbox.id) {
      this.compStatLists.selectAllCheckbox.id += "-stats";
      this.compStatLists.selectAllCheckbox.nextSibling.htmlFor += "-stats";
    }
  }
  getPlayerDetailCols(playerStore) {
    return [
      {
        key: "playername",
        name: "Player Name",
        width: 250,
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                playerStore.openPlayerProfile(row.row.playerid);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "eosmls", name: "MLS", width: 70, cellClass: "text-right" },
      { key: "platformYear", name: "Plat. Year" }
    ];
  }
  getStatDetailCols(compStore) {
    let cols = [{ key: "statisticname", name: "Statistic Name" }];

    if (compStore.displayStatWeights) {
      cols.push({
        key: "weight",
        name: "Avg Rank Weight",
        width: 150,
        formatter: row => {
          return (
            <div className="table-input">
              <NumberInput
                name={"statWeight" + row.row.index}
                onChange={value => {
                  if (value < 0) {
                    compStore.comp.slMasters[0].slDetails[row.row.index].weight = "0.0";
                  } else if (value > 100) {
                    compStore.comp.slMasters[0].slDetails[row.row.index].weight = "100.0";
                  } else {
                    compStore.comp.slMasters[0].slDetails[row.row.index].weight = value + "";
                  }
                }}
                value={row.row.weight * 1}
              />
            </div>
          );
        }
      });
    }

    return cols;
  }
  getSplitCols(compStore) {
    return [
      { key: "splitCategory", name: "Category", width: 180 },
      {
        key: "splitName",
        name: "Name",
        formatter: row => {
          return <span>{row.value + ("View All" === row.value ? "*" : "")}</span>;
        }
      },
      {
        key: "deleteSplit",
        name: "",
        width: 40,
        formatter: row => {
          return (
            <div className="d-flex justify-content-center">
              <Icon
                className="mlb-red-icon"
                iconName="fa-trash-alt"
                onClick={() => {
                  let index = compStore.comp.reportCriteria.statSplits.indexOf(row.row);
                  if (index > -1) {
                    compStore.comp.reportCriteria.statSplits.splice(index, 1);
                  }
                }}
              />
            </div>
          );
        }
      }
    ];
  }
  getFocusPlayerRow() {
    let { commonStore, compStore, lookupStore } = this.props.rootStore;
    let years = commonStore.getListOfYears(1988);
    if (compStore.displayPlayerListDetails.length > 0) {
      return (
        <div className="row m-0">
          <PiaWidget className="col-12">
            <div className="row m-0 col-12 d-flex justify-content-between form-inline highlighted-row">
              <BestLabel>Focus Player</BestLabel>
              <div className="col-4">
                <BestSelect
                  name="ecFocusPlayerId"
                  placeholder="Select a player list..."
                  options={compStore.displayPlayerListDetails}
                  onChange={option => {
                    compStore.setFocusPlayer(option);
                  }}
                  value={lookupStore.getDropdownItem(
                    compStore.displayPlayerListDetails,
                    compStore.comp.plMasters[0].focusplayerId,
                    "playerid",
                    "playername"
                  )}
                  getOptionValue={option => option.playerid}
                  getOptionLabel={option => option.playername}
                  styles={FormSelectStyles}
                />
              </div>
              <BestLabel>Platform Year</BestLabel>
              <div className="col-3 pr-0">
                <BestSelect
                  name="ecFocusPlayerYear"
                  placeholder="Select a player list..."
                  options={years}
                  onChange={option => {
                    compStore.comp.plMasters[0].focusplayerYear = option.value;
                  }}
                  value={lookupStore.getDropdownItem(years, compStore.comp.plMasters[0].focusplayerYear)}
                  styles={FormSelectStyles}
                />
              </div>
            </div>
          </PiaWidget>
        </div>
      );
    } else {
      return "";
    }
  }
  getPlayerListToolTipHtml(compStore, playerStore) {
    return (
      <ThemeContext.Provider value={Theme}>
        <CardContainer>
          <CardContainer.Header>
            <span className="header-title">Player List Options</span>
          </CardContainer.Header>
          <CardContainer.Body>
            <div className="container-fluid">
              {compStore.comp.plMasters[0].playerlistId ? (
                <React.Fragment>
                  <div className="row mx-0 my-2">
                    <LightButton
                      className="flex-grow-1"
                      onClick={() => {
                        ReactToolTip.hide();
                        playerStore.openPlayerList(
                          compStore.comp.plMasters[0].playerlistId,
                          true,
                          false,
                          compStore.comp.plMasters[0].plDetails
                        );
                      }}
                    >
                      Modify Basic Player List
                    </LightButton>
                  </div>
                  <div className="row mx-0 mb-2">
                    <LightButton
                      className="flex-grow-1"
                      onClick={() => {
                        ReactToolTip.hide();
                        playerStore.openPlayerList(
                          compStore.comp.plMasters[0].playerlistId,
                          true,
                          true,
                          compStore.comp.plMasters[0].plDetails
                        );
                      }}
                    >
                      Modify Advanced Player List
                    </LightButton>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="row mx-0 my-2">
                    <LightButton
                      className="flex-grow-1"
                      onClick={() => {
                        ReactToolTip.hide();
                        playerStore.openPlayerList(0, true, false, compStore.comp.plMasters[0].plDetails);
                      }}
                    >
                      Create New Basic Player List
                    </LightButton>
                  </div>

                  <div className="row mx-0 mb-2">
                    <LightButton
                      className="flex-grow-1"
                      onClick={() => {
                        ReactToolTip.hide();
                        playerStore.openPlayerList(0, true, true, compStore.comp.plMasters[0].plDetails);
                      }}
                    >
                      Create New Advanced Player List
                    </LightButton>
                  </div>
                </React.Fragment>
              )}
            </div>
          </CardContainer.Body>
        </CardContainer>
      </ThemeContext.Provider>
    );
  }
  getPlayerListContainer() {
    let { compStore, lookupStore, playerStore } = this.props.rootStore;
    return (
      <PiaWidget className="col-4 table-container comp-container">
        <CardContainer>
          <CardContainer.Header>
            <div className="header-item-row justify-content-between">
              <span className="header-title">Player List</span>
              <div className="d-flex col-6 pr-0 justify-content-end align-items-center">
                <div className="col-12 p-0 item">
                  <BestSelect
                    name="ecPlayerListId"
                    placeholder="Select a player list..."
                    options={playerStore.displayLists}
                    onChange={option => {
                      ReactToolTip.hide();
                      compStore.getPlayerList(option.playerlistId);
                    }}
                    value={lookupStore.getDropdownItem(
                      playerStore.displayLists,
                      compStore.comp.plMasters[0].lkupId !== null
                        ? compStore.comp.plMasters[0].lkupId
                        : compStore.comp.plMasters[0].playerlistId,
                      "playerlistId",
                      "playerlistname"
                    )}
                    getOptionValue={option => option.playerlistId}
                    getOptionLabel={option => option.playerlistname}
                    styles={FormSelectButtonStyles}
                  />
                </div>

                <React.Fragment>
                  <span data-for="player-list-options-tooltip" data-tip="" data-event="click focus">
                    <Icon iconName="fa-pen-square" styles={{ size: "2em" }} />
                  </span>
                  <ReactToolTip
                    id="player-list-options-tooltip"
                    class="tool-options"
                    place="bottom"
                    effect="solid"
                    globalEventOff="click unfocus"
                    scrollHide={false}
                    clickable={true}
                    isCapture={true}
                  >
                    {this.getPlayerListToolTipHtml(compStore, playerStore)}
                  </ReactToolTip>
                </React.Fragment>
              </div>
            </div>
          </CardContainer.Header>
          <CardContainer.Body>
            <div className="row m-0">
              <div className="col-12 p-0">
                <ReactDataGrid
                  id="react-data-grid-players"
                  ref={node => (this.compPlayerLists = node)}
                  columns={this.getPlayerDetailCols(playerStore)}
                  rowGetter={index => {
                    return compStore.displayPlayerListDetails[index];
                  }}
                  rowsCount={compStore.displayPlayerListDetails.length}
                  minHeight={308}
                  rowHeight={28}
                  rowSelection={{
                    showCheckbox: true,
                    onRowsSelected: rows => {
                      rows.forEach(row => {
                        if (row.row.playerid) {
                          compStore.comp.plMasters[0].plDetails[row.rowIdx].selectedoncompFlg = true;
                        }
                      });
                    },
                    onRowsDeselected: rows => {
                      rows.forEach(row => {
                        if (row.row.playerid) {
                          compStore.comp.plMasters[0].plDetails[row.rowIdx].selectedoncompFlg = false;
                        }
                      });
                    },
                    selectBy: {
                      isSelectedKey: "selectedoncompFlg"
                    },
                    enableShiftSelect: true
                  }}
                />
              </div>
            </div>
          </CardContainer.Body>
          <CardContainer.Footer>
            <ResultCount value={compStore.displayPlayerListDetails.length} type="player" />
          </CardContainer.Footer>
        </CardContainer>
      </PiaWidget>
    );
  }
  getStatListContainer() {
    let { compStore, lookupStore, statStore } = this.props.rootStore;
    return (
      <PiaWidget className="col-4 table-container comp-container">
        <CardContainer>
          <CardContainer.Header>
            <div className="header-item-row justify-content-between">
              <span className="header-title">Stat List</span>
              <div className="d-flex col-6 pr-0 justify-content-end align-items-center">
                <div className="col-12 p-0 item">
                  <BestSelect
                    name="ecStatListId"
                    placeholder="Select a stat list..."
                    options={statStore.displayLists}
                    onChange={option => {
                      compStore.getStatList(option.statlistId);
                    }}
                    value={lookupStore.getDropdownItem(
                      statStore.displayLists,
                      compStore.comp.slMasters[0].lkupId !== null
                        ? compStore.comp.slMasters[0].lkupId
                        : compStore.comp.slMasters[0].statlistId,
                      "statlistId",
                      "statlistname"
                    )}
                    getOptionValue={option => option.statlistId}
                    getOptionLabel={option => option.statlistname}
                    styles={FormSelectButtonStyles}
                  />
                </div>

                <Icon
                  iconName="fa-pen-square"
                  styles={{ size: "2em" }}
                  onClick={() => {
                    ReactToolTip.hide();
                    statStore.openStatList(
                      compStore.comp.slMasters[0].statlistId || 0,
                      true,
                      compStore.comp.slMasters[0].slDetails
                    );
                  }}
                />
              </div>
            </div>
          </CardContainer.Header>
          <CardContainer.Body>
            <div className="row m-0">
              <div className="col-12 p-0">
                <ReactDataGrid
                  id="react-data-grid-stats"
                  ref={node => (this.compStatLists = node)}
                  columns={this.getStatDetailCols(compStore)}
                  rowGetter={index => {
                    return compStore.displayStatListDetails[index];
                  }}
                  rowsCount={compStore.displayStatListDetails.length}
                  minHeight={308}
                  rowHeight={28}
                  rowSelection={{
                    showCheckbox: true,
                    onRowsSelected: rows => {
                      rows.forEach(row => {
                        if (row.row.id) {
                          compStore.comp.slMasters[0].slDetails[row.rowIdx].selectedoncompFlg = true;
                        }
                      });
                    },
                    onRowsDeselected: rows => {
                      rows.forEach(row => {
                        if (row.row.id) {
                          compStore.comp.slMasters[0].slDetails[row.rowIdx].selectedoncompFlg = false;
                        }
                      });
                    },
                    selectBy: {
                      isSelectedKey: "selectedoncompFlg"
                    },
                    enableShiftSelect: true
                  }}
                />
              </div>
            </div>
          </CardContainer.Body>
          <CardContainer.Footer></CardContainer.Footer>
        </CardContainer>
      </PiaWidget>
    );
  }

  getSplitListContainer() {
    let { compStore, lookupStore } = this.props.rootStore;
    return (
      <PiaWidget className="col-4 table-container comp-container">
        <CardContainer>
          <CardContainer.Header>
            <div className="header-item-row justify-content-between">
              <span className="header-title">Splits</span>
              <div className="d-flex col-8 pr-0 justify-content-end align-items-center">
                <div className="col-6 p-0 item">
                  <BestSelect
                    name="ecSplit"
                    placeholder="Select a split category..."
                    options={compStore.displaySplitCategories}
                    onChange={option => {
                      compStore.compSplitCategory = option;
                      compStore.compSplitName = "";
                    }}
                    value={lookupStore.getDropdownItem(
                      compStore.displaySplitCategories,
                      compStore.compSplitCategory.splitType,
                      "splitType",
                      "splitType"
                    )}
                    getOptionValue={option => option.splitType}
                    getOptionLabel={option => option.splitType}
                    styles={FormSelectButtonStyles}
                  />
                </div>

                <div className="col-6 p-0 item">
                  <BestSelect
                    name="ecSplitName"
                    placeholder="Select a split..."
                    options={compStore.compSplitCategory.splits}
                    onChange={option => {
                      compStore.compSplitName = option.splitName;
                    }}
                    value={lookupStore.getDropdownItem(
                      compStore.compSplitCategory.splits,
                      compStore.compSplitName,
                      "splitName",
                      "splitName"
                    )}
                    getOptionValue={option => option.splitName}
                    getOptionLabel={option => option.splitName}
                    styles={FormSelectButtonStyles}
                  />
                </div>

                <Icon
                  iconName="fa-plus-square"
                  onClick={() => {
                    compStore.addSplit();
                  }}
                  styles={{ size: "2em" }}
                />
              </div>
            </div>
          </CardContainer.Header>
          <CardContainer.Body>
            <div className="row m-0">
              <div className="col-12 p-0">
                <PiaTable data={compStore.displayCompSplits} columns={this.getSplitCols(compStore)} height={308} />
              </div>
            </div>
          </CardContainer.Body>
          <CardContainer.Footer></CardContainer.Footer>
        </CardContainer>
      </PiaWidget>
    );
  }
  render() {
    return (
      <React.Fragment>
        {this.getFocusPlayerRow()}
        <div className="row m-0">
          {this.getPlayerListContainer()}
          {this.getStatListContainer()}
          {this.getSplitListContainer()}
        </div>
      </React.Fragment>
    );
  }
}

EditCompLists.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(EditCompLists)));
