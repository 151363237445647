import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, BestQuickSearch, BestSelect, Modal, NumberInput, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";
import { FormSearchStyles } from "../../Theme";
import StatAutocompleteRow from "../autocomplete/StatAutocompleteRow";

class AddWeightModal extends React.Component {
  save(compFinderStore) {
    //compFinderStore.saveSearch();
  }

  render() {
    let { compFinderStore, lookupStore, statStore } = this.props.rootStore;
    let { weight } = this.props;

    return (
      <Modal show={compFinderStore.addWeightModalOpen} size="lg">
        <Modal.HeaderWithTitleAndClose
          title={"Add " + (weight.conditionalStats ? "Conditional" : "Stat") + " Weight"}
          close={() => {
            compFinderStore.closeAddWeightModal();
          }}
        />
        <Modal.Body>
          {weight.conditionalStats ? (
            <React.Fragment>
              <div className="row mx-0 form-group">
                <div className="col-2 d-flex align-items-center pl-0">
                  <BestLabel>Statistic Name</BestLabel>
                </div>
                <div className="col-6 pr-0">
                  <BestSelect
                    name="cfawStatId"
                    options={weight.conditionalStats}
                    onChange={option => {
                      weight.id = option.id;
                      weight.shortName = option.shortName;
                    }}
                    value={lookupStore.getDropdownItem(
                      weight.conditionalStats,
                      weight.shortName,
                      "shortName",
                      "shortName"
                    )}
                    getOptionValue={option => option.shortName}
                    getOptionLabel={option => option.shortName}
                  />
                </div>
              </div>

              <div className="row mx-0 form-group">
                <div className="col-2 d-flex align-items-center pl-0">
                  <BestLabel>Threshold</BestLabel>
                </div>
                <div className="col-4 pr-0">
                  <NumberInput
                    name="cfawPlatformWeight"
                    onChange={value => {
                      weight.threshold = value;
                    }}
                    value={weight.threshold}
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="row mx-0 form-group">
                <div className="col-2 d-flex align-items-center pl-0">
                  <BestLabel>Statistic Name</BestLabel>
                </div>
                <div className="col-6 pr-0">
                  <BestQuickSearch
                    options={lookupStore.autocompleteStatsOnly}
                    renderSuggestion={suggestion => {
                      return <StatAutocompleteRow stat={suggestion} />;
                    }}
                    getSuggestionValue={stat => {
                      return statStore.getAutocompleteText(stat);
                    }}
                    getSuggestions={(stats, searchTerm) => {
                      return statStore.filterBySearchTerm(stats, searchTerm);
                    }}
                    onSuggestionSelected={(event, { suggestion }) => {
                      weight.id.objectId = suggestion.statId;
                      weight.shortName = suggestion.statCode;
                    }}
                    styles={FormSearchStyles}
                  />
                </div>
              </div>
            </React.Fragment>
          )}

          <div className="row mx-0 form-group">
            <div className="col-2 d-flex align-items-center pl-0">
              <BestLabel>Platform Weight</BestLabel>
            </div>
            <div className="col-4 pr-0">
              <NumberInput
                name="cfawPlatformWeight"
                onChange={value => {
                  weight.platformWeight = value;
                }}
                value={weight.platformWeight}
              />
            </div>
          </div>

          <div className="row mx-0">
            <div className="col-2 d-flex align-items-center pl-0">
              <BestLabel>Career Weight</BestLabel>
            </div>
            <div className="col-4 pr-0">
              <NumberInput
                name="cfawCareerWeight"
                onChange={value => {
                  weight.careerWeight = value;
                }}
                value={weight.careerWeight}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row mx-0 col-12 p-0 justify-content-end">
            <PrimaryButton
              onClick={() => {
                if (weight.conditionalStats) {
                  compFinderStore.addConditionalWeight();
                } else {
                  compFinderStore.addStatWeight();
                }
              }}
            >
              Save
            </PrimaryButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

AddWeightModal.propTypes = {
  rootStore: PropTypes.object.isRequired,
  weight: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(AddWeightModal));
