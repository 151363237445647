import React from "react";
import { action, autorun, extendObservable } from "mobx";
import { saveAs } from "file-saver";
import _ from "lodash";
import Moment from "moment";
import { AlertConstants } from "../../constants/AlertConstants";
import CurrencyFormatter from "../../components/utilities/CurrencyFormatter";
import { ReportConstants } from "../../constants/ReportConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import ValueFormatter from "../../components/utilities/ValueFormatter";
import WidgetStore from "../WidgetStore";

class CompFinderStore extends WidgetStore {
  constructor(authStore, routerStore, alertStore, reportStore, piaApi) {
    super();
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.alertStore = alertStore;
    this.reportStore = reportStore;
    this.piaApi = piaApi;

    this.defaults = {
      activeTab: RouteConstants.COMP_FINDER.tabs[0],
      runSearchOnLoad: false,
      cfMaster: {
        className: "com.mlb.piaplus.db.CompFinderMaster",
        compFinderName: "",
        focusPlayerId: 0,
        focusPlayerName: "",
        focusPlayerYear: new Date().getFullYear().toString(),
        searchType: "arb",
        prorateType: "actuals",
        incLookbacksFlg: true,
        incCurrentClassCompsFlg: true,
        incMyContractsFlg: false,
        incPtContractsFlg: false,
        incNtContractsFlg: false,
        arbCountFlg: true,
        arbCountByMLSFlg: false,
        serviceClassFlg: false,
        serviceClassValue: 0,
        serviceLevelOp: "OR",
        eosRangeFlg: false,
        eosServiceRangeStart: 0.0,
        eosRangeLowValue: "0.000",
        eosServiceRangeEnd: 32.171,
        eosRangeHighValue: "32.171",
        numberOfResults: 10,
        showOverride: false
      },
      focusCols: [],
      resultCols: [],
      saveNewModalOpen: false,
      savedSearch: {
        className: "com.mlb.piaplus.db.CompFinderMaster",
        focusPlayerId: 0,
        focusPlayerName: "",
        compFinderName: "",
        lastUpdatedStart: Moment(new Date())
          .format("MM/DD/YYYY")
          .toString(),
        lastUpdatedEnd: Moment(new Date())
          .format("MM/DD/YYYY")
          .toString()
      },
      savedSearchResults: [],
      selectedSearchIds: [],
      addWeightModalOpen: false,
      positionWeights: {},
      reliefWeights: {},
      startingWeights: {},
      weightToAdd: {
        id: { type: "" },
        threshold: 0,
        platformWeight: 0,
        careerWeight: 0
      }
    };

    extendObservable(this, {
      activeTab: this.defaults["activeTab"],
      runSearchOnLoad: this.defaults["runSearchOnLoad"],
      cfMaster: this.defaults["cfMaster"],
      focusCols: this.defaults["focusCols"],
      resultCols: this.defaults["resultCols"],
      saveNewModalOpen: this.defaults["saveNewModalOpen"],
      savedSearch: this.defaults["savedSearch"],
      savedSearchResults: this.defaults["savedSearchResults"],
      selectedSearchIds: this.defaults["selectedSearchIds"],
      addWeightModalOpen: this.defaults["addWeightModalOpen"],
      positionWeights: this.defaults["positionWeights"],
      reliefWeights: this.defaults["reliefWeights"],
      startingWeights: this.defaults["startingWeights"],
      weightToAdd: this.defaults["weightToAdd"],
      resetStore: action(() => {
        this.activeTab = this.defaults["activeTab"];
        this.runSearchOnLoad = this.defaults["runSearchOnLoad"];
        this.cfMaster = this.defaults["cfMaster"];
        this.focusCols = this.defaults["focusCols"];
        this.resultCols = this.defaults["resultCols"];
        this.saveNewModalOpen = this.defaults["saveNewModalOpen"];
        this.savedSearch = this.defaults["savedSearch"];
        this.savedSearchResults = this.defaults["savedSearchResults"];
        this.selectedSearchIds = this.defaults["selectedSearchIds"];
        this.addWeightModalOpen = this.defaults["addWeightModalOpen"];
        this.positionWeights = this.defaults["positionWeights"];
        this.reliefWeights = this.defaults["reliefWeights"];
        this.startingWeights = this.defaults["startingWeights"];
        this.weightToAdd = this.defaults["weightToAdd"];
      })
    });

    autorun(() => {
      if (this.routerStore.isCompFinderPage && this.authStore.loggedIn) {
        this.getInitialSavedSearches();
        this.getPositionWeights();
        this.getReliefWeights();
        this.getStartingWeights();

        if (this.runSearchOnLoad) {
          this.runSearchOnLoad = false;
          this.findComps(false);
        }
      } else if (this.routerStore.isEditCompFinderPage && this.authStore.loggedIn) {
        this.parseCompFinderUrl();
      } else {
        this.resetStore();
      }
    });
  }

  parseCompFinderUrl() {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.EDIT_COMP_FINDER.route + RouteConstants.EDIT_COMP_FINDER.pathParams
    );
    let compFinderId = pathParams["compFinderId"];
    compFinderId = compFinderId * 1;

    this.loadCompFinder(compFinderId);
  }

  loadCompFinder(compFinderId) {
    this.piaApi.loadCompFinder(compFinderId).then(response => {
      //this.activeTab = "Search Criteria";
      this.cfMaster = response.payload;
      this.focusCols = this.getCompColumns(true);
      this.resultCols = this.getCompColumns(false);

      if (response.payload.eosRangeLowValue !== null) {
        this.cfMaster.eosServiceRangeStart = response.payload.eosRangeLowValue;
      }
      if (response.payload.eosRangeHighValue !== null) {
        this.cfMaster.eosServiceRangeEnd = response.payload.eosRangeHighValue;
      }

      this.activeTab = RouteConstants.COMP_FINDER.tabs[0];
      this.getInitialSavedSearches();
    });
  }

  findComps(homePlateFlag) {
    this.piaApi.findCfComps(this.cfMaster, homePlateFlag).then(response => {
      if (!!response) {
        if (response.eosRangeLowValue !== null) {
          response.eosServiceRangeStart = response.eosRangeLowValue;
        }
        if (response.eosRangeHighValue !== null) {
          response.eosServiceRangeEnd = response.eosRangeHighValue;
        }
        this.cfMaster = response;
        this.focusCols = this.getCompColumns(true);
        this.resultCols = this.getCompColumns(false);
      }
    });
  }

  getCompColumns(focus) {
    let baseCols = [
      { key: "playerName", name: "Name", width: focus ? 200 : 160 },
      {
        key: "platformYear",
        name: "Year",
        width: 80,

        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <span>{row.row.career ? "Career" : row.value}</span>;
        }
      },
      { key: "position", name: "POS", width: 60 },
      { key: "mls", name: "MLS", width: 60 },
      {
        key: "tender",
        name: "Tndr",
        width: 60,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <ValueFormatter value={row.value} applicable={!row.row.career} />;
        }
      },
      {
        key: "contractType",
        name: "Type",
        width: 60,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <ValueFormatter value={row.value} applicable={!row.row.career} />;
        }
      },
      {
        key: "contractYears",
        name: "Yrs",
        width: 60,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <ValueFormatter value={row.value} applicable={!row.row.career} />;
        }
      },
      {
        key: "salary",
        name: "PY+1 Salary",
        width: 120,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <CurrencyFormatter value={row.value} applicable={!row.row.career} />;
        }
      },
      {
        key: "pySalary",
        name: "PY Salary",
        width: 120,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <CurrencyFormatter value={row.value} applicable={!row.row.career} />;
        }
      },
      {
        key: "scoreFormatted",
        name: "Score",
        width: 80,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <ValueFormatter value={row.value + "%"} applicable={!row.row.career} />;
        }
      },
      {
        key: "dlDays",
        name: "IL",
        width: 60,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <span>{row.row.career ? row.row.dlDaysCareer : row.value}</span>;
        }
      }
    ];

    if (!focus) {
      baseCols.unshift({ key: "displayRank", name: "#", width: 40 });
    }

    let statCols = this.getStatCols(true);

    for (let index in statCols) {
      statCols[index].formatter = row => {
        let rowIndex = row.row.rowIndex || 0;
        let stat = null;

        if (focus) {
          if (this.cfMaster.focusPlayerOnly && this.cfMaster.focusPlayerOnly.compFinderStats) {
            stat = this.cfMaster.focusPlayerOnly.compFinderStats[index];
          }
        } else if (
          this.cfMaster.compFinderPlayersNoFocus &&
          this.cfMaster.compFinderPlayersNoFocus[rowIndex] &&
          this.cfMaster.compFinderPlayersNoFocus[rowIndex].compFinderStats
        ) {
          stat = this.cfMaster.compFinderPlayersNoFocus[rowIndex].compFinderStats[index];
        }

        if (stat) {
          if (row.row.career) {
            return <span>{stat.careerValue}</span>;
          } else {
            return <span>{stat.pyValue}</span>;
          }
        }
      };
      statCols[index].width = 60;
    }

    statCols[statCols.length - 1].width = undefined;

    return baseCols.concat(statCols);
  }

  getStatCols(addKey) {
    let statCols = [];

    if (this.cfMaster.displayStatline) {
      for (let index in this.cfMaster.displayStatline.userStatlineDetailsNoSupportingStats) {
        let stat = this.cfMaster.displayStatline.userStatlineDetailsNoSupportingStats[index];
        let col = { name: stat.statisticshortname, shortName: stat.statisticshortname };

        if (addKey) {
          col.key = "stat" + index;
        }

        statCols.push(col);
      }
    }

    return statCols;
  }

  exportComps() {
    this.piaApi.exportCfComps(this.cfMaster).then(file => {
      saveAs(file, "excel.xlsx");
    });
  }

  saveSearch(overrideFlag = false) {
    this.saveNewModalOpen = false;
    this.piaApi.saveSearch(this.cfMaster, overrideFlag).then(resp => {
      let response = resp.payload;
      if (resp.overrideMessage) {
        this.cfMaster.overrideMessage = resp.overrideMessage;
        this.cfMaster.overrideFn = () => {
          this.cfMaster.overrideMessage = null;
          this.cfMaster.showOverride = false;
          this.saveSearch(true);
        };
        this.cfMaster.showOverride = true;
      } else if (!!response) {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Search saved successfully"
        });

        this.routerStore.pushHistory(RouteConstants.EDIT_COMP_FINDER.route + "/" + response.compFinderId, false);
      }
    });
  }

  runCompReport() {
    let options = { cfMaster: this.cfMaster, cfColumnList: this.getStatCols(false) };
    this.reportStore.launchReport(ReportConstants.REPORT_COMPFINDER, options);
  }

  getInitialSavedSearches() {
    this.selectedSearchIds = [];
    this.piaApi.getInitialSavedSearches().then(response => {
      if (!!response) {
        this.savedSearchResults = response;
      }
    });
  }

  findSavedSearches() {
    this.selectedSearchIds = [];
    this.piaApi.findSavedSearches(this.savedSearch).then(response => {
      if (!!response) {
        this.savedSearchResults = response;
      }
    });
  }

  get displaySearchResults() {
    if (!this.savedSearchResults) {
      return [];
    }

    return this.savedSearchResults.map(search => {
      return {
        compFinderId: search.compFinderId,
        compFinderName: search.compFinderName,
        focusPlayerName: search.focusPlayerName,
        lastchDtstmp: search.lastchDtstmp,
        lastchDtstmpAmericaNY: search.lastchDtstmpAmericaNY,
        isSelected: _.includes(this.selectedSearchIds, search.compFinderId)
      };
    });
  }

  addSelectedSearches(rows) {
    rows.forEach(row => {
      if (row.compFinderId) {
        this.selectedSearchIds.push(row.compFinderId);
      }
    });
  }

  removeUnselectedSearches(rows) {
    let searchIdsToRemove = rows.map(row => row.compFinderId);
    this.selectedSearchIds = _.difference(this.selectedSearchIds, searchIdsToRemove);
  }

  deleteSavedSearches() {
    this.piaApi.deleteSavedSearches(this.selectedSearchIds).then(response => {
      if (!!response) {
        this.resetStore();
        this.activeTab = RouteConstants.COMP_FINDER.tabs[1];
        this.getInitialSavedSearches();
      }
    });
  }

  getPositionWeights = () => {
    this.piaApi.getPositionWeights().then(response => {
      this.positionWeights = response;
    });
  };

  getReliefWeights = () => {
    this.piaApi.getReliefWeights().then(response => {
      this.reliefWeights = response;
    });
  };

  getStartingWeights = () => {
    this.piaApi.getStartingWeights().then(response => {
      this.startingWeights = response;
    });
  };

  openAddWeightModal(type, conditionalStats, onAdd = () => {}) {
    this.weightToAdd.id.type = type;
    this.weightToAdd.onAdd = onAdd;

    if (conditionalStats && conditionalStats.length > 0) {
      this.weightToAdd.conditionalStats = conditionalStats;
      this.weightToAdd.id = conditionalStats[0].id;
      this.weightToAdd.shortName = conditionalStats[0].shortName;
    }

    this.addWeightModalOpen = true;
  }

  closeAddWeightModal() {
    this.weightToAdd = this.defaults["weightToAdd"];
    this.addWeightModalOpen = false;
  }

  addStatWeight() {
    this.piaApi.addStatWeight(this.weightToAdd).then(response => {
      if (!!response) {
        this.weightToAdd.onAdd();
        this.closeAddWeightModal();
      }
    });
  }

  updateStatWeight(weight) {
    this.piaApi.updateStatWeight(weight).then(response => {
      if (!!response) {
        weight.editMode = false;
      }
    });
  }

  deleteStatWeight(weight, onRemove = () => {}) {
    this.piaApi.deleteStatWeight(weight).then(response => {
      if (!!response) {
        onRemove();
      }
    });
  }

  updatePyvcWeight(weight, editProp) {
    this.piaApi.updatePyvcWeight(weight).then(response => {
      if (!!response) {
        weight[editProp] = false;
      }
    });
  }

  addConditionalWeight() {
    this.piaApi.addConditionalWeight(this.weightToAdd).then(response => {
      if (!!response) {
        this.weightToAdd.onAdd();
        this.closeAddWeightModal();
      }
    });
  }

  updateConditionalWeight(weight) {
    this.piaApi.updateConditionalWeight(weight).then(response => {
      if (!!response) {
        weight.editMode = false;
      }
    });
  }

  deleteConditionalWeight(weight, onRemove = () => {}) {
    this.piaApi.deleteConditionalWeight(weight).then(response => {
      if (!!response) {
        onRemove();
      }
    });
  }
}

export default CompFinderStore;
