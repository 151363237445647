import React from "react";
import PropTypes from "prop-types";

class ToFixedFormatter extends React.Component {
  render() {
    const { toFixed, value } = this.props;
    if (value) {
      return value.toFixed(toFixed);
    } else {
      let formatted = "0.";
      for (let i = 0; i < toFixed; i++) {
        formatted += "0";
      }
      return formatted;
    }
  }
}

ToFixedFormatter.defaultProps = {
  toFixed: 3
};

ToFixedFormatter.propTypes = {
  value: PropTypes.any,
  toFixed: PropTypes.array
};

export default ToFixedFormatter;
