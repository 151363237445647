import { action, autorun, extendObservable } from "mobx";
import { RouteConstants } from "../../constants/RouteConstants";
import { SharingConstants } from "../../constants/SharingConstants";
import WidgetStore from "../WidgetStore";

class SharingStore extends WidgetStore {
  constructor(authStore, routerStore, piaApi) {
    super();
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.piaApi = piaApi;

    this.defaults = {
      typeId: SharingConstants.TYPE.COMPARISONS,
      comments: "",
      userGroupLk: "BOC",
      selectedUser: { displayname: "" },
      shareUsers: [],
      historyWithOthers: [],
      historyWithUser: []
    };

    extendObservable(this, {
      typeId: this.defaults["typeId"],
      comments: this.defaults["comments"],
      userGroupLk: this.defaults["userGroupLk"],
      selectedUser: this.defaults["selectedUser"],
      shareUsers: this.defaults["shareUsers"],
      historyWithOthers: this.defaults["historyWithOthers"],
      historyWithUser: this.defaults["historyWithUser"],
      resetStore: action(() => {
        this.typeId = this.defaults["typeId"];
        this.comments = this.defaults["comments"];
        this.userGroupLk = this.defaults["userGroupLk"];
        this.selectedUser = this.defaults["selectedUser"];
        this.shareUsers = this.defaults["shareUsers"];
        this.historyWithOthers = this.defaults["historyWithOthers"];
        this.historyWithUser = this.defaults["historyWithUser"];
      })
    });

    autorun(() => {
      if (this.routerStore.isSharingPage && this.authStore.loggedIn) {
        this.parseUrl();
        this.getHistoryWithOthers();
        this.getHistoryWithUser();
      } else {
        this.resetStore();
      }
    });
  }

  parseUrl() {
    const pathParams = this.routerStore.getPathParams(RouteConstants.SHARING.route + RouteConstants.SHARING.pathParams);
    let typeId = pathParams["typeId"] || this.defaults["typeId"];
    this.typeId = typeId * 1;
  }

  getHistoryWithOthers() {
    this.piaApi.getSharingHistoryWithOthers().then(response => {
      if (!!response) {
        this.historyWithOthers = response;
      }
    });
  }

  getHistoryWithUser() {
    this.piaApi.getSharingHistoryWithUser().then(response => {
      if (!!response) {
        this.historyWithUser = response;
      }
    });
  }
}

export default SharingStore;
