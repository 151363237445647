import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import Moment from "moment";
import PropTypes from "prop-types";
import {
  BestLabel,
  BestQuickSearch,
  CardContainer,
  DangerButton,
  DatePickerInput,
  DateTimeFormatter,
  Input,
  LightButton,
  PrimaryButton
} from "best-common-react";
import { FormSearchStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PlayerAutocompleteRow from "../autocomplete/PlayerAutocompleteRow";
import TitleCard from "../widgets/TitleCard";
import { RouteConstants } from "../../constants/RouteConstants";

class SavedSearches extends React.Component {
  getSearchCols() {
    let { routerStore, piaApi, compFinderStore } = this.props.rootStore;
    let { EDIT_COMP_FINDER } = RouteConstants;
    return [
      {
        key: "compFinderName",
        name: "Comp Finder Name",
        width: 300,
        formatter: row => (
          <a
            href="/#"
            onClick={e => {
              e.preventDefault();
              routerStore.pushHistory(EDIT_COMP_FINDER.route + "/" + row.row.compFinderId, false);
              window.location.reload(true);
            }}
          >
            <span>{row.value}</span>
          </a>
        )
      },
      { key: "focusPlayerName", name: "Focus Player Name", width: 300 },
      {
        key: "lastchDtstmpAmericaNY",
        name: "Last Updated",
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      }
    ];
  }

  render() {
    let { compFinderStore, lookupStore, playerStore } = this.props.rootStore;
    const onRowsSelected = rows => {
      compFinderStore.addSelectedSearches(rows.map(row => row.row));
    };
    const onRowsDeselected = rows => {
      compFinderStore.removeUnselectedSearches(rows.map(row => row.row));
    };
    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="row m-0">
            <div className="col-3">
              <div className="row col-12 p-0 m-0">
                <BestLabel>Comp Finder Name</BestLabel>
              </div>

              <div className="row col-12 p-0 m-0">
                <Input
                  name="ssCompFinderName"
                  value={compFinderStore.savedSearch.compFinderName}
                  onChange={event => {
                    compFinderStore.savedSearch.compFinderName = event.target.value;
                  }}
                  editable
                />
              </div>
            </div>
            <div className="col-3">
              <div className="row col-12 p-0 m-0">
                <BestLabel>Focus Player Name</BestLabel>
              </div>

              <div className="row p-0 m-0">
                <div className="col-12 p-0">
                  <BestQuickSearch
                    options={lookupStore.autocompletePlayers}
                    renderSuggestion={suggestion => {
                      return <PlayerAutocompleteRow player={suggestion} />;
                    }}
                    getSuggestionValue={player => {
                      return playerStore.getAutocompleteText(player);
                    }}
                    getSuggestions={playerStore.filterBySearchTerm}
                    placeholder="select a focus player"
                    onSuggestionSelected={(event, { suggestion }) => {
                      compFinderStore.savedSearch.focusPlayerId = suggestion.playerId;
                      compFinderStore.savedSearch.focusPlayerName = suggestion.playerName;
                    }}
                    styles={FormSearchStyles}
                  />
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="row col-12 p-0 m-0">
                <BestLabel>Last Updated From</BestLabel>
              </div>

              <div className="row p-0 m-0">
                <div className="col-12 p-0">
                  <DatePickerInput
                    id="ssLastUpdatedFrom"
                    value={
                      compFinderStore.savedSearch.lastUpdatedStart
                        ? Moment(compFinderStore.savedSearch.lastUpdatedStart, "MM/DD/YYYY")
                        : null
                    }
                    onChange={date => {
                      compFinderStore.savedSearch.lastUpdatedStart = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-3">
              <div className="row col-12 m-0 p-0">
                <BestLabel>Last Updated To</BestLabel>
              </div>

              <div className="row m-0 p-0">
                <div className="col-12 p-0">
                  <DatePickerInput
                    id="ssLastUpdatedTo"
                    value={
                      compFinderStore.savedSearch.lastUpdatedEnd
                        ? Moment(compFinderStore.savedSearch.lastUpdatedEnd, "MM/DD/YYYY")
                        : null
                    }
                    onChange={date => {
                      compFinderStore.savedSearch.lastUpdatedEnd = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row col-12 mx-0 my-4 justify-content-end">
            <PrimaryButton
              onClick={() => {
                compFinderStore.findSavedSearches();
              }}
            >
              Search
            </PrimaryButton>
          </div>

          <div className="row col-12 mx-0 my-4">
            <TitleCard className="row col-12 m-0">
              <span>Search Results</span>
            </TitleCard>
          </div>

          <div className="px-3">
            <PiaTable
              data={compFinderStore.displaySearchResults}
              columns={this.getSearchCols()}
              rowSelection={{
                showCheckbox: true,
                onRowsSelected: onRowsSelected,
                onRowsDeselected: onRowsDeselected,
                selectBy: {
                  isSelectedKey: "isSelected"
                },
                enableShiftSelect: true
              }}
              height={compFinderStore.calculateTableHeight(compFinderStore.displaySearchResults.length, 308)}
            />
          </div>
        </CardContainer.Body>
        <CardContainer.Footer>
          <div className="header-item-row">
            <LightButton
              onClick={() => {
                compFinderStore.exportList(compFinderStore.savedSearchResults);
              }}
            >
              Export to Excel
            </LightButton>
            <LightButton>Share</LightButton>
            <DangerButton
              onClick={() => {
                compFinderStore.deleteSavedSearches();
              }}
            >
              Delete
            </DangerButton>
          </div>
        </CardContainer.Footer>
      </CardContainer>
    );
  }
}

SavedSearches.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(SavedSearches)));
