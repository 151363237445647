import React from "react";
import { inject, observer } from "mobx-react";
import { BestLabel, DangerButton, FormInput, LightButton, Modal, PrimaryButton } from "best-common-react";
import PropTypes from "prop-types";
import Parser from "rss-parser";
import { withTheme } from "styled-components";
import _ from "lodash";
import { AlertConstants } from "../../constants/AlertConstants";
import TitleCard from "../widgets/TitleCard";

class NewsFeedModal extends React.Component {
  constructor(props) {
    super(props);
    let { piaApi } = props.rootStore;
    this.state = {
      loaded: false,
      feedToFind: {},
      feeds: [],
      suggestedFeeds: []
    };
    piaApi.getSuggestedNewsFeeds().then(response => {
      if (!!response) {
        this.setState(state => {
          return {
            suggestedFeeds: response
          };
        });
      }
    });
  }

  async componentDidMount() {
    this.checkFeeds();
  }

  async componentDidUpdate() {
    this.checkFeeds();
  }

  async checkFeeds() {
    let { newsFeedStore } = this.props.rootStore;
    if (newsFeedStore.loaded && !this.state.loaded) {
      this.setState({ loaded: true, feeds: _.cloneDeep(newsFeedStore.feeds) });
    }
  }

  getSuggestedFeedRows(newsFeedStore) {
    if (!newsFeedStore.loaded) {
      return "";
    }

    let userFeedUrls = [];

    this.state.feeds.forEach(feed => {
      userFeedUrls.push(feed.feedURL);
    });

    let rows = [];

    this.state.suggestedFeeds.forEach((feed, index) => {
      let row = (
        <TitleCard key={"newfeed_" + index} className="row mx-0 mt-1">
          <div className="col-12 p-0 d-flex align-items-center justify-content-between">
            <BestLabel>{feed.feedName}</BestLabel>
            <PrimaryButton
              onClick={() => {
                this.setState(state => {
                  state.feeds.push(feed);

                  return {
                    feeds: state.feeds
                  };
                });
              }}
            >
              Add
            </PrimaryButton>
          </div>
        </TitleCard>
      );

      if (!userFeedUrls.includes(feed.feedURL) && !this.state.feeds.includes(feed)) {
        rows.push(row);
      }
    });
    return <React.Fragment>{rows}</React.Fragment>;
  }

  getCurrentFeedRows(newsFeedStore) {
    if (!newsFeedStore.loaded) {
      return "";
    }

    let rows = [];

    this.state.feeds.forEach((feed, index) => {
      let row = (
        <TitleCard key={"currentFeed_" + index} className="row mx-0 mt-1">
          <div className="col-12 p-0 d-flex align-items-center justify-content-between">
            <BestLabel>{feed.feedName}</BestLabel>
            <DangerButton
              onClick={() => {
                this.setState(state => {
                  state.feeds.splice(index, 1);

                  return {
                    feeds: state.feeds
                  };
                });
              }}
            >
              Remove
            </DangerButton>
          </div>
        </TitleCard>
      );

      rows.push(row);
    });

    return <React.Fragment>{rows}</React.Fragment>;
  }

  addFeed(alertStore, piaApi) {
    if (
      !this.state.feedToFind.feedURL ||
      "" === this.state.feedToFind.feedURL ||
      !this.state.feedToFind.feedName ||
      "" === this.state.feedToFind.feedName
    ) {
      return;
    }

    let feedUrl = this.state.feedToFind.feedURL.replace(/.+:\/\//, ""); // Remove http(s):// from string
    let parser = new Parser();
    parser.parseURL(piaApi.proxyUrl + feedUrl).then(
      response => {
        if (!!response) {
          response.feedName = this.state.feedToFind.feedName;
          response.feedURL = this.state.feedToFind.feedURL;
          this.setState(state => {
            state.feeds.push(response);
            return { feeds: state.feeds };
          });
        }
      },
      error => {
        alertStore.addAlert({
          type: AlertConstants.TYPES.DANGER,
          text: "Could not find the feed"
        });
      }
    );
  }

  saveFeeds(alertStore, newsFeedStore, piaApi) {
    piaApi.saveNewsFeeds(this.state.feeds).then(response => {
      if (!!response) {
        alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Feeds added successfully"
        });
        newsFeedStore.resetStore();
        newsFeedStore.updateNewsFeeds();
        this.close(newsFeedStore);
      }
    });
  }

  close(newsFeedStore) {
    this.setState({ loaded: false });
    newsFeedStore.openModal = false;
  }

  render() {
    let { alertStore, newsFeedStore, piaApi } = this.props.rootStore;
    return (
      <Modal show={newsFeedStore.openModal} size="lg">
        <Modal.HeaderWithTitleAndClose
          title="Add / Remove Feeds"
          close={() => {
            this.close(newsFeedStore);
          }}
        />
        <Modal.Body>
          <div className="row m-0">
            <div className="col-6">
              <div className="row m-0 modal-section-title">Suggested Feeds</div>

              <React.Fragment>{this.getSuggestedFeedRows(newsFeedStore)}</React.Fragment>
            </div>

            <div className="col-6">
              <div className="row m-0 modal-section-title">Current Feeds</div>

              <React.Fragment>{this.getCurrentFeedRows(newsFeedStore)}</React.Fragment>
            </div>

            <div className="col-6 pt-4">
              <div className="row m-0 modal-section-title">Add New Feed</div>

              <div className="row m-0">
                <div className="col-12 p-0">
                  <FormInput
                    name="nfFeedUrl"
                    label="Feed URL"
                    type="text"
                    value={this.state.feedToFind.feedURL}
                    onChangeFn={value => {
                      this.setState(state => {
                        state.feedToFind.feedURL = value;

                        return {
                          feedToFind: state.feedToFind
                        };
                      });
                    }}
                    editable
                  />
                </div>
              </div>

              <div className="row m-0">
                <div className="col-12 p-0">
                  <FormInput
                    name="nfFeedName"
                    label="Feed Name"
                    type="text"
                    value={this.state.feedToFind.feedName}
                    onChangeFn={value => {
                      this.setState(state => {
                        state.feedToFind.feedName = value;

                        return {
                          feedToFind: state.feedToFind
                        };
                      });
                    }}
                    editable
                  />
                </div>
              </div>

              <div className="row m-0">
                <div className="col-12 p-0 d-flex justify-content-end">
                  <PrimaryButton
                    onClick={() => {
                      this.addFeed(alertStore, piaApi);
                    }}
                  >
                    Add
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="row m-0 justify-content-end">
            <PrimaryButton
              onClick={() => {
                this.saveFeeds(alertStore, newsFeedStore, piaApi);
              }}
            >
              Save
            </PrimaryButton>
            <LightButton
              onClick={() => {
                this.close(newsFeedStore);
              }}
            >
              Cancel
            </LightButton>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

NewsFeedModal.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(NewsFeedModal)));
