import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { BestLabel, CardContainer, DateTimeFormatter, Icon, PriceFormatter } from "best-common-react";
import PiaTable from "../widgets/PiaTable";
import TitleCard from "../widgets/TitleCard";
import ToFixedFormatter from "../utilities/ToFixedFormatter";

class ArbitrationInfo extends React.Component {
  getCols(playerStore) {
    return [
      {
        key: "year",
        name: "Year",
        width: 60,
        formatter: row => (
          <a
            href="/#"
            onClick={event => {
              event.preventDefault();
              playerStore.profileArbitrationDetails = row.row;
            }}
            className="arb-row"
          >
            <span>{row.value}</span>
          </a>
        )
      },
      { key: "eosOrg", name: "EOS Org", width: 80 },
      { key: "arbOrg", name: "Arb Org", width: 80 },
      { key: "arbMLS", name: "EOS MLS", width: 80, formatter: row => <ToFixedFormatter value={row.value} /> },
      { key: "arbposition", name: "Position", width: 100 },
      { key: "arbstatus", name: "Arb Status", width: 120 },
      { key: "tenderStatus", name: "Tender", width: 75 },
      {
        key: "clubOffer",
        name: "Club Offer",
        width: 150,
        formatter: row => <PriceFormatter value={row.value} />
      },
      {
        key: "playerOffer",
        name: "Player Offer",
        width: 150,
        formatter: row => <PriceFormatter value={row.value} />
      },
      {
        key: "midPoint",
        name: "Mid Point",
        width: 150,
        formatter: row => <PriceFormatter value={row.value} />
      },
      { key: "decision", name: "Decision", width: 100 },
      {
        key: "awardOnAAV",
        name: "Award / AAV",
        formatter: row => <PriceFormatter value={row.value} />
      }
    ];
  }

  getDetailTable(details) {
    let decisions = [];

    if (details.arbitersDecisions && details.arbitersDecisions.length) {
      details.arbitersDecisions.forEach((decision, index) => {
        decisions.push(
          <div key={"decision_" + index} className="text-right">
            {decision}
          </div>
        );
      });
    }

    return (
      <React.Fragment>
        <div className="row mx-0 mt-4 mb-2">
          <div className="col-4 pl-0">
            <TitleCard>
              <span>Arbitration Details</span>
            </TitleCard>
          </div>
        </div>
        <div className="row mx-0 my-2">
          <div className="col-4 pl-0">
            <table>
              <tbody>
                <tr>
                  <td className="d-flex justify-content-between">
                    <BestLabel>Club Offer</BestLabel>
                    <span>
                      <PriceFormatter value={details.clubOffer} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-between">
                    <BestLabel>Player Offer</BestLabel>
                    <span>
                      <PriceFormatter value={details.playerOffer} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-between">
                    <BestLabel>Mid Point</BestLabel>
                    <span>
                      <PriceFormatter value={details.midPoint} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-between">
                    <BestLabel>Spread</BestLabel>
                    <span>
                      <PriceFormatter value={details.spread} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-between">
                    <BestLabel>Award / AAV</BestLabel>
                    <span>
                      <PriceFormatter value={details.awardOnAAV} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-between">
                    <BestLabel>Hearing Date</BestLabel>
                    <span>
                      <DateTimeFormatter value={details.hearingDate} />
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-between">
                    <BestLabel>Hearing Location</BestLabel>
                    <span>{details.hearingLocationCity}</span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-between">
                    <BestLabel>Arbiters</BestLabel>
                    <span>{decisions.length ? <div>{decisions}</div> : ""}</span>
                  </td>
                </tr>
                <tr>
                  <td className="d-flex justify-content-between">
                    <BestLabel>Decision</BestLabel>
                    <span>{details.decision}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    let { playerStore } = this.props.rootStore;
    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="container-fluid">
            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <TitleCard>
                  <div className="d-flex flex-fill justify-content-between align-items-center">
                    <span>Arbitration History</span>
                    <div className="item-row h-inherit col-3 p-0">
                      <div className="item card-item-separator"></div>
                      <div className="item">
                        <Icon
                          iconName="fa-file-download"
                          onClick={() => {
                            playerStore.exportProp(playerStore.player, "arbitrations");
                          }}
                          styles={{ size: "1.6em" }}
                        />
                      </div>
                    </div>
                  </div>
                </TitleCard>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-12 p-0">
                {playerStore.player.arbitrations ? (
                  <PiaTable
                    data={playerStore.player.arbitrations}
                    columns={this.getCols(playerStore)}
                    height={playerStore.calculateTableHeight(playerStore.player.arbitrations.length, 336)}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            {playerStore.profileArbitrationDetails.year
              ? this.getDetailTable(playerStore.profileArbitrationDetails)
              : ""}
          </div>
        </CardContainer.Body>
      </CardContainer>
    );
  }
}

ArbitrationInfo.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(ArbitrationInfo));
