import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { DangerButton, PrimaryButton } from "best-common-react";

class EditStatHeaderDetails extends React.Component {
  render() {
    let { statStore } = this.props.rootStore;
    return (
      <div className="row align-items-center h-100 m-0 p-0">
        <div className="col-7">
          <h5>
            {!statStore.stat.statName || "" === statStore.stat.statName
              ? "Untitled Stat"
              : statStore.stat.statName + " (" + statStore.stat.statCode + ")"}
          </h5>
        </div>
        <div className="col-5">
          <div className="item-row">
            <PrimaryButton
              className="col-2"
              onClick={() => {
                statStore.saveStat();
              }}
            >
              Save
            </PrimaryButton>
            <DangerButton
              className="col-2"
              onClick={() => {
                statStore.deleteStat();
              }}
              disabled={!statStore.stat.statId}
            >
              Delete
            </DangerButton>
          </div>
        </div>
      </div>
    );
  }
}

EditStatHeaderDetails.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(EditStatHeaderDetails));
