import React from "react";
import { inject, observer } from "mobx-react";
import Moment from "moment";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import {
  BestDropdown,
  CardContainer,
  DangerButton,
  DateTimeFormatter,
  FormInput,
  LightButton,
  PrimaryButton
} from "best-common-react";
import { FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import PiaWidget from "../widgets/PiaWidget";
import ResultCount from "../common/ResultCount";
import { RouteConstants } from "../../constants/RouteConstants";
import { SharingConstants } from "../../constants/SharingConstants";

class ManagePlayerQueries extends React.Component {
  getQueryTypes() {
    return [
      { value: "BOTH", label: "Basic and Advanced Queries" },
      { value: "BASE", label: "Basic Queries Only" },
      { value: "AVPL", label: "Advanced Queries Only" }
    ];
  }

  render() {
    let { lookupStore, playerStore, routerStore } = this.props.rootStore;
    let columns = playerStore.getQueryCols();
    let queryTypes = this.getQueryTypes();
    const onRowsSelected = rows => {
      playerStore.addSelectedQueries(rows.map(row => row.row));
    };
    const onRowsDeselected = rows => {
      playerStore.removeUnselectedQueries(rows.map(row => row.row));
    };
    return (
      <React.Fragment>
        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Player Query Search</span>
            </CardContainer.Header>
            <CardContainer.Body>
              <div className="row m-0">
                <div className="col-3">
                  <FormInput
                    name="pqQueryName"
                    label="Player Query Name"
                    type="text"
                    value={playerStore.querySearch.queryName}
                    onChangeFn={value => {
                      playerStore.querySearch.queryName = value;
                    }}
                    editable
                  />
                </div>

                <div className="col-3">
                  <BestDropdown
                    name="pqSearchFor"
                    title="Search For"
                    value={lookupStore.getDropdownItem(queryTypes, playerStore.querySearch.queryType)}
                    onChange={option => {
                      playerStore.querySearch.queryType = option.value;
                    }}
                    options={queryTypes}
                    styles={FormSelectStyles}
                  />
                </div>

                <div className="col-3">
                  <FormInput
                    name="pqLastUpdatedFrom"
                    label="Last Updated From"
                    type="date"
                    value={
                      playerStore.querySearch.lastUpdatedFromDte
                        ? Moment(playerStore.querySearch.lastUpdatedFromDte, "MM/DD/YYYY")
                        : playerStore.querySearch.lastUpdatedFromDte
                    }
                    onChangeFn={date => {
                      playerStore.querySearch.lastUpdatedFromDte = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                    formatter={DateTimeFormatter}
                    editable
                  />
                </div>

                <div className="col-3">
                  <FormInput
                    name="pqLastUpdatedTo"
                    label="Last Updated To"
                    type="date"
                    value={
                      playerStore.querySearch.lastUpdatedThroughDte
                        ? Moment(playerStore.querySearch.lastUpdatedThroughDte, "MM/DD/YYYY")
                        : playerStore.querySearch.lastUpdatedThroughDte
                    }
                    onChangeFn={date => {
                      playerStore.querySearch.lastUpdatedThroughDte = date ? Moment(date).format("MM/DD/YYYY") : date;
                    }}
                    formatter={DateTimeFormatter}
                    editable
                  />
                </div>
              </div>
            </CardContainer.Body>
            <CardContainer.Footer>
              <div className="header-item-row">
                <PrimaryButton
                  onClick={() => {
                    playerStore.findQueries();
                  }}
                >
                  Search
                </PrimaryButton>
              </div>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>

        <PiaWidget className="col-12 table-container">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Search Results</span>
            </CardContainer.Header>
            <CardContainer.Body>
              {playerStore.displayQueries.length > 0 ? (
                <PiaTable
                  data={playerStore.displayQueries}
                  columns={columns}
                  rowSelection={{
                    showCheckbox: true,
                    onRowsSelected: onRowsSelected,
                    onRowsDeselected: onRowsDeselected,
                    selectBy: {
                      isSelectedKey: "isSelected"
                    },
                    enableShiftSelect: true
                  }}
                  height={playerStore.calculateTableHeight(playerStore.displayQueries.length, 308)}
                />
              ) : (
                <div className="col-12">No Results</div>
              )}
            </CardContainer.Body>
            <CardContainer.Footer>
              <ResultCount value={playerStore.displayQueries.length} type="query" typePlural="queries">
                <div className="header-item-row">
                  <LightButton
                    onClick={() => {
                      playerStore.exportList(playerStore.queryResults);
                    }}
                  >
                    Export to Excel
                  </LightButton>
                  <LightButton
                    onClick={() => {
                      routerStore.pushHistory(
                        RouteConstants.SHARING.route + "/" + SharingConstants.TYPE.PLAYER_QUERIES,
                        false
                      );
                    }}
                  >
                    Share
                  </LightButton>
                  <DangerButton
                    onClick={() => {
                      playerStore.deleteQueries();
                    }}
                  >
                    Delete
                  </DangerButton>
                </div>
              </ResultCount>
            </CardContainer.Footer>
          </CardContainer>
        </PiaWidget>
      </React.Fragment>
    );
  }
}

ManagePlayerQueries.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(ManagePlayerQueries)));
