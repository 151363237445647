import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { DangerButton, PrimaryButton } from "best-common-react";
import HeaderInput from "./HeaderInput";

class EditStatListHeaderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false
    };
  }

  render() {
    let { compStore, statStore } = this.props.rootStore;
    return (
      <div className="d-flex flex-fill justify-content-between align-items-center h-100">
        <div className="pl-3">
          <HeaderInput
            value={statStore.statList.statlistname}
            onChange={event => {
              statStore.statList.statlistname = event.target.value;
            }}
          />
        </div>
        <div className="col-5">
          <div className="item-row">
            {statStore.statList.comparisononlyFlg ? (
              <DangerButton
                onClick={() => {
                  compStore.openComp(compStore.comp.comparisonId, {
                    plMasters: compStore.comp.plMasters,
                    slMasters: [statStore.statList]
                  });
                }}
              >
                Return to Comparison
              </DangerButton>
            ) : (
              ""
            )}
            <PrimaryButton
              onClick={() => {
                statStore.saveStatList();
              }}
            >
              Save Stat List
            </PrimaryButton>
          </div>
        </div>
      </div>
    );
  }
}

EditStatListHeaderDetails.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(EditStatListHeaderDetails));
