import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import AdminTools from "../../components/admin/AdminTools";
import { RouteConstants } from "../../constants/RouteConstants";

class AdminToolsContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.ADMIN_TOOLS.title);
  }

  render() {
    let { authStore } = this.props.rootStore;
    return (
      <div className="container-fluid">
        <div className="row mx-0 mb-4">{authStore.userData.admin ? <AdminTools /> : ""}</div>
      </div>
    );
  }
}

AdminToolsContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(AdminToolsContainer));
