import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { RouteConstants } from "../../constants/RouteConstants";

class HeaderTitle extends React.Component {
  getTitle(routerStore) {
    for (let key in RouteConstants) {
      if (routerStore.location.pathname === RouteConstants[key].route) {
        return RouteConstants[key].title;
      }
    }
  }
  render() {
    const { routerStore } = this.props.rootStore;

    return (
      <div className="row align-items-center h-100 m-0">
        <div className="col-12">
          <h5>{this.getTitle(routerStore)}</h5>
        </div>
      </div>
    );
  }
}

HeaderTitle.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(HeaderTitle));
