import { action, autorun, extendObservable } from "mobx";
import { ReportConstants } from "../../constants/ReportConstants";

class HomePlateStore {
  constructor(authStore, routerStore, lookupStore, reportStore, compFinderStore, compStore, piaApi) {
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.lookupStore = lookupStore;
    this.reportStore = reportStore;
    this.compFinderStore = compFinderStore;
    this.compStore = compStore;
    this.piaApi = piaApi;

    this.defaults = {
      quick1On1: {
        year1: new Date().getFullYear(),
        player1: {},
        year2: new Date().getFullYear(),
        player2: {},
        statListId: 0,
        salaryTypeId: 2,
        timeFrameId: -1,
        prorateType: "prorate"
      },
      statLists: [],
      standardPlayerLists: [],
      leagueLeaders: {
        batting: {
          leagueLeaderStat: { statId: -1 },
          statYear: new Date().getFullYear().toString(),
          useOriginalSort: true,
          leagueLeaderLeague: "ML",
          loadAllLeagueleaders: false
        },
        battingResults: [],
        pitching: {
          leagueLeaderStat: { statId: -1 },
          statYear: new Date().getFullYear().toString(),
          useOriginalSort: true,
          leagueLeaderLeague: "ML",
          loadAllLeagueleaders: false
        },
        pitchingResults: []
      }
    };

    extendObservable(this, {
      quick1On1: this.defaults["quick1On1"],
      statLists: this.defaults["statLists"],
      standardPlayerLists: this.defaults["standardPlayerLists"],
      leagueLeaders: this.defaults["leagueLeaders"],
      setPlayer1: action(pl => {
        this.updatePlayer1(pl);
      }),
      setPlayer2: action(pl => {
        this.updatePlayer2(pl);
      }),
      resetStore: action(() => {
        this.quick1On1 = this.defaults["quick1On1"];
        this.statLists = this.defaults["statLists"];
        this.standardPlayerLists = this.defaults["standardPlayerLists"];
        this.leagueLeaders = this.defaults["leagueLeaders"];
      })
    });

    autorun(() => {
      if (this.routerStore.isHomePlatePage && this.authStore.loggedIn && this.lookupStore.loaded) {
        this.getStatLists();
        this.getStandardPlayerLists();
      } else {
        this.resetStore();
      }
    });
  }

  getStatLists() {
    this.piaApi.getDefaultStatLists(false).then(response => {
      if (!!response) {
        this.statLists = response;
        this.updateStatListId();
      }
    });
  }

  updateStatListId() {
    // Get default user stat list ID
    if (this.statLists.length > 0) {
      for (let index in this.statLists) {
        let statList = this.statLists[index];

        if (
          (this.quick1On1.player1.startingPitcher && "LRD Starting" === statList.statlistname) ||
          (this.quick1On1.player1.reliefPitcher && "LRD Relief" === statList.statlistname) ||
          (!this.quick1On1.player1.startingPitcher &&
            !this.quick1On1.player1.reliefPitcher &&
            "LRD Batting" === statList.statlistname)
        ) {
          this.quick1On1.statListId = statList.statlistId;
          break;
        }
      }
    }
  }

  getStandardPlayerLists() {
    // Get standard player lists
    this.piaApi.getStandardPlayerLists().then(response => {
      if (!!response) {
        this.standardPlayerLists = response;
      }
    });
  }

  updatePlayer1(pl) {
    if (!!pl) {
      this.quick1On1.player1 = pl;
      this.updateStatListId();
    }
  }

  updatePlayer2(pl) {
    if (!!pl) {
      this.quick1On1.player2 = pl;
    }
  }

  runQuickOneOnOne() {
    this.reportStore.launchReport(ReportConstants.REPORT_1ON1, this.quick1On1, true);
  }

  createOneOnOneComp() {
    this.piaApi.createOneOnOneComp(this.quick1On1).then(response => {
      if (response) {
        this.compStore.openComp(0, response.cmpMaster);
      }
    });
  }

  getLeagueBattingLeaders() {
    this.piaApi.getLeagueLeaders(this.leagueLeaders.batting).then(response => {
      if (!!response) {
        this.leagueLeaders.battingResults = response;
      }
    });
  }

  getLeaguePitchingLeaders() {
    this.piaApi.getLeagueLeaders(this.leagueLeaders.pitching).then(response => {
      if (!!response) {
        this.leagueLeaders.pitchingResults = response;
      }
    });
  }
}

export default HomePlateStore;
