import { Component } from "react";
import { inject, observer } from "mobx-react";

import { withAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { RouteConstants } from "../../constants/RouteConstants";

class OktaAuthHelper extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: null };
  }

  async componentDidMount() {
    this.checkAuthentication();
  }

  async componentDidUpdate() {
    this.checkAuthentication();
  }

  async checkAuthentication() {
    const authenticated = await this.props.auth.isAuthenticated();
    const { authStore, piaApi, trackingHelper } = this.props.rootStore;

    let loginCallback = () => {
      trackingHelper.trackClickOnPage(RouteConstants.LOGIN.title, "Login Success");
    };

    if (
      this.props.location.pathname !== RouteConstants.IMPLICIT_CALLBACK.route &&
      authenticated !== this.state.authenticated
    ) {
      if (authenticated) {
        authStore.checkLocalStorage();

        if (!authStore.loggedIn && !authStore.isLoggingIn) {
          if (authStore.userData && authStore.userData.username) {
            piaApi.keepBoAlive().then(response => {
              authStore.login();
            });
          } else {
            authStore.login(loginCallback);
          }
        }
      }
      this.setState({ authenticated });
    }
  }

  render() {
    return "";
  }
}

OktaAuthHelper.propTypes = {
  rootStore: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default inject("rootStore")(withRouter(withAuth(observer(OktaAuthHelper))));
