import React from "react";
import { inject, observer } from "mobx-react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { CardContainer, FormInput, LightButton } from "best-common-react";
import PiaWidget from "../widgets/PiaWidget";

class AdminTools extends React.Component {
  render() {
    let { adminStore, lookupStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Reload Lookups</span>
            </CardContainer.Header>
            <CardContainer.Body>
              <div className="pia-responsive-grid">
                <LightButton
                  onClick={() => {
                    lookupStore.loadCollections();
                  }}
                >
                  Reload Cached Collections
                </LightButton>
                <LightButton
                  onClick={() => {
                    lookupStore.loadUniverseObjects();
                  }}
                >
                  Reload Universe Objects
                </LightButton>
                <LightButton
                  onClick={() => {
                    lookupStore.loadAutocompletes();
                  }}
                >
                  Reload Autocompletes
                </LightButton>
              </div>
            </CardContainer.Body>
          </CardContainer>
        </PiaWidget>

        <PiaWidget className="col-12">
          <CardContainer>
            <CardContainer.Header>
              <span className="header-title">Run Batches</span>
            </CardContainer.Header>
            <CardContainer.Body>
              <div className="pia-responsive-grid">
                <FormInput
                  name="batchPlayerIds"
                  label="Player IDs (leave blank for full run)"
                  type="text"
                  value={adminStore.playerIdString}
                  onChangeFn={value => {
                    // Only allow numbers and commas
                    if ("" === value || /^[0-9\,]+$/.test(value)) {
                      adminStore.playerIdString = value;
                    }
                  }}
                  editable
                />
              </div>
              <div className="pia-responsive-grid">
                <LightButton
                  onClick={() => {
                    adminStore.loadPlayerCache();
                  }}
                >
                  Load Player Cache
                </LightButton>
                <LightButton
                  onClick={() => {
                    adminStore.loadContractDetails();
                  }}
                >
                  Load Contract Details
                </LightButton>

                <LightButton
                  onClick={() => {
                    adminStore.syncStats();
                  }}
                >
                  Sync Stats
                </LightButton>
              </div>
            </CardContainer.Body>
          </CardContainer>
        </PiaWidget>
      </React.Fragment>
    );
  }
}

AdminTools.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(withTheme(observer(AdminTools)));
