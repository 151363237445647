import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { CardContainer, LightButton } from "best-common-react";
import AdditionalOptions from "./AdditionalOptions";
import { CompConstants } from "../../constants/CompConstants";
import PlayerListCriteriaTable from "../players/PlayerListCriteriaTable";
import { ReportConstants } from "../../constants/ReportConstants";
import TimeFrames from "./TimeFrames";
import TitleCard from "../widgets/TitleCard";

class IndustryComp extends React.Component {
  render() {
    let { compStore, trackingHelper } = this.props.rootStore;
    return (
      <React.Fragment>
        <CardContainer>
          <CardContainer.Body>
            <div className="container-fluid">
              <div className="row mx-0">
                <TimeFrames tab={CompConstants.TABS.INDUSTRY} />
                <AdditionalOptions tab={CompConstants.TABS.INDUSTRY} />
              </div>
              <div className="row mx-0 my-4 p-0">
                <div className="col-12 p-0">
                  <TitleCard>
                    <span>Report Thresholds</span>
                  </TitleCard>
                </div>
              </div>
              <PlayerListCriteriaTable thresholds={compStore.comp.reportCriteria.compReportCriteriaThresholdBeans} />
            </div>
          </CardContainer.Body>
          <CardContainer.Footer>
            <div className="header-item-row">
              <LightButton
                onClick={() => {
                  trackingHelper.trackClickAndRunComp(
                    ReportConstants.REPORT_INDUSTRY,
                    "Run Industry Rank Report Click"
                  );
                }}
              >
                Run Industry Rank Report
              </LightButton>
              <LightButton
                onClick={() => {
                  trackingHelper.trackClickAndRunComp(
                    ReportConstants.REPORT_COMPPERF,
                    "Run Composite Performance Report Click"
                  );
                }}
              >
                Run Composite Performance Report
              </LightButton>
            </div>
          </CardContainer.Footer>
        </CardContainer>
      </React.Fragment>
    );
  }
}

IndustryComp.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(IndustryComp));
