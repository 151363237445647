import React from "react";
import { inject, observer } from "mobx-react";
import IdleTimer from "react-idle-timer";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { BestLabel, Collapse, NavigationLogo } from "best-common-react";
import PropTypes from "prop-types";
import { AlertConstants } from "../../constants/AlertConstants";
import NavigationLinks from "./NavigationLinks";
import PiaHeader from "../header/PiaHeader";

class PiaNavigation extends React.Component {
  render() {
    const { alertStore, authStore, compStore, lookupStore, playerStore, statStore, routerStore } = this.props.rootStore;
    let builds = [{ name: "frontend", build: process.env.REACT_APP_VERSION || "" }];

    lookupStore.getBackendVersion().then(response => {
      if (response.hasOwnProperty("git") && response.git.hasOwnProperty("build")) {
        builds.push({ name: "backend", build: response.git.build.version });
      }
    });

    return (
      <React.Fragment>
        {authStore.loggedIn && !routerStore.isLoadingReportPage ? (
          <div className="page-container">
            <div className="nav-container">
              <div className="logo-container">
                <NavigationLogo
                  logo={"/images/pia-logo@2x.png"}
                  styles={{ height: "44px", mobileHeight: "44px" }}
                  builds={builds}
                />
              </div>
              <NavigationLinks signOut="Sign Out" />

              <div className="comp-info-container">
                <Collapse
                  title="Current Comparison"
                  iconAfterTitle={true}
                  expandIcon="fa-chevron-down"
                  collapseIcon="fa-chevron-up"
                >
                  <div className="row col-12 info-section">
                    <div className="row col-12 comparison-label">
                      <BestLabel>Comparison Name:</BestLabel>
                    </div>
                    <div className="row col-12 comparison-text">
                      <a
                        href="/#"
                        onClick={event => {
                          event.preventDefault();
                          compStore.openComp(compStore.comp.comparisonId);
                        }}
                      >
                        {compStore.comp.comparisonname}
                      </a>
                    </div>
                  </div>
                  <div className="row col-12 info-section">
                    <div className="row col-12 comparison-label">
                      <BestLabel>Focus Player Name:</BestLabel>
                    </div>
                    <div className="row col-12 comparison-text">
                      {compStore.comp.plMasters.length > 0 && compStore.comp.plMasters[0].focusplayerId ? (
                        <a
                          href="/#"
                          onClick={event => {
                            event.preventDefault();
                            playerStore.openPlayerProfile(compStore.comp.plMasters[0].focusplayerId);
                          }}
                        >
                          {compStore.comp.plMasters[0].focusplayername &&
                          "-" !== compStore.comp.plMasters[0].focusplayername
                            ? compStore.comp.plMasters[0].focusplayername
                            : "No Focus Player"}
                        </a>
                      ) : compStore.comp.plMasters[0].focusplayername &&
                        "-" !== compStore.comp.plMasters[0].focusplayername ? (
                        compStore.comp.plMasters[0].focusplayername
                      ) : (
                        "No Focus Player"
                      )}
                    </div>
                  </div>
                  <div className="row col-12 info-section">
                    <div className="row col-12 comparison-label">
                      <BestLabel>Player List: </BestLabel>
                    </div>
                    <div className="row col-12 comparison-text">
                      <a
                        href="/#"
                        onClick={event => {
                          event.preventDefault();
                          playerStore.openPlayerList(
                            compStore.comp.plMasters[0].playerlistId || 0,
                            true,
                            false,
                            compStore.comp.plMasters[0].plDetails
                          );
                        }}
                      >
                        {compStore.comp.plMasters[0].plDetails.length +
                          (compStore.comp.plMasters[0].plDetails.length == 1 ? " Player" : " Players")}
                      </a>
                    </div>
                  </div>
                  <div className="row col-12 info-section">
                    <div className="row col-12 comparison-label">
                      <BestLabel>Stat List: </BestLabel>
                    </div>

                    <div className="row col-12 comparison-text">
                      <a
                        href="/#"
                        onClick={event => {
                          event.preventDefault();
                          statStore.openStatList(
                            compStore.comp.slMasters[0].statlistId || 0,
                            true,
                            compStore.comp.slMasters[0].slDetails
                          );
                        }}
                      >
                        {compStore.comp.slMasters[0].slDetails.length +
                          (compStore.comp.slMasters[0].slDetails.length == 1 ? " Statistic" : " Statistics")}
                      </a>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
            <div
              className="content-container"
              onDragOver={event => {
                event.dataTransfer.dropEffect = "none";
              }}
            >
              <IdleTimer
                ref={ref => {
                  authStore.IdleTimer = ref;
                }}
                events={["keydown", "mousedown", "touchstart", "MSPointerDown"]}
                timeout={60000 * 239} // 3 hours 59 minutes
                onIdle={() => {
                  // Mark user as idle
                  authStore.isIdle = true;
                  // Show logout warning
                  alertStore.addAlert({
                    type: AlertConstants.TYPES.WARNING_PERSIST,
                    text: "You will be logged out due to inactivity in 1 minute."
                  });
                  // Set timeout for 1 minute
                  setTimeout(() => {
                    // Log out and show message if user is still idle
                    if (authStore.loggedIn && authStore.isIdle) {
                      alertStore.addAlert({
                        type: AlertConstants.TYPES.DANGER_PERSIST,
                        text: "You have been logged out due to inactivity."
                      });
                      authStore.logout();
                    }
                  }, 60000);
                }}
                onAction={() => {
                  authStore.isIdle = false;
                }}
              />
              <PiaHeader />
              <div className="child-container">{this.props.children}</div>
            </div>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

PiaNavigation.propTypes = {
  rootStore: PropTypes.object.isRequired,
  children: PropTypes.any
};

export default inject("rootStore")(withRouter(withTheme(observer(PiaNavigation))));
