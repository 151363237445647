import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import _ from "lodash";
import { BestSelect, CardContainer, Icon, PrimaryButton } from "best-common-react";
import { FormSelectStyles } from "../../Theme";
import PiaTable from "../widgets/PiaTable";
import { PlayerConstants } from "../../constants/PlayerConstants";
import { StatConstants } from "../../constants/StatConstants";
import SelectTimeFrame from "./SelectTimeFrame";
import StatLine from "./StatLine";
import TitleCard from "../widgets/TitleCard";

class Statistics extends React.Component {
  getStatSplitTypeOptions() {
    return [
      { value: PlayerConstants.STAT_SPLIT_TYPES.CAREER, label: PlayerConstants.STAT_SPLIT_TYPES.CAREER },
      { value: PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON, label: PlayerConstants.STAT_SPLIT_TYPES.POST_SEASON },
      { value: PlayerConstants.STAT_SPLIT_TYPES.MN_CAREER, label: PlayerConstants.STAT_SPLIT_TYPES.MN_CAREER },
      {
        value: PlayerConstants.STAT_SPLIT_TYPES.MN_POST_SEASON,
        label: PlayerConstants.STAT_SPLIT_TYPES.MN_POST_SEASON
      },
      { value: PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG, label: PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG },
      { value: PlayerConstants.STAT_SPLIT_TYPES.SPLITS, label: PlayerConstants.STAT_SPLIT_TYPES.SPLITS }
    ];
  }

  getYearOptions(playerStore) {
    let position = playerStore.player.position || "";
    let stats = [];
    let years = [];

    if ("RP" === position.toUpperCase() || "SP" === position.toUpperCase()) {
      stats = playerStore.player.playerStatsBean.pitchingCareer || [];
    } else {
      stats = playerStore.player.playerStatsBean.battingCareer || [];
    }

    stats.forEach(stat => {
      years.unshift({
        value: stat.year,
        label: stat.year + ""
      });
    });

    return years;
  }

  getSeasonSplitOptions() {
    return [
      { label: "Regular Season", value: "Regular Season" },
      {
        label: "Regular and Post Season",
        value: "Regular Season|Wild Card Game|Division Series|League Championship Series|World Series"
      },
      { label: "Post Season", value: "Wild Card Game|Division Series|League Championship Series|World Series" },
      { label: "Division Series", value: "Division Series" },
      { label: "League Championship Series", value: "League Championship Series" },
      { label: "World Series", value: "World Series" },
      { label: "All-Star Game", value: "All-Star Game" }
    ];
  }

  renderGameLogHeader(lookupStore, playerStore) {
    let years = this.getYearOptions(playerStore);
    let seasonSplits = this.getSeasonSplitOptions();

    return (
      <div className="row mx-0 mb-2">
        {playerStore.player.playerStatsBean.pitchByPitch ? (
          <div className="row m-0 item-row h-inherit">
            <PrimaryButton
              onClick={() => {
                playerStore.player.playerStatsBean.pitchByPitch = false;
                playerStore.player.playerStatsBean.gameDate = null;
              }}
            >
              Return to Game Log
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                playerStore.player.playerStatsBean.pitchByPitch = false;
              }}
            >
              Return to Game
            </PrimaryButton>
          </div>
        ) : playerStore.player.playerStatsBean.gameDate ? (
          <div className="row m-0 item-row h-inherit">
            <PrimaryButton
              onClick={() => {
                playerStore.player.playerStatsBean.gameDate = null;
              }}
            >
              Return to Game Log
            </PrimaryButton>
          </div>
        ) : (
          <React.Fragment>
            <div className="col-2 pl-0">
              <BestSelect
                name="ppSelectedSeason"
                value={lookupStore.getDropdownItem(years, playerStore.player.playerStatsBean.selectedSeason)}
                onChange={option => {
                  playerStore.player.playerStatsBean.selectedSeason = option.value;
                }}
                options={years}
                portal={document.body}
                styles={FormSelectStyles}
              />
            </div>

            <div className="col-3 pl-0">
              <BestSelect
                name="ppGameLogSplit"
                value={lookupStore.getDropdownItem(seasonSplits, playerStore.gameLogSplit)}
                onChange={option => {
                  playerStore.gameLogSplit = option.value;
                }}
                options={seasonSplits}
                portal={document.body}
                styles={FormSelectStyles}
              />
            </div>

            <PrimaryButton
              onClick={() => {
                playerStore.getGameLog();
              }}
              disabled={!playerStore.player.playerStatsBean.selectedSeason || !playerStore.gameLogSplit}
            >
              View Game Log
            </PrimaryButton>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderPitchByPitchTable(playerStore) {
    let cols = [
      { key: "inning", name: "Inn", width: 40 },
      { key: "paNumber", name: "PA#", width: 40 },
      { key: "pitcherName", name: "Pitcher", width: 250 },
      { key: "score", name: "Score", width: 60 },
      { key: "outs", name: "Outs", width: 50 },
      { key: "runners", name: "Runners", width: 80 },
      { key: "pitch", name: "Pitch", width: 50 },
      { key: "count", name: "Count", width: 55 },
      { key: "pitchType", name: "Pitch Type", width: 150 },
      { key: "speed", name: "Speed (mph)", width: 90 },
      { key: "pitchBreak", name: "Break", width: 50 },
      { key: "pitchFx", name: "Pitch Fx", width: 75 },
      { key: "result", name: "Result" }
    ];
    let data = [];
    let title = "Stats - " + playerStore.player.playerStatsBean.gameDate;
    let exportProp = "qgameLogBattingStatsPitchByPitch";

    if (playerStore.player.playerStatsBean.gameLogPitchByPitchBatting) {
      title = "Batting " + title;
      data = playerStore.player.playerStatsBean.gameLogPitchByPitchBatting;
    } else if (playerStore.player.playerStatsBean.gameLogPitchByPitchPitching) {
      title = "Pitching " + title;
      cols[1] = { key: "batterNumber", name: "Batter#", width: 80 };
      cols[2] = { key: "batterName", name: "Batter", width: 250 };
      data = playerStore.player.playerStatsBean.gameLogPitchByPitchPitching;
      exportProp = "qgameLogPitchingStatsPitchByPitch";
    }

    return (
      <React.Fragment>
        <div className="row mx-0 mb-2">
          <div className="col-12 p-0">
            <TitleCard>
              <div className="d-flex flex-fill justify-content-between align-items-center">
                <span>{title}</span>
                <div className="item-row h-inherit col-3 p-0">
                  <div className="item card-item-separator"></div>
                  <div className="item">
                    <Icon
                      iconName="fa-file-download"
                      onClick={() => {
                        playerStore.exportProp(playerStore.player.playerStatsBean, exportProp);
                      }}
                      styles={{ size: "1.6em" }}
                    />
                  </div>
                </div>
              </div>
            </TitleCard>
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-12 p-0">
            <PiaTable
              data={data}
              columns={cols}
              height={playerStore.calculateTableHeight(data.length, 336)}
              minColumnWidth={40}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderGameTable(playerStore) {
    let cols = [
      { key: "inning", name: "Inn", width: 40 },
      { key: "paNumber", name: "PA#", width: 40 },
      { key: "pitcherName", name: "Pitcher", width: 250 },
      { key: "score", name: "Score", width: 60 },
      { key: "outs", name: "Outs", width: 50 },
      { key: "runners", name: "Runners", width: 80 },
      { key: "result", name: "Result", width: 150 },
      { key: "playByPlay", name: "Play by Play" }
    ];
    let data = [];
    let title = "Stats - " + playerStore.player.playerStatsBean.gameDate;
    let exportProp = "qgameLogBattingStats";
    let batterPitcher = "batter";
    let loadPitchByPitch = () => {
      playerStore.getGameLogPitchByPitchBatter(playerStore.player.playerStatsBean.gameId);
    };

    if (playerStore.player.playerStatsBean.gameLogBattingStatsBeans) {
      title = "Batting " + title;
      data = playerStore.player.playerStatsBean.gameLogBattingStatsBeans;
    } else if (playerStore.player.playerStatsBean.gameLogPitchingStatsBeans) {
      title = "Pitching " + title;
      cols[1] = { key: "batterNumber", name: "Batter#", width: 80 };
      cols[2] = { key: "batterName", name: "Batter", width: 250 };
      data = playerStore.player.playerStatsBean.gameLogPitchingStatsBeans;
      exportProp = "qgameLogPitchingStats";
      batterPitcher = "pitcher";
      loadPitchByPitch = () => {
        playerStore.getGameLogPitchByPitchPitcher(playerStore.player.playerStatsBean.gameId);
      };
    }

    return (
      <React.Fragment>
        <div className="row mx-0 mb-2">
          <div className="col-12 p-0">
            <TitleCard>
              <div className="d-flex flex-fill justify-content-between align-items-center">
                <div>
                  <span>{title}</span>&nbsp;-&nbsp;
                  <span>
                    Click{" "}
                    <a
                      href="/#"
                      onClick={event => {
                        event.preventDefault();
                        loadPitchByPitch();
                      }}
                    >
                      here
                    </a>{" "}
                    for detailed Pitch by Pitch stats for this game for this {batterPitcher}
                  </span>
                </div>

                <div className="item-row h-inherit col-3 p-0">
                  <div className="item card-item-separator"></div>
                  <div className="item">
                    <Icon
                      iconName="fa-file-download"
                      onClick={() => {
                        playerStore.exportProp(playerStore.player.playerStatsBean, exportProp);
                      }}
                      styles={{ size: "1.6em" }}
                    />
                  </div>
                </div>
              </div>
            </TitleCard>
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-12 p-0">
            <PiaTable
              data={data}
              columns={cols}
              height={playerStore.calculateTableHeight(data.length, 336)}
              minColumnWidth={40}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  getStatSplitOptions(lookupStore, playerStore) {
    let splits = lookupStore.getCollection("splits");
    return _.filter(
      splits,
      split => playerStore.player.pitcher || ("Outcome (Pitching)" !== split.splitType && "Role" !== split.splitType)
    );
  }

  renderSplits(lookupStore, playerStore) {
    let statSplits = this.getStatSplitOptions(lookupStore, playerStore);
    let years = this.getYearOptions(playerStore);

    return (
      <React.Fragment>
        <div className="row mx-0 mb-2">
          <div className="col-12 p-0">
            <TitleCard>
              <span>Select Split(s) and Timeframe</span>
            </TitleCard>
          </div>
        </div>
        <div className="row mx-0 mb-2">
          <div className="col-6 pl-0">
            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <BestSelect
                  name="statsplit1"
                  value={lookupStore.getDropdownItem(
                    statSplits,
                    playerStore.statSplitCriteria.statsplit1,
                    "splitType",
                    "splitType"
                  )}
                  onChange={option => {
                    playerStore.statSplitCriteria.statsplit1 = option.splitType;
                  }}
                  options={statSplits}
                  getOptionValue={option => option.splitType}
                  getOptionLabel={option => option.splitType}
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>

            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <BestSelect
                  name="statsplit2"
                  value={lookupStore.getDropdownItem(
                    statSplits,
                    playerStore.statSplitCriteria.statsplit2,
                    "splitType",
                    "splitType"
                  )}
                  onChange={option => {
                    playerStore.statSplitCriteria.statsplit2 = option.splitType;
                  }}
                  options={statSplits}
                  getOptionValue={option => option.splitType}
                  getOptionLabel={option => option.splitType}
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>

            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <BestSelect
                  name="statsplit3"
                  value={lookupStore.getDropdownItem(
                    statSplits,
                    playerStore.statSplitCriteria.statsplit3,
                    "splitType",
                    "splitType"
                  )}
                  onChange={option => {
                    playerStore.statSplitCriteria.statsplit3 = option.splitType;
                  }}
                  options={statSplits}
                  getOptionValue={option => option.splitType}
                  getOptionLabel={option => option.splitType}
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>

            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <BestSelect
                  name="statsplit4"
                  value={lookupStore.getDropdownItem(
                    statSplits,
                    playerStore.statSplitCriteria.statsplit4,
                    "splitType",
                    "splitType"
                  )}
                  onChange={option => {
                    playerStore.statSplitCriteria.statsplit4 = option.splitType;
                  }}
                  options={statSplits}
                  getOptionValue={option => option.splitType}
                  getOptionLabel={option => option.splitType}
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>

            <div className="row mx-0 mb-2">
              <div className="col-12 p-0">
                <BestSelect
                  name="statsplit5"
                  value={lookupStore.getDropdownItem(
                    statSplits,
                    playerStore.statSplitCriteria.statsplit5,
                    "splitType",
                    "splitType"
                  )}
                  onChange={option => {
                    playerStore.statSplitCriteria.statsplit5 = option.splitType;
                  }}
                  options={statSplits}
                  getOptionValue={option => option.splitType}
                  getOptionLabel={option => option.splitType}
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>
            </div>
          </div>
          <div className="col-6 p-0">
            <SelectTimeFrame statSplitCriteria={playerStore.statSplitCriteria} years={years} />
          </div>
        </div>

        <div className="item-row h-inherit mb-4">
          <PrimaryButton
            onClick={() => {
              playerStore.getSplitStatLines();
            }}
          >
            Run Splits Report
          </PrimaryButton>
        </div>
      </React.Fragment>
    );
  }

  renderStatLines(playerStore) {
    return (
      <React.Fragment>
        {playerStore.player.pitcher ? (
          <React.Fragment>
            <StatLine type={StatConstants.TYPES.PITCHING} />
            <div className="row mt-4"></div>
          </React.Fragment>
        ) : (
          ""
        )}

        <StatLine type={StatConstants.TYPES.BATTING} />

        <div className="row mt-4"></div>

        <StatLine type={StatConstants.TYPES.FIELDING} />

        {!playerStore.player.pitcher ? (
          <React.Fragment>
            <div className="row mt-4"></div>
            <StatLine type={StatConstants.TYPES.PITCHING} />
          </React.Fragment>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }

  render() {
    let { lookupStore, playerStore } = this.props.rootStore;
    let statSplitTypes = this.getStatSplitTypeOptions();
    return (
      <CardContainer>
        <CardContainer.Body>
          <div className="container-fluid">
            <div className="row mx-0 mt-2 mb-4">
              <div className="col-4 pl-0">
                <BestSelect
                  name="ppStatLevel"
                  value={lookupStore.getDropdownItem(statSplitTypes, playerStore.playerStatSplitType)}
                  onChange={option => {
                    playerStore.player.playerStatsBean.pitchByPitch = false;
                    playerStore.player.playerStatsBean.gameDate = null;
                    playerStore.playerStatSplitType = option.value;
                  }}
                  options={statSplitTypes}
                  portal={document.body}
                  styles={FormSelectStyles}
                />
              </div>

              {PlayerConstants.STAT_SPLIT_TYPES.GAME_LOG === playerStore.playerStatSplitType ? (
                <div className="col-8 p-0">{this.renderGameLogHeader(lookupStore, playerStore)}</div>
              ) : (
                ""
              )}
            </div>

            {PlayerConstants.STAT_SPLIT_TYPES.SPLITS === playerStore.playerStatSplitType
              ? this.renderSplits(lookupStore, playerStore)
              : ""}

            {playerStore.player.playerStatsBean.pitchByPitch
              ? this.renderPitchByPitchTable(playerStore)
              : playerStore.player.playerStatsBean.gameDate
              ? this.renderGameTable(playerStore)
              : this.renderStatLines(playerStore)}
          </div>
        </CardContainer.Body>
      </CardContainer>
    );
  }
}

Statistics.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(Statistics));
