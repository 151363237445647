import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { DangerButton, PrimaryButton } from "best-common-react";
import HeaderInput from "./HeaderInput";

class EditCompHeaderDetails extends React.Component {
  render() {
    let { compStore } = this.props.rootStore;
    return (
      <div className="d-flex flex-fill justify-content-between align-items-center h-100">
        <div className="pl-3">
          <HeaderInput
            value={compStore.comp.comparisonname}
            onChange={event => {
              compStore.comp.comparisonname = event.target.value;
            }}
          />
        </div>
        <div className="col-5">
          <div className="item-row">
            <PrimaryButton
              className="col-2"
              onClick={() => {
                compStore.saveComp();
              }}
            >
              Save
            </PrimaryButton>
            <DangerButton
              className="col-2"
              onClick={() => {
                compStore.deleteComp();
              }}
              disabled={!compStore.comp.comparisonId}
            >
              Delete
            </DangerButton>
          </div>
        </div>
      </div>
    );
  }
}

EditCompHeaderDetails.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(EditCompHeaderDetails));
