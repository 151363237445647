import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

const CountContainer = styled.span`
  display: flex;
  justify-content: space-between;
  height: 100%;
  font-style: italic;
  color: ${props => props.theme["dark-grey"]};

  .result-count-number {
    font-weight: bold;
  }
`;

class ResultCount extends React.Component {
  render() {
    let { children, type, typePlural, value } = this.props;
    const count = value || 0;
    const plural = typePlural || type + "s";
    return (
      <CountContainer>
        <span>
          Reporting on <span className="result-count-number">{count + " "}</span>
          {1 === count ? type : plural}
        </span>
        <span>{children}</span>
      </CountContainer>
    );
  }
}

ResultCount.defaultProps = {
  value: 0,
  typePlural: undefined
};

ResultCount.propTypes = {
  value: PropTypes.number,
  type: PropTypes.string.isRequired,
  typePlural: PropTypes.string,
  children: PropTypes.any
};

export default inject("rootStore")(withTheme(observer(ResultCount)));
