import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import NewsFeedModal from "../../components/news-feeds/NewsFeedModal";
import NewsFeeds from "../../components/news-feeds/NewsFeeds";
import { RouteConstants } from "../../constants/RouteConstants";

class NewsFeedContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.MY_NEWS_FEED.title);
  }

  render() {
    return (
      <div className="container-fluid">
        <NewsFeedModal />
        <NewsFeeds />
      </div>
    );
  }
}

NewsFeedContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(NewsFeedContainer));
