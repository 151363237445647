import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import CompFinder from "../../components/comp-finder/CompFinder";
import LeagueLeaders from "../../components/home-plate/LeagueLeaders";
import QuickOneOnOne from "../../components/home-plate/QuickOneOnOne";
import StandardPlayerLists from "../../components/home-plate/StandardPlayerLists";
import { RouteConstants } from "../../constants/RouteConstants";

class HomePlateContainer extends Component {
  componentDidMount() {
    const { trackingHelper } = this.props.rootStore;
    trackingHelper.trackPageView(RouteConstants.HOME_PLATE.title);
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row m-0">
          <QuickOneOnOne />
        </div>
        <div className="row m-0">
          <CompFinder className="simple-footer col-12" homePlate={true} />
          <StandardPlayerLists className="table-container col-12" />
        </div>
        <div className="row m-0">
          <LeagueLeaders className="simple-footer col-12 pb-4" />
        </div>
      </div>
    );
  }
}

HomePlateContainer.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(HomePlateContainer));
