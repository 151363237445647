import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { DataTable } from "best-common-react";
import Theme from "../../Theme";

class PiaTable extends Component {
  render() {
    const { ...rest } = this.props;
    const rowHeight = Theme.sizeRowHeightNum * 1;
    const headerHeight = Theme.sizeHeaderHeightNum * 1;
    return <DataTable headerRowHeight={headerHeight} rowHeight={rowHeight} {...rest} />;
  }
}

export default inject("rootStore")(observer(PiaTable));
