import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { BestSelect, PrimaryButton } from "best-common-react";
import { FormSelectStyles } from "../../Theme";
import IconTip from "../widgets/IconTip";

class NewsFeedHeaderDetails extends React.Component {
  getTooltipHtml() {
    return (
      <p>
        My News Feeds Usage
        <br />
        <br />
        Use the controls on this page to customize your news feeds.
        <br />
        <br />
        The Add News Feeds widget allows you to enter the URL <br />
        for any feed available on the internet.
        <br />
        You can give it a custom name by which it will be <br />
        referenced when you are viewing news stories
        <br />
        associated with that feed - this name should be <br />
        something which will help you identify the <br />
        source of the news story.
        <br />
        <br />
        The Suggested News Feeds widget allows you to add <br />
        some of the most popular news feeds automatically, <br />
        without having to find the URL.
        <br />
        <br />
        The News widget lists all news stories from <br />
        all the feeds which you have added.
        <br />
        They are sorted by date with the most recent on top.
        <br />
        Clicking on a story opens that story in a new browser window.
        <br />
        <br />
        You can remove feeds from the list by using the <br />
        Remove Feed control at the top right of the widget.
      </p>
    );
  }
  render() {
    let { lookupStore, newsFeedStore } = this.props.rootStore;
    return (
      <div className="row m-0 align-items-center h-100 p-0">
        <div className="col-7">
          <h5>
            News Feeds{" "}
            <IconTip
              toolTipClassName="news-header-tool-tip"
              iconName="fa-question-circle"
              place="bottom"
              offset={{ right: 100 }}
              tipHtml={this.getTooltipHtml()}
            />
          </h5>
        </div>
        <div className="col-5">
          <div className="row m-0 align-items-center justify-content-end">
            <div className="col-9 pr-0">
              <BestSelect
                name="nfDisplayFeedId"
                value={lookupStore.getDropdownItem(newsFeedStore.feedOptions, newsFeedStore.displayFeedId)}
                onChange={option => {
                  newsFeedStore.displayFeedId = option.value;
                }}
                options={newsFeedStore.feedOptions}
                styles={FormSelectStyles}
              />
            </div>
            <div className="col-3 d-flex pr-0">
              <PrimaryButton
                className="flex-fill"
                onClick={() => {
                  newsFeedStore.openModal = true;
                }}
              >
                Add/Delete
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

NewsFeedHeaderDetails.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(NewsFeedHeaderDetails));
