import React from "react";
import PropTypes from "prop-types";
import { PriceFormatter } from "best-common-react";

class CurrencyFormatter extends React.Component {
  formatValue(applicable, value) {
    if (applicable) {
      return <PriceFormatter value={value} />;
    } else {
      return "N/A";
    }
  }

  render() {
    const { applicable, value } = this.props;
    return this.formatValue(applicable, value);
  }
}

CurrencyFormatter.defaultProps = {
  applicable: true
};

CurrencyFormatter.propTypes = {
  value: PropTypes.any,
  applicable: PropTypes.bool
};

export default CurrencyFormatter;
